import React, {useState} from "react";
import {Button, Modal} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/pro-light-svg-icons";
import {Notification} from "../../order_form/components/AssetsManager";
import {DeletedProduct, Product, ProductFeed} from "../types";
import OrderFormFacebookProducts from "../../order_form/containers/OrderFormFacebookProducts";
import styled from "styled-components";
import SmallProductSquare from "../products/SmallProductSquare";

type ProductsProps = {
    value?: ProductFeed,
    onChange: (ProductFeed) => void,
    errors: Array<string>,
    min?: number,
    max?: number,
    multipleOf?: number,
};

const Products = ({
    value = null,
    errors = [],
    min,
    max,
    multipleOf,
    onChange,
}: ProductsProps) => {
    const [selectedFeed, setSelectedFeed] = useState<ProductFeed | undefined>(value);
    const [facebookFeedModal, setFacebookFeedModal] = useState<boolean>(false);

    const products = selectedFeed?.products || [];
    const deletedProducts = selectedFeed?.deleted_products || [];
    const everyProduct = products.concat(deletedProducts);
    const handleProductDelete = (product) => {
        setSelectedFeed({
            ...selectedFeed,
            products: products.filter(prod => prod.id !== product.id),
        });
    };
    const handleModalHide = () => {
        setSelectedFeed(value);
        setFacebookFeedModal(false)
    };

    const productCount = value?.products?.length || 0;

    const isValid = isValidProductSelection(products, min, max, multipleOf);
    const alertMessage = productMessage(products, min, max, multipleOf);

    return <div className='mx-auto text-center'>
        <Button
            variant='outline-secondary'
            className={`py-3 mb-2 position-relative ${errors.length > 0 ? "border-danger" : ""}`}
            onClick={(_) => setFacebookFeedModal(true)}
        >
            <FontAwesomeIcon icon={faPlus} size='4x'/>
            <h6 className='mt-1'>Attach Products</h6>
            <Notification number={productCount}/>
        </Button>
        <Modal
            size='xl'
            show={facebookFeedModal}
            onHide={handleModalHide}
        >
            <Modal.Header closeButton>
                Select products you want to use.
            </Modal.Header>
            <Modal.Body>
                <Alert isValid={isValid}>
                    {alertMessage}
                </Alert>
                <ProductRow products={everyProduct} onDelete={handleProductDelete}/>
                <OrderFormFacebookProducts
                    value={selectedFeed}
                    onChange={(value) => setSelectedFeed(value)}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button
                    className='float-right'
                    variant='primary'
                    disabled={!isValid}
                    onClick={(_) => {
                        onChange(selectedFeed);
                        setFacebookFeedModal(false);
                    }}
                >
                    Update
                </Button>
            </Modal.Footer>
        </Modal>
    </div>;
};

export default Products

const isValidProductSelection = (products: Array<Product>, min?: number, max?: number, multipleOf?: number): boolean => {
    const productCount = products.length;

    if (min !== undefined && productCount < min) {
        return false;
    }

    if (max !== undefined && productCount > max) {
        return false;
    }

    if (multipleOf !== undefined && productCount % multipleOf !== 0) {
        return false;
    }

    return true;
}

const productMessage = (products: Array<Product>, min?: number, max?: number, multipleOf?: number): string => {
    if (products.length > 0) {
        return `You have selected ${products.length} product${products.length !== 1 ? 's' : ''}.`;
    }

    if (min === max) {
        return `Please select ${min} product${min !== 1 ? 's' : ''}.`;
    }

    if (multipleOf === undefined) {
        return `Please select ${min}-${max} products.`;
    }

    return `Please select ${min}-${max} products in multiples of ${multipleOf}.`
}


type AlertProps = {
    isValid: boolean,
};

const Alert = ({isValid, children}: React.PropsWithChildren<AlertProps>): JSX.Element =>
    <p className={`alert ${isValid ? "border-success" : "border-danger"}`}>
        {children}
    </p>


const ProductRowContainer = styled.div`
  display: flex;
  overflow-x: scroll;
  max-height: 10rem;
`;

type ProductRowProps = {
    products: Array<Product | DeletedProduct>,
    onDelete: (Product) => void,
};

const ProductRow = ({products, onDelete}: ProductRowProps) => {
    if (products.length === 0) {
        return <></>;
    }

    return <ProductRowContainer>
        {products.map(product =>
            <SmallProductSquare
                product={product}
                readOnly={product.hasOwnProperty('deleted_at')}
                onDelete={onDelete}
            />)}
    </ProductRowContainer>;
}