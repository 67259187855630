import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Form } from 'react-bootstrap';
import styled from 'styled-components';
import Select from 'react-select';
import { COUNTRY_OPTIONS } from './countries';
import { META_VERTICALS } from './verticals';
import CheckboxOption from '../../../order_form/components/CheckboxOption';
import {
  MPA_ONBOARDING_COMPLETED,
  MPA_ONBOARDING_SUBMITTED,
  MPA_ONBOARDING_WAITING_FOR_SUPPPLIER,
} from '../../../../lib/constants';
import { useSelector } from 'react-redux';
import { selectSession } from 'features/session/sessionSlice';

const Container = styled.div`
  font-size: 14px;
  .alert svg {
    margin-right: 8px;
  }
`;
const CheckboxContainer = styled.div`
  display: flex;
  gap: 12px;
`;
const ReadOnlyValue = styled.div`
  font-size: 16px;
  font-weight: 300;
  line-height: 25.2px;
  text-align: left;
  font-style: italic;
  color: #777785;
`;
const StyledContainer = styled(Container)`
  input[type='text']::placeholder {
    font-size: 14px;
  }
`;
const StyledLabel = styled(Form.Label)`
  color: #000;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 115%;
  margin-bottom: 0.5rem;
`;
export const statusMap = {
  'not-started': 'Not Started',
  new: 'Not Started',
  submitted: 'In Progress',
  failed: 'Unsuccessful',
  completed: 'Completed',
};

export const OnboardingForm = ({
  errors,
  onSubmit,
  previousFormFields,
  readOnly,
  supplierId,
  onBoardingStatus,
  disabled,
}) => {
  const session = useSelector(selectSession);
  const [organisation] = useState(session.user.active_organisation);
  const adSettings = organisation?.settings?.ad_settings;
  const [formData, setFormData] = useState(previousFormFields);

  const computePageHandlers = () => {
    if (
      [MPA_ONBOARDING_COMPLETED, MPA_ONBOARDING_SUBMITTED, MPA_ONBOARDING_WAITING_FOR_SUPPPLIER].includes(
        onBoardingStatus
      )
    ) {
      return previousFormFields.page_handler;
    } else {
      return adSettings?.page_handlers;
    }
  };

  useEffect(() => {
    setFormData({
      ...previousFormFields,
      page_handlers: computePageHandlers(),
    });
  }, [previousFormFields, supplierId]);

  const handleChange = (fieldName) => (event) => {
    const value = event.target.value;
    setFormData((prev) => ({ ...prev, [fieldName]: value }));
  };

  const handleChangeCountriesTargeted = (items) => {
    setFormData((prev) => ({ ...prev, countries_targeting: items.map((item) => item.value) }));
  };

  const handleChangeSupplierRegistrationCountry = (item) => {
    setFormData((prev) => ({ ...prev, registration_country: item.value }));
  };

  const handleChangeVertical = (item) => {
    setFormData((prev) => ({ ...prev, vertical: item.value }));
  };
  const handlePageHandlers = (value) => {
    if (formData?.page_handlers?.includes(value)) {
      setFormData((prev) => ({
        ...prev,
        page_handlers: formData.page_handlers.filter((item) => item !== value),
      }));
    } else {
      setFormData((prev) => ({ ...prev, page_handlers: [...prev.page_handlers, value] }));
    }
  };

  const handleClickSubmit = () => {
    onSubmit(formData);
  };
  /**
   *
   * @param {Array<string>} values - array of values
   * @param {Array<{value:string,label:string}>} arr - array of objects with value and label
   * @returns string - concatinated string of labels
   */

  const getLabel = (values, arr) => {
    if (!values) return '';
    return values
      .map((item) => {
        if (!arr) return '';
        return arr?.find((option) => option.value === item)?.label;
      })
      .join(', ');
  };
  return (
    <StyledContainer>
      <Form>
        <Form.Group>
          <StyledLabel>Page(s) to display ads</StyledLabel>
          {!readOnly ? (
            <CheckboxContainer>
              <CheckboxOption
                disabled={disabled}
                value='retailer'
                checked={formData?.page_handlers?.includes('retailer')}
                onChange={(v) => handlePageHandlers(v)}
                label='Retailer Page'
              />
              <CheckboxOption
                disabled={disabled}
                value='supplier'
                checked={formData?.page_handlers?.includes('supplier')}
                onChange={(v) => handlePageHandlers(v)}
                label='Supplier Page'
              />
            </CheckboxContainer>
          ) : (
            <ReadOnlyValue>
              {formData?.page_handlers
                ?.map((handler) => handler.charAt(0).toUpperCase() + handler.slice(1) + ' page')
                .join(', ')}
            </ReadOnlyValue>
          )}
        </Form.Group>
        <Form.Group className='mb-3' controlId='formSupplierWebsite'>
          <StyledLabel>Website</StyledLabel>
          <div className='mb-1'>
            This should be the location on the Retailer website where all Supplier products are displayed.
          </div>
          {!readOnly ? (
            <Form.Control
              className={`mt-2 ${errors?.website?.length > 0 ? 'is-invalid' : ''}`}
              type='text'
              disabled={disabled}
              placeholder='Example: https://www.retailer.com/supplier-products'
              value={formData.website || ''}
              onChange={handleChange('website')}
            />
          ) : (
            <ReadOnlyValue>{formData.website}</ReadOnlyValue>
          )}
          <Form.Text className='text-muted'>
            {errors?.website?.length > 0 && <div className='text-danger'>{errors?.website[0]}</div>}
          </Form.Text>
        </Form.Group>

        <Form.Group className='mb-3' controlId='formCountriesTargeted'>
          <StyledLabel>Countries Targeted</StyledLabel>
          {!readOnly ? (
            <Select
              aria-invalid={errors?.countries_targeting?.length > 0}
              isMulti
              onChange={handleChangeCountriesTargeted}
              options={COUNTRY_OPTIONS}
              placeholder='Example: Australia, New Zealand, United Kingdom, United States of America'
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  ...(state.isDisabled ? { backgroundColor: '#eaecf4' } : {}),
                }),
              }}
              isDisabled={disabled}
              value={COUNTRY_OPTIONS.filter((option) => {
                return (formData.countries_targeting || []).includes(option.value);
              })}
            />
          ) : (
            <ReadOnlyValue>{getLabel(formData.countries_targeting, COUNTRY_OPTIONS)}</ReadOnlyValue>
          )}
          <Form.Text className='text-muted'>
            {errors?.countries_targeting?.length > 0 && (
              <div className='text-danger'>{errors?.countries_targeting[0]}</div>
            )}
          </Form.Text>
        </Form.Group>

        <Form.Group className='mb-3' controlId='formSupplierRegistrationCountry'>
          <StyledLabel>Supplier Registration Country</StyledLabel>
          {!readOnly ? (
            <Select
              aria-invalid={errors?.registration_country?.length > 0}
              onChange={handleChangeSupplierRegistrationCountry}
              options={COUNTRY_OPTIONS}
              placeholder='Example: Australia'
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  ...(state.isDisabled ? { backgroundColor: '#eaecf4' } : {}),
                }),
              }}
              isDisabled={disabled}
              value={COUNTRY_OPTIONS.find((option) => {
                return formData?.registration_country === option.value;
              })}
            />
          ) : (
            <ReadOnlyValue>{getLabel([formData.registration_country], COUNTRY_OPTIONS)}</ReadOnlyValue>
          )}
          <Form.Text className='text-muted'>
            {errors?.registration_country?.length > 0 && (
              <div className='text-danger'>{errors?.registration_country[0]}</div>
            )}
          </Form.Text>
        </Form.Group>

        <Form.Group className='mb-3' controlId='formVertical'>
          <StyledLabel>Vertical</StyledLabel>
          {!readOnly ? (
            <Select
              aria-invalid={errors?.vertical?.length > 0}
              onChange={handleChangeVertical}
              options={META_VERTICALS}
              placeholder='Example: Technology'
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  ...(state.isDisabled ? { backgroundColor: '#eaecf4' } : {}),
                }),
              }}
              isDisabled={disabled}
              value={META_VERTICALS.find((option) => {
                return formData?.vertical === option.value;
              })}
            />
          ) : (
            <ReadOnlyValue>{getLabel([formData.vertical], META_VERTICALS)}</ReadOnlyValue>
          )}
          <Form.Text className='text-muted'>
            {errors?.vertical?.length > 0 && <div className='text-danger'>{errors?.vertical[0]}</div>}
          </Form.Text>
        </Form.Group>

        {!readOnly && (
          <Button disabled={readOnly} onClick={handleClickSubmit} variant='primary'>
            Onboard Supplier
          </Button>
        )}
      </Form>
    </StyledContainer>
  );
};

OnboardingForm.propTypes = {
  errors: PropTypes.shape({
    website: PropTypes.arrayOf(PropTypes.string),
    countries_targeting: PropTypes.arrayOf(PropTypes.string),
    registration_country: PropTypes.arrayOf(PropTypes.string),
    vertical: PropTypes.arrayOf(PropTypes.string),
  }),
  onSubmit: PropTypes.func.isRequired,
  previousFormFields: PropTypes.shape({
    website: PropTypes.string,
    countries_targeting: PropTypes.string,
    registration_country: PropTypes.string,
    vertical: PropTypes.string,
    page_handler: PropTypes.arrayOf(PropTypes.string),
  }),
  readOnly: PropTypes.bool,
  supplierId: PropTypes.string,
  onBoardingStatus: PropTypes.string,
  disabled: PropTypes.bool,
};
