import { faCube } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

const PackageRowContainer = styled.article`
    border: 1px solid #D4CCFF;
    border-radius: 8px;
    padding: 32px;
    ${props => props.active &&
        'background-color: #F3F0FF;'}
    margin-bottom: 20px;
`

const HeaderContainer = styled.div`
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
    font-color: #3A3B4F;
    display: flex;
    align-items: center;
`

const ActionGroup = styled.span`
    margin-left: auto;
`

const InfoContainer = styled.div`
    border-radius: 8px;
    background-color: #F8F8F8;
    padding: 16px;
    ${props => props.active &&
        'background-color: #ffffff;'}
`

const InfoGroup = styled.span`
    margin-right: 20px;
    font-weight: 300;
    font-size: 18px;
    display: inline-flex;
    align-items: center;
`

const InfoLabel = styled.span`
    font-weight: 700;
    margin-right: 8px;
    font-size: 14px;
`

const PackageRow = ({ header, active = false, dates, group, minSpend, actions }) =>
    <PackageRowContainer active={active}>
        <HeaderContainer>
            <FontAwesomeIcon icon={faCube} className='mr-3' /> {header}
            <ActionGroup>
                {actions}
            </ActionGroup>
        </HeaderContainer>
        <InfoContainer active={active}>
            <InfoGroup><InfoLabel>Dates</InfoLabel>{dates ?? '—'}</InfoGroup>
            <InfoGroup><InfoLabel>Group</InfoLabel>{group ?? '—'}</InfoGroup>
            <InfoGroup><InfoLabel>Min. Spend</InfoLabel>{minSpend}</InfoGroup>
        </InfoContainer>
    </PackageRowContainer>

export default PackageRow
