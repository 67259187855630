import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cacheFetchRetailers, selectAllRetailers } from 'features/retailers/retailersSlice';

type Retailer = {
  id: string;
  name: string;
};

export const useRetailers = (): Array<Retailer> => {
  const dispatch = useDispatch();
  const stateRetailers = useSelector(selectAllRetailers);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(cacheFetchRetailers());
  }, [dispatch]);

  return stateRetailers.map(({ id, name }) => ({ id, name }));
};