import React, { useMemo, useState } from 'react';
import {
  Box,
  GridColDef,
  GridPaginationModel,
  Typography,
  DataGrid,
  GridRowSelectionModel,
  GridRowModel,
} from '@zitcha/component-library';
import { usePlanAdSets } from 'v2/lib/hooks/usePlanAdSets';
import { AdSet, AdSetStatusEnum, MediaSpace, PerPageEnum } from 'v2/lib/api/ad-management/model';
import { RenderSchedule, RenderActions, RenderAdSetActions } from './ViewPlanAdSetsTableCellRenderers';
import { useAdSetActions } from 'v2/lib/hooks/useAdSetActions';
import { PlanViewModals } from '../PlanViewModals';
import { ViewMode } from '../ViewModeType';
import { BudgetCell, DiscountCell, RateCell } from '../../NewPlanAdSetsCellRenders';
import { useUserOrganisation } from 'v2/lib/hooks/useUserOrganisation';
import { usePlan } from '../../PlanContext';

interface ViewPlanAdSetsTableProps {
  viewMode: ViewMode;
}

export const ViewPlanAdSetsTable = ({ viewMode }: ViewPlanAdSetsTableProps) => {
  const organisation = useUserOrganisation();
  const { id, updatedAdSets } = usePlan();

  // view ad set modal state
  const [viewAdSet, setViewAdSet] = useState<AdSet | null>(null);

  // view ad set modal state
  const [editAdSet, setEditAdSet] = useState<AdSet | null>(null);

  // single approve/reject modals state
  const [approveAdSetId, setApproveAdSetId] = useState<string | null>(null);
  const [rejectAdSetId, setRejectAdSetId] = useState<string | null>(null);

  // bulk action state
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  const [selectedAdSets, setSelectedAdSets] = useState<Array<AdSet>>([]);

  const {
    planAdSetsData,
    planAdSetsPagination,
    changePlanAdSetsPagination,
    isLoading,
  } = usePlanAdSets(id, { per_page: viewMode === ViewMode.EDITING ? PerPageEnum.NUMBER_5 : PerPageEnum.NUMBER_10 });

  const dataGridPaginationModel = {
    pageSize: planAdSetsPagination.perPage ?? PerPageEnum.NUMBER_15,
    page: (planAdSetsPagination.currentPage ?? 1) - 1, //-1 to match 0 vs 1 based indexing
  };

  const handlePaginationUpdate = (model: GridPaginationModel) => {
    changePlanAdSetsPagination(model.page + 1, model.pageSize as PerPageEnum);
  };

  const { approveAdSet, rejectAdSet } = useAdSetActions(planAdSetsPagination.currentPage, planAdSetsPagination.perPage);

  const columns: Array<GridColDef> = [
    {
      field: 'status',
      headerName: 'Ad set actions',
      renderCell: (params) => <RenderAdSetActions {...params} />,
      width: 120,
    },
    {
      field: 'Schedule',
      headerName: 'Schedule',
      width: 240,
      renderCell: (params) => <RenderSchedule bundleIds={params.row.bundleIds} />,
    },
    {
      field: 'mediaSpace',
      headerName: 'Media type',
      valueGetter: (mediaSpace: MediaSpace) => mediaSpace?.name,
    },
    { field: 'placement', headerName: 'Placement', width: 300, renderCell: (params) => params.value.name },
    { field: 'skuCodes', headerName: 'SKU code(s)' },
    {
      field: 'rate',
      headerName: 'Rate',
      minWidth: 130,
      editable: false,
      renderCell: (params) => <RateCell {...params} />,
    },
    {
      field: 'discount',
      headerName: 'Discount',
      minWidth: 70,
      editable: false,
      renderCell: (params) => <DiscountCell {...params} />,
    },
    {
      field: 'budget',
      headerName: 'Budget',
      minWidth: 130,
      editable: false,
      renderCell: (params) => <BudgetCell {...params} />,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: (params) =>
        <RenderActions
          adSet={params.row}
          viewAdSet={setViewAdSet}
          editAdSet={setEditAdSet}
          approveAdSet={setApproveAdSetId}
          rejectAdSet={setRejectAdSetId}
          viewMode={viewMode}
          canApproveOrReject={organisation?.is_retailer}
        />,
      width: 150,
    },
  ];

  const rows = useMemo(() => {
    return planAdSetsData?.map((adSet: AdSet) => {
      const updatedAdSet = updatedAdSets.find((updatedAdSet) => updatedAdSet.id === adSet.id) ?? {};
      return {
        id: adSet.id,
        ads: adSet.ads,
        mediaSpace: adSet.mediaSpace,
        audience: adSet.audience,
        endAt: adSet.endAt,
        name: adSet.name,
        plan: adSet.plan,
        startAt: adSet.startAt,
        status: adSet.status,
        skuCodes: adSet?.skus,
        bundleIds: adSet.bundleIds,
        placement: { name: adSet.bundleLocationNames },
        ...updatedAdSet,
      };
    });
  }, [planAdSetsData, updatedAdSets]);

  const handleRowSelectionModelChange = (newSelection: GridRowSelectionModel) => {
    const newSelectedAdSets = rows.filter((row) => row.id && newSelection.includes(row.id));
    setSelectedAdSets(newSelectedAdSets);
    setRowSelectionModel(newSelection);
  };

  const resetRowSelection = () => {
    setRowSelectionModel([]);
    setSelectedAdSets([]);
  };

  const tableTitle = viewMode === ViewMode.REVIEWING ?
    `${planAdSetsPagination.total} ad sets to review` :
    'Edit existing ad sets';

  return (
    <>
      <Box mb={1}>
        <Typography variant='h5' color='text.primary'>{tableTitle}</Typography>
      </Box>
      <Box sx={{ backgroundColor: 'white' }} padding={2}>
        <DataGrid
          rows={rows ?? []}
          columns={columns}
          loading={isLoading}
          checkboxSelection={viewMode === ViewMode.REVIEWING && organisation?.is_retailer}
          disableColumnSorting
          disableColumnFilter
          paginationMode='server'
          paginationModel={dataGridPaginationModel}
          rowCount={planAdSetsPagination.total || 0}
          onPaginationModelChange={handlePaginationUpdate}
          pageSizeOptions={[5, 10, 15, 25, 50, 100]}
          rowSelectionModel={rowSelectionModel}
          onRowSelectionModelChange={handleRowSelectionModelChange}
          isRowSelectable={(params: GridRowModel) => {
            const adSetStatus = params.row.status;
            const adSetIsApprovedOrRejected =
              adSetStatus === AdSetStatusEnum.reserved || adSetStatus === AdSetStatusEnum.rejected;
            return !adSetIsApprovedOrRejected;
          }}
        />
      </Box>
      <PlanViewModals
        selectedAdSets={selectedAdSets}
        approveAdSetId={approveAdSetId}
        rejectAdSetId={rejectAdSetId}
        setApproveAdSetId={setApproveAdSetId}
        setRejectAdSetId={setRejectAdSetId}
        approveAdSet={approveAdSet}
        rejectAdSet={rejectAdSet}
        resetRowSelection={resetRowSelection}
        viewAdSet={viewAdSet}
        setViewAdSet={setViewAdSet}
        editAdSet={editAdSet}
        setEditAdSet={setEditAdSet}
        planAdSetsPagination={planAdSetsPagination}
      />
    </>
  );
};
