import React, { useEffect, useState, useMemo } from 'react';
import {
  CloseIcon,
  Box,
  Autocomplete,
  TextField,
  DateRangePicker,
  DateRange,
  InputAdornment,
  SearchIcon,
  IconButton,
} from '@zitcha/component-library';
import { useBrands } from 'v2/lib/hooks/useBrands';
import { PlanSearch, PlanSearchStatusesItem } from 'v2/lib/api/ad-management';
import { useAllWallets } from 'v2/lib/hooks/useWallets';
import Wallet from 'v2/Types/Wallets';
import { useRetailers } from 'v2/lib/hooks/useRetailers';
import { useUserOrganisation } from 'v2/lib/hooks/useUserOrganisation';

type PlansTableFiltersProps = {
  //eslint-disable-next-line
  onFilterChange: (newFilter: Partial<PlanSearch>) => void;
};

const statusNameMapping: Record<PlanSearchStatusesItem, string> = {
  [PlanSearchStatusesItem.agreed]: 'Agreed',
  [PlanSearchStatusesItem.archived]: 'Archived',
  [PlanSearchStatusesItem.planning]: 'Draft',
  [PlanSearchStatusesItem.proposed]: 'Proposed',
};

type FilterOption = { id: string; selected: boolean; name: string };

export const PlansTableFilters: React.FC<PlansTableFiltersProps> = ({ onFilterChange }) => {
  const organisation = useUserOrganisation();
  const allBrands = useBrands();
  const retailers = useRetailers();
  const { wallets: allWallets, isLoading: isWalletsLoading } = useAllWallets();

  const allStatuses: Array<PlanSearchStatusesItem> = Object.keys(
    PlanSearchStatusesItem
  ) as Array<PlanSearchStatusesItem>;

  // Convert allStatuses, allBrands, and allWallets to options format for Autocomplete
  // Each option is an object with an id, name, and selected property
  const [statusOptions, setStatusOptions] = useState<Array<FilterOption>>(
    allStatuses.map((status) => ({ id: status, name: statusNameMapping[status], selected: false }))
  );
  const [brandOptions, setBrandOptions] = useState<Array<FilterOption>>(
    allBrands.map((brand) => ({ ...brand, selected: false, id: brand.id }))
  );
  const [retailerOptions, setRetailerOptions] = useState<Array<FilterOption>>(
    retailers.map((retailer) => ({ ...retailer, selected: false, id: retailer.id }))
  );
  const [walletOptions, setWalletOptions] = useState<Array<FilterOption>>(
    allWallets.map((wallet: Wallet) => ({ ...wallet, selected: false, id: wallet.id }))
  );

  // Compute selected options from options
  // These are the options that the user has selected in the Autocomplete components
  const selectedStatusOptions = useMemo(
    () => statusOptions.filter((option: FilterOption) => option.selected),
    [statusOptions]
  );
  const selectedBrandOptions = useMemo(
    () => brandOptions.filter((option: FilterOption) => option.selected),
    [brandOptions]
  );
  const selectedRetailerOptions = useMemo(
    () => retailerOptions.filter((option: FilterOption) => option.selected),
    [retailerOptions]
  );
  const selectedWalletOptions = useMemo(
    () => walletOptions.filter((option: FilterOption) => option.selected),
    [walletOptions]
  );

  const [dateRange, setDateRange] = useState<DateRange | undefined>(undefined);
  const [planName, setPlanName] = useState<string>('');

  // When selected options change, call onFilterChange to update the filter
  useEffect(() => {
    onFilterChange({
      statuses: selectedStatusOptions.length
        ? selectedStatusOptions.map((option: FilterOption) => option.id as PlanSearchStatusesItem)
        : undefined,
      supplier_organisation_ids: selectedBrandOptions.length
        ? selectedBrandOptions.map((option: FilterOption) => option.id)
        : undefined,
      owner_organisation_ids: selectedRetailerOptions.length
        ? selectedRetailerOptions.map((option: FilterOption) => option.id)
        : undefined,
      wallet_ids: selectedWalletOptions.length
        ? selectedWalletOptions.map((option: FilterOption) => option.id)
        : undefined,
      wallet_start_date: dateRange?.to ? dateRange?.from?.toISOString() : undefined,
      wallet_end_date: dateRange?.to?.toISOString(),
      name: planName || undefined,
    });
  }, [
    selectedStatusOptions,
    selectedBrandOptions,
    selectedRetailerOptions,
    selectedWalletOptions,
    dateRange?.to,
    planName,
  ]);

  // When allWallets changes, update walletOptions
  // This is necessary because allWallets is fetched asynchronously
  useEffect(() => {
    if (!isWalletsLoading) {
      setWalletOptions(
        allWallets.map((wallet: Wallet & FilterOption) => ({ ...wallet, selected: false, id: wallet.id }))
      );
    }
  }, [allWallets, isWalletsLoading]);

  return (
    <Box sx={{ display: 'flex', gap: 2, padding: 2 }} className='twd-overflow-auto'>
      <Autocomplete
        id='status-filter'
        value={selectedStatusOptions}
        options={statusOptions}
        onChange={(_, newValue: Array<FilterOption>) => {
          setStatusOptions(
            statusOptions.map((option: FilterOption) => ({ ...option, selected: newValue.includes(option) }))
          );
        }}
        sx={{ minWidth: 150 }}
        multiple
        getOptionLabel={(option: FilterOption) => option.name}
        renderInput={(params) => <TextField {...params} label='Status' />}
      />

      {organisation.is_retailer ? (
        <Autocomplete
          id='brand-filter'
          value={selectedBrandOptions}
          options={brandOptions}
          onChange={(_, newValue: Array<FilterOption>) => {
            setBrandOptions(
              brandOptions.map((option: FilterOption) => ({
                ...option,
                selected: newValue.some((value) => value.id === option.id),
              }))
            );
          }}
          sx={{ minWidth: 150 }}
          multiple
          getOptionLabel={(option: FilterOption) => option.name}
          renderInput={(params) => <TextField {...params} label='Brand' />}
        />
      ) : (
        <Autocomplete
          id='retailer-filter'
          value={selectedRetailerOptions}
          options={retailerOptions}
          onChange={(_, newValue: Array<FilterOption>) => {
            setRetailerOptions(
              retailerOptions.map((option: FilterOption) => ({
                ...option,
                selected: newValue.some((value) => value.id === option.id),
              }))
            );
          }}
          sx={{ minWidth: 150 }}
          multiple
          getOptionLabel={(option: FilterOption) => option.name}
          renderInput={(params) => <TextField {...params} label='Retailer' />}
        />
      )}

      <Autocomplete
        id='wallet-filter'
        loading={isWalletsLoading}
        value={selectedWalletOptions}
        options={walletOptions}
        onChange={(_, newValue: Array<FilterOption>) => {
          setWalletOptions(
            walletOptions.map((option: FilterOption) => ({
              ...option,
              selected: newValue.some((value) => value.id === option.id),
            }))
          );
        }}
        sx={{ minWidth: 150 }}
        multiple
        getOptionLabel={(option: FilterOption) => option.name}
        renderInput={(params) => <TextField {...params} label='Wallet' />}
      />

      <DateRangePicker
        placeholderText='Wallet date range'
        date={dateRange}
        setDate={setDateRange}
        showClearButton={true}
      />

      <TextField
        id='name-filter'
        label='Search by plan name'
        variant='outlined'
        sx={{ minWidth: 290 }}
        value={planName}
        onChange={(event) => setPlanName(event.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: planName && (
            <InputAdornment position='end'>
              <IconButton onClick={() => setPlanName('')} size='small'>
                <CloseIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};
