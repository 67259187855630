import React from 'react';
import { Typography, Box, Stack } from '@zitcha/component-library';
import { PlansTable } from './PlansTable';
import { NewPlanModalButton } from 'v2/components/NewPlanModal/NewPlanModalButton';
import { useUserOrganisation } from 'v2/lib/hooks/useUserOrganisation';

export const PlansTabContent = () => {
  const organisation = useUserOrganisation();

  return (
    <Box sx={{ width: '100%', height: '100%', mt: 3 }} data-testid='PlansContent'>
      <Stack direction='column' spacing={2}>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Typography variant='body1'>
            {organisation.is_retailer
              ? 'A collection of ad sets from a brand intended to run on your retail media network.'
              : 'A collection of ad sets intended to run on a retail media network.'}
          </Typography>
          <NewPlanModalButton />
        </Box>
        <PlansTable />
      </Stack>
    </Box>
  );
};

export default PlansTabContent;
