import moment from 'moment';

export const DISPLAY_DATE_FORMAT = 'D MMM YYYY';

export const formatDisplayDate = (date: moment.Moment | Date | undefined | null): string => {
  if (date === undefined || date === null) {
    return '';
  }

  // Wrap the input date with moment to ensure compatibility and use .format() to standardize the date presentation.
  const momentDate = moment(date);

  // Check if the moment date is valid before formatting.
  return momentDate.isValid() ? momentDate.format(DISPLAY_DATE_FORMAT) : '';
};

export const formatDisplayDateWithTime = (date: moment.Moment | Date | undefined | null) => {
  if (date === undefined || date === null) {
    return '';
  }

  // Wrap the input date with moment to ensure compatibility and use .format() to standardize the date presentation.
  const momentDate = moment(date);

  // Check if the moment date is valid before formatting.
  return momentDate.isValid() ? `${formatDisplayDate(momentDate)} ${moment(momentDate).format('hh:mm A')}` : '';
}

// This function formats the date to be displayed using formatDisplayDate() if the date is older than a month
// otherwise it uses moment's fromNow() function to display the date in a relative format.
export const formatRecentDate = (date: moment.Moment | Date | undefined | null): string => {
  if (date === undefined || date === null) {
    return '';
  }

  const momentDate = moment(date);

  if (!momentDate.isValid()) {
    return '';
  }

  const oneMonthAgo = moment().subtract(1, 'months');

  if (momentDate.isAfter(oneMonthAgo)) {
    return momentDate.fromNow();
  } else {
    return formatDisplayDate(momentDate);
  }
};