import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Navigate, Outlet } from 'react-router-dom';
import { clearSession, refreshSession, selectUser, selectAuthToken } from '../features/session/sessionSlice';
import styled from 'styled-components';
import useNetworkBranding from './OrganisationSettings/useNetworkBranding';
import Chrome from 'v2/components/Navigation/Chrome';
import ChromeLegacy from './layout/Chrome';
import { useGetFeatureFlags } from 'v2/lib/api/system-api';

const StyledLoaderWrapper = styled.div`
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;

  p {
    font-size: 1.2em;
  }
`;

const ProtectedRoutes = () => {
  const [loading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const authToken = useSelector(selectAuthToken);
  const user = useSelector(selectUser);
  const location = useLocation();
  const isLogoutRoute = location.pathname === '/logout';
  const { isInitialLoading } = useNetworkBranding();
  const { data: featureFlags, isLoading: isFeatureFlagsLoading } = useGetFeatureFlags();
  const refresh = useCallback(async () => {
    try {
      const res = await dispatch(refreshSession());
      if (res && res.error) {
        dispatch(clearSession());
      }
    } catch (e) {
      dispatch(clearSession());
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!authToken || isLogoutRoute) {
      setIsLoading(false);
      return;
    }
    if (!user) {
      refresh();
    } else {
      setIsLoading(false);
    }
  }, []);

  if (!authToken) {
    const loginUrl = '/login?postLoginPath=' + location.pathname;
    return <Navigate to={loginUrl} replace />;
  }
  if (loading || isInitialLoading || isFeatureFlagsLoading)
    return (
      <StyledLoaderWrapper>
        <p>The page is loading, please wait.</p>
        <FontAwesomeIcon className='fa-spin' style={{ cursor: 'pointer' }} icon={faSpinner} size='4x' />
      </StyledLoaderWrapper>
    );

  return featureFlags?.feature_planning ? (
    <Chrome>
      <Outlet />
    </Chrome>
  ) : (
    <ChromeLegacy>
      <Outlet />
    </ChromeLegacy>
  );
};

export default ProtectedRoutes;
