import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Nav } from 'react-bootstrap';
import styles from '../sidebar.module.scss';
import { isLightColor } from '../utils';
import useNetworkBranding from 'app/OrganisationSettings/useNetworkBranding';

export const SidebarLink = ({ to, icon, label }) => {
  const { data } = useNetworkBranding();
  const settings = data?.settings;
  return (
    <Nav.Item as='li' role='presentation' className='mb-4'>
      <Link className='nav-link' to={to}>
        <span
          className=' sidebar_button'
          style={{
            color: isLightColor(settings?.theme?.primary_colour) ? '#000' : '#fff',
          }}
        >
          <FontAwesomeIcon icon={icon} size='lg' className='sidebar_button' />
          <label className={`${styles.sidebar_nav_label} sidebar_button`}>{label}</label>
        </span>
      </Link>
    </Nav.Item>
  );
};

SidebarLink.propTypes = {
  to: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
};
