import { useState } from 'react';

export const useCopyToClipboard = () => {
  const [copySuccess, setCopySuccess] = useState(false);

  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 1000);
    } catch (err) {
      setCopySuccess(false);
    }
  };

  return { copySuccess, copyToClipboard };
};
