import { Box, Toolbar } from '@zitcha/component-library';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { NavBar } from './NavBar';
import { SideNav } from './SideNav';
import { ContextI } from './types';
import { useSelector } from 'react-redux';
import { selectSession, selectUser } from 'features/session/sessionSlice';
import {
  INVITE_EMPLOYEE,
  MANAGE_ORGANISATION_SETTINGS,
  MANAGE_SUPPLIER_SETTINGS,
  OWNED_CHANNELS_MANAGEMENT_WEB,
  USER_MANAGEMENT,
  INVITE_SUPPLIERS,
  CREATE_ORDERS,
} from 'lib/permissions';
import { useLocation } from 'react-router-dom';
import { colorConstants } from 'v2/constants/colorConstants';

export const NavigationContext = createContext<ContextI>({
  drawerWidth: 4,
  setDrawerWidth: () => undefined,
  toggleDrawerWidth: () => undefined,
  drawerSizes: {
    expanded: 15,
    collapsed: 4,
  },
  user: {
    first_name: '',
    last_name: '',
    active_organisation: {
      id: '',
      name: '',
      settings: {
        logo: {
          url: '',
        },
      },
    },
  },
  user_organisations: [],
  permissions: {
    hasPermissions: false,
    canManageUsers: false,
    canManageChannelWeb: false,
    canManageSuppliers: false,
    isRetailer: false,
    hasWallets: false,
    hasInventoryManagementEnabled: false,
    hasBrandStoresEnabled: false,
    canInviteEmployees: false,
    canInviteSupppliers: false,
    canCreateOrders: false,
    channelsEnabledFlagSet: false,
  },
  chromeContentBackgroundColor: {
    color: colorConstants.DEFAULT_BACKGROUND_COLOR,
    setColor: () => undefined,
    resetColor: () => undefined,
  },
});

const Chrome = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();
  const navContext = useContext(NavigationContext);
  const [drawerWidth, setDrawerWidth] = useState(navContext.drawerSizes.collapsed);
  const [page, setPage] = useState(location.pathname.split('/').pop() || 'Dashboard'); // ['plans-ad-sets'] => 'plans-ad-sets'
  const [chromeContentBackgroundColor, setChromeContentBackgroundColor] = useState(
    colorConstants.DEFAULT_BACKGROUND_COLOR
  );
  const session = useSelector(selectSession);
  const user = useSelector(selectUser);

  const userPermissions = user?.role?.permissions ? user?.role?.permissions?.map((p: { name: string }) => p.name) : [];
  const userOrganisations = session?.user_organisations;
  const channelsEnabledFlagSet = session?.channels_enabled;
  const hasWallets: boolean = user?.active_organisation?.has_wallets;
  const isRetailer: boolean = user?.active_organisation?.is_retailer;
  const hasInventoryManagementEnabled: boolean = session?.feature_flags?.features?.inventory_management;
  const hasBrandStoresEnabled: boolean = session?.feature_flags?.ad_types?.brand_store;
  const hasPermissions: boolean =
    userPermissions &&
    (userPermissions.includes(MANAGE_ORGANISATION_SETTINGS) ||
      userPermissions.includes(USER_MANAGEMENT) ||
      userPermissions.includes(OWNED_CHANNELS_MANAGEMENT_WEB) ||
      userPermissions.includes(MANAGE_SUPPLIER_SETTINGS));
  const canManageUsers: boolean =
    userPermissions.includes(MANAGE_ORGANISATION_SETTINGS) || userPermissions.includes(USER_MANAGEMENT);
  const canManageChannelWeb: boolean = userPermissions.includes(OWNED_CHANNELS_MANAGEMENT_WEB);
  const canManageSuppliers: boolean = userPermissions.includes(MANAGE_SUPPLIER_SETTINGS);
  const canInviteEmployees: boolean = userPermissions.includes(INVITE_EMPLOYEE);
  const canInviteSupppliers: boolean = userPermissions.includes(INVITE_SUPPLIERS);
  const canCreateOrders: boolean = userPermissions.includes(CREATE_ORDERS);

  const permissions = {
    hasPermissions,
    canManageUsers,
    canManageChannelWeb,
    canManageSuppliers,
    hasWallets,
    isRetailer,
    hasInventoryManagementEnabled,
    hasBrandStoresEnabled,
    canInviteEmployees,
    canInviteSupppliers,
    canCreateOrders,
    channelsEnabledFlagSet,
  } as const;

  const toggleDrawerWidth = () => {
    setDrawerWidth(
      drawerWidth === navContext.drawerSizes.expanded
        ? navContext.drawerSizes.collapsed
        : navContext.drawerSizes.expanded
    );
  };

  const pages = {
    settings: 'Settings',
    'plans-ad-sets': 'Plans & Ad Sets',
    channels: 'Channels',
    invite: 'Invites',
    orders: 'Orders',
    order: 'Order',
    suppliers: 'Suppliers',
    users: 'Users',
    inventory: 'Inventory',
    brandstores: 'Brand Store',
    wallets: 'Wallets',
    reports: 'Reports',
    packages: 'Packages',
  } as const;

  const pageName = Object.keys(pages).find((key) => location.pathname.includes(key)) as keyof typeof pages;

  useEffect(() => {
    if (pageName) {
      setPage(pages[pageName]);
    } else {
      setPage(location.pathname.split('/').pop() || 'Dashboard');
    }
  }, [location.pathname]);

  return (
    <NavigationContext.Provider
      value={{
        drawerWidth,
        setDrawerWidth,
        toggleDrawerWidth,
        drawerSizes: {
          expanded: 15,
          collapsed: 4,
        },
        user,
        user_organisations: userOrganisations,
        permissions,
        chromeContentBackgroundColor: {
          color: chromeContentBackgroundColor,
          setColor: setChromeContentBackgroundColor,
          resetColor: () => setChromeContentBackgroundColor(colorConstants.DEFAULT_BACKGROUND_COLOR),
        },
      }}
    >
      <Box bgcolor={chromeContentBackgroundColor}>
        <NavBar page={page} />
        <SideNav />
        <Box
          sx={{
            height: '100vh',
            overflow: 'auto',
            paddingLeft: '4rem',
          }}
        >
          <Toolbar />
          {children}
        </Box>
      </Box>
    </NavigationContext.Provider>
  );
};

export default Chrome;
