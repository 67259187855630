import { useSelector } from 'react-redux';
import { selectUserOrganisation } from 'features/session/sessionSlice';

type Permission = {
    id: number;
    name: string;
    guard_name: string;
    created_at: string;
    updated_at: string;
    parent_permission_id: null | number;
    user_type: string;
    pivot: {
        role_id: number;
        permission_id: number;
    };
};

type Role = {
    id: number;
    name: string;
    organisation_id: string;
    type: null | string;
    has_all_permissions: boolean;
    is_undeletable: boolean;
    permissions: Array<Permission>;
};

type User = {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
    active_organisation_id: string;
    email_verified_at: string;
    is_active: boolean;
    get_notification: boolean;
    created_at: string;
    updated_at: string;
    deleted_at: string | null;
    jobtitle: string | null;
    role: Role;
    preferences_json: string | null;
    active_organisation: Record<string, unknown>;
};

type Logo = {
    url: string;
    name: string;
    path: string;
    storage: string;
    extension: string;
    creator_id: string;
};

type Settings = {
    logo: Logo;
    timezone: string;
    defaultCurrency: string;
};

export type Organisation = {
    id: string;
    name: string;
    is_active: boolean;
    settings: Settings;
    created_at: string;
    updated_at: string;
    deleted_at: string | null;
    is_sandboxmode: boolean;
    permissions: null | Record<string, unknown>;
    is_retailer: boolean;
    has_wallets: boolean;
    users: Array<User>;
};

export const useUserOrganisation = (): Organisation => {
    return useSelector(selectUserOrganisation);
};

