import React from 'react'

const Urls = ({ url = '' }) => {
   
    return <article>
        <div className='mb-2'>
            <p className='mb-0'><b>Matching</b></p>
            <small className='mb-2'>This is the URL of the page this space would appear on.</small>
        </div>
        <div className='border rounded border-secondary p-3'>
            <div>
                <small><b>URL</b></small>
                <hr />
                {url !== '' ?
                <div>{url}</div>
                :
                <span>There is no URL assigned for this space. <a href='mailto:support@zitcha.com'>Please contact support.</a></span>}
            </div>
        </div>
    </article>
}

Urls.propTypes = {

}

export default Urls
