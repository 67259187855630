import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import styled from "styled-components";

const StyledSearch = styled.div`
  display: flex;
  align-items: center;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: #777785;
`;

const SearchContainer = styled.div`
  width: ${props => props.width ? props.width : 'unset'};
  margin-left: auto;
`;

const SearchInput = styled.input`
  flex: none;
  flex-grow: 0;
  font-weight: 500;
  font-size: 13.33px;
  border-width: 0 0 1px 0 !important;
  border-radius: 0 !important;
  border-color: #777785 !important;
  background-color: transparent !important;
  padding: 6px 28px 6px 12px;
  outline: none;

  &:active {
    border-color: #000000 !important;
  }

  &:focus {
    border-color: #6051fb !important;
  }
`;

type SearchProps = {
    onChange: (EventTarget) => void,
    value: string,
    readOnly?: boolean,
    disabled?: boolean,
}

const Search = ({onChange, value, readOnly = false, disabled = false}: SearchProps): JSX.Element => {
    return <SearchContainer className='input-group'>
        <SearchInput
            type='text'
            size={20}
            className='border-right-0'
            value={value}
            id='search'
            placeholder='Search'
            readOnly={readOnly}
            disabled={disabled}
            onChange={onChange}
        />
        <StyledSearch>
            <FontAwesomeIcon icon={faSearch}/>
        </StyledSearch>
    </SearchContainer>;
};

export default Search;