import moment from "moment/moment";
import 'moment-timezone';
import {Ad, Order, OrderStatus} from '../common/types';


export function shouldAdStartDateBeReadonly(order: Order, ad: Ad, timezone: string, isLive = false): boolean {
  if (!ad.start_at) {
    return false;
  }

  if (!isLive && (order.status === OrderStatus.PENDING_APPROVAL || order.status === OrderStatus.DRAFT)) {
    return false;
  }

  const nowInRetailerTimezone =  moment().tz(timezone);
  const startAt = moment.tz(ad.start_at, timezone);

  return nowInRetailerTimezone.isAfter(startAt);
}
