import React, { useEffect, useState } from 'react';
import {
  Alert,
  CircularProgress,
  CloseIcon,
  IconButton,
  Snackbar,
  CheckIcon,
} from '@zitcha/component-library';

export const SnackbarNotifications = ({
  isSuccess,
  isError,
  isProcessing,
  messages = {
    error: 'Error',
    success: 'Success',
  },
}: {
  isSuccess: boolean;
  isError: boolean;
  isProcessing?: boolean;
  messages?: {
    error: string;
    success: string;
  };
}) => {
  const [snackbar, setSnackbar] = useState<{
    isOpen: boolean;
    severity: 'success' | 'error' | undefined;
    message: string;
  }>({
    isOpen: false,
    severity: undefined,
    message: '',
  });

  useEffect(() => {
    if (isSuccess || isError || isProcessing) {
      setSnackbar({
        isOpen: true,
        severity: isSuccess || isProcessing ? 'success' : 'error',
        message: isSuccess ? messages.success : isError ? messages.error : 'Processing',
      });
    }
  }, [isError, isSuccess, isProcessing]);

  const handleDismissSnackbar = () => {
    setSnackbar({ message: '', severity: undefined, isOpen: false });
  };
  if (!snackbar.isOpen || !snackbar.severity) return null;
  return (
    <>
      <Snackbar
        open={snackbar.isOpen}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={5000}
        onClose={handleDismissSnackbar}
      >
        <Alert
          severity={snackbar.severity}
          variant='filled'
          iconMapping={{
            success: <CheckIcon fontSize='inherit' />,
          }}
          icon={isProcessing ? false : undefined}
          action={
            <IconButton aria-label='close' color='inherit' size='small' onClick={handleDismissSnackbar}>
              <CloseIcon fontSize='inherit' />
            </IconButton>
          }
          sx={{
            display: 'flex',
            alignItems: 'center',
            '.MuiAlert-message': {
              display: 'flex',
              alignItems: 'center',
            },
          }}
        >
          <>
            {isProcessing ? (
              <CircularProgress
                size={20}
                color='inherit'
                sx={{
                  marginRight: '1rem',
                }}
              />
            ) : null}
          </>
          <>{snackbar.message}</>
        </Alert>
      </Snackbar>
    </>
  );
};
