import React from 'react';
import { TextField } from '@mui/material';
import { RenderableMediaTypeFieldProperties } from 'v2/lib/api/inventory';

interface DateFieldComponentProps {
  properties: RenderableMediaTypeFieldProperties & {
    minDate?: string;
    maxDate?: string;
    label?: string;
  };
  fieldName?: string;
  onChange: (...event: Array<any>) => void;
  onBlur: () => void;
  value: any;
}

export const DateFieldComponent: React.FC<DateFieldComponentProps> = ({ properties, fieldName, onChange, onBlur, value }) => {
  const controlledValue = value || '';
  return (
    <TextField
      type='date'
      id={`${fieldName}-date-field-${properties.label}`}
      variant='outlined'
      fullWidth
      InputLabelProps={{ shrink: true }}
      inputProps={{
        min: properties.minDate,
        max: properties.maxDate,
      }}
      onChange={onChange}
      onBlur={onBlur}
      value={controlledValue}
      data-testid={`${fieldName}-date-field-component`}
    />
  );
};