import React from 'react'
import PropTypes from 'prop-types'
import styles from './filedropzone.module.scss'

const CallToAction = ({ fileRef }) => (
    <div className={`${styles.container_text}`}>
        <p className='d-inline'>Drag and drop files or </p>
        <button
            className={`d-line border rounded ${styles.container_button}`}
            onClick={e => {
                e.preventDefault()
                fileRef.current.click()
            }}
        >
      Upload
        </button>
    </div>
)

CallToAction.propTypes = {
    fileRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    ]),
}

export default CallToAction
