import React, { useRef } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import OrderPagination from '../features/orders/components/OrderPagination';
import Orders from '../features/orders/components/Orders';
import Filters from '../features/orders/components/Filters';
import useOrdersLogic from '../features/orders/hooks/useOrdersLogic';

const ArchivedOrders = () => {
  const listTopRef = useRef(null);
  const {
    data: orders,
    error,
    isLoading,
    handleClick,
    searchParams,
    setSearchParams,
  } = useOrdersLogic({
    archived: true,
  });

  return (
    <>
      <Helmet>
        <title>Archived Orders - {process.env.REACT_APP_NAME}</title>
      </Helmet>

      <Container className='pt-4' ref={listTopRef}>
        <Row>
          <Col>
            <h3>Archived Orders</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <Filters onChange={setSearchParams} filterFields={searchParams} />
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col>
            <Orders data={orders?.data.data} isLoading={isLoading} error={error} />
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col>
            <OrderPagination
              totalItems={orders?.data.total}
              currentPage={orders?.data.current_page || 1}
              itemsPerPage={orders?.data.per_page || 20}
              onClick={handleClick}
              disabled={isLoading}
              ref={listTopRef}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ArchivedOrders;
