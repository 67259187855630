import React from 'react'
import { Button } from 'react-bootstrap'
import styled from 'styled-components'
import { ErrorFeedback } from '../form_elements/Common'
import Errors from '../form_elements/Errors'

const ActionContainer = styled.section`
    border: 1px solid #ECECEC;
    border-radius: 8px;
    background-color: #F2F2F2;
    padding: 20px;
`

const ActionButtonsContainer = styled.div`
    display: flex;
    gap: 8px;
`

const PrimaryButton = styled(Button)`
    background-color: #5995ED;
    border: 1px solid #5995ED;
    padding: 12px 50px;
`

const SecondaryButton = styled(Button)`
    margin-left: auto;
    border-color: #777785;
    color: #777785;
    background-color: #fff;
    padding: 12px 45px;

    &:hover,
    &:not(:disabled):not(.disabled):active,
    &:focus {
      background-color: #777785;
      color: #fff;
      border: 1px solid #777785 !important;
    }
    &:disabled {
        opacity: 0.5;
        background-color: transparent !important;
        border: 1px solid #777785 !important;
        color: #777785 !important;
    }
`

const PreviewButton = styled(SecondaryButton)`
    color: #5995ED;
    border-color: #5995ED;
    margin-left: 0;
`

const ActionBar = ({ onCancel = () => { }, onSave = () => { }, onPreview = () => { }, errors = [] }) => {
    return <ActionContainer>
        <ActionButtonsContainer>
            <SecondaryButton onClick={onCancel}>
                Cancel
            </SecondaryButton>
            <PreviewButton onClick={onPreview}>
                Preview Listing
            </PreviewButton>
            <PrimaryButton onClick={onSave}>
                Save
            </PrimaryButton>
        </ActionButtonsContainer>
        <div className='text-right'>
            <ErrorFeedback type='invalid' isInvalid={errors.length > 0}>
                <Errors errors={errors} />
            </ErrorFeedback>
        </div>
    </ActionContainer>
}

export default ActionBar
