import React from 'react';
import styled from 'styled-components';

const RadioContainer = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  color: black;
`;

const RadioInput = styled.input`
  margin-right: 12px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const LabelText = styled.span`
  font-weight: bold;
`;

const DescriptionText = styled.span`
  font-weight: normal;
`;

type RadioOptionValue = string | number;

interface RadioOptionProps {
  name: string;
  value: RadioOptionValue;
  checked: boolean;
  // eslint-disable-next-line no-unused-vars
  onChange: (value: RadioOptionValue) => void;
  label: string;
  description?: string;
  readOnly?: boolean;
}

export const RadioOption = ({
  name,
  value,
  checked,
  onChange,
  label,
  description,
  readOnly
}: RadioOptionProps) => {
  const handleChange = (value: RadioOptionValue) => {
    if (!readOnly) {
      onChange(value);
    }
  };

  return (
    <RadioContainer onClick={() => handleChange(value)}>
      <RadioInput
        type='radio'
        name={name}
        value={value}
        checked={checked}
        disabled={readOnly}
      />
      <TextContainer>
        <LabelText>{label}</LabelText>
        <DescriptionText>{description}</DescriptionText>
      </TextContainer>
    </RadioContainer>
  );
};
