import React, { useEffect, useState } from 'react';
import { TextField } from '@zitcha/component-library';
import { Popover } from '@mui/material';
import { PlacementList } from '../PlacementList/PlacementList';
import type { Location } from '../../lib/api/ad-management';
import { useUserOrganisation } from 'v2/lib/hooks/useUserOrganisation';
type PlacementProps = {
  onPlacementChange: (location: Location) => void;
  location: Location | null;
};

export const Placement: React.FC<PlacementProps> = ({ onPlacementChange, location }) => {
  const [placement, setPlacement] = useState<Location | null>(location || null);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const organisation = useUserOrganisation();
  const handlePlacementClick = (event: React.MouseEvent<HTMLDivElement>) => setAnchorEl(event.currentTarget);
  const handlePlacementClose = () => setAnchorEl(null);
  const handlePlacementChange = (location: Location | undefined) => {
    if (!location) return;
    setPlacement(location);
    onPlacementChange(location);
    handlePlacementClose();
  };
  useEffect(() => {
    if (location) {
      setPlacement(location);
    }
  }, [location]);
  const textValue = placement ? `${placement?.type} : ${placement?.name}` : '';

  return (
    <>
      <TextField
        id='placement'
        data-testid='placement'
        value={textValue}
        fullWidth
        variant='standard'
        onClick={handlePlacementClick}
        autoComplete='off'
      ></TextField>
      <Popover
        open={Boolean(anchorEl)}
        data-testid='popover-placement'
        anchorEl={anchorEl}
        onClose={handlePlacementClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <PlacementList
          retailerId={organisation.id}
          onSelectPlacement={handlePlacementChange}
          onClose={handlePlacementClose}
        />
      </Popover>
    </>
  );
};

export default Placement;
