import {PLATFORM_FACEBOOK, PLATFORM_GOOGLE} from "../../lib/platforms";
import moment from "moment";

export enum OrderStatus {
  PENDING_APPROVAL = 'pending_approval',
  APPROVED = 'approved',
  NOT_APPROVED = 'not_approved',
  COMPLETED = 'completed',
  PAUSED = 'paused',
  LIVE = 'live',
  DRAFT = 'draft',
}


export type Organisation = {
  id: string,
  name: string,
}

export type User = {
  id: string,
  first_name: string,
  last_name: string,
  email: string,
  active_organisation: Organisation,
  role: Role,
}

export type Role = {
  name: string,
}

export type RequestStatus = 'idle' | 'loading' | 'succeeded' | 'failed';

export type ProductFeed = {
  group: ProductSet,
  products: Array<Product>,
  deleted_products?: Array<DeletedProduct>,
};

export type ProductSet = {
    id: string,
    name: string,
    platform: typeof PLATFORM_FACEBOOK | typeof PLATFORM_GOOGLE,
};

export type Product = {
  id: string,
  name: string;
  image_url: string,
  title: string,
  price: number,
  currency: string,
};

export type ProductApiResponse = {
  data: Array<Product>,
  after: string,
  count: number|undefined
};

export type DeletedProduct = Product & {
  deleted_at: string,
};


export type Asset = {
  id: string,
  assettype: string,
  url: string,
  name: string,
  metadata: {
    width: number,
    height: number,
  }
}

export type Order = {
  status: OrderStatus,
}

export type Ad = {
  start_at?: moment.Moment,
  stop_at?: moment.Moment,
}
