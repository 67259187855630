import React from 'react';
import { FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { RenderableMediaTypeFieldProperties } from 'v2/lib/api/inventory';

interface RadioOptionsComponentProps {
  properties: RenderableMediaTypeFieldProperties & {
    options?: string; // expects a string of comma separated values
    defaultSelected?: string;
    label?: string;
  };
  fieldName?: string;
  onChange: (...event: Array<any>) => void;
  onBlur: () => void;
  value: string;
}

export const RadioOptionsComponent = ({ properties, fieldName, onChange, onBlur, value }: RadioOptionsComponentProps) => {
  const optionsArray = properties?.options?.split(',');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  const controlledValue = value || properties.defaultSelected || '';

  return (
    <FormControl component='fieldset' data-testid={`${fieldName}-radio-options-component`} onBlur={onBlur}>
      <RadioGroup
        aria-label={properties.label}
        name={`${fieldName}-radio-${properties.label}`}
        value={controlledValue}
        onChange={handleChange}
      >
        {optionsArray?.map((option, index) => (
          <FormControlLabel key={index} value={option.trim()} control={<Radio />} label={option.trim()} />
        ))}
      </RadioGroup>
    </FormControl>
  );
};