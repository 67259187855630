import { Box } from '@zitcha/component-library';
import React from 'react';

export const BrandPerformance = () => {
  return (
    <Box height={200} borderColor={'grey'} border={1} p={2}>
      Brand Performance not implemented
    </Box>
  );
};
