import { Location, useGetLocations } from '../api/ad-management';
import { useUserOrganisation } from './useUserOrganisation';

const key = 'location';

export const useBundleLocations = () => {
  const organisation = useUserOrganisation();
  const { data, isLoading } = useGetLocations(
    {
      organisation_id: organisation.id,
      per_page: 100, //<- we might need to find a way to support infinite scroll
    },
    {
      query: { queryKey: [key, organisation.id] },
    }
  );

  const placementOptions: Array<Partial<{
    id: string;
    name: string;
    selected: boolean;
  }>> =
    data?.data?.map((placement: Location) => ({
      id: placement.id,
      name: placement.name,
      selected: false,
    })) ?? [];

  return { placementOptions, isLoading };
};
