import { createAsyncThunk, createEntityAdapter, createSlice , createSelector} from '@reduxjs/toolkit';
import { getRetailers } from '../../lib/api';

export const fetchRetailers = createAsyncThunk(
  'retailers/fetchRetailers',
  async (arg, { dispatch, rejectWithValue }) => {
    try {
      const response = await getRetailers();
      return response.data.data;
    } catch (err) {
      if (!err.response?.data) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const cacheFetchRetailers = createAsyncThunk(
  'retailers/cacheFetchRetailers',
  async (arg, { getState, dispatch }) => {
    if (getState().retailers.status === 'idle') {
      const action = await dispatch(fetchRetailers());
      return action.payload;
    }
  }
);

const retailersAdapter = createEntityAdapter({
  // Sort alphabetically
  sortComparer: (a, b) => (a.name < b.name ? -1 : +(a.name > b.name)),
});

export const {
  selectAll: selectAllRetailers,
  selectIds: selectRetailerIds,
  selectById: selectRetailerById,
} = retailersAdapter.getSelectors((state) => state.retailers);

const retailersSlice = createSlice({
  name: 'retailers',

  initialState: retailersAdapter.getInitialState({
    retailers: [],
    status: 'idle',
  }),

  reducers: {
    retailerDeleted: retailersAdapter.removeOne,
    retailersCleared: retailersAdapter.removeAll,
  },

  extraReducers: (builder) => {
    builder.addCase(fetchRetailers.pending, (state, action) => {
      state.status = 'loading';
    });

    builder.addCase(fetchRetailers.fulfilled, (state, action) => {
      if (state.status === 'loading') {
        retailersAdapter.setAll(state, action.payload);
        state.status = 'succeeded';
      }
    });

    builder.addCase(fetchRetailers.rejected, (state, action) => {
      if (state.status === 'loading') {
        state.status = 'failed';
      }
    });
  },
});
// Selectors
/**
 * 
 * @param {string | undefined} retailerId 
 * @returns retailer object
 */
export const selectRetailer =(retailerId)=> createSelector(
  (state)=>state.retailers.entities,
  (retailers) => retailers[retailerId]
)
export const {retailerDeleted,retailersCleared} = retailersSlice.actions;

export default retailersSlice.reducer;
