import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import styled from 'styled-components';

const CheckHole = styled.span`
  position: absolute;
  top: 10px;
  right: 10px;

  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: 1px solid #6c757d;
`;

const CheckCircle = styled.span`
  position: absolute;
  top: 10px;
  right: 10px;

  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  color: #fff;
  background-color: #4FAD80;

  display: flex;
  align-items: center;
`;


type CheckProps = {
    checked: boolean,
};

const Check = ({checked = false, ...props}: CheckProps): JSX.Element => {
    if (!checked) {
        return <Unchecked {...props} />;
    }

    return <Checked  {...props} />;
};

const Unchecked = (): JSX.Element => <CheckHole className='check-hole'></CheckHole>;

const Checked = (): JSX.Element => <div>
    <Unchecked/>
    <CheckCircle className='check-circle'>
        <FontAwesomeIcon icon={faCheck} size='xs' className='mx-auto'/>
    </CheckCircle>
</div>;


export default Check;