import PropTypes from "prop-types";
import React from "react";
import Behaviours from "./audience/Behaviours";
import FacebookAge from "./audience/FacebookAge";
import FacebookGender from "./audience/FacebookGender";
import Interests from "./audience/Interests";
import Locations from "./audience/Locations";

const FacebookAudienceTargetingRow = ({
  controlId,
  readOnly = false,
  audience = {},
  adType,
  onChange = () => { },
  errors = {},
}) => {
  const handleChange = (val) => {
    onChange({ ...audience, ...val });
  };

  return (
    <div>
      <Locations
        onChange={(locations) => handleChange({ locations })}
        value={audience.locations}
        platform='facebook'
        readOnly={readOnly}
        errors={errors.locations}
      />
      <FacebookAge
        onChange={({ min_age, max_age }) => handleChange({ min_age, max_age })}
        value={{
          min_age: audience.min_age,
          max_age: audience.max_age,
        }}
        readOnly={readOnly}
        errors={errors.age}
      />
      <FacebookGender
        id={`${controlId}-gender`}
        onChange={(gender) => handleChange({ gender })}
        value={audience.gender}
        readOnly={readOnly}
        errors={errors.gender}
      />
      <Interests
        onChange={(interests) => handleChange({ interests })}
        value={audience.interests}
        platform='facebook'
        readOnly={readOnly}
        errors={errors.interests}
      />
      <Behaviours
        onChange={(behaviours) => handleChange({ behaviours })}
        value={audience.behaviours}
        adType={adType}
        platform='facebook'
        readOnly={readOnly}
        errors={errors.behaviours}
      />
    </div>
  );
};

FacebookAudienceTargetingRow.propTypes = {
  controlId: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  audience: PropTypes.object,
  adType: PropTypes.string,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  errors: PropTypes.object,
};

const FacebookAudienceTargeting = ({
  controlId,
  readOnly = false,
  audience = {},
  adType,
  onChange = () => { },
  errors = {},
}) => {
  const handleChange = (val) => {
    if (!readOnly) {
      onChange(val);
    }
  };
  return (
    <div>
      <FacebookAudienceTargetingRow
        controlId={controlId}
        key={audience.id}
        adType={adType}
        audience={audience}
        onChange={(val) => handleChange(val)}
        errors={errors}
      />
    </div>
  );
};

FacebookAudienceTargeting.propTypes = {
  controlId: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  adType: PropTypes.string,
  audience: PropTypes.object,
  onChange: PropTypes.func,
  errors: PropTypes.object,
};

export default FacebookAudienceTargeting;
