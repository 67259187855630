import PropTypes from "prop-types";
import React, { useRef } from "react";
import { Form } from "react-bootstrap";
import Errors from "../../Errors";

const withSingleFileUpload = (WrapperComponent) => {
  return React.forwardRef(({
    required = false,
    readOnly = false,
    onChange = () => { },
    value = {},
    errors = [],
    accept = [],
    ...props
  }, ref) => {
    const localRef = useRef(null);
    const fileRef = ref || localRef

    return <div>
      <WrapperComponent {...props} onClick={() => fileRef.current.click()} />
      <Form.File
        className='d-none'
        ref={fileRef}
        required={required}
        disabled={readOnly}
        accept={accept.map((f) => `.${f}`).join(",")}
        isInvalid={errors.length > 0}
        multiple
        onChange={e => onChange(e.target.files[0])}
        onClick={e => (e.target.value = null)} // This allows selecting the same file again.
      />
      <Form.Control.Feedback
        className={errors.length > 0 ? "d-block" : "d-none"}
        type='invalid'
      >
        <Errors errors={errors} />
      </Form.Control.Feedback>
    </div>
  })
}

withSingleFileUpload.propTypes = {
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
  errors: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.arrayOf(PropTypes.object),
  accept: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default withSingleFileUpload;
