import PropTypes from 'prop-types';
import { useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { DescriptionContainer, ErrorFeedback, LabelText, TextInput } from './Common';
import Errors from './Errors';
import { useSelector } from 'react-redux';
import { selectUserOrganisationCurrency } from '../../../session/sessionSlice';
import { currencySymbol } from '../../../../lib/financial';

const formatNumberString = (budget) => budget.toString().replace(/[^\d.]|\.(?=.*\.)/g, '');
const Currency = ({
  controlId,
  readOnly = false,
  required = false,
  label,
  description,
  value,
  onChange = () => {},
  errors = [],
}) => {
  const [amount, setAmount] = useState(value);
  const currency = useSelector(selectUserOrganisationCurrency);
  const symbol = currencySymbol(currency);

  const handleBudgetChange = (e) => {
    if (readOnly) {
      return;
    }
    let val = formatNumberString(e.target.value);
    if (val === '') {
      e.target.value = null;
      onChange(null);
      setAmount('');
      return;
    }

    let num = Number(val);
    if (!isNaN(num)) {
      onChange(num);
      setAmount(num.toLocaleString('en', { maximumFractionDigits: 2 }));
    }
  };

  return (
    <Form.Group controlId={controlId}>
      <LabelText>
        {label} {required && label && '*'}{' '}
      </LabelText>
      <DescriptionContainer>{description}</DescriptionContainer>
      <InputGroup>
        <InputGroup.Prepend>
          <InputGroup.Text>
            <strong>{symbol}</strong>
          </InputGroup.Text>
        </InputGroup.Prepend>
        <TextInput
          type='text'
          className='rounded-right'
          maxLength={9}
          min={0}
          required={required}
          readOnly={readOnly}
          disabled={readOnly}
          value={amount}
          isInvalid={errors.length}
          onBlur={handleBudgetChange}
          onChange={(e) => setAmount(formatNumberString(e.target.value))}
        />
      </InputGroup>
      <ErrorFeedback type='invalid' isInvalid={errors.length > 0}>
        <Errors errors={errors} />
      </ErrorFeedback>
    </Form.Group>
  );
};

Currency.propTypes = {
  controlId: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.number,
  label: PropTypes.string,
  description: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
};

export default Currency;
