import React from 'react';
import { TextField } from '@mui/material';
import { RenderableMediaTypeFieldProperties } from 'v2/lib/api/inventory';

interface NumberFieldComponentProps {
  properties: RenderableMediaTypeFieldProperties & {
    min?: number;
    max?: number;
    label?: string;
    placeholder?: string;
    step?: number;
  };
  fieldName?: string;
  onChange: (...event: Array<any>) => void;
  onBlur: () => void;
  value: string | number;
}

export const NumberFieldComponent = ({ properties, fieldName, onChange, onBlur, value }: NumberFieldComponentProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  const controlledValue = value || '';

  return (
    <TextField
      type='number'
      id={`${fieldName}-number-field-${properties.label}`}
      placeholder={properties.placeholder}
      variant='outlined'
      fullWidth
      value={controlledValue}
      onChange={handleChange}
      onBlur={onBlur}
      InputProps={{
        inputProps: {
          min: properties.min,
          max: properties.max,
          step: properties.step,
        },
      }}
      data-testid={`${fieldName}-number-field-component`}
    />
  );
};