import { Box, Divider, Stack, Typography } from '@zitcha/component-library';
import React, { useMemo } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  MediaSpace,
  RenderableMediaTypeField,
  RenderableMediaTypeFieldBelongsTo,
  useGetRenderableByMediaType,
} from 'v2/lib/api/inventory';
import { DynamicMediaTypeField } from './DynamicMediaTypeField';
import { Control, useWatch } from 'react-hook-form';
import { AdSetFormValues } from './AdSetModal';
import { AccordionSummaryDetails } from './AccordionSummaryDetails';

interface AdsTabProps {
  control: Control<AdSetFormValues>;
  mediaSpace: MediaSpace;
}

export const AdsTab: React.FC<AdsTabProps> = ({ control, mediaSpace }) => {
  // State to hold fetched renderable media type data
  const {
    data: renderableMediaTypeData,
    error: renderableMediaTypeError,
    isLoading: isLoadingRenderableMediaType,
  } = useGetRenderableByMediaType(mediaSpace?.mediaTypeId || '', { query: { enabled: !!mediaSpace } });

  const ads = useWatch({ name: 'ads', control });

  const fieldDefinitions = useMemo(
    () =>
      (renderableMediaTypeData?.data ?? []).filter((field) => field.belongsTo === RenderableMediaTypeFieldBelongsTo.Ad),
    [renderableMediaTypeData]
  );

  if (isLoadingRenderableMediaType) {
    return (
      <Box height={100} alignContent={'center'}>
        <Typography variant='body1'>Loading...</Typography>
      </Box>
    );
  }

  if (renderableMediaTypeError) {
    return (
      <Box height={100} alignContent={'center'}>
        <Typography variant='body1'>Error fetching media type data</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ marginTop: '1rem' }}>
      {ads.map((_, index) => (
        <AdAssetAccordion
          key={index}
          control={control}
          adIndex={index}
          mediaSpace={mediaSpace}
          fieldDefinitions={fieldDefinitions}
        />
      ))}
    </Box>
  );
};

const AdAssetAccordion = ({
  control,
  adIndex,
  mediaSpace,
  fieldDefinitions,
}: {
  control: Control<AdSetFormValues>;
  adIndex: number;
  mediaSpace: MediaSpace;
  fieldDefinitions: Array<RenderableMediaTypeField>;
}) => {
  return (
    <Accordion
      disableGutters
      sx={{
        boxShadow: 'none',
        borderTop: 'solid rgba(0, 0, 0, 0.12)',
        borderTopWidth: 'thin',
        borderRadius: '0px !important',
      }}
    >
      <AccordionSummary
        expandIcon={<KeyboardArrowDownIcon />}
        aria-controls={`ad-accordion-${adIndex}`}
        id={`ad-accordion-header-${adIndex}`}
      >
        <AccordionSummaryDetails adIndex={adIndex} mediaSpaceName={mediaSpace?.name} />
      </AccordionSummary>
      <Divider />
      <AccordionDetails>
        <Stack gap={2}>
          {fieldDefinitions.map((field, fieldIndex) => (
            <Box key={`${fieldIndex}-${field.name}`}>
              <DynamicMediaTypeField fieldDefinition={field} control={control} adIndex={adIndex} />
            </Box>
          ))}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};
