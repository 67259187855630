import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { selectUser } from '../features/session/sessionSlice';
import Invite from '../features/users/Invite';
import { INVITE_EMPLOYEE } from '../lib/permissions';

const InviteSupplier = () => {
  const user = useSelector(selectUser);
  const userPermissions = user?.role?.permissions;
  const canInviteEmployees = userPermissions ? userPermissions.find((p) => p.name === INVITE_EMPLOYEE) : false;

  if (!canInviteEmployees) {
    return <Navigate to='/' replace />;
  }

  return (
    <>
      <Helmet>
        <title>Invite Employee - {process.env.REACT_APP_NAME}</title>
      </Helmet>
      <Container className='pt-4'>
        <Row className='mb-3'>
          <Col lg='6'>
            <Invite />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default InviteSupplier;
