import React from 'react';
import { Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { MPA_ONBOARDING_FAILED, MPA_ONBOARDING_SUBMITTED } from '../../../../lib/constants';
import { styled } from 'styled-components';

interface SecondStepAlertProps {
  isEligible: boolean;
  mpaOnboardingStatus: string;
  mpaOnboardingUnsuccessfulReason?: string;
}

export function SecondStepAlert({
  isEligible,
  mpaOnboardingStatus,
  mpaOnboardingUnsuccessfulReason = '',
}: SecondStepAlertProps) {
  if (!isEligible) {
    return (
      <StyledNotEligibleAlert variant='not-eligible-info'>
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <span>
            <FontAwesomeIcon icon={faInfoCircle} />
          </span>
          <span>The supplier must be eligible before you can action the following steps.</span>
        </span>
      </StyledNotEligibleAlert>
    );
  }
  return (
    <div>
      <div style={{ marginTop: '18px' }}>
        {mpaOnboardingStatus === MPA_ONBOARDING_SUBMITTED && (
          <StyledInfoAlert variant='submitted'>
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <span>
                <FontAwesomeIcon icon={faInfoCircle} />
              </span>
              <span>
                Submission successful! Your supplier’s onboarding may take up to an hour to be completed. Please check
                back later for updates.
              </span>
            </span>
          </StyledInfoAlert>
        )}
        {mpaOnboardingStatus === MPA_ONBOARDING_FAILED && (
          <StyledFailedAlert variant='failed'>
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <span>
                <FontAwesomeIcon icon={faInfoCircle} />
              </span>
              <span>
                {mpaOnboardingUnsuccessfulReason}
                <div>
                  Please reach out to <a href='mailto://support@zitcha.com'>Zitcha Support</a> for further assistance.
                </div>
              </span>
            </span>
          </StyledFailedAlert>
        )}
      </div>
    </div>
  );
}

const StyledAlert = styled(Alert)`
  width: 100%;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  max-width: fit-content;
  margin-bottom: 0rem;
  svg {
    color: #000000;
  }
  span {
    font-size: 0.875rem;
    color: #000000;
    font-weight: 600;
  }
  a {
    color: #7155ff;
    text-decoration: underline;
  }
`;
const StyledInfoAlert = styled(StyledAlert)`
  background: #b5d0f7;
`;
const StyledFailedAlert = styled(StyledAlert)`
  background: #ef767a;
`;
const StyledNotEligibleAlert = styled(StyledAlert)`
  background-color: #b5d0f7;
  margin-top: 1em;
`;
