import React, { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';

export interface Page {
  id: string;
  page_id: string;
  name: string;
  iconUrl: string;
}
type Pagehandler = 'retailer' | 'supplier';
interface Props {
  pages: Array<Page>;
  isRetailer?: boolean;
  pageHandler?: Array<Pagehandler>;
}
const ITEMS_PER_PAGE = 5;
function PagesList({ pages, isRetailer = false, pageHandler = [] }: Props) {
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(pages.length / ITEMS_PER_PAGE);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const currentPages = pages.slice(startIndex, endIndex);

  const handlePrevPage = () => {
    setCurrentPage((oldPage) => Math.max(oldPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((oldPage) => Math.min(oldPage + 1, totalPages));
  };

  if (pages?.length === 0 && isRetailer && pageHandler?.includes('supplier')) {
    return (
      <Placeholder>
        The page(s) the Supplier shares can be seen here once they have connected their Meta account with you.
      </Placeholder>
    );
  }

  if (pages?.length === 0 && pageHandler?.includes('retailer')) {
    return <Placeholder>No pages found.</Placeholder>;
  }

  return (
    <div
      style={{
        width: 'fit-content',
      }}
    >
      <List>
        {currentPages.map((page) => (
          <li key={page.id}>
            <img src={page.iconUrl} alt={page.name} />
            {page.name} (#{page.page_id})
          </li>
        ))}
      </List>
      {pages.length > 5 && (
        <Pagination>
          <button onClick={handlePrevPage} disabled={currentPage === 1} id='prev'>
            <FontAwesomeIcon icon={faAngleLeft} />
          </button>
          <div>
            <span>
              {startIndex + 1} - {Math.min(endIndex, pages.length)}
            </span>
            <span>of</span>
            <span>{pages.length}</span>
          </div>
          <button onClick={handleNextPage} disabled={currentPage === totalPages} id='next'>
            <FontAwesomeIcon icon={faAngleRight} />
          </button>
        </Pagination>
      )}
    </div>
  );
}
export { PagesList };
const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  width: fit-content;
  li {
    display: flex;
    align-items: center;
    color: #777785;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 300;
    line-height: 140%;
    height: 3.5rem;
    padding: 0rem 1rem;
    align-items: center;
    gap: 0.5rem;
    border-bottom: 1px solid #f2efff;
    background: #fff;
    align-self: stretch;
    img {
      width: 1.5rem;
      height: 1.5rem;
      object-fit: cover;
    }
    &:last-of-type {
      border-bottom: none;
    }
  }
`;
const Pagination = styled.div`
  border: 1px solid #b9b9c0;
  background: #fff;
  display: flex;
  justify-content: space-between;
  button {
    all: unset;
    cursor: pointer;
    display: flex;
    padding: 1rem;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    &:disabled {
      cursor: not-allowed;
      color: #b9b9c0;
    }
    &:first-child {
      border-right: 1px solid #b9b9c0;
    }
    &:last-child {
      border-left: 1px solid #b9b9c0;
    }
  }
  div {
    width: 12rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    span {
      color: #000;
      text-align: center;
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 300;
      line-height: 140%;
    }
  }
`;
const Placeholder = styled.p`
  color: #000;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
`;
