import { PagesList } from 'app/OrganisationSettings/PagesList/PagesList';
import React from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import { styled } from 'styled-components';
import { OnboardingStatus } from './OnboardingStatus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { MPA_ONBOARDING_COMPLETED } from '../../../../lib/constants';
import { useActivePages } from 'features/order_form/standard_ad_section_tabs/useActivePages';

type Pagehandler = 'retailer' | 'supplier';
interface ThirdStepProps {
  retailerId: string;
  supplierId: string;
  mpaOnboardingStatus:
    | 'not-started'
    | 'new'
    | 'submitted'
    | 'failed'
    | 'completed'
    | 'action-required'
    | 'waiting_for_supplier_input';
  isRetailer: boolean;
  pageHander: Array<Pagehandler>;
}

const ThirdStep = ({ retailerId, supplierId, mpaOnboardingStatus, isRetailer, pageHander }: ThirdStepProps) => {
  const { activePages, businessManagerId } = useActivePages({
    retailerId,
    supplierId,
    enabled: !!(retailerId && supplierId),
  });

  return (
    <Row>
      <Col md='8'>
        {pageHander.includes('supplier') && (
          <>
            <Row>
              <Col>&nbsp;</Col>
            </Row>
            {mpaOnboardingStatus !== MPA_ONBOARDING_COMPLETED && (
              <StyledAlert variant='warning'>
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  <span>
                    <FontAwesomeIcon icon={faExclamationTriangle} />
                  </span>
                  <span>The Supplier has been notified to share their Meta page(s) with you.</span>
                </span>
              </StyledAlert>
            )}
            <StepTitle>
              <h5>Step 3: Page(s) to display ads</h5>
              <OnboardingStatus status={mpaOnboardingStatus} showLabel={false} />
            </StepTitle>
          </>
        )}
        <StepLabel>Business Manager (BM) ID</StepLabel>
        <HeadDesc>
          This is the child business manager app ID that Zitcha uses to enable Meta Managed Partner Ads.
        </HeadDesc>
        <BMID>{businessManagerId || ''}</BMID>
        <StepLabel>Page(s)</StepLabel>
        {activePages.length > 0 && !isRetailer && <p>To revoke access please contact support.</p>}
        <PagesList pages={activePages || []} isRetailer={isRetailer} pageHandler={pageHander} />
      </Col>
    </Row>
  );
};
export { ThirdStep };

const HeadDesc = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 0.5rem;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  text-aligned: left;
`;
const StepLabel = styled.span`
  color: #000;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 115%;
  margin-bottom: 1rem;
`;
const StepTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 1em;
  h5 {
    color: #000;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 0;
  }
`;
const BMID = styled.span`
  display: block;
  color: var(--tp-base-colour-50, #777785);
  font-size: 1.125rem;
  font-style: italic;
  font-weight: 300;
  line-height: 140%;
  margin-bottom: 1rem;
`;
const StyledAlert = styled(Alert)`
  width: 100%;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  background: #ffe787;
  font-size: 0.875rem;
  color: #00001e;
  font-weight: 600;
  max-width: fit-content;
  svg {
    color: #00001e;
  }
`;
