import PropTypes from "prop-types";
import React, { useState } from "react";
import { useEffect } from "react";
import { Col, Form } from "react-bootstrap";
import MultiRangeSlider from "multi-range-slider-react";
import Errors from "../Errors";

const maxAge = 65;
const minAge = 18;

const FacebookAge = ({
  readOnly = false,
  required = false,
  value = {},
  errors = [],
  onChange = () => { },
}) => {
  const [state, setState] = useState({
    value: value,
  });

  useEffect(() => {
    if (value?.min_age === undefined && value?.max_age === undefined) {
      onChange({
        min_age: minAge,
      });
    }
  }, []);

  const [isDirty, setIsDirty] = useState(false);

  setTimeout(() => {
    if (isDirty) {
      onChange({
        min_age: state?.value?.min_age,
        max_age: state?.value?.max_age,
      });

      setIsDirty(false);
    }
  }, 1000);

  return (
    <Form.Group controlId='age-group'>
      <Form.Label>Age</Form.Label>
      <Form.Row>
        <Col lg='6'>
          <div
            style={{ marginLeft: "10px", width: "70%", paddingBottom: "15px" }}
          >
            <MultiRangeSlider
              style={{ border: 'none', boxShadow: 'none' }}
              min={minAge}
              ruler={false}
              thumbLeftColor={'#6c757d'}
              thumbRightColor={'#6c757d'}
              barInnerColor={'#fff'}
              max={maxAge}
              step={1}
              disabled={readOnly}
              minValue={state?.value?.min_age ? parseInt(state.value.min_age) : minAge}
              maxValue={state?.value?.max_age ? parseInt(state.value.max_age) : maxAge}
              onChange={(e) => {
                //handleInput(e);
                setState({
                  value: {
                    min_age: e.minValue,
                    max_age: e.maxValue,
                  },
                });
                setIsDirty(true);
              }}
            />
          </div>
        </Col>
      </Form.Row>
      <Form.Control.Feedback type='invalid'>
        <Errors errors={errors} />
      </Form.Control.Feedback>
    </Form.Group>
  );
};

FacebookAge.propTypes = {
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.shape({
    min_age: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    max_age: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  errors: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
};

export default FacebookAge;
