import {Product, ProductFeed, ProductSet, RequestStatus} from "../../types";
import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import {getProducts} from "../../../../lib/api";
import useDebounce from "../../../../lib/hooks/useDebounce";
import {DEFAULT_DEBOUNCE_TIME} from "../../../../lib/defaults";
import {useInfiniteScroll} from "../../../../lib/hooks/useInfiniteScroll";
import ControlsContainer from "../../controls/ControlsContainer";
import Dropdown from "../../controls/Dropdown";
import Search from "../../controls/Search";
import ProductSelectorBody from "./ProductSelectorBody";
import ProductRectangle from "../ProductRectangle";

export type SelectorProps = {
    productSets: Array<ProductSet>,
    value: ProductFeed,
    onChange: (ProductFeed) => void,
};
export const Selector = (props: SelectorProps) => {
    const {
        productSets = [],
        value,
        onChange,
    } = props;

    const [products, setProducts] = useState<Array<Product>>([]);
    const [filter, setFilter] = useState<string>('');
    const [status, setStatus] = useState<RequestStatus>('idle');
    const [count, setCount] = useState<number>(0);
    const [after, setAfter] = useState<string | undefined>(undefined);
    const abortControllerRef = useRef<AbortController>(new AbortController());
    const limit = 25;

    const options = productSets.map(productSet => ({value: productSet.id, label: productSet.name}));
    const isSelected = (product: Product): boolean => value.products.some(p => p.id === product.id);

    const resetProducts = () => {
        setProducts([]);
        setAfter(undefined);
        setCount(0);
    };
    const handleFilterChange = e => setFilter(e.target.value);
    const handleProductSetChange = e => {
        onChange({
            group: productSets.find(set => set.id === e.target.value),
            products: [],
            deleted_products: [],
        });
        resetProducts();
    }
    const handleProductSelected = (product: Product) => {
        // Remove product
        if (isSelected(product)) {
            onChange({
                ...value,
                products: value.products.filter(p => p.id !== product.id),
            })
            return
        }

        // Add Product
        onChange({
            ...value,
            products: [...value.products, product],
        })
    }

    const handleReqError = thrown => {
        if (!axios.isCancel(thrown)) {
            setStatus('failed')
            throw thrown;
        }
    }

    const handleNewProducts = ({data, after, count}) => {
        setProducts(data);
        setAfter(after)
        setCount(count)
        setStatus('succeeded');
    }

    const handleAddProducts = ({data, after, count}) => {
        setProducts([...products, ...data]);
        setAfter(after);
        setCount(count);
        setStatus('succeeded');
    }

    const handleAbort = (abortControllerRef) => {
        abortControllerRef.current.abort();
        abortControllerRef.current = new AbortController();
    }

    // Handle initial page load and product set change.
    useEffect(() => {
        resetProducts();
        setStatus('loading');

        getProducts(value.group.id, filter, after, limit, {signal: abortControllerRef.current.signal})
            .then(resp => handleNewProducts(resp.data))
            .catch(handleReqError);

        return () => handleAbort(abortControllerRef)
    }, [value.group.id]);


    // Handle product text filter
    const debouncedProducts = useDebounce((groupId, filter) => {
        resetProducts();
        setStatus('loading');

        getProducts(groupId, filter, undefined, limit, {signal: abortControllerRef.current.signal})
            .then(resp => handleNewProducts(resp.data))
            .catch(handleReqError);
    }, DEFAULT_DEBOUNCE_TIME);

    useEffect(() => {
        if (status === 'idle') {
            return;
        }

        debouncedProducts(value.group.id, filter);

        return () => handleAbort(abortControllerRef);
    }, [filter]);

    // Handle infinite scroll
    const ref = useInfiniteScroll(() => {
        if (products.length >= count || status === 'loading') {
            return;
        }

        setStatus('loading');
        getProducts(value.group.id, filter, after, limit, {signal: abortControllerRef.current.signal})
            .then(resp => handleAddProducts(resp.data))
            .catch(handleReqError);

        return () => handleAbort(abortControllerRef);
    }, [products.length, count, status, after, filter, value.group.id]);

    return <div>
        <ControlsContainer>
            <Dropdown
                label='Product Set'
                width='15rem'
                value={value.group?.id || ''}
                options={options}
                onChange={handleProductSetChange}
            />
            <Search
                value={filter}
                onChange={handleFilterChange}
            />
        </ControlsContainer>
        <ProductSelectorBody ref={ref} status={status} productsCount={products.length}>
            {products.map(product => (
                <ProductRectangle
                    key={product.id}
                    src={product.image_url}
                    name={product.title}
                    price={product.price}
                    currency={product.currency}
                    checked={isSelected(product)}
                    onClick={() => handleProductSelected(product)}
                />
            ))}
        </ProductSelectorBody>
    </div>
};