import React from 'react';
import { MenuItem } from '@zitcha/component-library';
import { Select, InputLabel, FormControl } from '@mui/material';
import { AdSetStatusEnum } from 'v2/lib/api/ad-management';

type SelectOption = {
  value: string | number | undefined | AdSetStatusEnum;
  label: string;
};

type CustomSelectProps = {
  id: string;
  label: string;
  options: Array<SelectOption>;
  selectedValue: AdSetStatusEnum | undefined;
  onChange: (value: AdSetStatusEnum | undefined) => void;
};

export const CustomSelect: React.FC<CustomSelectProps> = ({ id, label, options, selectedValue, onChange }) => {
  return (
    <>
      <FormControl sx={{ minWidth: 140, width: '100%' }}>
        <InputLabel id={`${id}-label`} color='primary'>
          {label}
        </InputLabel>
        <Select
          id={id}
          labelId={`${id}-label`}
          value={selectedValue}
          onChange={(event) => onChange(event.target.value as AdSetStatusEnum)}
          label={label}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};
