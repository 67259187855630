import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { faWindow } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import CircularBadge from '../orders/components/CircularBadge'
import styles from './ad_section.module.scss'
import { hasLiveOrderBeingViewed } from './orderFormSlice'

const AdContainer = React.forwardRef((props, ref) => {
    const {
        readOnly = false,
        header = '',
        headerActions = undefined,
        ad = {},
        errors = {},
        onDelete = () => { },
        children,
    } = props
    const liveOrderViewed = useSelector(hasLiveOrderBeingViewed)
    const [deleteModal, setDeleteModal] = useState(false)

    return <section className={`rounded ${styles.section} ${Object.keys(errors).length > 0 ? styles.error_section : ""}`} ref={ref}>
        <div className={`${styles.ad_header}`}>
            <div className='d-flex align-items-center'>
                <div className={styles.ad_header_title}>
                    <FontAwesomeIcon icon={faWindow} style={{ marginRight: "5px" }}/>{header}
                </div>
                {headerActions}
                {!readOnly && !ad.package_collection_id && !liveOrderViewed && (
                    <Button
                        className={headerActions ? '' : 'ml-auto'}
                        variant='outline-muted'
                        onClick={(e) => setDeleteModal(true)}
                    >
                        <FontAwesomeIcon icon={faTimes} />
                    </Button>
                )}
                {ad?.linked_campaign?.live_status === "live" && (
                    <div className='ml-3 mr-2 mb-1'>
                        <CircularBadge />
                    </div>
                )}
            </div>
            {ad?.linked_campaign?.id &&
                <div className={styles.linked_campaign}>{ad.platform.charAt(0).toUpperCase() + ad.platform.slice(1)} Campaign ID: {ad?.linked_campaign?.id}</div>
            }
        </div>

        <div>
            {children}
        </div>

        <Modal show={deleteModal} onHide={() => setDeleteModal(false)}>
            <Modal.Header closeButton>Remove ad?</Modal.Header>
            <Modal.Body>
                Are you sure you want to remove this ad? An unsaved ad will be
                unrecoverable.
            </Modal.Body>
            <Modal.Footer>
                <Button variant='secondary' onClick={() => setDeleteModal(false)}>
                    Cancel
                </Button>
                <Button
                    type='submit'
                    variant='danger'
                    className='ml-2'
                    onClick={(e) => {
                        onDelete();
                        setDeleteModal(false);
                    }}
                >
                    Remove
                </Button>
            </Modal.Footer>
        </Modal>
    </section>
})

AdContainer.propTypes = {
    controlId: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
    ad: PropTypes.shape({
        platform: PropTypes.string,
        type: PropTypes.string.isRequired,
    }).isRequired,
    assets: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
        })
    ),
    onChange: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    errors: PropTypes.object,
}

export default AdContainer
