import React from 'react';
import styled from 'styled-components';
import { UploadDynamicAsset } from './UploadDynamicAsset';
import { useSelector } from "react-redux";
import { selectOwnerOrganisation } from '../../orderFormSlice';
import { DEFAULT_MEDIA_TYPES_VALUE } from '../../../../app/OrganisationSettings/Broadsign/constants';
import { cloneDeep } from 'lodash';

const ConditionalUploadSectionTopPadding = styled.div`
  margin-top: ${(props) => (props.isFirst ? '0px' : '22px')};
`;

export const AssetCardContent = ({ ad, creativeAssetIndex, handleChange, orderAssets, readOnly }) => {
  const organisation = useSelector(selectOwnerOrganisation);
  const selectedMediaEntry = ad?.broadsign_media?.[creativeAssetIndex] || [];
  const availableMediaTypes = organisation?.in_store_screens_settings?.media_types ?? cloneDeep(DEFAULT_MEDIA_TYPES_VALUE);

  if (!ad?.screen_types?.length) return <>No Screen Types Selected</>;

  return ad?.screen_types?.map((screenType, index) => (
    <ConditionalUploadSectionTopPadding isFirst={index === 0} key={screenType}>
      <UploadDynamicAsset
        screenType={screenType}
        availableMediaTypes={availableMediaTypes[screenType]}
        ad={ad}
        creativeAssetIndex={creativeAssetIndex}
        handleChange={handleChange}
        value={selectedMediaEntry.find((v) => v.screen_type === screenType) || {}}
        readOnly={readOnly}
        orderAssets={orderAssets}
      />
    </ConditionalUploadSectionTopPadding>
  ));
};
