import React from 'react';
import { useAdSetBundle } from './useAdSetBundle';
import { AdSetData } from './PlanContext';

interface AdSetBundleWrapperProps {
  adSet: AdSetData;
  retailerId: string | null;
  children: (isLoading: boolean) => React.ReactNode;
}

export const AdSetBundleWrapper: React.FC<AdSetBundleWrapperProps> = ({ adSet, retailerId, children }) => {
  const { isLoading } = useAdSetBundle(adSet, retailerId);
  return <>{children(isLoading)}</>;
};
