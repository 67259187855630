import React from 'react';
import { Badge } from 'react-bootstrap';
import styled from 'styled-components';
import { STATUS_PENDING_APPROVAL } from '../../../lib/orders';



const NewBadge = styled(Badge)`
  color: #3a3b4f;
  background-color: #ffe787;
`;
type Pending= typeof STATUS_PENDING_APPROVAL
interface BadgeProps {
  variant: 'info'| 'primary'| 'secondary'| 'danger'| Pending | 'success',
  label:string
}

const StatusBadge = ({ variant, label }:BadgeProps) => {
  let SBadge = Badge;
  if (variant === STATUS_PENDING_APPROVAL) {
    SBadge = NewBadge;
  }

  return <SBadge variant={variant || 'primary'}>{label}</SBadge>;
};

export default StatusBadge;
