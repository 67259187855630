import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import styles from '../owned_space_section.module.scss';
import Products from '../../common/inputs/Products';

export const FeedTab = ({ webSpace, readOnly, errors, ad, template, handleChange, productsCustomFields }) => (
  <div className={styles.form_section}>
    <Container>
      <Row>
        <Col sm='12'>
          {webSpace?.format === 'product' && !readOnly && (
            <div className='d-flex flex-column overflow-auto p-2'>
              <Products
                errors={errors?.products}
                value={ad.feed}
                min={template?.meta_fields?.props?.['min-products']}
                max={template?.meta_fields?.props?.['max-products']}
                multipleOf={template?.meta_fields?.props?.['products-group']}
                onChange={(newFeed) => {
                  const feed = { ...ad?.feed };
                  const products = (feed?.products ?? []).map((product) => product.id);
                  const newProducts = Array.isArray(newFeed?.products) ? [...newFeed.products] : [];
                  const adCustomProductFields = {};

                  productsCustomFields.forEach((field) => {
                    if (ad && ad.hasOwnProperty(field.id)) {
                      adCustomProductFields[field.id] = ad[field.id];
                    }
                  });

                  newProducts.map((product) => {
                    const oldProduct = products?.find((p) => p.id === product.id);
                    if (oldProduct) {
                      return {
                        ...oldProduct,
                      };
                    }
                    return product;
                  });

                  newProducts.forEach((product) => {
                    // if new product (not in the old list)
                    if (!products.includes(product.id)) {
                      Object.keys(adCustomProductFields).forEach((key) => {
                        product[key] = adCustomProductFields[key];
                      });
                    }
                  });

                  handleChange({ feed: newFeed });
                }}
              />
            </div>
          )}
        </Col>
      </Row>
    </Container>
  </div>
);
