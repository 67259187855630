import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-bootstrap';
import { DescriptionContainer, ErrorFeedback, LabelText, LengthBadge, TextAreaInput } from './Common';
import Errors from './Errors';

const TextArea = ({
  controlId,
  label,
  description,
  readOnly = false,
  disabled = false,
  required = false,
  placeholder = '',
  value = '',
  onChange = () => {},
  errors = [],
  maxLength,
  maxLengthSoft,
}) => (
  <Form.Group controlId={controlId}>
    <Form.Label className='d-flex align-self-center align-items-center font-weight-bold'>
      <LabelText>
        {label} {required && '*'}{' '}
      </LabelText>
      {maxLengthSoft !== undefined && (
        <LengthBadge readOnly={readOnly} className='ml-auto' variant='primary'>
          {value.length} / {maxLengthSoft} characters
        </LengthBadge>
      )}
    </Form.Label>
    <DescriptionContainer>{description}</DescriptionContainer>
    <TextAreaInput
      as='textarea'
      rows='3'
      placeholder={placeholder}
      readOnly={readOnly}
      disabled={disabled || readOnly}
      value={value}
      isInvalid={errors.length > 0}
      onKeyUp={(e) => {
        e.preventDefault();
        if (e.key === 'Enter') {
          onChange(value + '\n');
        }
      }}
      onChange={(e) => onChange(e.target.value)}
      required={required}
      maxLength={maxLength}
      style={{
        border: '1px solid #ced4da',
      }}
    />
    <ErrorFeedback type='invalid' isInvalid={errors.length > 0}>
      <Errors errors={errors} />
    </ErrorFeedback>
  </Form.Group>
);

TextArea.propTypes = {
  controlId: PropTypes.string.isRequired,
  label: PropTypes.string,
  description: PropTypes.string,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  maxLength: PropTypes.number,
  maxLengthSoft: PropTypes.number,
};

export default TextArea;
