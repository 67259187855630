import { Carousel } from 'react-bootstrap'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './google_text.module.scss'

const Body = ({ link, header, description }) =>
    <div className={`py-3 px-4 ${styles.container}`}>
        <div className={styles.subtext}>
            <span className={styles.ad}>
                Ad<span style={{ padding: '0 5px' }}>·</span>
            </span>
            <span className={styles.link}>
                <span>{link}</span>
            </span>
            <span className={styles.arrow}></span>
        </div>
        <div className={styles.header}>{header}</div>
        <div className={styles.body}>{description}</div>
    </div>

Body.propTypes = {
    header: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
}


const GoogleExpandedText = ({ header, descriptions, link }) => (
    <Carousel indicators={false} controls={descriptions.length > 1} interval={null} style={{ minWidth: '17.5rem' }}>
        {descriptions.map((description, i) =>
            <Carousel.Item key={i} className='px-5'>
                <Body
                    header={header}
                    description={description}
                    link={link}
                />
            </Carousel.Item>)}
    </Carousel>

)

GoogleExpandedText.propTypes = {
    header: PropTypes.string.isRequired,
    descriptions: PropTypes.arrayOf(PropTypes.string).isRequired,
    link: PropTypes.string.isRequired,
}

export default GoogleExpandedText
