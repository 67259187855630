import {  faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import Errors from '../Errors';
import InterestRow from './InterestRow';

const Interests = ({ readOnly = false, required = false, platform, value = [], errors = [], onChange = () => {} }) => {
  const [newField, setNewField] = useState(false);

  const handleChange = (i, obj) => {
    let arr = value.slice();
    arr[i] = obj;
    onChange(arr);
  };
  const handleDelete = (val, id) => onChange(value.filter((_, i) => `interest-row-${i}` !== id));

  return (
    <Form.Group
      controlId='interest-group'
      className='col-md-6'
      style={{ display: 'inline-block', float: 'left', width: '100%', 'padding-left': '0', 'margin-left': '0' }}
    >
      <div className='d-flex'>
        <Form.Label>Interests</Form.Label>
        <a
          className='ml-2'
          target='_blank'
          rel='noopener noreferrer'
          href={
            platform === 'facebook'
              ? 'https://www.zitcha.com/en/knowledge/hc/en-us/articles/4402350882841-about-facebook-s-interest-audiences'
              : 'https://www.zitcha.com/en/knowledge/hc/en-us/articles/4402394892953-which-google-audiences-are-available-in-zitcha'
          }
        >
          <FontAwesomeIcon icon={faInfoCircle} />
        </a>
      </div>
      <Form.Control.Feedback type='invalid' className={errors.length > 0 ? 'd-block' : ''}>
        <Errors errors={errors} />
      </Form.Control.Feedback>
      <InterestRow
        id={`interest-row-${value.length}`}
        readOnly={readOnly}
        required={required}
        platform={platform}
        onAddClick={(val) => {
          handleChange(value.length, val);
        }}
        onDelete={(e) => {
          handleDelete(e);
          setNewField(false);
        }}
      />
    </Form.Group>
  );
};

Interests.propTypes = {
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  platform: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.any, type: PropTypes.string })),
  errors: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
};

export default Interests;
