import { debounce } from 'debounce';
import { sortBy as lodashSortBy } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import OrganisationCampaignStatistics from '../../features/campaign/OrganisationCampaignStatistics';
import MultiSelect from '../../features/order_form/components/MultiSelect';
import SingleCategorySelect from '../../features/order_form/components/SingleCategorySelect';
import {
  selectDashboardStatisticsOptions,
  selectSession,
  selectUser,
  selectUserOrganisationCurrency,
} from '../../features/session/sessionSlice';
import { getDashbardStatistics } from '../../lib/api';
import { FALLBACK_CURRENCY, calculateRoas } from '../../lib/financial';
import { cachedFetchBrands, selectAllBrands } from '../../features/brands/brandsSlice';
import { cacheFetchRetailers, selectAllRetailers } from '../../features/retailers/retailersSlice';
import { Throbber } from '../../features/common/Throbber';

const MetricsOverview = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const options = useSelector(selectDashboardStatisticsOptions);
  const retailers = useSelector(selectAllRetailers);
  const suppliers = useSelector(selectAllBrands);
  const [filters, setFilters] = useState({});
  const retailerCurrency = useSelector(selectUserOrganisationCurrency);
  const platforms = useSelector(selectSession).platforms;

  const updateFilters = () => {
    const filters = {
      after: '',
      before: '',
      metrics: user.preferences_json?.dashboard_statistics || options.map((option) => option.id),
      platforms: platforms.map((platform) => platform.id),
      suppliersOrRetailers:
        suppliers.length > 0 ? 'select-all-suppliers' : retailers.length > 0 ? retailers[0]['id'] : '',
      type: metricsType.map((m) => m.id),
    };
    setFilters(filters);
  };

  useEffect(() => {
    updateFilters();
  }, [options, platforms, suppliers, retailers]);

  useEffect(() => {
    dispatch(cachedFetchBrands());
    dispatch(cacheFetchRetailers());
  }, []);

  const metricsType = [
    { id: 'orders', label: 'Orders' },
    { id: 'performance', label: 'Performance' },
    { id: 'financial', label: 'Financial' },
  ];

  const retailersAndSuppliers = useMemo(
    () => [
      {
        label: 'Retailers',
        type: 'retailers',
        options: lodashSortBy(
          retailers.map((r) => ({ value: r.id, label: r.name })),
          'label'
        ),
      },
      {
        label: 'Suppliers',
        type: 'suppliers',
        options:
          suppliers.length > 0
            ? [
                { value: 'select-all-suppliers', label: 'All Suppliers' },
                ...lodashSortBy(
                  suppliers.map((s) => ({ value: s.id, label: s.name })),
                  'label'
                ),
              ]
            : [],
      },
    ],
    [suppliers, retailers]
  );

  const [metrics, setMetrics] = useState([]);

  const debounceHandler = useMemo(
    () =>
      debounce((filters) => {
        getDashbardStatistics(filters)
          .then((statistics) => {
            let stats = statistics.data.data;
            stats = stats.filter((s) => s.id !== 'total_roas');
            let conv = stats.find((s) => s.id === 'total_conversion_value')?.value ?? 0;
            let spend = stats.find((s) => s.id === 'spend')?.value ?? 0;
            let roas = calculateRoas(conv, spend);

            stats = [...stats, { id: 'total_roas', label: 'ROAS', unit: 'currency', value: roas }];
            setMetrics(
              stats.filter((m) => {
                if (m.label.toLowerCase().includes('order') && filters.type.includes('orders')) {
                  return true;
                } else if (m?.unit === 'currency' && filters.type.includes('financial')) {
                  return true;
                } else if (
                  !m.label.toLowerCase().includes('order') &&
                  m?.unit !== 'currency' &&
                  filters.type.includes('performance')
                ) {
                  return true;
                }
                return false;
              })
            );
          })
          .catch(() => {});
      }, 800),
    []
  );

  useEffect(() => {
    if (filters?.suppliersOrRetailers) {
      debounceHandler(filters);
    }
  }, [filters, debounceHandler]);

  const setSelection = (options, value) => {
    if (options && options.length > 0) {
      for (const i in options) {
        let tmp = options[i];
        let res = tmp.options.find((option) => value === option.value);
        if (res) {
          return res;
        }
      }
    }
  };

  const categorySelectValue = useMemo(
    () => setSelection(retailersAndSuppliers, filters.suppliersOrRetailers),
    [retailersAndSuppliers, filters.suppliersOrRetailers]
  );

  const currency =
    retailers.find((r) => r.id === categorySelectValue?.value)?.settings?.defaultCurrency ||
    retailerCurrency ||
    FALLBACK_CURRENCY;

  return (
    <div>
      <div className='d-flex align-items-center mb-4'>
        <h5 className='mb-0'>Performance Overview</h5>
        <span
          className='ml-auto d-flex flex-wrap justify-content-end'
          style={{
            minWidth: '12rem',
            flexGrow: '1',
            flexShrink: '0',
            flexBasis: '12rem',
          }}
        >
          {(suppliers.length > 0 || retailers.length > 0) && (
            <span className='mr-2 my-auto' style={{ minWidth: '13rem' }}>
              <SingleCategorySelect
                title={
                  suppliers.length > 0 && retailers.length > 0
                    ? 'Suppliers or Retailers'
                    : suppliers.length > 0
                    ? 'Suppliers'
                    : 'Retailers'
                }
                options={retailersAndSuppliers}
                value={categorySelectValue}
                onChange={(value) => {
                  setFilters({ ...filters, suppliersOrRetailers: value });
                }}
              />
            </span>
          )}
          <span className='mr-2 my-auto' style={{ minWidth: '12rem' }}>
            <MultiSelect
              title='Type'
              options={metricsType.map((type) => ({
                label: type.label,
                value: type.id,
              }))}
              value={filters.type}
              onChange={(value) => {
                setFilters({ ...filters, type: value });
              }}
            />
          </span>
        </span>
      </div>
      {metrics.length === 0 && <Throbber />}
      <OrganisationCampaignStatistics metrics={metrics} currency={currency} />
    </div>
  );
};

export default MetricsOverview;
