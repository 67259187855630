import PropTypes from 'prop-types'
import React from 'react'
import styles from './google_short_video.module.scss'

const GoogleShortVideo = ({ src, header, link, callToAction }) => {
    return (
        <div className='p-3 bg-white'>
            <div className={styles.display_text}>Not actual display of the ad on the device</div>
            <div className='embed-responsive embed-responsive-16by9'>
                <video
                    width='100%'
                    key={src}
                    className='embed-responsive-item'
                    controls
                    style={{ backgroundColor: 'black' }}
                >
                    <source src={src} />
                </video>
            </div>
            <div className={styles.info}>
                <div className={styles.ad_background}>
                    <div className={styles.ad_logo_container}>
                        <img src='/ad_preview/logo.png' alt='' className={styles.ad_logo} />
                    </div>
                    <div>
                        <div>{header}</div>
                        <div className={styles.ad_link_container}>
                            <span className={styles.ad_badge}>Ad</span>
                            <div className={styles.ad_link}>{link}</div>
                        </div>
                    </div>
                    <div className='ml-auto'>
                        <div className={styles.ad_button}>
                            {callToAction}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

GoogleShortVideo.propTypes = {
    src: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    callToAction: PropTypes.string.isRequired,
}

export default GoogleShortVideo
