import { Stack } from '@zitcha/component-library';
import React from 'react';
import { MetricsCard } from 'v2/components/DataDisplay/MetricsCard';

export const KeyMetrics = () => {
  return (
    <Stack spacing={2} direction='row' flexWrap='wrap' useFlexGap>
      <MetricsCard heading='Revenue' subText='£141,235 this past month' percent={6} />
      <MetricsCard heading='Click-through-rate' subText='5% this past month' percent={-20} />
      <MetricsCard heading='Conversion rate' subText='7% this past month' percent={38} />
      <MetricsCard heading='Return on ad spend' subText='£42,478 this past month' percent={38} />
    </Stack>
  );
};
