/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * Zitcha Ad Management API
 * This is the API endpoint to manage ads.
 * OpenAPI spec version: 1.0.0
 */
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import type {
  InfiniteData,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type {
  AdSet,
  AdSetSearch,
  Ads,
  Asset,
  Bundle,
  BundleSearch,
  CreateAdSetBody,
  CreateAdSetFromBundleBody,
  CreateDiscountRequestBody,
  DiscountResponseResponse,
  GetAds200,
  GetAds404,
  GetBundles200,
  GetCalendarPeriods200,
  GetCalendarPeriodsParams,
  GetLocationTypes200,
  GetLocationTypesParams,
  GetLocations200,
  GetLocationsParams,
  GetPlanAdSets200,
  GetPlanAdSetsParams,
  GetPlans200,
  Plan,
  PlanCreateForm,
  PlanSearch,
  PlanUpdateForm,
  PostAssetsBody,
  SearchAdSets200,
  TransitionAdSetBody,
  TransitionPlanBody,
  UpdateAdSetBody,
  UpdateAdsInAdSetBody,
  UpdateDiscountRequestBody,
} from '../model';
import { customInstance } from '../../../axios/axiosMainInstance';
import type { ErrorType, BodyType } from '../../../axios/axiosMainInstance';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * Get all the bundles that match the specified criteria
 * @summary Find all the bundles
 */
export const getBundles = (bundleSearch: BodyType<BundleSearch>, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<GetBundles200>(
    {
      url: `/ad-management/v1/bundle/search`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: bundleSearch,
    },
    options
  );
};

export const getGetBundlesQueryKey = (bundleSearch: BundleSearch) => {
  return [`/ad-management/v1/bundle/search`, bundleSearch] as const;
};

export const getGetBundlesQueryOptions = <TData = Awaited<ReturnType<typeof getBundles>>, TError = ErrorType<void>>(
  bundleSearch: BundleSearch,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getBundles>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBundlesQueryKey(bundleSearch);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBundles>>> = ({ signal }) =>
    getBundles(bundleSearch, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getBundles>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetBundlesQueryResult = NonNullable<Awaited<ReturnType<typeof getBundles>>>;
export type GetBundlesQueryError = ErrorType<void>;

/**
 * @summary Find all the bundles
 */
export const useGetBundles = <TData = Awaited<ReturnType<typeof getBundles>>, TError = ErrorType<void>>(
  bundleSearch: BundleSearch,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getBundles>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetBundlesQueryOptions(bundleSearch, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Get the details of a bundle by its Id.
 * @summary Get a bundle
 */
export const getBundle = (bundleId: string, options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<Bundle>({ url: `/ad-management/v1/bundle/${bundleId}`, method: 'GET', signal }, options);
};

export const getGetBundleQueryKey = (bundleId: string) => {
  return [`/ad-management/v1/bundle/${bundleId}`] as const;
};

export const getGetBundleQueryOptions = <TData = Awaited<ReturnType<typeof getBundle>>, TError = ErrorType<void>>(
  bundleId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getBundle>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBundleQueryKey(bundleId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBundle>>> = ({ signal }) =>
    getBundle(bundleId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!bundleId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getBundle>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetBundleQueryResult = NonNullable<Awaited<ReturnType<typeof getBundle>>>;
export type GetBundleQueryError = ErrorType<void>;

/**
 * @summary Get a bundle
 */
export const useGetBundle = <TData = Awaited<ReturnType<typeof getBundle>>, TError = ErrorType<void>>(
  bundleId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getBundle>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetBundleQueryOptions(bundleId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get the available locations
 */
export const getLocations = (
  params: GetLocationsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<GetLocations200>({ url: `/ad-management/v1/location`, method: 'GET', params, signal }, options);
};

export const getGetLocationsQueryKey = (params: GetLocationsParams) => {
  return [`/ad-management/v1/location`, ...(params ? [params] : [])] as const;
};

export const getGetLocationsInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof getLocations>>, GetLocationsParams['page']>,
  TError = ErrorType<void>,
>(
  params: GetLocationsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof getLocations>>,
        TError,
        TData,
        Awaited<ReturnType<typeof getLocations>>,
        QueryKey,
        GetLocationsParams['page']
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetLocationsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getLocations>>, QueryKey, GetLocationsParams['page']> = ({
    signal,
    pageParam,
  }) => getLocations({ ...params, page: pageParam || params?.['page'] }, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getLocations>>,
    TError,
    TData,
    Awaited<ReturnType<typeof getLocations>>,
    QueryKey,
    GetLocationsParams['page']
  > & { queryKey: QueryKey };
};

export type GetLocationsInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getLocations>>>;
export type GetLocationsInfiniteQueryError = ErrorType<void>;

/**
 * @summary Get the available locations
 */
export const useGetLocationsInfinite = <
  TData = InfiniteData<Awaited<ReturnType<typeof getLocations>>, GetLocationsParams['page']>,
  TError = ErrorType<void>,
>(
  params: GetLocationsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof getLocations>>,
        TError,
        TData,
        Awaited<ReturnType<typeof getLocations>>,
        QueryKey,
        GetLocationsParams['page']
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetLocationsInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getGetLocationsQueryOptions = <TData = Awaited<ReturnType<typeof getLocations>>, TError = ErrorType<void>>(
  params: GetLocationsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getLocations>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetLocationsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getLocations>>> = ({ signal }) =>
    getLocations(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getLocations>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetLocationsQueryResult = NonNullable<Awaited<ReturnType<typeof getLocations>>>;
export type GetLocationsQueryError = ErrorType<void>;

/**
 * @summary Get the available locations
 */
export const useGetLocations = <TData = Awaited<ReturnType<typeof getLocations>>, TError = ErrorType<void>>(
  params: GetLocationsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getLocations>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetLocationsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get the available location types
 */
export const getLocationTypes = (
  params: GetLocationTypesParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<GetLocationTypes200>(
    { url: `/ad-management/v1/location-types`, method: 'GET', params, signal },
    options
  );
};

export const getGetLocationTypesQueryKey = (params: GetLocationTypesParams) => {
  return [`/ad-management/v1/location-types`, ...(params ? [params] : [])] as const;
};

export const getGetLocationTypesQueryOptions = <
  TData = Awaited<ReturnType<typeof getLocationTypes>>,
  TError = ErrorType<void>,
>(
  params: GetLocationTypesParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getLocationTypes>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetLocationTypesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getLocationTypes>>> = ({ signal }) =>
    getLocationTypes(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getLocationTypes>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetLocationTypesQueryResult = NonNullable<Awaited<ReturnType<typeof getLocationTypes>>>;
export type GetLocationTypesQueryError = ErrorType<void>;

/**
 * @summary Get the available location types
 */
export const useGetLocationTypes = <TData = Awaited<ReturnType<typeof getLocationTypes>>, TError = ErrorType<void>>(
  params: GetLocationTypesParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getLocationTypes>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetLocationTypesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get the available calendar periods
 */
export const getCalendarPeriods = (
  params: GetCalendarPeriodsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<GetCalendarPeriods200>(
    { url: `/ad-management/v1/calendar-period`, method: 'GET', params, signal },
    options
  );
};

export const getGetCalendarPeriodsQueryKey = (params: GetCalendarPeriodsParams) => {
  return [`/ad-management/v1/calendar-period`, ...(params ? [params] : [])] as const;
};

export const getGetCalendarPeriodsQueryOptions = <
  TData = Awaited<ReturnType<typeof getCalendarPeriods>>,
  TError = ErrorType<void>,
>(
  params: GetCalendarPeriodsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getCalendarPeriods>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCalendarPeriodsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCalendarPeriods>>> = ({ signal }) =>
    getCalendarPeriods(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getCalendarPeriods>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCalendarPeriodsQueryResult = NonNullable<Awaited<ReturnType<typeof getCalendarPeriods>>>;
export type GetCalendarPeriodsQueryError = ErrorType<void>;

/**
 * @summary Get the available calendar periods
 */
export const useGetCalendarPeriods = <TData = Awaited<ReturnType<typeof getCalendarPeriods>>, TError = ErrorType<void>>(
  params: GetCalendarPeriodsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getCalendarPeriods>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCalendarPeriodsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Get all the plans that match the specified criteria
 * @summary Find all the plans
 */
export const getPlans = (planSearch: BodyType<PlanSearch>, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<GetPlans200>(
    {
      url: `/ad-management/v1/plan/search`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: planSearch,
    },
    options
  );
};

export const getGetPlansQueryKey = (planSearch: PlanSearch) => {
  return [`/ad-management/v1/plan/search`, planSearch] as const;
};

export const getGetPlansQueryOptions = <TData = Awaited<ReturnType<typeof getPlans>>, TError = ErrorType<void>>(
  planSearch: PlanSearch,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPlans>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPlansQueryKey(planSearch);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPlans>>> = ({ signal }) =>
    getPlans(planSearch, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPlans>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPlansQueryResult = NonNullable<Awaited<ReturnType<typeof getPlans>>>;
export type GetPlansQueryError = ErrorType<void>;

/**
 * @summary Find all the plans
 */
export const useGetPlans = <TData = Awaited<ReturnType<typeof getPlans>>, TError = ErrorType<void>>(
  planSearch: PlanSearch,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPlans>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPlansQueryOptions(planSearch, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Create a new plan.
 * @summary Create a plan
 */
export const createPlan = (
  planCreateForm: BodyType<PlanCreateForm>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<Plan>(
    {
      url: `/ad-management/v1/plan`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: planCreateForm,
    },
    options
  );
};

export const getCreatePlanMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createPlan>>,
    TError,
    { data: BodyType<PlanCreateForm> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createPlan>>,
  TError,
  { data: BodyType<PlanCreateForm> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof createPlan>>, { data: BodyType<PlanCreateForm> }> = (
    props
  ) => {
    const { data } = props ?? {};

    return createPlan(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreatePlanMutationResult = NonNullable<Awaited<ReturnType<typeof createPlan>>>;
export type CreatePlanMutationBody = BodyType<PlanCreateForm>;
export type CreatePlanMutationError = ErrorType<void>;

/**
 * @summary Create a plan
 */
export const useCreatePlan = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createPlan>>,
    TError,
    { data: BodyType<PlanCreateForm> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<Awaited<ReturnType<typeof createPlan>>, TError, { data: BodyType<PlanCreateForm> }, TContext> => {
  const mutationOptions = getCreatePlanMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Get the details of a plan by its Id.
 * @summary Get a plan
 */
export const getPlan = (id: string, options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<Plan>({ url: `/ad-management/v1/plan/${id}`, method: 'GET', signal }, options);
};

export const getGetPlanQueryKey = (id: string) => {
  return [`/ad-management/v1/plan/${id}`] as const;
};

export const getGetPlanQueryOptions = <TData = Awaited<ReturnType<typeof getPlan>>, TError = ErrorType<void>>(
  id: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPlan>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPlanQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPlan>>> = ({ signal }) =>
    getPlan(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPlan>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPlanQueryResult = NonNullable<Awaited<ReturnType<typeof getPlan>>>;
export type GetPlanQueryError = ErrorType<void>;

/**
 * @summary Get a plan
 */
export const useGetPlan = <TData = Awaited<ReturnType<typeof getPlan>>, TError = ErrorType<void>>(
  id: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPlan>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPlanQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Update a plan by its Id.
 * @summary Update a plan
 */
export const updatePlan = (
  id: string,
  planUpdateForm: BodyType<PlanUpdateForm>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<Plan>(
    {
      url: `/ad-management/v1/plan/${id}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: planUpdateForm,
    },
    options
  );
};

export const getUpdatePlanMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePlan>>,
    TError,
    { id: string; data: BodyType<PlanUpdateForm> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updatePlan>>,
  TError,
  { id: string; data: BodyType<PlanUpdateForm> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updatePlan>>,
    { id: string; data: BodyType<PlanUpdateForm> }
  > = (props) => {
    const { id, data } = props ?? {};

    return updatePlan(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdatePlanMutationResult = NonNullable<Awaited<ReturnType<typeof updatePlan>>>;
export type UpdatePlanMutationBody = BodyType<PlanUpdateForm>;
export type UpdatePlanMutationError = ErrorType<void>;

/**
 * @summary Update a plan
 */
export const useUpdatePlan = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePlan>>,
    TError,
    { id: string; data: BodyType<PlanUpdateForm> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updatePlan>>,
  TError,
  { id: string; data: BodyType<PlanUpdateForm> },
  TContext
> => {
  const mutationOptions = getUpdatePlanMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Transition a plan by its Id.
 * @summary Transition a plan
 */
export const transitionPlan = (
  id: string,
  transitionPlanBody: BodyType<TransitionPlanBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    {
      url: `/ad-management/v1/plan/${id}/transition`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: transitionPlanBody,
    },
    options
  );
};

export const getTransitionPlanMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof transitionPlan>>,
    TError,
    { id: string; data: BodyType<TransitionPlanBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof transitionPlan>>,
  TError,
  { id: string; data: BodyType<TransitionPlanBody> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof transitionPlan>>,
    { id: string; data: BodyType<TransitionPlanBody> }
  > = (props) => {
    const { id, data } = props ?? {};

    return transitionPlan(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type TransitionPlanMutationResult = NonNullable<Awaited<ReturnType<typeof transitionPlan>>>;
export type TransitionPlanMutationBody = BodyType<TransitionPlanBody>;
export type TransitionPlanMutationError = ErrorType<void>;

/**
 * @summary Transition a plan
 */
export const useTransitionPlan = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof transitionPlan>>,
    TError,
    { id: string; data: BodyType<TransitionPlanBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof transitionPlan>>,
  TError,
  { id: string; data: BodyType<TransitionPlanBody> },
  TContext
> => {
  const mutationOptions = getTransitionPlanMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Get all the ad sets that match the specified criteria
 * @summary Find all the ad sets
 */
export const searchAdSets = (adSetSearch: BodyType<AdSetSearch>, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<SearchAdSets200>(
    {
      url: `/ad-management/v1/ad-sets/search`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: adSetSearch,
    },
    options
  );
};

export const getSearchAdSetsQueryKey = (adSetSearch: AdSetSearch) => {
  return [`/ad-management/v1/ad-sets/search`, adSetSearch] as const;
};

export const getSearchAdSetsQueryOptions = <TData = Awaited<ReturnType<typeof searchAdSets>>, TError = ErrorType<void>>(
  adSetSearch: AdSetSearch,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof searchAdSets>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSearchAdSetsQueryKey(adSetSearch);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof searchAdSets>>> = ({ signal }) =>
    searchAdSets(adSetSearch, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof searchAdSets>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SearchAdSetsQueryResult = NonNullable<Awaited<ReturnType<typeof searchAdSets>>>;
export type SearchAdSetsQueryError = ErrorType<void>;

/**
 * @summary Find all the ad sets
 */
export const useSearchAdSets = <TData = Awaited<ReturnType<typeof searchAdSets>>, TError = ErrorType<void>>(
  adSetSearch: AdSetSearch,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof searchAdSets>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSearchAdSetsQueryOptions(adSetSearch, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Get the ad sets for a plan.
 * @summary Get the ad sets for a plan
 */
export const getPlanAdSets = (
  plan: string,
  params?: GetPlanAdSetsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<GetPlanAdSets200>(
    { url: `/ad-management/v1/plan/${plan}/ad-sets`, method: 'GET', params, signal },
    options
  );
};

export const getGetPlanAdSetsQueryKey = (plan: string, params?: GetPlanAdSetsParams) => {
  return [`/ad-management/v1/plan/${plan}/ad-sets`, ...(params ? [params] : [])] as const;
};

export const getGetPlanAdSetsQueryOptions = <
  TData = Awaited<ReturnType<typeof getPlanAdSets>>,
  TError = ErrorType<void>,
>(
  plan: string,
  params?: GetPlanAdSetsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPlanAdSets>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPlanAdSetsQueryKey(plan, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPlanAdSets>>> = ({ signal }) =>
    getPlanAdSets(plan, params, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!plan, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPlanAdSets>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPlanAdSetsQueryResult = NonNullable<Awaited<ReturnType<typeof getPlanAdSets>>>;
export type GetPlanAdSetsQueryError = ErrorType<void>;

/**
 * @summary Get the ad sets for a plan
 */
export const useGetPlanAdSets = <TData = Awaited<ReturnType<typeof getPlanAdSets>>, TError = ErrorType<void>>(
  plan: string,
  params?: GetPlanAdSetsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPlanAdSets>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPlanAdSetsQueryOptions(plan, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Create a new ad set for a plan.
 * @summary Create an ad set for a plan
 */
export const createAdSet = (
  plan: string,
  createAdSetBody: BodyType<CreateAdSetBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<AdSet>(
    {
      url: `/ad-management/v1/plan/${plan}/ad-sets`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createAdSetBody,
    },
    options
  );
};

export const getCreateAdSetMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createAdSet>>,
    TError,
    { plan: string; data: BodyType<CreateAdSetBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createAdSet>>,
  TError,
  { plan: string; data: BodyType<CreateAdSetBody> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createAdSet>>,
    { plan: string; data: BodyType<CreateAdSetBody> }
  > = (props) => {
    const { plan, data } = props ?? {};

    return createAdSet(plan, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateAdSetMutationResult = NonNullable<Awaited<ReturnType<typeof createAdSet>>>;
export type CreateAdSetMutationBody = BodyType<CreateAdSetBody>;
export type CreateAdSetMutationError = ErrorType<void>;

/**
 * @summary Create an ad set for a plan
 */
export const useCreateAdSet = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createAdSet>>,
    TError,
    { plan: string; data: BodyType<CreateAdSetBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createAdSet>>,
  TError,
  { plan: string; data: BodyType<CreateAdSetBody> },
  TContext
> => {
  const mutationOptions = getCreateAdSetMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Create a new ad set from a bundle for a plan.
 * @summary Create an ad set from a bundle for a plan
 */
export const createAdSetFromBundle = (
  planId: string,
  bundleId: string,
  createAdSetFromBundleBody?: BodyType<CreateAdSetFromBundleBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<AdSet>(
    {
      url: `/ad-management/v1/plan/${planId}/ad-sets/bundle/${bundleId}`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createAdSetFromBundleBody,
    },
    options
  );
};

export const getCreateAdSetFromBundleMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createAdSetFromBundle>>,
    TError,
    { planId: string; bundleId: string; data: BodyType<CreateAdSetFromBundleBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createAdSetFromBundle>>,
  TError,
  { planId: string; bundleId: string; data: BodyType<CreateAdSetFromBundleBody> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createAdSetFromBundle>>,
    { planId: string; bundleId: string; data: BodyType<CreateAdSetFromBundleBody> }
  > = (props) => {
    const { planId, bundleId, data } = props ?? {};

    return createAdSetFromBundle(planId, bundleId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateAdSetFromBundleMutationResult = NonNullable<Awaited<ReturnType<typeof createAdSetFromBundle>>>;
export type CreateAdSetFromBundleMutationBody = BodyType<CreateAdSetFromBundleBody>;
export type CreateAdSetFromBundleMutationError = ErrorType<void>;

/**
 * @summary Create an ad set from a bundle for a plan
 */
export const useCreateAdSetFromBundle = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createAdSetFromBundle>>,
    TError,
    { planId: string; bundleId: string; data: BodyType<CreateAdSetFromBundleBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createAdSetFromBundle>>,
  TError,
  { planId: string; bundleId: string; data: BodyType<CreateAdSetFromBundleBody> },
  TContext
> => {
  const mutationOptions = getCreateAdSetFromBundleMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Update an ad set by its Id.
 * @summary Update an ad set
 */
export const updateAdSet = (
  id: string,
  updateAdSetBody: BodyType<UpdateAdSetBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<AdSet>(
    {
      url: `/ad-management/v1/ad-sets/${id}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: updateAdSetBody,
    },
    options
  );
};

export const getUpdateAdSetMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAdSet>>,
    TError,
    { id: string; data: BodyType<UpdateAdSetBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateAdSet>>,
  TError,
  { id: string; data: BodyType<UpdateAdSetBody> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateAdSet>>,
    { id: string; data: BodyType<UpdateAdSetBody> }
  > = (props) => {
    const { id, data } = props ?? {};

    return updateAdSet(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateAdSetMutationResult = NonNullable<Awaited<ReturnType<typeof updateAdSet>>>;
export type UpdateAdSetMutationBody = BodyType<UpdateAdSetBody>;
export type UpdateAdSetMutationError = ErrorType<void>;

/**
 * @summary Update an ad set
 */
export const useUpdateAdSet = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAdSet>>,
    TError,
    { id: string; data: BodyType<UpdateAdSetBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateAdSet>>,
  TError,
  { id: string; data: BodyType<UpdateAdSetBody> },
  TContext
> => {
  const mutationOptions = getUpdateAdSetMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Transition an ad set by its Id.
 * @summary Transition an ad set
 */
export const transitionAdSet = (
  id: string,
  transitionAdSetBody: BodyType<TransitionAdSetBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    {
      url: `/ad-management/v1/ad-sets/${id}/transition`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: transitionAdSetBody,
    },
    options
  );
};

export const getTransitionAdSetMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof transitionAdSet>>,
    TError,
    { id: string; data: BodyType<TransitionAdSetBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof transitionAdSet>>,
  TError,
  { id: string; data: BodyType<TransitionAdSetBody> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof transitionAdSet>>,
    { id: string; data: BodyType<TransitionAdSetBody> }
  > = (props) => {
    const { id, data } = props ?? {};

    return transitionAdSet(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type TransitionAdSetMutationResult = NonNullable<Awaited<ReturnType<typeof transitionAdSet>>>;
export type TransitionAdSetMutationBody = BodyType<TransitionAdSetBody>;
export type TransitionAdSetMutationError = ErrorType<void>;

/**
 * @summary Transition an ad set
 */
export const useTransitionAdSet = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof transitionAdSet>>,
    TError,
    { id: string; data: BodyType<TransitionAdSetBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof transitionAdSet>>,
  TError,
  { id: string; data: BodyType<TransitionAdSetBody> },
  TContext
> => {
  const mutationOptions = getTransitionAdSetMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Get the ads for an ad set by its Id.
 * @summary Get the ads for an ad set
 */
export const getAds = (adSet: string, options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<GetAds200>({ url: `/ad-management/v1/ad-sets/${adSet}/ads`, method: 'GET', signal }, options);
};

export const getGetAdsQueryKey = (adSet: string) => {
  return [`/ad-management/v1/ad-sets/${adSet}/ads`] as const;
};

export const getGetAdsQueryOptions = <TData = Awaited<ReturnType<typeof getAds>>, TError = ErrorType<GetAds404>>(
  adSet: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAds>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAdsQueryKey(adSet);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAds>>> = ({ signal }) =>
    getAds(adSet, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!adSet, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAds>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAdsQueryResult = NonNullable<Awaited<ReturnType<typeof getAds>>>;
export type GetAdsQueryError = ErrorType<GetAds404>;

/**
 * @summary Get the ads for an ad set
 */
export const useGetAds = <TData = Awaited<ReturnType<typeof getAds>>, TError = ErrorType<GetAds404>>(
  adSet: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAds>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAdsQueryOptions(adSet, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Update ads within an ad set by its Id.
 * @summary Update ads for an ad set
 */
export const updateAdsInAdSet = (
  adSet: string,
  updateAdsInAdSetBody: BodyType<UpdateAdsInAdSetBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<Ads>(
    {
      url: `/ad-management/v1/ad-sets/${adSet}/ads`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: updateAdsInAdSetBody,
    },
    options
  );
};

export const getUpdateAdsInAdSetMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAdsInAdSet>>,
    TError,
    { adSet: string; data: BodyType<UpdateAdsInAdSetBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateAdsInAdSet>>,
  TError,
  { adSet: string; data: BodyType<UpdateAdsInAdSetBody> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateAdsInAdSet>>,
    { adSet: string; data: BodyType<UpdateAdsInAdSetBody> }
  > = (props) => {
    const { adSet, data } = props ?? {};

    return updateAdsInAdSet(adSet, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateAdsInAdSetMutationResult = NonNullable<Awaited<ReturnType<typeof updateAdsInAdSet>>>;
export type UpdateAdsInAdSetMutationBody = BodyType<UpdateAdsInAdSetBody>;
export type UpdateAdsInAdSetMutationError = ErrorType<void>;

/**
 * @summary Update ads for an ad set
 */
export const useUpdateAdsInAdSet = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAdsInAdSet>>,
    TError,
    { adSet: string; data: BodyType<UpdateAdsInAdSetBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateAdsInAdSet>>,
  TError,
  { adSet: string; data: BodyType<UpdateAdsInAdSetBody> },
  TContext
> => {
  const mutationOptions = getUpdateAdsInAdSetMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Upload a new media asset to the server
 * @summary Upload a media asset
 */
export const postAssets = (
  postAssetsBody: BodyType<PostAssetsBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  const formData = new FormData();
  if (postAssetsBody.file !== undefined) {
    formData.append('file', postAssetsBody.file);
  }

  return customInstance<Asset>(
    {
      url: `/ad-management/v1/assets`,
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    },
    options
  );
};

export const getPostAssetsMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAssets>>,
    TError,
    { data: BodyType<PostAssetsBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postAssets>>,
  TError,
  { data: BodyType<PostAssetsBody> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAssets>>, { data: BodyType<PostAssetsBody> }> = (
    props
  ) => {
    const { data } = props ?? {};

    return postAssets(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostAssetsMutationResult = NonNullable<Awaited<ReturnType<typeof postAssets>>>;
export type PostAssetsMutationBody = BodyType<PostAssetsBody>;
export type PostAssetsMutationError = ErrorType<void>;

/**
 * @summary Upload a media asset
 */
export const usePostAssets = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAssets>>,
    TError,
    { data: BodyType<PostAssetsBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<Awaited<ReturnType<typeof postAssets>>, TError, { data: BodyType<PostAssetsBody> }, TContext> => {
  const mutationOptions = getPostAssetsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Get the details of an asset by its Id.
 * @summary Get an asset
 */
export const getAsset = (assetId: string, options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<Asset>({ url: `/ad-management/v1/assets/${assetId}`, method: 'GET', signal }, options);
};

export const getGetAssetQueryKey = (assetId: string) => {
  return [`/ad-management/v1/assets/${assetId}`] as const;
};

export const getGetAssetQueryOptions = <TData = Awaited<ReturnType<typeof getAsset>>, TError = ErrorType<void>>(
  assetId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAsset>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAssetQueryKey(assetId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAsset>>> = ({ signal }) =>
    getAsset(assetId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!assetId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAsset>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAssetQueryResult = NonNullable<Awaited<ReturnType<typeof getAsset>>>;
export type GetAssetQueryError = ErrorType<void>;

/**
 * @summary Get an asset
 */
export const useGetAsset = <TData = Awaited<ReturnType<typeof getAsset>>, TError = ErrorType<void>>(
  assetId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAsset>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAssetQueryOptions(assetId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Delete an existing asset from the server
 * @summary Delete an asset
 */
export const deleteAssetsAssetId = (assetId: string, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<void>({ url: `/ad-management/v1/assets/${assetId}`, method: 'DELETE' }, options);
};

export const getDeleteAssetsAssetIdMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteAssetsAssetId>>, TError, { assetId: string }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<Awaited<ReturnType<typeof deleteAssetsAssetId>>, TError, { assetId: string }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteAssetsAssetId>>, { assetId: string }> = (
    props
  ) => {
    const { assetId } = props ?? {};

    return deleteAssetsAssetId(assetId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteAssetsAssetIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteAssetsAssetId>>>;

export type DeleteAssetsAssetIdMutationError = ErrorType<void>;

/**
 * @summary Delete an asset
 */
export const useDeleteAssetsAssetId = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteAssetsAssetId>>, TError, { assetId: string }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<Awaited<ReturnType<typeof deleteAssetsAssetId>>, TError, { assetId: string }, TContext> => {
  const mutationOptions = getDeleteAssetsAssetIdMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Create a discount
 */
export const postDiscounts = (
  createDiscountRequestBody: BodyType<CreateDiscountRequestBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<DiscountResponseResponse>(
    {
      url: `/ad-management/v1/discounts`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createDiscountRequestBody,
    },
    options
  );
};

export const getPostDiscountsMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postDiscounts>>,
    TError,
    { data: BodyType<CreateDiscountRequestBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postDiscounts>>,
  TError,
  { data: BodyType<CreateDiscountRequestBody> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postDiscounts>>,
    { data: BodyType<CreateDiscountRequestBody> }
  > = (props) => {
    const { data } = props ?? {};

    return postDiscounts(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostDiscountsMutationResult = NonNullable<Awaited<ReturnType<typeof postDiscounts>>>;
export type PostDiscountsMutationBody = BodyType<CreateDiscountRequestBody>;
export type PostDiscountsMutationError = ErrorType<void>;

/**
 * @summary Create a discount
 */
export const usePostDiscounts = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postDiscounts>>,
    TError,
    { data: BodyType<CreateDiscountRequestBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postDiscounts>>,
  TError,
  { data: BodyType<CreateDiscountRequestBody> },
  TContext
> => {
  const mutationOptions = getPostDiscountsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Update a discount
 */
export const putDiscountsDiscountId = (
  discountId: string,
  updateDiscountRequestBody: BodyType<UpdateDiscountRequestBody>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<DiscountResponseResponse>(
    {
      url: `/ad-management/v1/discounts/${discountId}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: updateDiscountRequestBody,
    },
    options
  );
};

export const getPutDiscountsDiscountIdMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putDiscountsDiscountId>>,
    TError,
    { discountId: string; data: BodyType<UpdateDiscountRequestBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putDiscountsDiscountId>>,
  TError,
  { discountId: string; data: BodyType<UpdateDiscountRequestBody> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putDiscountsDiscountId>>,
    { discountId: string; data: BodyType<UpdateDiscountRequestBody> }
  > = (props) => {
    const { discountId, data } = props ?? {};

    return putDiscountsDiscountId(discountId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutDiscountsDiscountIdMutationResult = NonNullable<Awaited<ReturnType<typeof putDiscountsDiscountId>>>;
export type PutDiscountsDiscountIdMutationBody = BodyType<UpdateDiscountRequestBody>;
export type PutDiscountsDiscountIdMutationError = ErrorType<void>;

/**
 * @summary Update a discount
 */
export const usePutDiscountsDiscountId = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putDiscountsDiscountId>>,
    TError,
    { discountId: string; data: BodyType<UpdateDiscountRequestBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putDiscountsDiscountId>>,
  TError,
  { discountId: string; data: BodyType<UpdateDiscountRequestBody> },
  TContext
> => {
  const mutationOptions = getPutDiscountsDiscountIdMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Delete a discount
 */
export const deleteDiscountsDiscountId = (discountId: string, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<void>({ url: `/ad-management/v1/discounts/${discountId}`, method: 'DELETE' }, options);
};

export const getDeleteDiscountsDiscountIdMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteDiscountsDiscountId>>,
    TError,
    { discountId: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteDiscountsDiscountId>>,
  TError,
  { discountId: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteDiscountsDiscountId>>, { discountId: string }> = (
    props
  ) => {
    const { discountId } = props ?? {};

    return deleteDiscountsDiscountId(discountId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteDiscountsDiscountIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteDiscountsDiscountId>>
>;

export type DeleteDiscountsDiscountIdMutationError = ErrorType<void>;

/**
 * @summary Delete a discount
 */
export const useDeleteDiscountsDiscountId = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteDiscountsDiscountId>>,
    TError,
    { discountId: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteDiscountsDiscountId>>,
  TError,
  { discountId: string },
  TContext
> => {
  const mutationOptions = getDeleteDiscountsDiscountIdMutationOptions(options);

  return useMutation(mutationOptions);
};
