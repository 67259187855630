import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Col, Form, InputGroup, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import styled from 'styled-components';
import Datetime from 'react-datetime';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { formatDisplayDate, DISPLAY_DATE_FORMAT } from 'helpers/DateHelpers';
import { DEFAULT_MEDIA_TYPES_VALUE } from "../constants";
import type { MediaTypes } from "../types";
import { cloneDeep } from 'lodash';

const AvailabilityHeader = styled.span`
  font-weight: bold;
  font-size: 18px;
`;

const StyledOL = styled.ol`
  list-style: none;
  padding-left: 0;
`;

const StyledLI = styled.li`
  margin: 5px 5px 5px 0;
`;

interface FormErrors {
  orientation?: Array<string>;
  max_booking_date?: Array<string>;
  'media_types.Landscape'?: Array<string>;
  'media_types.Portrait'?: Array<string>;
}

interface AvailabilitySectionProps {
  values: {
    max_booking_date: string;
    orientation: Array<string>;
    media_types: MediaTypes;
  };
  isSaving: boolean;
  /* eslint-disable no-unused-vars */
  onChange: (value: object) => void;
  formErrors: FormErrors;
}

const AvailabilitySection = ({
  values = { max_booking_date: '', orientation: [], media_types: cloneDeep(DEFAULT_MEDIA_TYPES_VALUE) },
  isSaving = false,
  onChange,
  formErrors,
}: AvailabilitySectionProps) => {
  const [landscapeChecked, setLandscapeChecked] = useState(values?.orientation.includes('Landscape'));
  const [portraitChecked, setPortraitChecked] = useState(values?.orientation.includes('Portrait'));
  const [bundledChecked, setBundledChecked] = useState(values?.orientation.includes('Landscape_&_Portrait'));

  const [landscapeVideoChecked, setLandscapeVideoChecked] = useState(values?.media_types.Landscape?.includes('video'));
  const [landscapeImageChecked, setLandscapeImageChecked] = useState(values?.media_types.Landscape?.includes('image'));
  const [portraitVideoChecked, setPortraitVideoChecked] = useState(values?.media_types.Portrait?.includes('video'));
  const [portraitImageChecked, setPortraitImageChecked] = useState(values?.media_types.Portrait?.includes('image'));

  const isValidUpToDate = (current: moment.Moment) => current.isSameOrAfter(moment(), 'day');

  useEffect(() => {
    const selectedOrientations: Array<string> = [];

    if (landscapeChecked && portraitChecked) {
      setBundledChecked(true);
    }

    if (landscapeChecked) {
      selectedOrientations.push('Landscape');
    }

    if (portraitChecked) {
      selectedOrientations.push('Portrait');
    }

    if (bundledChecked) {
      selectedOrientations.push('Landscape_&_Portrait');
    }

    onChange({ orientation: selectedOrientations });
  }, [landscapeChecked, portraitChecked, bundledChecked]);

  useEffect(() => {
    const selectedMediaTypes: MediaTypes = cloneDeep(DEFAULT_MEDIA_TYPES_VALUE);

    if (landscapeVideoChecked) {
      selectedMediaTypes.Landscape.push('video');
    }

    if (landscapeImageChecked) {
      selectedMediaTypes.Landscape.push('image');
    }

    if (portraitVideoChecked) {
      selectedMediaTypes.Portrait.push('video');
    }

    if (portraitImageChecked) {
      selectedMediaTypes.Portrait.push('image');
    }

    onChange({ media_types: selectedMediaTypes });
  }, [landscapeVideoChecked, landscapeImageChecked, portraitVideoChecked, portraitImageChecked]);

  return (
    <>
      <Row className='mb-3'>
        <Col>
          <h3>Availability</h3>
        </Col>
      </Row>
      <hr />
      <Row className='mb-3'>
        <Col>
          <AvailabilityHeader>Screen Types</AvailabilityHeader>
        </Col>
        <Col>
          <AvailabilityHeader>How far in advance suppliers can order</AvailabilityHeader>{' '}
          <OverlayTrigger
            placement='top'
            overlay={<Tooltip id='info-popover'>No screens beyond this date will be available.</Tooltip>}
          >
            <FontAwesomeIcon icon={faCircleInfo} size='sm' />
          </OverlayTrigger>
        </Col>
      </Row>
      <Row className='mb-4'>
        <Col>
          <StyledOL>
            <StyledLI>
              <Form.Check
                checked={landscapeChecked}
                value={'Landscape'}
                onChange={() => setLandscapeChecked(!landscapeChecked)}
                disabled={isSaving}
                inline
                name='orientation'
                label='Landscape'
              />
            </StyledLI>
            <StyledLI>
              <Form.Check
                checked={portraitChecked}
                value={'Portrait'}
                onChange={() => setPortraitChecked(!portraitChecked)}
                disabled={isSaving}
                inline
                name='orientation'
                label='Portrait'
              />
            </StyledLI>
            <StyledLI>
              <Form.Check
                checked={bundledChecked}
                value={'Landscape_&_Portrait'}
                onChange={() => setBundledChecked(!bundledChecked)}
                disabled={isSaving}
                inline
                name='orientation'
                label='Bundled (landscape and portrait)'
              />
            </StyledLI>
          </StyledOL>
          {formErrors?.orientation && (
            <Form.Text className='text-danger'>
              {formErrors.orientation.toString().replace('orientation', 'available screen types')}
            </Form.Text>
          )}
        </Col>
        <Col>
          <InputGroup>
            <Datetime
              value={values.max_booking_date}
              dateFormat='YYYY-MM-DD'
              renderInput={(props, openCalendar) => {
                // Format the display value for the user without affecting the underlying value format
                // eslint-disable-next-line react/prop-types
                return <Form.Control {...props} value={formatDisplayDate(props.value)} onClick={openCalendar} />;
              }}
              timeFormat={false}
              inputProps={{ placeholder: `Up to ${DISPLAY_DATE_FORMAT}`, disabled: isSaving }}
              isValidDate={isValidUpToDate}
              onChange={(value) => {
                const date = moment.isMoment(value) ? moment(value.toString()).format('YYYY-MM-DD') : value;
                onChange({ max_booking_date: date });
              }}
            />
            <InputGroup.Append>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faCalendar} />
              </InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
          {formErrors?.max_booking_date && (
            <Form.Text className='text-danger'>
              {formErrors.max_booking_date
                .toString()
                .replace('max booking date', 'how far in advance suppliers can order')}
            </Form.Text>
          )}
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col>
          <AvailabilityHeader>Landscape Screen Media Types</AvailabilityHeader>
        </Col>
        <Col>
          <AvailabilityHeader>Portrait Screen Media Types</AvailabilityHeader>
        </Col>
      </Row>
      <Row className='mb-4'>
        <Col>
          <StyledOL>
            <StyledLI>
              <Form.Check
                checked={landscapeVideoChecked}
                value={'Landscape_Video'}
                onChange={() => setLandscapeVideoChecked(!landscapeVideoChecked)}
                disabled={isSaving}
                inline
                name='media_types'
                label='Video'
              />
            </StyledLI>
            <StyledLI>
              <Form.Check
                checked={landscapeImageChecked}
                value={'Landscape_Image'}
                onChange={() => setLandscapeImageChecked(!landscapeImageChecked)}
                disabled={isSaving}
                inline
                name='media_types'
                label='Image'
              />
            </StyledLI>
          </StyledOL>
          {formErrors['media_types.Landscape'] && (
            <Form.Text className='text-danger'>
              {formErrors['media_types.Landscape']
                .toString()
                .replace('media_types.Landscape', 'Landscape screen media types')}
            </Form.Text>
          )}
        </Col>
        <Col>
          <StyledOL>
            <StyledLI>
              <Form.Check
                checked={portraitVideoChecked}
                value={'Portrait_Video'}
                onChange={() => setPortraitVideoChecked(!portraitVideoChecked)}
                disabled={isSaving}
                inline
                name='media_types'
                label='Video'
              />
            </StyledLI>
            <StyledLI>
              <Form.Check
                checked={portraitImageChecked}
                value={'Portrait_Image'}
                onChange={() => setPortraitImageChecked(!portraitImageChecked)}
                disabled={isSaving}
                inline
                name='media_types'
                label='Image'
              />
            </StyledLI>
          </StyledOL>
          {formErrors['media_types.Portrait'] && (
            <Form.Text className='text-danger'>
              {formErrors['media_types.Portrait']
                .toString()
                .replace('media_types.Portrait', 'Portrait screen media types')}
            </Form.Text>
          )}
        </Col>
      </Row>
    </>
  );
};

export default AvailabilitySection;
