import { faHandWave } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { useSelector } from 'react-redux';
import { selectUser } from '../../features/session/sessionSlice';


const WelcomeMessage = () => {
    const user = useSelector(selectUser);

    return <h3 className='mb-5 font-weight-bold'><FontAwesomeIcon icon={faHandWave} /> Welcome back, {user.first_name}!</h3>;
};

export default WelcomeMessage
