import React, {ReactElement, useState} from 'react';
import Tooltip from "../tooltip/Tooltip";
import {Button} from "react-bootstrap";
import FileUploadModal from "../assets/FileUploadModal";
import {Asset} from "../types";
import styled from "styled-components";
import FileRectangle from "./Assets/FileRectangle";
import ErrorsFormFeedback from "../ErrorsFormFeedback";

const Container = styled.div`
  margin-bottom: 10px;
`;

const UploadMediaButton = styled(Button)`
  margin-top: 5px;
`;

type MediaUploadProps = {
    readOnly?: boolean,
    headline: string,
    required?: boolean,
    info?: string | ReactElement,
    value?: Asset,
    accept: Array<string>,
    errors?: Array<string>,

    onChange: (v?: Asset) => void,
};

const SingleMediaUpload = ({
  value,
  readOnly = false,
  headline,
  required = false,
  info,
  onChange = () => { /* do nothing */ },
  accept,
  errors = [],
}: MediaUploadProps) => {
    const [show, setShow] = useState<boolean>(false);

    const handleShow = () => setShow(true);
    const handleHide = () => setShow(false);

    const handleChange = (files: Array<(Asset | undefined)>) => onChange(files[0]);

    const passedValue = value ? [value] : [];

    return <Container>
        <div className='font-weight-bold'>
            {headline} {required && '*'} {info && <Tooltip type='info' text={info}/>}
        </div>
        {value && <FileRectangle file={value}/>}
        <UploadMediaButton
            disabled={readOnly}
            variant={errors.length === 0 ? 'outline-secondary' : 'outline-danger'}
            className='w-100'
            onClick={handleShow}
        >
            <div>Upload Media</div>
        </UploadMediaButton>
        <ErrorsFormFeedback errors={errors}/>
        <FileUploadModal
            onChange={handleChange}
            max={1}
            value={passedValue}
            accept={accept}
            show={show}
            onHide={handleHide}
        />
    </Container>;
}

export default SingleMediaUpload;
