import { faChevronDown, faChevronUp } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import PropTypes from 'prop-types'

const SelectionContainer = styled.div`
  border: 1px solid #d0c6ff;
  border-radius: 4px;
  cursor: pointer;
`;

const SelectionHeaderContainer = styled.div`
  padding: 12px 15px;
`

const SelectionHeader = styled.h6`
  font-weight: bold;
  margin-bottom: 0;
`;

const Content = styled.div`
  padding: 12px 15px;
  display: none;

  &[aria-expanded="true"] {
    display: block;
  }
`;

const Accordian = ({
  onCollapse = () => { },
  open = true,
  header,
  children,
  onClick = () => {}
}) => {
  return (
    <SelectionContainer>
      <SelectionHeaderContainer className='d-flex align-items-center' onClick={onCollapse}>
        <SelectionHeader>{header}</SelectionHeader>
        <span className='ml-auto'>
          <FontAwesomeIcon icon={open ? faChevronUp : faChevronDown} />
        </span>
      </SelectionHeaderContainer>
      <Content aria-expanded={open ? "true" : "false"}>
        {children}
      </Content>
    </SelectionContainer>
  );
};

Accordian.propTypes = {
  open: PropTypes.bool,
  header: PropTypes.any,
  onCollapse: PropTypes.func,
}

export default Accordian
