import { faInfoCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Breadcrumbs from 'app/components/Breadcrumbs';
import Chrome from 'app/layout/Chrome';
import { Eligibility } from 'features/organisation_settings/components/FacebookMPA/Elegibility';
import { OnboardingStatus } from 'features/organisation_settings/components/FacebookMPA/OnboardingStatus';
import { selectUser } from 'features/session/sessionSlice';
import { prepareSharePageData, getPageSharingStatus } from 'lib/api/mpa';
import { unixTimestampToDate } from 'lib/dates';
import React, { useEffect, useMemo } from 'react';
import { Alert, Button, Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { Navigate, useParams, useSearchParams, useLocation } from 'react-router-dom';
import { styled } from 'styled-components';
import { selectRetailer } from '../../../features/retailers/retailersSlice';
import { PagesList } from '../PagesList/PagesList';
import { Accordion } from './Accordion';
import { useRetailerSettings } from './useRetailerSettings';
import { toast } from 'react-toastify';
import {
  MPA_ONBOARDING_COMPLETED,
  MPA_ONBOARDING_WAITING_FOR_SUPPPLIER,
  MPA_ONBOARDING_NEW,
  MPA_ONBOARDING_IN_PROGRESS,
} from '../../../lib/constants';
import { useActivePages } from 'features/order_form/standard_ad_section_tabs/useActivePages';

const breadcrumbs = [
  {
    title: 'Home',
    path: '/',
  },
  {
    title: 'Settings',
    path: '/organisation/settings',
  },
  {
    title: 'Retailer Settings',
    path: '/organisation/settings/retailers/:organisationId',
    active: true,
  },
];

export default function RetailerSettings() {
  const location = useLocation();
  const { organisationId } = useParams<{ organisationId: string }>();
  const [searchParams] = useSearchParams();
  const user = useSelector(selectUser);
  const retailer = useSelector(selectRetailer(organisationId));
  const isRetailer = user.active_organisation.is_retailer;
  const supplierId = user.active_organisation.id;
  const state = useRetailerSettings({ organisationId, supplierId, retailer });

  const [pageSharingStatus, setPageSharingStatus] = React.useState<{
    state: string | null;
    error: string | null;
  }>({
    state: null,
    error: null,
  });

  useEffect(() => {
    if (searchParams.get('isredirect') && searchParams.get('stateKey')) {
      const fetchStatus = async () => {
        const res = await getPageSharingStatus({
          stateKey: searchParams.get('stateKey'),
          retailerId: retailer?.id,
        });
        setPageSharingStatus(res?.data);
      };
      if (searchParams.get('stateKey')) {
        fetchStatus();
      }
    }
  }, [searchParams.get('isredirect'), searchParams.get('stateKey')]);

  useEffect(() => {
    if (pageSharingStatus?.error) {
      toast.error(pageSharingStatus.error, {
        hideProgressBar: true,
        icon: false,
        bodyClassName: 'toast-body',
      });
    }
    if (!pageSharingStatus?.error && pageSharingStatus?.state) {
      toast.success('Page(s) shared successfully.', {
        hideProgressBar: true,
        icon: false,
        bodyClassName: 'toast-body',
      });
    }
  }, [pageSharingStatus]);

  const sharePageswithRetailer = async () => {
    const fullUrlWithoutSearchParamsOrHash = `${window.location.protocol}//${window.location.host}${location.pathname}`;
    const redirectUrl = `${fullUrlWithoutSearchParamsOrHash}?isredirect=true`;
    const res = await prepareSharePageData(retailer?.id, redirectUrl);
    if (res.status === 200 && res?.data) {
      const path = new URL(res?.data?.redirect_url);
      window.location.href = path.href;
    }
  };

  const { retailerSettings } = state;
  const {
    activePages,
    businessManagerId,
    isLoading: isLoadingActivePages,
  } = useActivePages({
    retailerId: retailer.id,
    supplierId,
    enabled: retailerSettings?.eligibility.is_eligible || false,
  });

  const hasOnlySupplierSelected = useMemo(
    () =>
      retailerSettings?.settings?.page_handler?.includes('supplier') &&
      retailerSettings?.settings?.page_handler?.length === 1,
    [retailerSettings?.settings.page_handler]
  );

  const hasSupplierSelected = useMemo(
    () => retailerSettings?.settings?.page_handler?.includes('supplier'),
    [retailerSettings?.settings.page_handler]
  );

  if (isRetailer) {
    return <Navigate to='/' />;
  }
  if (retailer && !retailer?.facebook_mpa_enabled) {
    return <Navigate to='/organisation/settings' />;
  }

  return (
    <>
      <Helmet>
        <title>
          Retailer Settings for {retailer?.name || ''} - {process.env.REACT_APP_NAME}
        </title>
      </Helmet>

      <Chrome>
        {!state.loading && !isLoadingActivePages ? (
          <Container>
            <Breadcrumbs items={breadcrumbs} />
            <Row>
              <Col>
                <Title>Retailer Settings: {retailer?.name}</Title>
                <Subtitle>Customise settings for {retailer?.name}.</Subtitle>
              </Col>
            </Row>
            <Accordion title='Meta - Managed Partner Ads (MPA)'>
              <Eligibility
                isEligible={retailerSettings?.eligibility?.is_eligible || false}
                lastStatusCheck={unixTimestampToDate(retailerSettings?.eligibility?.last_check || 0)}
                notEligibleReason={retailerSettings?.eligibility?.reason_description || ''}
                enableRecheck={false}
              />
              {retailerSettings?.eligibility?.is_eligible && (
                <div>
                  <OnboardingStatusContainer>
                    <h3>Onboarding</h3>
                    <OnboardingStatus
                      status={
                        retailerSettings?.status === MPA_ONBOARDING_WAITING_FOR_SUPPPLIER
                          ? MPA_ONBOARDING_COMPLETED
                          : retailerSettings?.status || MPA_ONBOARDING_NEW
                      }
                      showLabel={false}
                    />
                  </OnboardingStatusContainer>
                  {retailerSettings.status !== MPA_ONBOARDING_IN_PROGRESS &&
                    retailerSettings.status !== MPA_ONBOARDING_NEW && (
                      <div>
                        <Info>
                          <FontAwesomeIcon icon={faInfoCircle} />
                          <p>
                            The retailer has provided onboarding information on your behalf, such as your website,
                            countries targeted, registration country and vertical.
                          </p>
                        </Info>
                        <PageHandlers>
                          <label>Page to display ads</label>
                          <p>
                            {retailerSettings?.settings?.page_handler?.map((page, index, array) => (
                              <span key={page}>
                                {`${page.charAt(0).toUpperCase() + page.slice(1)} page`}
                                {index < array.length - 1 ? ', ' : ''}
                              </span>
                            ))}
                          </p>
                        </PageHandlers>
                        <SharePagesContainer>
                          {hasSupplierSelected && retailerSettings?.status !== MPA_ONBOARDING_COMPLETED && (
                            <StyledWarningAlert variant='warning'>
                              <FontAwesomeIcon icon={faExclamationTriangle} />
                              <p>You need to share your Meta page(s) with the Retailer</p>
                            </StyledWarningAlert>
                          )}
                          <div className='headline'>
                            <h3>Page(s) available for brands</h3>
                            {hasSupplierSelected && retailerSettings?.status !== MPA_ONBOARDING_COMPLETED && (
                              <OnboardingStatus status='action-required' showLabel={false} />
                            )}
                          </div>
                          <p>
                            {hasOnlySupplierSelected
                              ? 'You will need to share the pages you want to use for MPA with the retailer.'
                              : 'Where ads are displayed for Meta Managed Partner Ads (MPAs).'}
                          </p>
                          <h4>Retailer Business Manager (BM) ID</h4>
                          <p>{'For your reference, this is the Retailer’s Business Manager ID.'}</p>
                          <BMID>{businessManagerId || 'Business Manager ID not found.'}</BMID>

                          <>
                            <h4>Pages</h4>
                            <PagesList pages={activePages} pageHandler={retailerSettings?.settings?.page_handler} />
                          </>
                          {hasSupplierSelected && (
                            <div className='footer'>
                              <p>
                                You can only share pages which are owned by the brand&apos;s Business Manager. You
                                won&apos;t be able to share pages which are only shared with brand&apos;s Business
                                Manager.
                              </p>
                              <Button variant='outline-primary' onClick={sharePageswithRetailer}>
                                Share Page(s) with Retailer
                              </Button>
                            </div>
                          )}
                        </SharePagesContainer>
                      </div>
                    )}
                </div>
              )}
            </Accordion>
          </Container>
        ) : (
          <Center>Loading...</Center>
        )}
      </Chrome>
    </>
  );
}
const Title = styled.h1`
  color: #000;
  font-size: 2.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  margin-bottom: 1rem;
`;
const Subtitle = styled.h2`
  color: var(--tp-base-colour-50, #777785);
  font-size: 1.4375rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
`;
const OnboardingStatusContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  h3 {
    color: var(--tp-base-colour-75, #3a3b4f);
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    margin: 0;
  }
`;
const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 96vh;
  font-size: 2rem;
`;
const Info = styled.div`
  color: var(--tp-base-colour-100, #00001e);
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
  display: flex;
  gap: 0.5rem;
  align-items: center;
  svg {
    color: var(--tp-base-colour-100, #00001e);
    font-family: 'Font Awesome 6 Pro';
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  p {
    margin: 0;
  }
  margin-bottom: 1rem;
  margin-top: 0.5rem;
`;
const PageHandlers = styled.div`
  label {
    color: #000;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 115%;
  }
  span {
    color: #777785;
    font-size: 1rem;
    font-style: normal;
    font-weight: 300;
    line-height: 140%;
  }
  p {
    margin: 0;
  }
  margin-bottom: 1rem;
`;
const SharePagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  h3 {
    color: var(--tp-base-colour-75, #3a3b4f);
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    margin: 0;
  }
  h4 {
    color: #000;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 115%;
    margin: 0;
  }
  p {
    color: #000;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 300;
    line-height: 140%;
    margin: 0;
  }
  .headline {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  .footer {
    display: flex;
    flex-direction: column;
    gap: 1em;
    .btn {
      max-width: 20rem;
    }
    max-width: 40rem;
  }
`;
const BMID = styled.span`
  color: #777785;
  display: block;
  font-size: 1.125rem;
  font-style: italic;
  font-weight: 300;
  line-height: 140%;
`;

const StyledAlert = styled(Alert)`
  border-radius: 0.25rem;

  display: flex;
  justify-content: center;
  width: fit-content;
  align-items: center;
  gap: 6px;
  margin-bottom: 0;
  p {
    margin: 0;
  }
  * {
    color: #00001e;
  }
`;

const StyledWarningAlert = styled(StyledAlert)`
  background: #ffe787;
`;
