import React from 'react';
import { RenderableMediaTypeFieldProperties } from 'v2/lib/api/inventory';
import { Asset } from 'v2/lib/api/ad-management';
import { DragAndDropUploader } from 'v2/components/DragAndDropUploader/DragAndDropUploader';

interface UploaderComponentProps {
  properties: RenderableMediaTypeFieldProperties & {
    fileType?: string;
    label?: string;
    minFiles?: number;
    maxFiles?: number;
  };
  fieldName?: string;
  onChange: (assets: Array<Asset>) => void;
  onBlur: () => void;
  value: Array<Asset>;
}

const calcFileTypes = (fileType: string | undefined) => {
  switch (fileType) {
    case 'image':
      return ['image/*'];
    case 'video':
      return ['video/*'];
    case 'audio':
      return ['audio/*'];
    default:
      return ['any'];
  }
};

export const UploaderComponent: React.FC<UploaderComponentProps> = ({
  properties,
  fieldName,
  onChange,
  onBlur,
  value,
}) => {
  const handleAssetChange = (assets: Array<Asset>) => {
    onChange(assets);
  };

  const controlledValue = value || [];

  return (
    <div
      onBlur={onBlur}
      id={`${fieldName}-uploader-${properties.label}`}
      data-testid={`${fieldName}-uploader-component`}
    >
      <DragAndDropUploader
        onAssetChange={handleAssetChange}
        assets={controlledValue}
        fileTypes={calcFileTypes(properties?.fileType)}
        minFiles={properties.minFiles}
        maxFiles={properties.maxFiles}
      />
    </div>
  );
};
