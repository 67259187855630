import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Typography,
  Chip,
  IconButton,
  TextField,
  ContentCopyIcon,
  CheckIcon,
  Divider,
  Autocomplete,
} from '@zitcha/component-library';
import { Plan, PlanStatus } from '../../../lib/api/ad-management';
import { statusNameMapping, statusChipVariantMap, statusChipColorMap } from 'v2/utils/planStatusUtils';
import { formatDisplayDate, formatRecentDate } from 'helpers/DateHelpers';
import Wallet from 'v2/Types/Wallets';
import { ViewMode } from './ViewModeType';
import { useUserOrganisation } from 'v2/lib/hooks/useUserOrganisation';
import { usePlan } from '../PlanContext';
import { DetailItem } from './components/DetailItem';
import { useCopyToClipboard } from 'v2/lib/hooks/useCopyToClipboard';
import { calculateTotalBudget } from 'v2/utils/newPlanUtils';
import { useWallets } from 'v2/lib/hooks/useWallets';

interface PlanDetailsSectionProps {
  mode: ViewMode;
}

export const PlanDetailsSection: React.FC<PlanDetailsSectionProps> = ({ mode }) => {
  const plan = usePlan();
  const organisation = useUserOrganisation();
  const { copySuccess, copyToClipboard } = useCopyToClipboard();

  const status = plan.status as PlanStatus;
  const statusName = statusNameMapping[status];
  const variant = statusChipVariantMap[status];
  const color = statusChipColorMap[status];
  const updatedAt = plan.updatedAt ? new Date(plan.updatedAt) : new Date();
  const createdAt = plan.createdAt ? new Date(plan.createdAt) : new Date();

  const [selectedWallet, setSelectedWallet] = useState<Wallet | null>(null);

  const { wallets, isLoading: isWalletsLoading } = useWallets(plan?.supplier?.id as string);

  const findMatchingWallet = (wallets: Array<Wallet>, planData: Plan) => {
    return wallets.find((wallet) => wallet.id === planData.wallet?.id) || null;
  };

  useEffect(() => {
    if (isWalletsLoading || !plan?.wallet?.id || !wallets) return;
    setSelectedWallet(findMatchingWallet(wallets, plan));
  }, [plan, wallets, isWalletsLoading, mode]);

  const newAdSetsTotalValue = useMemo(() => {
    return calculateTotalBudget(plan.newAdSets);
  }, [plan.newAdSets]);
  const planValue = (plan.planValue || 0) + newAdSetsTotalValue || 0; //todo get plan value from BE

  if (isWalletsLoading) return <Box>Loading...</Box>;

  return (
    <Box display='flex' justifyContent='space-between' my={3}>
      <Box id='plan-info' display='flex' flexWrap='wrap' gap={1} alignItems='center'>
        <DetailItem
          label='Plan status'
          value={<Chip label={statusName} variant={variant} color={color} size='small' />}
        />
        <Divider orientation='vertical' variant='middle' flexItem />
        <DetailItem
          label='Plan ID'
          value={
            <IconButton
              color='default'
              size='small'
              ariaLabel={'Copy plan ID'}
              onClick={() => copyToClipboard(plan.id || '')}
            >
              {copySuccess ? <CheckIcon /> : <ContentCopyIcon />}
            </IconButton>
          }
        />
        {organisation?.is_retailer ? (
          <>
            <Divider orientation='vertical' variant='middle' flexItem />
            <DetailItem label='Supplier' value={<Typography variant='body1'>{plan?.supplier?.name}</Typography>} />
          </>
        ) : (
          <>
            <Divider orientation='vertical' variant='middle' flexItem />
            <DetailItem label='Retailer' value={<Typography variant='body1'>{plan?.owner?.name}</Typography>} />
          </>
        )}
        <Divider orientation='vertical' variant='middle' flexItem />
        <DetailItem
          label='Last modified'
          value={<Typography variant='body1'>{formatRecentDate(updatedAt)}</Typography>}
        />
        <Divider orientation='vertical' variant='middle' flexItem />
        <DetailItem
          label='Plan created at'
          value={<Typography variant='body1'>{formatDisplayDate(createdAt)}</Typography>}
        />
        <Divider orientation='vertical' variant='middle' flexItem />
        <DetailItem label='Plan value' value={<Typography variant='body1'>£{planValue || ' - '}</Typography>} />
        <Divider orientation='vertical' variant='middle' flexItem />
        <DetailItem
          label='Remaining wallet balance'
          value={
            <Typography variant='body1'>
              {' '}
              {/*todo add currency formatting*/}
              {selectedWallet ? `£${selectedWallet?.available_balance - planValue}` : 'no wallet'}
            </Typography>
          }
        />
      </Box>
      {mode === ViewMode.EDITING ? (
        //todo add type to options
        <Autocomplete
          options={wallets}
          getOptionLabel={(option) => (option ? `${option.name}: £${option.available_balance}` : 'no wallet')}
          value={selectedWallet}
          onChange={(_, newValue) => {
            if (newValue === null) {
              // If the Autocomplete input is cleared, use findMatchingWallet to set selectedWallet
              setSelectedWallet(findMatchingWallet(wallets, plan));
            } else {
              setSelectedWallet(newValue);
            }
          }}
          renderInput={(params) => <TextField {...params} variant='standard' label='Wallet' />}
          sx={{ minWidth: 250 }}
        />
      ) : (
        <TextField
          id='plan-wallet'
          label='Wallet'
          variant='standard'
          size='small'
          sx={{ minWidth: '250px' }}
          value={selectedWallet ? `${selectedWallet.name}: £${selectedWallet.available_balance}` : 'no wallet'}
        />
      )}
    </Box>
  );
};
