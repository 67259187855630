import React, { useMemo, useState } from 'react';
import {
  Button,
  Typography,
  Box,
  InputAdornment,
  MenuItem,
  SendIcon,
  EditIcon,
  VisibilityIcon,
  FormControl,
  Select,
  ShoppingCartCheckoutIcon,
} from '@zitcha/component-library';
import { EditableField } from 'v2/components/EditableField/EditableField';
import { PlanStatus } from 'v2/lib/api/ad-management';
import { ViewMode } from './ViewModeType';
import { usePlanActions } from 'v2/lib/hooks/usePlanActions';
import { AreYouSureModal } from 'v2/components/AreYouSureModal/AreYouSureModal';
import Wallet from 'v2/Types/Wallets';
import { useUserOrganisation } from 'v2/lib/hooks/useUserOrganisation';
import { PlanData, usePlan, usePlanDispatch } from '../PlanContext';
import { useProposePlan } from './useProposePlan';

const ProposeChangesButton = ({
  enabled,
  onSuccessfulPropose,
}: {
  enabled: boolean;
  onSuccessfulPropose: () => void;
}) => {
  const { status } = usePlan();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const planIsAgreed = status === PlanStatus.agreed;

  const { loading, proposePlan } = useProposePlan()
  // Propose changes to the plan, including the new plan name and wallet
  // Add new ad sets to the plan
  const handleProposeChanges = async () => {
    try {
      await proposePlan();
      onSuccessfulPropose();
    } catch (error) {
      console.error('Failed to propose changes: ', error);
    }

    setIsModalOpen(false);
  };

  const buttonLabel = planIsAgreed ? 'Save Changes' : 'Propose Changes';

  return (
    <>
      <Button
        disabled={!enabled || loading}
        endIcon={<SendIcon />}
        sx={{ width: '190px', maxHeight: '43px' }}
        id='propose-plan-button'
        onClick={() => setIsModalOpen(true)}
        loading={loading}
      >
        {buttonLabel}
      </Button>
      <AreYouSureModal
        isOpen={isModalOpen}
        title='Propose Changes'
        message='Are you sure you want to propose changes to this plan? Clicking yes will send a request to the plan owner to review your changes.'
        onYes={handleProposeChanges}
        onNo={() => setIsModalOpen(false)}
        onClose={() => setIsModalOpen(false)}
        loading={loading}
      />
    </>
  );
};

const AgreePlanButton = () => {
  const { id, status } = usePlan()
  const planIsAgreed = status === PlanStatus.agreed;

  const { agreePlan } = usePlanActions();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleAgreePlan = async () => {
    if (id) {
      await agreePlan(id);
      setIsModalOpen(false);
    }
  };

  const buttonLabel = planIsAgreed ? 'Plan agreed' : 'Agree plan';

  return (
    <>
      <Button
        disabled={planIsAgreed}
        endIcon={<ShoppingCartCheckoutIcon />}
        sx={{ width: '190px', maxHeight: '43px' }}
        id='agree-plan-button'
        onClick={() => setIsModalOpen(true)}
      >
        {buttonLabel}
      </Button>
      <AreYouSureModal
        isOpen={isModalOpen}
        message='Are you sure you want to agree to this plan? Clicking yes will irreversably agree to the plan.'
        onClose={() => setIsModalOpen(false)}
        onYes={handleAgreePlan}
        onNo={() => setIsModalOpen(false)}
      />
    </>
  );
};

export const PlanViewHeadingSection = ({
  mode,
  setMode,
  selectedWallet,
  onSuccessfulPropose,
}: {
  mode: ViewMode;
  setMode: React.Dispatch<React.SetStateAction<ViewMode>>;
  selectedWallet: Wallet | null;
  onSuccessfulPropose: () => void;
}) => {
  const organisation = useUserOrganisation();
  const { name, newAdSets, wallet } = usePlan()
  const dispatch = usePlanDispatch()
  const [initialPlanName] = useState(name ?? 'Undefined');

  const allAdSetsHaveBundleId = useMemo(() => {
    return newAdSets.every((adSet) => adSet?.bundleIds && adSet.bundleIds?.length > 0);
  }, [newAdSets]);

  const updatePlanName = (newPlanName: string) => dispatch({ type: 'updatePlan', plan: { name: newPlanName } as PlanData });

  // Check if a change has been made to the plan name or wallet
  const changeMade = initialPlanName !== name || selectedWallet?.id !== wallet?.id || newAdSets.length > 0;

  const proposeChangesButtonEnabled = changeMade && allAdSetsHaveBundleId;

  return (
    <Box id='plan-heading-section' display='flex' justifyContent='space-between' mt={3}>
      <Box display='flex'>
        <Typography variant='h4' color='primary' mr={2}>
          Plan
        </Typography>
        {mode === ViewMode.EDITING ? (
          <EditableField initialValue={name || 'Undefined'} editable={true} onChange={updatePlanName} />
        ) : (
          <EditableField initialValue={name || 'Undefined'} editable={false} />
        )}
      </Box>
      <Box display='inline-flex' justifyContent='space-between' gap={2}>
        <FormControl variant='standard'>
          <Select
            labelId='mode-select-label'
            id='mode-select'
            value={mode}
            variant='filled'
            onChange={(e) => setMode(e.target.value as ViewMode)}
            startAdornment={
              <InputAdornment position='start'>{mode === 'editing' ? <EditIcon /> : <VisibilityIcon />}</InputAdornment>
            }
            sx={{
              '& .MuiFilledInput-input': {
                paddingTop: '10px',
                paddingBottom: '10px',
              },
              minWidth: '180px',
            }}
          >
            <MenuItem value={ViewMode.EDITING}>Editing</MenuItem>
            <MenuItem value={ViewMode.REVIEWING}>Reviewing</MenuItem>
          </Select>
        </FormControl>
        {mode === ViewMode.EDITING && (
          <ProposeChangesButton
            enabled={proposeChangesButtonEnabled}
            onSuccessfulPropose={onSuccessfulPropose}
          />
        )}
        {organisation.is_retailer && mode === ViewMode.REVIEWING && (
          <AgreePlanButton />
        )}
      </Box>
    </Box>
  );
};
