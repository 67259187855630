import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cachedFetchBrands, selectAllBrands } from 'features/brands/brandsSlice';

export const useBrands = () => {
  const dispatch = useDispatch();
  const stateBrands = useSelector(selectAllBrands);

  useEffect(() => {
    // need to disable rules due to store being in js
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(cachedFetchBrands());
  }, [dispatch]);

  const brands: Array<{
    id: string;
    name: string;
  }> = stateBrands.map((brand) => ({
    id: brand.id,
    name: brand.name,
  }));

  return brands;
};
