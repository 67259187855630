import React from 'react'
import { Form } from 'react-bootstrap'
import PropTypes from 'prop-types'

const PreviewDropdown = ({ placements = [], value, disabled, onChange = () => { } }) => {
    if (!value) {
        onChange(placements[0].id)
    }

    return <Form.Control as='select' value={value} disabled={disabled} onChange={e => onChange(e.target.value)}>
        {placements.map(placement =>
            <option key={placement.id} value={placement.id}>{placement.label}</option>
        )}
    </Form.Control>
}

PreviewDropdown.propTypes = {
    placements: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
    })),
    value: PropTypes.string,
    onChange: PropTypes.func,
}

export default PreviewDropdown
