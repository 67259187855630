import React from 'react';
import styled from 'styled-components';
type Handler = 'retailer' | 'supplier';
const CheckboxOption = ({
  value,
  onChange,
  checked,
  label,
  disabled,
}: {
  value: Handler;
  checked: boolean;
  onChange: <T>(v: T) => void;
  label: string;
  disabled?: boolean;
}) => {
  return (
    <CheckboxContainer>
      <StyledCheckbox
        name={value}
        id={value}
        value={value}
        checked={checked}
        onChange={(e) => onChange(e.target.value as Handler)}
        disabled={disabled}
      />
      <Label htmlFor={value}>{label}</Label>
    </CheckboxContainer>
  );
};

export default CheckboxOption;

const StyledCheckbox = styled.input.attrs({ type: 'checkbox' })`
  accent-color: #7155ff;
  width: 16px;
  height: 16px;
`;
const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  color: black;
  gap: 4.5px;
`;
const Label = styled.label`
  font-weight: 300;
  font-size: 1em;
  display: inline-block;
  margin: 0;
`;
