import axios from '../../axios'

export const getBrandingAssets = (org_id) => {
    return axios.get(`/organisations/${org_id}/layout_assets`)
}

export const getOrganisations = (session) => {
    return axios.get(`/organisations`);
}

export const getOrganisation = (orgID) => {
    return axios.get(`/organisations/${orgID}`)
}

export const getOrganisationRelation = (retailerId, brandId) => {
    return axios.get(`/organisations/relation/${retailerId}/${brandId}`);
}

export const updateOrganisationSettings = (org_id, data) => {
    return axios.put(`/organisations/${org_id}`, data);
}

export const uploadOrganisationLogo = (org_id, logo, {onUploadProgress} = {
    onUploadProgress: () => {
    }
}) => {
    const form = new FormData()
    form.append('logo', logo)
    return axios.post(`/organisations/${org_id}/logo`, form, {
        onUploadProgress: onUploadProgress,
    });
}

export const uploadOrganisationNetworkLogo = (
    logo,
    { onUploadProgress } = {
      onUploadProgress: () => {},
    }
  ) => {
    const form = new FormData();
    form.append('network_logo', logo);
    return axios.post(`/network_branding/upload`, form, {
      onUploadProgress: onUploadProgress,
    });
  };

export async function updateSettings(org_id, data) {
    return axios.put(`/organisations/${org_id}/settings`, data);
}

export function enableOrganisationLink(org_id) {
    return axios.post(`/organisations/${org_id}/enable`, '');
}

export function disableOrganisationLink(org_id) {
    return axios.post(`/organisations/${org_id}/disable`, '');
}

export function refreshFacebookData() {
    return axios.get(`/organisations/facebook/refresh`);
}

export function refreshGoogleData() {
    return axios.get(`/organisations/google/refresh`);
}

// Returns a temporary URL to the order reports file
export function getOrderReportUrl(org_id, params) {
    return axios.get(`/organisations/${org_id}/report/orders?` + params);
}

export async function updateRoleDetails(organisationId, roleId, name, permissions) {
    const form = new FormData()
    form.set('name', name)
    form.set('permissions', JSON.stringify(permissions))

    return axios.post(`/organisations/${organisationId}/roles/${roleId}`, form);
}

export async function createRole(organisationId, name, permissions) {
    const form = new FormData()
    form.set('name', name)
    form.set('permissions', JSON.stringify(permissions))

    return axios.post(`/organisations/${organisationId}/roles`, form);
}

export async function makeVirtualSpacesRequest(org_id, spacename, displayLocation,
        format, subformat, notes, mediafile, { onUploadProgress } = { onUploadProgress: () => { } }) {
    const form = new FormData()
    form.set('name', spacename)
    form.set('displayLocation', displayLocation)
    form.set('format', format)
    form.set('subformat', subformat)
    form.set('notes', notes)
    form.append('mediafile', mediafile)
    return axios.post(`/organisations/${org_id}/ownedspace/virtual/request`, form, {
        onUploadProgress: onUploadProgress,
    })
}

export async function makeTemplateEditRequest(org_id, web_template_id, name, description) {
  const form = new FormData()
  form.set('name', name)
  form.set('description', description)
    return axios.patch(`/organisations/${org_id}/ownedtemplate/${web_template_id}`, form)
}

export async function makeTemplateRequest(org_id, name, notes, mediafile,
                                          { onUploadProgress } = { onUploadProgress: () => { } }) {
  const form = new FormData()
  form.set('name', name)
  form.set('notes', notes)
  form.append('mediafile', mediafile)
return axios.post(`/organisations/${org_id}/ownedtemplate`, form, {
    onUploadProgress: onUploadProgress,
})
}

export async function makeVirtualSpacesNewCategoryRequest(org_id, name, slot_pricing_mode,
    slot_price, slot_bid_mode, { onUploadProgress } = { onUploadProgress: () => { } }) {
const form = new FormData()
form.set('name', name)
form.set('slot_pricing_mode', slot_pricing_mode)
form.set('slot_price', slot_price)
form.set('slot_bid_mode', slot_bid_mode)

return axios.post(`/organisations/${org_id}/ownedspace/category`, form, {
    onUploadProgress: onUploadProgress,
})
}
