import React from 'react';
import { FormControlLabel, Checkbox } from '@mui/material';
import { RenderableMediaTypeFieldProperties } from 'v2/lib/api/inventory';

interface CheckboxComponentProps {
  properties: RenderableMediaTypeFieldProperties & {
    defaultChecked?: string;
    checkboxLabel?: string;
  };
  fieldName?: string;
  onChange: (...event: Array<any>) => void;
  onBlur: () => void;
  value?: boolean;
}

export const CheckboxComponent = ({ properties, fieldName, onChange, onBlur, value }: CheckboxComponentProps) => {
  const isChecked = value !== undefined ? value : properties.defaultChecked === 'true';

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={isChecked}
          onChange={onChange}
          onBlur={onBlur}
        />
      }
      label={properties.checkboxLabel}
      data-testid={`${fieldName}-checkbox-component`}
    />
  );
};