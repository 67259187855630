import { useGetBundle } from '../api/ad-management';

//call useGetBundle with bundleIdString, if bundleId is undefined return null values
const useSafeGetBundle = (bundleId: string | undefined) => {
    const bundleIdString = bundleId ?? '';
    const result = useGetBundle(bundleIdString);

    if (bundleId === undefined) {
        return {
            data: null,
            error: null,
            isLoading: false,
        };
    }

    return result;
}

export { useSafeGetBundle };