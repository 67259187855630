import React, {useRef, useState} from 'react';
import styled from 'styled-components';
import AddSquareButton from "./AddSquareButton";
import CallToAction from "./CallToAction";

const DropContainer = styled.div`
  min-height: 200px;
  border: 2px dashed #5a5c69;
  display: flex;

  ${props => props.empty ? `
    justify-content: center;
    align-items: center;
  `
          : `
    justify-content: left;
    align-items: start;
  `}

  ${props => props.dragged && `
    border: 2px dashed rgb(0, 95, 204);
    background-color: #f2fcff;
  `}
`;

const FileInput = styled.input`
  display: none;
`;

type FileDropzoneProps = {
    accept?: Array<string>,
    readOnly?: boolean,
    onChange: (fl: FileList) => void,
};

const FileDropzone = (props: React.PropsWithChildren<FileDropzoneProps>) => {
    const {
        accept = [],
        readOnly = false,
        children,
        onChange,
    } = props;

    const fileRef = useRef(null);
    const [dragged, setDragged] = useState<boolean>(false);
    const isEmpty = children === undefined

    const handleChange = files => onChange(files);

    const handleInputChange = e => handleChange(e.target.files);

    const handleDrop = e => {
        if (readOnly) return;
        handleChange(e.dataTransfer.files);
        setDragged(false);
    }

    const handleDragOver = e => {
        if (readOnly) return;
        setDragged(true);
    }

    const handleDragLeave = e => {
        if (readOnly) return;
        setDragged(false)
    }

    const handleClick = () => fileRef.current.click();


    let addButton;
    if (!readOnly && !isEmpty) {
        addButton = <AddSquareButton onClick={handleClick}/>;
    }
    if (!readOnly && isEmpty) {
        addButton = <CallToAction onClick={handleClick}/>
    }

    return <DropContainer
        className='rounded'
        dragged={dragged}
        empty={isEmpty}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
    >
        <FileInput
            type='file'
            ref={fileRef}
            accept={accept.map(f => `.${f}`).join(',')}
            multiple
            onChange={handleInputChange}
        />
        {children}
        {addButton}
    </DropContainer>
};

export default FileDropzone;
