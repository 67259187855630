import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { organisationRetailerPackages } from '../../lib/api';
import DashboardOrders from './DashboardOrders';
import MetricsOverview from './MetricsOverview';
import PackageOffers from './PackageOffers';
import WelcomeMessage from './WelcomeMessage';
import { markAdBlockMessageViewed, selectAdBlockMessageViewed } from '../../features/segment/segmentSlice';
import { isSegmentImplemented } from '../../helpers/segment';
import AdBlockModal from './AdBlockModal';

const BannerContainer = styled.div`
  padding: 63px 120px;
  border-radius: 0px;
  background: linear-gradient(358deg, #e3e5ff 75%, #ebedff 25%);
`;

const Dashboard = () => {
  const [packages, setPackages] = useState([]);
  const dispatch = useDispatch();
  const messageViewed = useSelector(selectAdBlockMessageViewed);
  const [showAdBlockModal, setShowAdBlockModal] = useState(!messageViewed && !isSegmentImplemented());

  useEffect(() => {
    organisationRetailerPackages().then((resp) => setPackages(resp.data.data));
  }, []);

  const handleOnAccept = (e) => {
    e.preventDefault();
    setShowAdBlockModal(false);
    dispatch(markAdBlockMessageViewed());
  };

  return (
    <>
      <Helmet>
        <title>Dashboard - {process.env.REACT_APP_NAME}</title>
      </Helmet>

      <Container fluid>
        <Row className='mb-5'>
          <Col xs='12' className='p-0'>
            <BannerContainer>
              <WelcomeMessage />
              <MetricsOverview />
            </BannerContainer>
          </Col>
        </Row>
        <Row className='px-5'>
          <Col xs='12' xl={packages.length === 0 ? 12 : 8} className='mb-5'>
            <DashboardOrders />
          </Col>
          <Col xs='12' xl='4' className={`${packages.length === 0 ? 'd-none' : ''}`}>
            <PackageOffers packages={packages} />
          </Col>
        </Row>
      </Container>
      <AdBlockModal show={showAdBlockModal} onAccept={handleOnAccept} />
    </>
  );
};

export default Dashboard;
