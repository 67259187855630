import React, { useState } from 'react';
import { Alert, Button, Col, Container, Form, InputGroup, Row, Spinner } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import { refreshSession, selectSession } from '../features/session/sessionSlice';
import { refreshUser, resetPassword, updateUserDetails } from '../lib/api';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import Breadcrumbs from './components/Breadcrumbs';

const StyledTitle = styled.div`
  font-weight: bold;
  h3 {
    font-size: 22px;
  }
  color: #000;
  text-align: left;
  width: 100%;
`;

const StyledSave = styled(Button)`
  &,
  &:hover,
  &:focus,
  &:active,
  &::selection {
    background: #7155ff;
    border-color: #644bf8 !important;
  }
`;

const selectStyles = {
  option: (styles) => ({
    ...styles,
    backgroundColor: 'while',
    color: 'black',
    overflow: 'wrap',
  }),
};

const OrganisationUserEdit = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const session = useSelector(selectSession);
  const dispatch = useDispatch();
  const [organisation, setOrganisation] = useState(session.user.active_organisation);
  const [msg, setMsg] = useState(null);
  const [userActionMsg, setUserActionMsg] = useState(null);
  const [isSavingDetails, setIsSavingDetails] = useState();
  const [isPerformingAction, setIsPerformingAction] = useState(false);
  const [selectedUser, setSelectedUser] = useState(
    session.user.active_organisation.users.find((user) => user.id === id)
  );
  const [thisUser, setThisUser] = useState(session.user);

  const breadcrumbItems = [
    {
      path: '/organisation/settings',
      title: 'Organisation Settings',
    },
    {
      path: `/organisation/settings/${selectedUser?.id}`,
      title: `${selectedUser?.first_name} ${selectedUser?.last_name}`,
      active: true,
    },
  ];

  const [userDetailsForm, setUserDetailsForm] = useState({
    first_name: '',
    last_name: '',
    jobtitle: '',
    role: '',
    is_active: false,
  });

  const [userActions, setUserActions] = useState([{ value: 'passwordReset', label: "Reset user's password" }]);

  const [userRoles, setUserRoles] = useState(
    session?.available_roles
      ? session?.available_roles.map((r) => {
          return { value: r.id, label: r.name };
        })
      : []
  );
  const [selectedUserAction, setSelectedUserAction] = useState(null);

  useEffect(() => {
    setUserDetailsForm({
      first_name: selectedUser?.first_name,
      last_name: selectedUser?.last_name,
      jobtitle: selectedUser?.jobtitle,
      role: selectedUser?.role?.id,
      is_active: selectedUser?.is_active,
    });
  }, []);

  const handleUserAction = (e) => {
    e.preventDefault();
    setMsg(null);
    setUserActionMsg(null);

    if (selectedUserAction === null || selectedUserAction === '') {
      setUserActionMsg({
        type: 'danger',
        body: 'Please select user action.',
      });
      return;
    }

    setIsPerformingAction(true);

    if (selectedUserAction?.value === 'passwordReset') {
      resetPassword(selectedUser.email)
        .then((res) => {
          refreshUser().then((res) => {
            dispatch(refreshSession());
            setMsg({});
            setIsPerformingAction(false);
            toast.success('The user has been sent a reset password email');
          });
        })
        .catch((err) => {
          const errors = err?.response?.data;
          const keys = Object.keys(errors || {});
          if (!errors || !keys.length || typeof errors === 'string') {
            setUserActionMsg({
              type: 'danger',
              body: 'Could not perform the action, please try again.',
            });
          } else {
            let tmp = [];
            keys.forEach((errorKey) => {
              tmp.push(errors[errorKey]);
            });
            setUserActionMsg({
              type: 'danger',
              body: tmp,
            });
          }
        });
    }
  };

  const handleUpdateUserDetailsSubmit = (e) => {
    e.preventDefault();
    setMsg(null);
    setUserActionMsg(null);
    setIsSavingDetails(true);

    updateUserDetails(
      selectedUser.id,
      userDetailsForm.first_name,
      userDetailsForm.last_name,
      null,
      userDetailsForm.jobtitle,
      userDetailsForm.role,
      userDetailsForm.is_active
    )
      .then((res) => {
        refreshUser().then((res) => {
          dispatch(refreshSession());
          setMsg({});
          setIsSavingDetails(false);
          navigate(`/organisation/settings`);
          window.location.reload();
        });
      })
      .catch((err) => {
        setIsSavingDetails(false);
        const errors = err?.response?.data;
        const keys = Object.keys(errors || {});
        if (!errors || !keys.length || typeof errors === 'string') {
          setMsg({
            type: 'danger',
            body: 'Could not save the details, please try again.',
          });
        } else {
          let tmp = [];
          keys.forEach((errorKey) => {
            tmp.push(errors[errorKey]);
          });
          setMsg({
            type: 'danger',
            body: tmp,
          });
        }
      });
  };

  return (
    <>
      <Helmet>
        <title>
          Organisation Settings for {organisation.name} - {process.env.REACT_APP_NAME}
        </title>
      </Helmet>

      <Container>
        <Breadcrumbs items={breadcrumbItems} />
        <Row className='mb-3'>
          <Col>
            <div className='rounded py-3 d-flex'>
              <div className='d-inline-block w-100'>
                <h3>
                  {selectedUser?.first_name} {selectedUser?.last_name}
                </h3>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className='rounded py-3 d-flex'>
              <StyledTitle className='d-inline-block'>
                <h3 className='font-weight-bold'>User Details</h3>
                <hr />
              </StyledTitle>
            </div>
          </Col>
        </Row>

        <Form id='test' onSubmit={(e) => handleUpdateUserDetailsSubmit(e)}>
          <Row className='mb-2'>
            <Col lg='6'>
              <Form.Group>
                <Form.Label>First Name:</Form.Label>
                <InputGroup>
                  <Form.Control
                    type='text'
                    value={userDetailsForm?.first_name}
                    style={session?.theme?.components?.form_control}
                    onChange={(e) => setUserDetailsForm({ ...userDetailsForm, first_name: e.target.value })}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col lg='6'>
              <Form.Group>
                <Form.Label>Last Name:</Form.Label>
                <InputGroup>
                  <Form.Control
                    type='text'
                    value={userDetailsForm?.last_name}
                    style={session?.theme?.components?.form_control}
                    onChange={(e) => setUserDetailsForm({ ...userDetailsForm, last_name: e.target.value })}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col lg='6'>
              <Form.Group>
                <Form.Label>Jobtitle:</Form.Label>
                <InputGroup>
                  <Form.Control
                    type='text'
                    value={userDetailsForm?.jobtitle}
                    style={session?.theme?.components?.form_control}
                    onChange={(e) => setUserDetailsForm({ ...userDetailsForm, jobtitle: e.target.value })}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col lg='6'>
              <Form.Group>
                <Form.Label>Email:</Form.Label>
                <InputGroup>
                  <Form.Control
                    type='text'
                    value={selectedUser?.email}
                    style={session?.theme?.components?.form_control}
                    disabled
                  />
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
          <Form.Group className='mb-3'>
            {thisUser.id !== selectedUser.id && (
              <div>
                <div>
                  <div className='custom-control custom-switch'>
                    <input
                      type='checkbox'
                      className='custom-control-input'
                      id='isActive'
                      value={true}
                      checked={userDetailsForm?.is_active}
                      onChange={(e) => setUserDetailsForm({ ...userDetailsForm, is_active: e.target.checked })}
                    />
                    <label className='custom-control-label' htmlFor='isActive'>
                      Is active
                    </label>
                  </div>
                </div>
                <div>&nbsp;</div>
                <Row className='mb-3'>
                  <Col lg='6'>
                    <label>Role:</label>
                    <Select
                      placeholder='Select User Role'
                      styles={selectStyles}
                      value={userRoles.find((r) => r.value === userDetailsForm?.role)}
                      onChange={(e) => setUserDetailsForm({ ...userDetailsForm, role: e.value })}
                      options={userRoles}
                    />
                  </Col>
                </Row>
              </div>
            )}
            {thisUser.id === selectedUser.id && (
              <div>
                <FontAwesomeIcon icon={faInfoCircle} size='1x' style={{ marginRight: '4px', marginLeft: '2px' }} />
                <span>You cannot change active /inactive or role for yourself.</span>
              </div>
            )}
          </Form.Group>
        </Form>
        <hr />
        <Form onSubmit={(e) => handleUserAction(e)}>
          <Row className='mb-2'>
            <Col lg='6'>
              <h3>User operations</h3>
              <Form.Group>
                <Select
                  placeholder='Select Action'
                  styles={selectStyles}
                  value={selectedUserAction}
                  onChange={(e) => {
                    setSelectedUserAction(e);
                  }}
                  options={userActions}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col>
              <Button variant='danger' className='mr-auto px-3' type='submit' size='md' disabled={isPerformingAction}>
                <span className='small d-flex'>
                  {isPerformingAction && (
                    <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' className='mr-2' />
                  )}
                  <span>Perform Action</span>
                </span>
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              {userActionMsg && !Array.isArray(userActionMsg.body) && (
                <Alert className='mt-3' variant={userActionMsg.type}>
                  {userActionMsg.body}
                </Alert>
              )}
              {userActionMsg && Array.isArray(userActionMsg.body) && (
                <Alert className='mt-3' variant={userActionMsg.type}>
                  {userActionMsg.body.map((key, i) => (
                    <li>{userActionMsg.body[i]}</li>
                  ))}
                </Alert>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledSave className='mr-auto px-5 py-2' onClick={() => navigate('/organisation/settings')}>
                <span className='small d-flex'>
                  <span>Back</span>
                </span>
              </StyledSave>
              <StyledSave
                variant='primary'
                className='mr-auto px-5 py-2 float-right'
                type='submit'
                size='md'
                disabled={isSavingDetails}
                form='test'
              >
                <span className='small d-flex'>
                  {isSavingDetails && (
                    <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' className='mr-2' />
                  )}
                  <span>Save Changes</span>
                </span>
              </StyledSave>
            </Col>
          </Row>
          <Row>
            <Col>
              {msg && !Array.isArray(msg.body) && (
                <Alert className='mt-3' variant={msg.type}>
                  {msg.body}
                </Alert>
              )}
              {msg && Array.isArray(msg.body) && (
                <Alert className='mt-3' variant={msg.type}>
                  {msg.body.map((key, i) => (
                    <li>{msg.body[i]}</li>
                  ))}
                </Alert>
              )}
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
};

export default OrganisationUserEdit;
