import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";
import { Badge, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";
import { selectTheme } from "../../../session/sessionSlice";
import Errors from "../Errors";

const Text = ({
  header,
  controlId,
  placeholder = "",
  readOnly = false,
  disabled = false,
  required = false,
  value = "",
  onChange = () => {},
  errors = [],
  minLength=0,
  maxLength,
  maxLengthSoft,
  notice,
  info,
}) => {
  const theme = useSelector(selectTheme);

  return (
    <Form.Group controlId={controlId}>
      <Form.Label className='d-flex align-self-center align-items-center font-weight-bold'>
        <span>
          {header} {required && "*"}{" "}
          {info && (
            <OverlayTrigger
              placement='left'
              overlay={
                <Tooltip id={`${controlId}-info-popover`}>{info}</Tooltip>
              }
            >
              <FontAwesomeIcon icon={faQuestionCircle} size='sm' />
            </OverlayTrigger>
          )}
        </span>
        {maxLengthSoft !== undefined && (
          <Badge className='ml-auto' variant={minLength <= value.length && maxLengthSoft >= value.length ? "primary" : "danger"}>
            {value.length} / {maxLengthSoft} characters
          </Badge>
        )}
      </Form.Label>
      {notice && (
        <Form.Control.Feedback className='d-block text-body mb-2'>
          {notice}
        </Form.Control.Feedback>
      )}
      <Form.Control
        type='text'
        style={theme.components?.form_control}
        readOnly={readOnly}
        disabled={disabled}
        placeholder={placeholder}
        value={value}
        isInvalid={errors.length > 0}
        onChange={(e) => onChange(e.target.value)}
        required={required}
        minLength={minLength}
        maxLength={maxLength}
      />
      <Form.Control.Feedback type='invalid'>
        <Errors errors={errors} />
      </Form.Control.Feedback>
    </Form.Group>
  );
};

Text.propTypes = {
  header: PropTypes.string.isRequired,
  controlId: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  maxLength: PropTypes.number,
  maxLengthSoft: PropTypes.number,
  notice: PropTypes.string,
  info: PropTypes.string,
};

export default Text;
