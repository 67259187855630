import React from 'react';
import { FormControlLabel, Switch } from '@mui/material';
import { RenderableMediaTypeFieldProperties } from 'v2/lib/api/inventory';

interface ToggleComponentProps {
  properties: RenderableMediaTypeFieldProperties & {
    defaultChecked?: boolean;
    toggleLabel?: string;
  };
  fieldName?: string;
  onChange: (...event: Array<any>) => void;
  onBlur: () => void;
  value: any;
}

export const ToggleComponent = ({
  properties,
  fieldName,
  onChange,
  onBlur,
  value,
}: ToggleComponentProps) => {
  const controlledValue = value || properties.defaultChecked || false
  return (
    <FormControlLabel
      control={
        <Switch
          onChange={onChange}
          onBlur={onBlur}
          value={Boolean(controlledValue)}
        />
      }
      label={properties.toggleLabel}
      data-testid={`${fieldName}-toggle-component`}
    />
  );
};