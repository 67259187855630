import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import ConnectedInsightsView from '../metrics/ConnectedInsightsView';

export const MetricsTab = ({ insights = {}, campaign, currency }) => {
  return (
    <Container>
      <Row>
        <Col sm='12'>
          <ConnectedInsightsView insights={insights} refreshedAt={campaign?.refreshed_at || null} currency={currency} />
        </Col>
      </Row>
    </Container>
  );
};

MetricsTab.propTypes = {
  insights: PropTypes.object.isRequired,
};
