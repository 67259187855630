import React, { useEffect, useMemo, useState } from 'react';
import { DateRangePicker, Box, type DateRange, Autocomplete, TextField } from '@zitcha/component-library';
import { AdSetSearch, AdSetStatusEnum } from 'v2/lib/api/ad-management';
import { CustomSelect } from './CustomSelect';
import { useBrands } from 'v2/lib/hooks/useBrands';
import { useBundleLocations } from 'v2/lib/hooks/useBundleLocations';
import { useRetailers } from '../../lib/hooks/useRetailers';
import { MediaType, useGetMediaSpaces } from 'v2/lib/api/inventory';
import { useUserOrganisation } from 'v2/lib/hooks/useUserOrganisation';

type AdSetsTableFiltersProps = {
  onFilterChange: (newFilter: Partial<AdSetSearch>) => void;
  isRetailerView: boolean;
};
type FilterOption<T> = { id: T; selected: boolean; name: string };

export const AdSetsTableFilters: React.FC<AdSetsTableFiltersProps> = ({ onFilterChange, isRetailerView }) => {
  const organisation = useUserOrganisation();
  const { data: getMediaTypesData, isLoading: isLoadingMediaTypes } = useGetMediaSpaces({
    organisation_id: organisation.id,
  });
  const brands = useBrands();
  const retailers = useRetailers();

  const brandOptions = useMemo(
    () =>
      brands.map((brand) => ({
        id: brand.id,
        selected: false,
        name: brand.name,
      })),
    [brands]
  );
  const retailerOptions = useMemo(
    () =>
      retailers.map((retailer) => ({
        id: retailer.id,
        selected: false,
        name: retailer.name,
      })),
    [retailers]
  );

  const { placementOptions: placements, isLoading: isLoadingPlacements } = useBundleLocations();
  const [activeToggleFilter, setActiveToggleFilter] = useState<AdSetStatusEnum | undefined>(undefined);
  const allStatuses: Array<AdSetStatusEnum> = Object.keys(AdSetStatusEnum) as Array<AdSetStatusEnum>;
  const [statusOptions, setStatusOptions] = useState<Array<FilterOption<string>>>(
    allStatuses.map((status) => ({ id: status, selected: false, name: status }))
  );

  const [mediaTypeOptions, setMediaTypeOptions] = useState<Array<FilterOption<string>>>([]);

  useEffect(() => {
    const mediaTypes = getMediaTypesData?.data;
    if (mediaTypes) {
      setMediaTypeOptions(
        mediaTypes.map((mediaType: MediaType) => ({ id: mediaType.id, selected: false, name: mediaType.name }))
      );
    }
  }, [getMediaTypesData]);

  const [selectedDateRange, setSelectedDateRange] = useState<DateRange | undefined>({ from: undefined, to: undefined });
  const [placementOptions, setPlacementOptions] = useState<Array<FilterOption<string>>>(
    placements as Array<FilterOption<string>>
  );
  //compute selected options from options
  const selectedStatusOptions = useMemo(
    () => statusOptions?.filter((option: FilterOption<string>) => option.selected),
    [statusOptions]
  );
  const [selectedBrandOptions, setSelectedBrandOptions] = useState(
    brandOptions.filter((option: FilterOption<string>) => option.selected)
  );

  const [selectedRetailerOptions, setSelectedRetailerOptions] = useState(
    retailerOptions?.filter((option: FilterOption<string>) => option.selected)
  );

  const selectedMediaTypeOptions = useMemo(
    () => mediaTypeOptions?.filter((option: FilterOption<string>) => option.selected),
    [mediaTypeOptions]
  );

  const selectedPlacementOptions = useMemo(
    () => placementOptions?.filter((option: FilterOption<string>) => option.selected),
    [placementOptions]
  );

  useEffect(() => {
    if (!isLoadingPlacements) {
      setPlacementOptions(placements as Array<FilterOption<string>>);
    }
  }, [isLoadingPlacements]);

  const calculateActiveToggleFilterValue = (activeToggleFilter: AdSetStatusEnum | string | undefined) => {
    switch (activeToggleFilter) {
      case AdSetStatusEnum.live:
        return [AdSetStatusEnum.live];
      case AdSetStatusEnum.paused:
        return [AdSetStatusEnum.paused];
      default:
        return [];
    }
  };

  const calculateStatusFilter = () => {
    return [
      ...selectedStatusOptions.map((option: FilterOption<AdSetStatusEnum>) => option.id),
      ...calculateActiveToggleFilterValue(activeToggleFilter),
    ];
  };

  const getRetailerSupplierFilters = () => {
    if (isRetailerView) {
      return {
        supplier_ids:
          selectedBrandOptions && selectedBrandOptions.length > 0
            ? selectedBrandOptions.map((option: FilterOption<string>) => option.id)
            : undefined,
      };
    }
    return {
      retailer_ids:
        selectedRetailerOptions && selectedRetailerOptions.length > 0
          ? selectedRetailerOptions.map((option: FilterOption<string>) => option.id)
          : undefined,
    };
  };

  useEffect(() => {
    onFilterChange({
      statuses: calculateStatusFilter().length ? calculateStatusFilter() : undefined,
      media_space_ids:
        selectedMediaTypeOptions && selectedMediaTypeOptions.length > 0
          ? selectedMediaTypeOptions.map((option) => option.id)
          : undefined,
      start_date: selectedDateRange?.to ? selectedDateRange?.from?.toISOString() : undefined,
      end_date: selectedDateRange?.to?.toISOString(),
      ...getRetailerSupplierFilters(),
    });
  }, [
    selectedBrandOptions,
    selectedRetailerOptions,
    selectedMediaTypeOptions,
    selectedDateRange,
    selectedStatusOptions,
    activeToggleFilter,
  ]);

  return (
    <Box sx={{ display: 'flex', gap: 2, padding: 2 }} className='twd-overflow-auto'>
      <CustomSelect
        id='active-toggle'
        label='Active toggle'
        options={[
          { value: undefined, label: 'Active & Inactive' },
          { value: AdSetStatusEnum.live, label: 'Active' },
          { value: AdSetStatusEnum.paused, label: 'Inactive' },
        ]}
        selectedValue={activeToggleFilter}
        onChange={setActiveToggleFilter}
      />

      <Autocomplete
        id='status'
        label='Status'
        options={statusOptions}
        value={selectedStatusOptions}
        onChange={(_, newValue: Array<FilterOption<string>>) => {
          setStatusOptions(
            statusOptions?.map((option) => ({
              ...option,
              selected:
                newValue?.some((selectedOption: FilterOption<string>) => selectedOption.id === option.id) || false,
            }))
          );
        }}
        sx={{ minWidth: 200 }}
        multiple
        getOptionLabel={(option: FilterOption<string>) => option.name}
        renderInput={(params) => <TextField {...params} label='Status' />}
      />

      {isRetailerView ? (
        <Autocomplete
          id='brand'
          value={selectedBrandOptions}
          options={brandOptions}
          onChange={(_, newValue: Array<FilterOption<string>>) => {
            setSelectedBrandOptions(
              brandOptions
                .filter((option) => newValue.find((selectedOption) => selectedOption.id === option.id))
                .map((option) => ({ ...option, selected: true }))
            );
          }}
          sx={{ minWidth: 200 }}
          multiple
          getOptionLabel={(option: FilterOption<string>) => option.name}
          renderInput={(params) => <TextField {...params} label='Brands' />}
        />
      ) : (
        <Autocomplete
          id='retailer'
          value={selectedRetailerOptions}
          options={retailerOptions}
          onChange={(_, newValue: Array<FilterOption<string>>) => {
            setSelectedRetailerOptions(
              retailerOptions
                .filter((option) => newValue.find((selectedOption) => selectedOption.id === option.id))
                .map((option) => ({ ...option, selected: true }))
            );
          }}
          sx={{ minWidth: 200 }}
          multiple
          getOptionLabel={(option: FilterOption<string>) => option.name}
          renderInput={(params) => <TextField {...params} label='Retailers' />}
        />
      )}

      <Autocomplete
        id='media-type'
        value={selectedMediaTypeOptions}
        options={mediaTypeOptions}
        onChange={(_, newValue: Array<FilterOption<string>>) => {
          setMediaTypeOptions(
            mediaTypeOptions?.map((option) => ({
              ...option,
              selected:
                newValue?.some((selectedOption: FilterOption<string>) => selectedOption.id == option.id) || false,
            }))
          );
        }}
        sx={{ minWidth: 200 }}
        multiple
        getOptionLabel={(option: FilterOption<string>) => option.name}
        renderInput={(params) => <TextField {...params} label='Media types' />}
        loading={isLoadingMediaTypes}
      />

      <DateRangePicker
        placeholderText='Schedule'
        date={selectedDateRange}
        setDate={(date) => setSelectedDateRange(date)}
      />
      <Autocomplete
        id='placements'
        label='Placements'
        options={placementOptions}
        value={selectedPlacementOptions}
        onChange={(_, newValue: Array<FilterOption<string>>) => {
          setPlacementOptions(
            placementOptions?.map((option) => ({
              ...option,
              selected:
                newValue?.some((selectedOption: FilterOption<string>) => selectedOption.id == option.id) || false,
            }))
          );
        }}
        sx={{ minWidth: 200 }}
        multiple
        getOptionLabel={(option: FilterOption<string>) => option.name}
      />
    </Box>
  );
};
