import React, { useEffect, useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { selectUser } from '../session/sessionSlice';
import Breadcrumbs from '../../app/components/Breadcrumbs';
import { getAudienceUsage } from '../../lib/api/inventory';
import InventoryAudienceDetails from '../owned_space_form/components/InventoryAudienceDetails';
import InventoryAudiencePerformance from '../owned_space_form/components/InventoryAudiencePerformance';
import InventoryAudienceActions from '../owned_space_form/components/InventoryAudienceActions';
import InventoryAudienceBookingSchedule from '../owned_space_form/components/InventoryAudienceBookingSchedule';

const OrganisationInventoryAudienceDetails = () => {
  const user = useSelector(selectUser);
  const { audience_id } = useParams();
  const [audienceUsageData, setAudienceUsageData] = useState([]);

  useEffect(() => {
    getAudienceUsage(user.active_organisation.id, audience_id)
      .then((res) => {
        let data = res.data;
        if (data) {
          setAudienceUsageData(data);
        }
      })
      .catch((err) => { });
  }, []);

  const breadcrumbItems = [
    {
      path: '/inventory/home',
      title: 'Your Inventory',
    },
    {
      path: `/inventory/ownedspace/usage/${audience_id}`,
      title: audienceUsageData?.summary?.name || 'Your Audience',
      active: true,
    },
  ];

  return (
    <>
      <Helmet>
        <title>Edit Virtual Space - {process.env.REACT_APP_NAME}</title>
      </Helmet>

      <Container className='pt-4'>
        <Breadcrumbs items={breadcrumbItems} />
        <Row>
          <Col>
            <Form>
              <div className='mb-3 d-flex'>
                <Link to={breadcrumbItems[0].path}>
                  <FontAwesomeIcon icon={faChevronLeft} /> Back to Your Inventory
                </Link>
              </div>
              <div className='mb-5 d-flex'>
                <div>
                  <div className='d-flex align-items-center mb-2'>
                    <h3>{audienceUsageData?.summary?.name || 'Your Audience'}</h3>{' '}
                  </div>
                </div>
              </div>
              <Container fluid>
                <Row>
                  <Col>
                    <InventoryAudienceDetails audience={audienceUsageData?.summary} />
                  </Col>
                  <Col>
                    <InventoryAudiencePerformance performance={audienceUsageData?.performance} />
                  </Col>
                </Row>
                <Row>
                  <Col>&nbsp;</Col>
                  <Col>&nbsp;</Col>
                </Row>
                <Row>
                  <Col>
                    <InventoryAudienceActions audience={audienceUsageData} />
                  </Col>
                  <Col>
                    <InventoryAudienceBookingSchedule schedule={audienceUsageData?.schedule} />
                  </Col>
                </Row>
              </Container>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default OrganisationInventoryAudienceDetails;
