import React from "react";
import propTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import {OverlayTrigger, Popover} from "react-bootstrap";

const PopoverHint = ({popoverText, trigger = ["hover", "focus"], placement = "right"}) => (
    <OverlayTrigger
        placement={placement}
        trigger={trigger}
        overlay={
            <Popover>
                <Popover.Content>{popoverText}</Popover.Content>
            </Popover>
        }
    >
        <FontAwesomeIcon icon={faInfoCircle} className='ml-1'/>
    </OverlayTrigger>
);

PopoverHint.prototype = {
    popoverText: propTypes.string.isRequired,
    trigger: propTypes.string,
    placement: propTypes.string,
};

export default PopoverHint;
