import { useQueryClient } from '@tanstack/react-query';
import { type AdSet, useTransitionAdSet, type SearchAdSets200 } from '../api/ad-management';

const AdsetQueryKeys = ['searchAdSets'];
export const useToggleAdset = () => {
  const queryClient = useQueryClient();
  const { mutate, isSuccess, isPending } = useTransitionAdSet({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: AdsetQueryKeys });
      },
      onMutate: async (data) => {
        // get last used key that includes searchAdSets
        const queryKeys = queryClient.getQueryCache().findAll({
          queryKey: AdsetQueryKeys,
        });
        const lastQueryKey = queryKeys[queryKeys.length - 1];
        await queryClient.cancelQueries({ queryKey: lastQueryKey.queryKey });
        const previousValue = await queryClient.getQueryData(lastQueryKey.queryKey);
        queryClient.setQueryData(lastQueryKey.queryKey, (old: SearchAdSets200) => {
          return {
            ...old,
            data: old?.data?.map((adSet: AdSet) => {
              if (adSet.id === data.id) {
                return {
                  ...adSet,
                  status: data.data.status,
                };
              }
              return adSet;
            }),
          };
        });
        return { previousValue };
      },
    },
  });

  return {
    mutate,
    isSuccess,
    isPending,
  };
};
