import styled from 'styled-components';

const Svg = styled.svg`
  min-width: 13px;
  stroke: #777785;
`;

export const ChevronDownIcon = () => {
  return (
    <Svg width='13' height='13' viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1.64258 4.25L6.14258 8.75L10.6426 4.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </Svg>
  );
};
