import React, { SyntheticEvent, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Typography, Tabs, Tab, Box } from '@zitcha/component-library';
import { PlansTabContent } from 'v2/features/plansAndAdSets/PlansTabContent';
import { AdSetsTabContent } from 'v2/features/plansAndAdSets/AdSetsTabContent';
import { useSearchParams } from 'react-router-dom';

type TabValue = 'plans' | 'ad-sets';

export const PlansAdSetsView = () => {
  const [selectedTab, setSelectedTab] = useSearchParams();

  useEffect(() => {
    if (!selectedTab.get('tab')) {
      setSelectedTab({ tab: 'plans' });
    }
  }, [selectedTab.get, setSelectedTab]);

  const handleTabChange = (_: SyntheticEvent, newValue: TabValue) => {
    setSelectedTab({ tab: newValue });
  };

  return (
    <>
      <Helmet>
        {/*if we want to change the title for child views, Outlet needs to replace Helmet and Chrome*/}
        <title data-testid='pageTitle'>Plans & Ad Sets - {process.env.REACT_APP_NAME}</title>
      </Helmet>

      <Box id='plan-heading-section' display='flex' justifyContent='space-between' mt={3}>
        <Typography variant='h4' data-testid='pageHeading'>
          Plans & ad sets
        </Typography>
      </Box>
      <Box id='plan-heading-section' display='flex' justifyContent='space-between' mt={3}>
        <Tabs value={selectedTab.get('tab') || 'plans'} onChange={handleTabChange} aria-label='Plans and Ad Sets Tabs'>
          <Tab label='PLANS' value='plans' data-testid='plansTab' />
          <Tab label='AD SETS' value='ad-sets' data-testid='adSetsTab' />
        </Tabs>
      </Box>
      {selectedTab.get('tab') === 'plans' && <PlansTabContent />}
      {selectedTab.get('tab') === 'ad-sets' && <AdSetsTabContent />}
    </>
  );
};
