import React from 'react'
import Select, { components } from 'react-select'

const MultiSelect = ({ title, options = [], value = [], onChange = () => { } }) => {
    let optionsWithSelect = [{ value: 'select-all', label: 'Select All' }, ...options]
    value = options.filter(option => value.includes(option.value))

    if (value.length === options.length) {
        optionsWithSelect = [{ value: 'unselect-all', label: 'Unselect All' }, ...options]
        value = [{ value: 'unselect-all', label: 'Unselect All' }, ...value]
    }
    return <Select
        isMulti
        controlShouldRenderValue={false}
        isClearable={false}
        isSearchable={false}
        className='basic-multi-select'
        classNamePrefix='select'
        placeholder={title}
        styles={selectStyles}
        components={{ Option, MultiValue, DropdownInput }}
        hideSelectedOptions={false}
        backspaceRemovesValue={false}
        closeMenuOnSelect={false}
        value={value}
        onChange={selected => {
            // User presses select all
            if (selected.some(s => s.value === 'select-all')) {
                onChange(options.map(s => s.value))
            } else if (selected.length === options.length) { // User unselects something when full
                if (selected.every(s => s.value !== 'unselect-all') && value.length > selected.length) { // User presses unselect
                    onChange([])
                } else { // User presses unselect when all selected, or presses select when almost all selected
                    onChange(selected.filter(s => s.value !== 'unselect-all').map(s => s.value))
                }
            } else {
                onChange(selected.map(s => s.value))
            }
        }}
        options={optionsWithSelect}
    />
}

const selectStyles = {
    option: styles => ({
        ...styles,
        backgroundColor: 'while',
        color: 'black',
        overflow: 'wrap',
    }),
}

const Option = props => (
    <div>
        <components.Option {...props}>
            <div className='d-flex align-items-top'>
                <input type='checkbox' checked={props.isSelected} className='mr-2 mt-1' onChange={() => null} />
                <label className='my-0' style={{ wordWrap: 'break-word' }}>{props.label}</label>
            </div>
        </components.Option>
    </div>
)

const MultiValue = props => (
    <components.MultiValue {...props}>
        <span>{props.data.label}</span>
    </components.MultiValue>
)

const DropdownInput = props => (
    <components.Input {...props}>
        <span>Metrics</span>
    </components.Input>
)

export default MultiSelect