import { useState } from 'react';
import { useGetPlans } from 'v2/lib/api/ad-management';
import { PlanSearch, PerPageEnum } from 'v2/lib/api/ad-management/model';

export const useFetchPlans = (initialPlanSearch: PlanSearch = {}) => {
  const [planSearch, setPlanSearch] = useState(initialPlanSearch);
  const [planPage, setPlanPage] = useState<number>(1);
  const [plansPerPage, setPlansPerPage] = useState<PerPageEnum>(PerPageEnum.NUMBER_10);

  const plansMutation = useGetPlans({
    page: planPage,
    per_page: plansPerPage,
    ...planSearch,
  });

  const changePlanPagination = (newPage: number, newPerPage: PerPageEnum) => {
    if (newPage > 0 && newPage !== planPage) {
      setPlanPage(newPage);
    }

    if (newPerPage > 0 && newPerPage !== plansPerPage) {
      setPlansPerPage(newPerPage);
    }
  };

  return {
    plansData: plansMutation.data?.data ?? [],
    planPagination: {
      currentPage: plansMutation.data?.meta?.current_page,
      from: plansMutation.data?.meta?.from,
      lastPage: plansMutation.data?.meta?.last_page,
      perPage: plansMutation.data?.meta?.per_page,
      to: plansMutation.data?.meta?.to,
      total: plansMutation.data?.meta?.total,
    },
    changePlanPagination,
    isLoadingPlans: plansMutation.isLoading,
    plansError: plansMutation.error,
    setPlanSearch,
  };
};
