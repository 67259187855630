import { useTheme, Button, SvgIconProps, Typography, IconButton } from '@zitcha/component-library';
import React, { useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { NavigationContext } from './Chrome';

type Icon = React.ElementType<SvgIconProps>;
function MenuItem({ link, Icon, title, onlyIcon }: { link: string; Icon?: Icon; title: string; onlyIcon?: boolean }) {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isCurrentPage = location.pathname + location.search === link;
  const navContext = useContext(NavigationContext);

  return (
    <div>
      {onlyIcon && Icon ? (
        <IconButton
          aria-label='menu-item'
          onClick={() => {
            navigate(link);
          }}
          sx={{
            color: `${isCurrentPage ? theme.palette.primary.main : theme.palette.grey[700]}`,
            backgroundColor: `${isCurrentPage ? theme.palette.grey[200] : 'transparent'}`,
            minWidth: '2.65rem',
            width: '2.65rem',
            height: '2.65rem',
            '&:hover': {
              color: theme.palette.primary.main,
            },
          }}
          size='large'
        >
          <Icon />
        </IconButton>
      ) : (
        <Button
          onClick={() => {
            navigate(link);
            navContext.setDrawerWidth(navContext.drawerSizes.collapsed);
          }}
          sx={{
            ...styles,
            color: `${isCurrentPage ? theme.palette.primary.main : theme.palette.grey[700]}`,
            backgroundColor: `${isCurrentPage ? theme.palette.grey[200] : 'transparent'}`,
            padding: '.65rem 1.375rem',
            height: '2.65rem',
            boxShadow: 'none',
            '&:hover': {
              color: theme.palette.primary.main,
              backgroundColor: `${theme.palette.grey[200]}`,
              boxShadow: 'none',
            },
          }}
          size='large'
        >
          {Icon && <Icon />}
          <Typography variant='body1'>{title}</Typography>
        </Button>
      )}
    </div>
  );
}
export { MenuItem };
const styles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  width: '100%',
  gap: '1.5rem',
  textTransform: 'none',
  fontWeight: '400',
  lineHeight: '150%',
  letterSpacing: '0.00938rem',
};
