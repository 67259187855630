import styled from 'styled-components';

const Svg = styled.svg`
  min-width: 13px;
  fill: #777785;
`;

export const DatePickerIcon = () => {
  return (
    <Svg width='13' height='15' viewBox='0 0 13 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10.6426 2C11.5996 2 12.3926 2.79297 12.3926 3.75V12.5C12.3926 13.4844 11.5996 14.25 10.6426 14.25H1.89258C0.908203 14.25 0.142578 13.4844 0.142578 12.5V3.75C0.142578 2.79297 0.908203 2 1.89258 2H2.98633V0.90625C2.98633 0.550781 3.25977 0.25 3.64258 0.25C3.99805 0.25 4.29883 0.550781 4.29883 0.90625V2H8.23633V0.90625C8.23633 0.550781 8.56445 0.25 8.89258 0.25C9.24805 0.25 9.54883 0.550781 9.54883 0.90625V2H10.6426ZM10.6426 12.9375C10.8613 12.9375 11.0801 12.7461 11.0801 12.5V5.5H1.45508V12.5C1.45508 12.7461 1.64648 12.9375 1.89258 12.9375H10.6426Z'
      />
    </Svg>
  );
};
