import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from 'react';
import { Button } from "react-bootstrap";
import styled from 'styled-components'


const CardContainer = styled.article`
    padding: 0 15px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    gap: 8px;

    ${props => props.readOnly && 'filter: grayscale(1);'}
`

const InfoContainer = styled.div`
    border-radius: 4px;
    border: 1px solid #E5E5E5;
    background: #fff;
    padding: 8px;
`

const ButtonContainer = styled.div`
`

const Label = styled.div`
    text-align: center;
`

const ImageNode = styled.img`
    width: 100%;
    font-weight: 500;
    font-color: #6C757D;
    margin-bottom: 10px;
`

const AddButton = styled(Button)`
    border-radius: 0px;
    font-color: #7155FF;
    background: #fff;
    border: none;

    &:hover,
    &:focus,
    &:active,
    &:not(:disabled):not(.disabled):active {
        background: #E5E5E5;
    }
`


const AdFormatCard = ({ readOnly = false, imageSrc, label, onClick = () => { } }) =>
    <CardContainer readOnly={readOnly}>
        <InfoContainer>
            <ImageNode src={imageSrc} />
            <Label>
                {label}
            </Label>
        </InfoContainer>
        {!readOnly &&
            <ButtonContainer>
                <AddButton onClick={onClick}>
                    <FontAwesomeIcon icon={faPlus} color='#7155FF' />
                </AddButton>
            </ButtonContainer>
        }
    </CardContainer>

AdFormatCard.propTypes = {
    imageSrc: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func,
}

export default AdFormatCard
