import React, { useState } from 'react';
import { styled } from 'styled-components';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Accordion = ({ title, children }: { title: string; children: React.ReactNode }) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <Section>
      <button className='accordion-header' onClick={() => setIsOpen(!isOpen)}>
        <h3>{title}</h3>
        <span>{isOpen ? <FontAwesomeIcon icon={faAngleUp} /> : <FontAwesomeIcon icon={faAngleDown} />}</span>
      </button>
      {isOpen && <>{children}</>}
    </Section>
  );
};
export { Accordion };
const Section = styled.div`
  margin-top: 4.13rem;
  display: flex;
  width: 100%;
  max-width: 84rem;
  padding: var(--2, 1rem) var(--2, 1rem) var(--3, 1.5rem) var(--2, 1rem);
  flex-direction: column;
  align-items: flex-start;
  gap: 1.125rem;
  border-radius: var(--borderRadius, 0.25rem);
  border: 1px solid var(--tp-base-colour-25, #b9b9c0);
  background: #fff;
  .accordion-header {
    all: unset;
    display: flex;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
    align-items: center;
    h3 {
      color: var(--tp-base-colour-75, #3a3b4f);
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 500;
      line-height: 145%;
      margin: 0;
    }
  }
`;
