import React, {useEffect, useState} from 'react'
import {Button, Modal} from "react-bootstrap";
import FileUpload from "../inputs/FileUpload";
import {Asset} from "../types";

type FileUploadModalProps = {
    onHide: () => void,
    show: boolean,
    max: number,
    accept: Array<string>,
    value: Array<Asset>,
    onChange: (newValue: Array<Asset>) => void,
};

const FileUploadModal = ({
  onHide = () => { /* do nothing */ },
  show = false,
  max,
  accept,
  value = [],
  onChange = () => { /* do nothing */ },
}: FileUploadModalProps) => {
    const [files, setFiles] = useState<Array<Asset>>(value);
    const [isUploading, setIsUploading] = useState<boolean>(false);

    const handleUploadStart = () => setIsUploading(true);
    const handleUploadEnd = () => setIsUploading(false);
    const handleChange = (newValue) => setFiles(newValue);
    const handleInsert = () => {
        onChange(files);
        onHide();
    }

    useEffect(() => {
        setFiles(value);
    }, [value]);

    return <Modal
        show={show}
        onHide={onHide}
    >
        <Modal.Header closeButton>
            <Modal.Title>Select media to add.</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <FileUpload
                onChange={handleChange}
                max={max}
                value={files}
                accept={accept}
                onUploadStart={handleUploadStart}
                onUploadEnd={handleUploadEnd}
            />
        </Modal.Body>
        <Modal.Footer>
            <Button
                variant='primary'
                disabled={isUploading}
                onClick={handleInsert}
            >
                Insert
            </Button>
        </Modal.Footer>
    </Modal>
}

export default FileUploadModal
