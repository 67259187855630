import {faTimesCircle} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import React from 'react'
import styled from "styled-components";
import {Asset} from "../../types";


const ImgMedia = styled.img`
  object-fit: cover;
`;

const VideoElement = styled.video`
  object-fit: cover;
`

const VideoMedia = ({src}) => (
    <VideoElement
        controls={false}
        width='100%'
        className='p-1 w-100 h-100 rounded'
    >
        <source src={src}/>
    </VideoElement>
)

const NoElement = styled.div`
  overflow-wrap: break-word;
`;

const NoMedia = ({text}) =>
    <NoElement className='p-1 w-100 h-100 rounded'>
        {text}
    </NoElement>

const DeleteButton = styled.button`
  top: 8px;
  right: 8px;
  position: absolute;
  display: none;
  line-height: 0;
  border-radius: 50%;
  background: #fff;
`;

const Container = styled.div`
  border: 2px dashed #5a5c69;
  width: 100px;
  height: 100px;
  position: relative;

  &:hover ${DeleteButton} {
    display: block;
  }

  @media screen and (max-width: 768px) {
    ${DeleteButton} {
      display: block;
    }
  }
`;


type FileSquareProps = {
    file: Asset,
    readOnly: boolean,
    onDelete?: () => void,
}


const FileSquare = (props: FileSquareProps) => {
    const {
        file,
        readOnly = false
    } = props;

    let media;
    if (file.assettype === 'image') {
        media = <ImgMedia className='p-1 w-100 h-100 rounded' src={file.url} alt={file.name}/>;
    } else if (file.assettype === 'video') {
        media = <VideoMedia src={file.url}/>;
    } else {
        media = <NoMedia text={file.name}/>;
    }

    let deleteButton;
    if (!readOnly && props.onDelete) {
        deleteButton = <DeleteButton
            className='border-0 p-0'
            onClick={e => {
                e.preventDefault();
                props.onDelete();
            }}
        >
            <FontAwesomeIcon icon={faTimesCircle}/>
        </DeleteButton>;
    }

    return (
        <Container className='m-3 rounded'>
            {media}
            {deleteButton}
        </Container>
    )
}


export default FileSquare
