import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import { Form, InputGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import styled from "styled-components";
import Errors from "../Errors";

const StyledTooltip = styled(Tooltip)`
  .tooltip-inner {
    max-width: 400px;
    text-align: left;
  }
`;

const Weight = ({
  controlId,
  readOnly = false,
  required = true,
  label = "Weight",
  value,
  placeholder = "",
  onChange = () => { },
  errors = [],
}) => {

  const [weight, setWeight] = useState(value)

  const handleWeightChange = useCallback((e) => {
    let num = Number(e.target.value);
    if (!isNaN(num)) {
      onChange(num);
      setWeight(num)
    }
  }, [onChange])

  return (
    <Form.Group controlId={controlId}>
      <div className='d-flex'>
        <Form.Label className='font-weight-bold'>
          <span className='mr-2'>{label}</span>
          <OverlayTrigger
            placement='bottom'
            trigger={["hover", "focus"]}
            overlay={
              <StyledTooltip id={`${controlId}-info-popover`}>
                <ul>
                  <li>The ads targeting the same space will be ordered depending upon the weight.</li>
                  <li>Ad with higher weight will be displayed higher/before other ads within the same auction.</li>
                  <li>Weight defaults to 0, and could be defined to be within -100 to 100.</li>
                  <li>During an auction the ad’s weight will take precedence over the bid amount.</li>
                  <li>This field won’t be visible to your suppliers.</li>
                </ul>
              </StyledTooltip>
            }
          >
            <FontAwesomeIcon icon={faQuestionCircle} size='sm' />
          </OverlayTrigger>
          
        </Form.Label>
      </div>
      <InputGroup>
        <Form.Control
          type='number'
          max={100}
          min={-100}
          required={required}
          readOnly={readOnly}
          value={weight}
          placeholder={placeholder}
          isInvalid={errors.length}
          onBlur={handleWeightChange}
          onChange={e => setWeight(e.target.value)}
        />
      </InputGroup>
      <Form.Control.Feedback type='invalid' className={`${errors.length > 0 && 'd-block'}`}>
        <Errors errors={errors} />
      </Form.Control.Feedback>
    </Form.Group>
  );
};

Weight.propTypes = {
  controlId: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.number,
  placeholder: PropTypes.string,
  label: PropTypes.string.isRequired,
  adType: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
};

export default Weight;
