import React from 'react';
import { Box, CloseIcon, IconButton } from '@zitcha/component-library';
import { Asset, useDeleteAssetsAssetId, useGetAsset } from 'v2/lib/api/ad-management';
import LoadingPreview from './LoadingPreview';

interface FilePreviewProps {
  asset: Asset;
  deleteAsset: (asset: Asset) => void;
}

const FilePreview: React.FC<FilePreviewProps> = ({ asset, deleteAsset }) => {
  const { data, isLoading } = useGetAsset(asset.id as string);
  const assetData = data?.data;
  const { mutateAsync: deleteAssetMutation, isPending: isDeleting } = useDeleteAssetsAssetId();

  const handleDelete = async () => {
    if (!asset.id) return;
    try {
      await deleteAssetMutation({ assetId: asset.id });
      deleteAsset(asset);
    } catch (error) {
      alert('Failed to delete asset. Please try again.');
    }
  };

  if (isLoading) {
    return <LoadingPreview />;
  }
  return (
    <Box sx={{ position: 'relative' }}>
      {assetData?.assetType === 'video' ? (
        <video
          data-testid='video-preview'
          src={assetData.url}
          controls
          style={{
            aspectRatio: '16/9',
            width: '200px',
            height: 'auto',
            borderRadius: '5px',
          }}
        />
      ) : (
        <img
          data-testid='image-preview'
          src={assetData?.url}
          alt={`Asset ID: ${assetData?.id}`}
          style={{ borderRadius: '5px', width: '200px', height: '150px', objectFit: 'cover', margin: 0 }}
        />
      )}
      <Box
        sx={{
          position: 'absolute',
          top: '5px',
          right: '5px',
          backgroundColor: 'rgba(0,0,0,0.5)',
          borderRadius: '50%',
        }}
      >
        <IconButton
          disabled={isDeleting}
          onClick={handleDelete}
          color='primary'
          sx={{
            backgroundColor: 'rgba(255,255,255,0.5)',
          }}
          size='small'
        >
          <CloseIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

interface FilePreviewsProps {
  assets: Array<Asset>;
  deleteAsset: (asset: Asset) => void;
}

export const FilePreviews: React.FC<FilePreviewsProps> = ({ assets, deleteAsset }) => {
  return (
    <Box
      display='flex'
      flexDirection='row'
      gap={1}
      flexWrap='wrap'
      alignItems='flex-start'
      sx={{
        marginTop: '1rem',
      }}
    >
      {assets.map((asset) => (
        <FilePreview key={asset.id} asset={asset} deleteAsset={deleteAsset} />
      ))}
    </Box>
  );
};
