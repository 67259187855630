import React from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
interface SupplierSettingsTabBtnProps {
  id: 'permissions' | 'ad-type' | 'facebook' | 'google' | 'wallets';
}

function SupplierSettingsTabBtn({ id }: SupplierSettingsTabBtnProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const handleClick = () => {
    setSearchParams({ tab: id });
  };
  return (
    <TabControl type='button' id={id} active={(searchParams.get('tab') === id).toString()} onClick={handleClick}>
      {id.replace('-', ' ')}
    </TabControl>
  );
}
export { SupplierSettingsTabBtn };
const TabControl = styled.button<{ active: string }>`
  all: unset;
  cursor: pointer;
  color: #000;
  font-size: 18px;
  padding: 0;
  margin: 0.5rem 1rem 0.5rem 0;
  border-bottom: ${(props) => (props.active === 'true' ? '2px solid #7155FF' : '2px solid transparent')};
  transition: all 0.3s;
  text-transform: capitalize;
  &:hover {
    border-bottom: ${(props) => (props.active === 'true' ? '' : '2px solid #7155FF')};
  }
`;
