import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PlanData } from 'v2/features/plansAndAdSets/PlanContext';
import { useCreatePlan, useTransitionPlan } from 'v2/lib/api/ad-management';
import { BodyType } from '../axios/axiosMainInstance';
import { PlanCreateForm } from 'v2/lib/api/ad-management';
import { useProposePlan } from 'v2/features/plansAndAdSets/PlanPage/useProposePlan';
import { NewPlanModalState } from 'v2/components/NewPlanModal/NewPlanModal';

export const useNewPlanActions = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const createPlan = useCreatePlan();
  const { proposePlan, savePlan } = useProposePlan();
  const transitionPlan = useTransitionPlan();

  const transformPlanData = (planData: PlanData): BodyType<PlanCreateForm> => ({
    name: planData.name || '',
    owner_organisation_id: planData.retailerId || '',
    supplier_organisation_id: planData.brandId || '',
    wallet_id: planData.walletId || '',
  });

  const createAndSavePlan = async (planData: PlanData) => {
    setLoading(true);
    try {
      const transformedPlanData = transformPlanData(planData);
      const createdPlan = await createPlan.mutateAsync({ data: transformedPlanData });

      if (createdPlan.id) {
        await savePlan(createdPlan.id);
        navigate(`/plans-ad-sets/${createdPlan.id}`);
      }
    } catch (error) {
      console.error('Failed to save new plan:', error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const createAndProposePlan = async (planData: PlanData) => {
    setLoading(true);
    try {
      const transformedPlanData = transformPlanData(planData);
      const createdPlan = await createPlan.mutateAsync({ data: transformedPlanData });

      if (createdPlan.id) {
        await proposePlan(createdPlan.id);
        navigate('/plans-ad-sets', { replace: true });
      }
    } catch (error) {
      console.error('Failed to propose new plan:', error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  // todo - use plan context in new plan modal (and use createAndProposePlan fn instead)
  const createAndProposePlanWithoutPlanContext = async (plan: NewPlanModalState) => {
    const requiredFields = ['name', 'retailerId', 'brandId', 'walletId'] as const;
    const missingFields = requiredFields.filter((field) => !plan[field]);

    if (missingFields.length > 0) {
      throw new Error(`Cannot propose plan: Missing required fields: ${missingFields.join(', ')}`);
    }

    const createdPlan = await createPlan.mutateAsync({
      data: {
        name: plan.name,
        owner_organisation_id: plan.retailerId,
        supplier_organisation_id: plan.brandId,
        wallet_id: plan.walletId,
      },
    });

    transitionPlan.mutate(
      { id: createdPlan.id, data: { status: 'proposed' } },
      { onSuccess: () => navigate('/plans-ad-sets', { replace: true }) }
    );
  };

  return {
    loading,
    createAndSavePlan,
    createAndProposePlan,
    createAndProposePlanWithoutPlanContext,
  };
};
