import PropTypes from "prop-types";
import React from "react";
import Chart from "react-google-charts";
import moment from "moment/moment";
import { useState } from "react";

const columns = [
  { type: "string", id: "Order" },
  { type: "date", id: "Start" },
  { type: "date", id: "End" },
];

const InventoryAudienceBookingSchedule = ({ schedule, errors = {} }) => {

  const [filter, setFilter] = useState([30, 'days']);

  let graphData = [];
  (schedule??[]).forEach(ad => {
    if (moment(ad.start_at).isBetween(moment().subtract(filter[0], filter[1]), moment())) {
      graphData.push([ad.name, new Date(ad.start_at), new Date(ad.stop_at)])
    }
  });

  return (
    <div className='border rounded p-3'>
    <article>
      <div className='mb-2'>
        <h5>Booking Schedule</h5>
      </div>
      <div>
        <div>
            <select
              className='custom-select mb-2 mr-sm-2'
              id='inlineFormCustomSelectPref'
              onChange={(e) => setFilter((e.target.value).split(','))}
            >
              <option value={[7, 'days']}>Last 7 Days</option>
              <option value={[14, 'days']}>Last 14 Days</option>
              <option value={[30, 'days']} selected>Last 30 Days</option>
              <option value={[90, 'days']}>Last 90 Days</option>
            </select>
            {graphData.length > 0 && <Chart
              chartType='Timeline'
              data={[columns, ...graphData]}
              labels='none'
              legendToggle
              is3D='true'
              options={{
                colors: ['#5995ED', '#FFE787', '#87F5C0'],
                height: `${200 + (20 * graphData.length)}`,
                tooltip: { isHtml: true },
                allowHTML: true,
                backgroundColor: "#F7F5FF",
                alternatingRowStyle: true,
                bar: { groupWidth: "100%" },
                timeline: { groupByRowLabel: false },
              }}
            />}
        </div>
      </div>
    </article>
    </div>
  );
};

InventoryAudienceBookingSchedule.propTypes = {
  schedule: PropTypes.object,
  errors: PropTypes.object,
};

export default InventoryAudienceBookingSchedule;
