import { useQuery } from '@tanstack/react-query';
import axios from '../../../axios';

export function useActivePages({
  retailerId,
  supplierId,
  enabled = true,
}: {
  retailerId: string;
  supplierId: string;
  enabled?: boolean;
}) {
  const { data, isLoading, isSuccess } = useQuery({
    queryKey: ['facebookMPA', { retailerId, supplierId }],
    queryFn: async () => {
      try {
        const res = await axios.get(`/organisations/mpa/${retailerId}/${supplierId}/pages`);
        return res.data;
      } catch (error) {
        return error.response.data;
      }
    },
    enabled,
  });
  return {
    isLoading,
    activePages: data?.pages || [],
    isSuccess,
    businessManagerId: data?.businessId || '',
  };
}
