import PropTypes from 'prop-types'
import React from 'react'
import { Form } from 'react-bootstrap'
import Errors from '../Errors'

const ages = ['18-24', '25-34', '35-44', '45-54', '55-64', '65+']

const GoogleAge = ({
    controlId,
    readOnly = false,
    required = false,
    value = [],
    errors = [],
    onChange = () => { },
}) => {

    const handleChange = (age, checked) => {
        let nval = value.filter(v => v !== age)
        if (checked) {
            nval.push(age)
        }
        onChange(nval)
    }

    return (
        <Form.Group controlId={controlId}>
            <Form.Label>Age</Form.Label>
            <div className='d-flex flex-wrap justify-contents-center'>
                {ages.map(age => (
                    <Form.Check
                        key={age}
                        id={`${controlId}-age-${age}`}
                        custom
                        inline
                        type='checkbox'
                        readOnly={readOnly}
                        required={required}
                        label={age}
                        checked={value.some(v => v === age)}
                        onChange={e => handleChange(age, e.target.checked)}
                    />
                ))}
            </div>
            <Form.Control.Feedback type='invalid'>
                <Errors errors={errors} />
            </Form.Control.Feedback>
        </Form.Group>
    )
}

GoogleAge.propTypes = {
    controlId: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    value: PropTypes.arrayOf(PropTypes.string),
    errors: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func,
}

export default GoogleAge
