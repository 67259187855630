import React, { useState } from 'react';
import moment from 'moment/moment';
import { useSelector } from 'react-redux';
import { selectAllOrders } from '../../../orders/ordersSlice';
import { Col, Container, Row } from 'react-bootstrap';
import { getadfromadtytpe, strToIcon } from './createAdHelpers';
import { Group } from './Group';
import { AdBox } from './AdBox';
import PropTypes from 'prop-types';

export const Entrypoints = ({ objective, adtype, states, entrytype }) => {
  let entryGroup = Object.keys(objective); //by_objective or by_network tabs

  const [showgroup, setGroup] = useState(entryGroup[0] ?? null);

  const currentDate = moment();

  const allOrders = useSelector(selectAllOrders);
  const allBespoke = (id) => {
    let ammount = 0;
    allOrders.forEach((ad) => ad?.format?.includes(id) && ammount++);
    return ammount;
  };

  const filteredBespoke = adtype.filter((ad) => {
    if (ad?.availability_start && ad?.availability_end) {
      let availabilityStart = moment(ad.availability_start);
      let availabilityEnd = moment(ad.availability_end);
      return currentDate.isBetween(availabilityStart, availabilityEnd);
    } else if (ad?.availability_start) {
      let availabilityStart = moment(ad.availability_start);
      return currentDate.isAfter(availabilityStart);
    } else if (ad?.availability_end) {
      let availabilityEnd = moment(ad.availability_end);
      return currentDate.isBefore(availabilityEnd);
    }

    return true;
  });

  const isDisabled = (ad) => {
    return (
      ad?.custom_fields.classification === 'physical' && allBespoke(ad?.control_id) >= ad?.custom_fields.stock_level
    );
  };

  return (
    <Container className='container-fluid' style={{ paddingTop: '20px' }}>
      <Row>
        {entrytype !== 'bespoke' && (
          <Col xl={2} lg={3} md={3} style={{ overflowX: 'scroll', height: '600px' }}>
            {entryGroup.map((group) => (
              <div
                onClick={() => {
                  setGroup(group);
                }}
              >
                <Group
                  keyGroup={group}
                  selectstate={showgroup}
                  icon={strToIcon(objective[group].icon)}
                  title={objective[group].title}
                  tooltip={objective[group].tooltip}
                />
              </div>
            ))}
          </Col>
        )}
        <Col
          xl={entrytype === 'bespoke' ? 12 : 10}
          lg={entrytype === 'bespoke' ? 12 : 9}
          md={entrytype === 'bespoke' ? 12 : 9}
          style={{ overflowX: 'scroll', height: '600px' }}
        >
          <Row className={entrytype === 'bespoke' ? 'p-2' : ''}>
            {entrytype !== 'bespoke' &&
              entryGroup.map(
                (group) =>
                  showgroup === group && (
                    <>
                      {objective[group].adtypes.map((ad) => {
                        let type = getadfromadtytpe(ad, adtype);
                        return (
                          type !== undefined && (
                            <AdBox
                              key={type.id}
                              keyID={type.id}
                              title={type.label}
                              description={type.description}
                              recommended={objective[group].recommended === type.control_id ? true : false}
                              imageURL={type?.placeholder_images?.large}
                              adtype={type}
                              requirements={type.descriptors}
                              states={states}
                            />
                          )
                        );
                      })}
                    </>
                  )
              )}
            {entrytype === 'bespoke' && (
              <>
                {filteredBespoke.map((ad) => (
                  <AdBox
                    key={ad.id}
                    title={`${ad.label} ${isDisabled(ad) ? ' - (Out of Stock)' : ''}`}
                    description={ad.description}
                    recommended={false}
                    imageURL={ad.placeholder_images.large}
                    adtype={ad}
                    requirements={ad.descriptors}
                    states={states}
                    isBespoke={true}
                    disabled={isDisabled(ad)}
                  />
                ))}
                {filteredBespoke.length === 0 && (
                  <div className='w-100 text-center m-5'>
                    <p>No Bespoke Products available</p>
                  </div>
                )}
              </>
            )}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

Entrypoints.propTypes = {
  objective: PropTypes.object,
  adtype: PropTypes.array,
  states: PropTypes.func,
};
