import React, { useRef, useEffect } from 'react';
import StandardAdSection from './StandardAdSection';
import PropTypes from 'prop-types';
import OwnedSpaceSection from './OwnedSpaceSection';
import BespokeProductSection from './BespokeProductSection';
import { STATUS_APPROVED, stripAdErrors } from '../../lib/orders';
import { useSelector } from 'react-redux';
import { hasLiveOrderBeingViewed, selectLiveOrder, selectOrder, selectOwnerOrganisation } from './orderFormSlice';
import moment from 'moment-timezone';
import { PLATFORM_NATIVE } from '../../lib/platforms';
import { LIVE_EDITABLE_AD_TYPES } from '../../lib/enums/ad_types';

const AdSection = React.forwardRef((props, ref) => {
  const {
    readOnly = false,
    index,
    ad = {},
    errors = {},
    extra = {},
  } = props;

  const liveOrderViewed = useSelector(hasLiveOrderBeingViewed);
  const liveOrder = useSelector(selectLiveOrder);
  const adSectionRef = useRef();
  const order = useSelector(selectOrder);
  const organisation = useSelector(selectOwnerOrganisation);

  useEffect(() => {
    const errorKeys = Object.keys(errors ?? {});
    for (let key of errorKeys) {
      const keys = key.split('.');
      if (!keys || keys.length === 0) {
        continue;
      }
      const isDatajson = keys[0] === 'data_json';
      const adIndex = parseInt(keys[1]) ?? null;

      // Scroll to the adSection setup by ref when the adIndex matches the index of the ad
      if (isDatajson && adIndex === index) {
        adSectionRef?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
        break;
      }
    }
  }, [errors, adSectionRef]);

  if (ad.platform === 'bespoke_product') {
    return <BespokeProductSection ref={adSectionRef} key={ad.id} {...props} errors={stripAdErrors(errors, index)} />;
  }


  if (LIVE_EDITABLE_AD_TYPES.includes(ad.type)) {
    let makeReadOnly = readOnly;
    if (liveOrderViewed) {
      const retailerTimezone = organisation?.settings?.['timezone'] ?? 'UTC';
      const nowInRetailerTimezone =  moment().tz(retailerTimezone);
      const originalAd = liveOrder.data_json.find((a) => a.id === ad.id);
      if (!originalAd) {
        throw 'Need original ad in old order.';
      }
      const adStop = moment.tz(originalAd.stop_at, retailerTimezone);
      makeReadOnly = readOnly || (liveOrderViewed && nowInRetailerTimezone?.isAfter(adStop));
    }

    if (ad.platform === PLATFORM_NATIVE) {
      return (
        <OwnedSpaceSection
          ref={adSectionRef}
          key={ad.id}
          {...props}
          errors={stripAdErrors(errors, index)}
          readOnly={makeReadOnly}
        />
      );
    } else {
      return (
        <StandardAdSection
          ref={adSectionRef}
          key={ad.id}
          {...props}
          errors={stripAdErrors(errors, index)}
          readOnly={makeReadOnly}
          extra={extra}
        />
      );
    }
  }

  return (
    <StandardAdSection
      ref={adSectionRef}
      key={ad.id}
      {...props}
      errors={stripAdErrors(errors, index)}
      readOnly={readOnly || liveOrderViewed || order.status === STATUS_APPROVED}
    />
  );
});

AdSection.propTypes = {
  controlId: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  ad: PropTypes.shape({
    platform: PropTypes.string,
    type: PropTypes.string.isRequired,
  }).isRequired,
  assets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ),
  isNew: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  errors: PropTypes.object,
  extra: PropTypes.shape({ inStoreScreensTabIsReadOnly: PropTypes.bool }),
};

export default AdSection;
