import React, { SyntheticEvent, useMemo, useState } from 'react';
import { Box, Button, Modal, Tab, Tabs, Typography } from '@zitcha/component-library';
import CheckIcon from '@mui/icons-material/Check';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
  AdSet,
  Location,
  CalendarPeriod,
} from 'v2/lib/api/ad-management';
import { DetailsTable } from './DetailsTable';
import { MediaSpace, RenderableMediaTypeField } from 'v2/lib/api/inventory';
import { AdsTab } from './AdsTab';
import { useForm } from 'react-hook-form';

export type FormAdField = {
  value?: unknown;
  fieldDefinition: RenderableMediaTypeField;
};

export type FormAd = Array<FormAdField>;

export interface AdSetFormValues {
  name: string;
  bundleId: string | null;
  schedule: CalendarPeriod | null;
  mediaSpace: MediaSpace | null;
  placement: Location | null;
  rate: number;
  discount: number;
  budget: number;
  ads: Array<FormAd>;
  skuCodes: Array<string>;
}

const saveErrorHandler = (error: Error): void => {
  // todo - use SnackbarNotifications?
  //eslint-disable-next-line no-console
  alert('Save Error handling not implemented: ' + error);
};


export const AdSetModal = ({ isOpen, isReadOnly, adSet: existingAdSet, onClose, saveHandler, retailerId }: AdSetModalProps) => {
  const [selectedAdSetsTab, setSelectedAdSetsTab] = useState<AdSetsTabValue>('details');

  const ads = useMemo(
    () => {
      if ((existingAdSet?.ads?.length ?? 0) > 0) {
        return existingAdSet.ads;
      }

      return [{}]
    },
    [existingAdSet]
  )

  const { control, handleSubmit, setValue, watch } = useForm<AdSetFormValues>({
    defaultValues: {
      name: existingAdSet?.name || '',
      bundleId: existingAdSet?.bundleIds?.[0] || null,
      schedule: null,
      mediaSpace: null,
      placement: null,
      rate: 0,
      discount: 0,
      budget: 0,
      ads: ads,
      skuCodes: existingAdSet?.ads?.flatMap((ad) => ad.fields?.sku || []) || [],
    },
  });

  const selectedMediaSpace = watch('mediaSpace');

  const handleTabChange = (_: SyntheticEvent, newValue: AdSetsTabValue) => setSelectedAdSetsTab(newValue);

  const onSubmit = (data: AdSetFormValues) => {
    const updatedAdSet: AdSet = {
      ...existingAdSet,
      name: data.name,
      bundleIds: [data.bundleId || ''],
      mediaSpace: data.mediaSpace || undefined,
      schedule: data.schedule || undefined,
      placement: data.placement || undefined,
      ads: data.ads,
    };
    if (saveHandler) {
      saveHandler(updatedAdSet)
        .then(() => onClose())
        .catch((e: Error) => saveErrorHandler(e));
    }
  };

  return (
    <Modal open={isOpen} onClose={onClose} size='large'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography variant='h5'>{isReadOnly ? 'Ad set details' : 'Edit ad set'}</Typography>
        {!isReadOnly && (
          <div className='d-flex justify-content-between'>
            <Typography variant='body1' data-testid='pageHeading' mt={2} mb={1}>
              Add information specific to the media type.
            </Typography>
          </div>
        )}
        <Tabs value={selectedAdSetsTab} onChange={handleTabChange}>
          <Tab color='error' icon={<CheckIcon />} iconPosition={'start'} label='Details' value={'details'} />
          <Tab icon={<MoreHorizIcon />} iconPosition={'start'} label={`Ads`} value={'ads'} />
        </Tabs>
        <div style={{ display: selectedAdSetsTab === 'details' ? 'block' : 'none' }}>
          <DetailsTable control={control} setValue={setValue} existingAdSet={existingAdSet} retailerId={retailerId} />
        </div>
        <div style={{ display: selectedAdSetsTab === 'ads' ? 'block' : 'none' }}>
          <AdsTab control={control} mediaSpace={selectedMediaSpace as MediaSpace} />
        </div>
        <Box display='flex' justifyContent='space-between' alignItems='center' mt={2}>
          <Button
            variant='text'
            color='error'
            aria-label='Close without saving'
            className='w-5/12 mr-3'
            onClick={onClose}
            data-test='closeWithoutSavingButton'
          >
            CLOSE WITHOUT SAVING
          </Button>
          <Button
            variant='contained'
            color='primary'
            size='large'
            type='submit'
            aria-label='Save ad set'
            data-test='saveAdSetButton'
            disabled={isReadOnly}
          >
            SAVE AD SET
          </Button>
        </Box>
      </form>
    </Modal>
  );
};

type AdSetsTabValue = 'details' | 'ads';

export type AdSetModalSaveHandler = (
  updatedAdSet: AdSet,
) => Promise<void>;

interface AdSetModalProps {
  isOpen: boolean;
  isReadOnly: boolean;
  retailerId: string
  adSet: AdSet;
  onClose: () => void;
  saveHandler?: AdSetModalSaveHandler;
}
