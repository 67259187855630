import React from 'react';
import { TextField } from '@mui/material';
import { RenderableMediaTypeFieldProperties } from 'v2/lib/api/inventory';

interface ColorPickerComponentProps {
  properties: RenderableMediaTypeFieldProperties & {
    defaultColor?: string;
    label?: string;
  };
  fieldName?: string;
  onChange: (...event: Array<any>) => void;
  onBlur: () => void;
  value: any;
}

export const ColorPickerComponent = ({ properties, fieldName, onChange, onBlur, value }: ColorPickerComponentProps) => {
  const controlledValue = value || properties.defaultColor || '';
  return (
    <TextField
      type='color'
      id={`${fieldName}-color-picker-${properties.label}`}
      variant='outlined'
      fullWidth
      onChange={onChange}
      onBlur={onBlur}
      value={controlledValue}
      InputLabelProps={{ shrink: true }}
      data-testid={`${fieldName}-color-picker-component`}
    />
  );
};