import React from 'react'
import { Form } from 'react-bootstrap'
import PropType from 'prop-types'
import Errors from '../../order_form/components/Errors'

const Name = ({ value = '', errors = [], onChange = () => { } }) => (
    <Form.Group>
        <p><b>Space Name</b></p>
        <Form.Control
            value={value}
            isInvalid={errors.length > 0}
            onChange={e => onChange(e.target.value)}
        />
        <Form.Control.Feedback type='invalid'>
            <Errors errors={errors} />
        </Form.Control.Feedback>
    </Form.Group>
)

Name.propTypes = {
    value: PropType.string,
    errors: PropType.arrayOf(PropType.string),
    onChange: PropType.func,
}

export default Name
