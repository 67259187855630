/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable unused-imports/no-unused-vars */
// disabling for unimplemented feature
import React, { useState } from 'react';
import { IconButton, ManageSearchIcon, TextField } from '@zitcha/component-library';
import { Grid } from '@mui/material';

type SkuCodesProps = {
  onSkuCodesChange: (updatedSkuCodes: Array<string>) => void;
  data: Array<string>;
};

export const SkuCodes: React.FC<SkuCodesProps> = ({ onSkuCodesChange, data }) => {
  const [skuCodes, setSkuCodes] = useState<Array<string>>(data ?? []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <TextField
          className='twd-w-full'
          id='skus'
          variant='standard'
          value={skuCodes.join(', ')}
          inputProps={{ readOnly: true }}
        />
      </Grid>
      <Grid item xs={4}>
        <IconButton
          color='primary'
          ariaLabel={''}
          onClick={() => {
            //eslint-disable-next-line no-console
            console.log('SKU code modal not implemented yet.');
          }}
        >
          <ManageSearchIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default SkuCodes;
