import { Control, Controller } from 'react-hook-form';
import React from 'react';
import { RenderableMediaTypeField } from 'v2/lib/api/inventory';
import { InputComponent } from './DynamicMediaTypeFieldComponents/InputComponent';
import { ColorPickerComponent } from './DynamicMediaTypeFieldComponents/ColorPickerComponent';
import { UploaderComponent } from './DynamicMediaTypeFieldComponents/UploaderComponent';
import { CheckboxComponent } from './DynamicMediaTypeFieldComponents/CheckboxComponent';
import { ToggleComponent } from './DynamicMediaTypeFieldComponents/ToggleComponent';
import { RadioOptionsComponent } from './DynamicMediaTypeFieldComponents/RadioOptionsComponent';
import { NumberFieldComponent } from './DynamicMediaTypeFieldComponents/NumberFieldComponent';
import { DateFieldComponent } from './DynamicMediaTypeFieldComponents/DateFieldComponent';
import { DateRangeComponent } from './DynamicMediaTypeFieldComponents/DateRangeComponent';
import { FallbackComponent } from './DynamicMediaTypeFieldComponents/FallbackComponent';
import { TextAreaComponent } from './DynamicMediaTypeFieldComponents/TextAreaComponent';
import { Box, Typography } from '@zitcha/component-library';
import { AdSetFormValues } from './AdSetModal';

interface DynamicMediaTypeFieldProps {
  fieldDefinition: RenderableMediaTypeField;
  control: Control<AdSetFormValues>;
  adIndex: number;
}

export const DynamicMediaTypeField = ({ fieldDefinition, control, adIndex }: DynamicMediaTypeFieldProps) => {
  const { type = '', properties = {}, name: fieldName } = fieldDefinition;
  const { label } = properties;
  // Construct the unique field name using adIndex and the field's sequence
  const uniqueFieldName = `ads.${adIndex}.fields.${fieldName}.value`;

  return (
    <Box minHeight={'80px'}>
      <Typography variant='body1'>{label as string | undefined}</Typography>
      <Controller
        name={uniqueFieldName}
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <DynamicComponent
            type={type}
            fieldDefinition={fieldDefinition}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
          />
        )}
      />
    </Box>
  );
};

interface DynamicComponentProps<T> {
  type: string;
  fieldDefinition: RenderableMediaTypeField;
  onChange: (value: T) => void;
  onBlur: () => void;
  value: T;
}

const DynamicComponent = <T,>({
  type,
  fieldDefinition,
  onChange,
  onBlur,
  value,
}: DynamicComponentProps<T>) => {
  const { properties = {}, name } = fieldDefinition;
  switch (type) {
    case 'InputComponent':
      return <InputComponent properties={properties} fieldName={name} onChange={(e) => onChange(e.target.value as T)} onBlur={onBlur} value={value} />;
    case 'TextAreaComponent':
      return <TextAreaComponent properties={properties} fieldName={name} onChange={(e) => onChange(e.target.value as T)} onBlur={onBlur} value={value} />;
    case 'ColorPickerComponent':
      return <ColorPickerComponent properties={properties} fieldName={name} onChange={onChange} onBlur={onBlur} value={value} />;
    case 'UploaderComponent':
      return <UploaderComponent properties={properties} fieldName={name} onChange={onChange} onBlur={onBlur} value={value} />;
    case 'CheckboxComponent':
      return <CheckboxComponent properties={properties} fieldName={name} onChange={(e) => onChange(e.target.checked as T)} onBlur={onBlur} value={value as boolean} />;
    case 'ToggleComponent':
      return <ToggleComponent properties={properties} fieldName={name} onChange={(e) => onChange(e.target.checked as T)} onBlur={onBlur} value={value} />;
    case 'RadioOptionsComponent':
      return <RadioOptionsComponent properties={properties} fieldName={name} onChange={onChange} onBlur={onBlur} value={value} />;
    case 'NumberFieldComponent':
      return <NumberFieldComponent properties={properties} fieldName={name} onChange={(e) => onChange(e.target.value as unknown as T)} onBlur={onBlur} value={value} />;
    case 'DateFieldComponent':
      return <DateFieldComponent properties={properties} fieldName={name} onChange={onChange} onBlur={onBlur} value={value} />;
    case 'DateRangeComponent':
      return <DateRangeComponent properties={properties} fieldName={name} onChange={onChange} onBlur={onBlur} value={value} />;
    default:
      return <FallbackComponent type={type} fieldName={name} />;
  }
};
