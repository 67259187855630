import React from 'react';
import { ComboBox } from '../../../app/components/ComboBox/ComboBox';
import { Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useActivePages } from './useActivePages';

const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
`;

const OptionImage = styled.img`
  max-width: 20px;
  margin-right: 10px;
`;

const SubText = styled.div`
  font-size: 12px;
  color: #3a3b4f;
  margin-bottom: 8px;
`;

const SinglePageOption = ({ page }) => {
  return (
    <>
      <SubText>This is the page your ad will display on. No action is required.</SubText>
      <OptionContainer>
        <OptionImage src={page.logo} alt={page.name + 'logo'} />
        {page.label}
      </OptionContainer>
    </>
  );
};

const BoldHeader = styled.div`
  margin-top: 24px;
  margin-bottom: 8px;
  font-size: 14px;
  color: #000000;
  font-weight: 500;
`;

const BusinessManagerId = styled.div`
  font-size: 18px;
  color: #777785;
  font-weight: 300;
`;

const VerticalPadding = styled.div`
  height: 200px;
`;

export const ActivePageTab = ({ ad, handleChange, readOnly, retailerId, supplierId, errors }) => {
  const onChange = (selectedOption) => {
    handleChange({ active_page: selectedOption });
  };
  const { activePages, isLoading, businessManagerId } = useActivePages({ retailerId, supplierId });

  const options = activePages?.map((page) => {
    return {
      label: `${page.name} (#${page.page_id})`,
      name: page.name,
      id: page.page_id,
      logo: page.iconUrl,
    };
  });

  if (isLoading) {
    return (
      <Row className='text-center d-flex align-items-center justify-content-center'>
        <FontAwesomeIcon className='fa-spin' style={{ cursor: 'pointer' }} icon={faSpinner} size='4x' />
      </Row>
    );
  }

  if (!options?.length) {
    return <Row className='text-center d-flex align-items-center justify-content-center'>No items to display</Row>;
  }

  return (
    <Container>
      {options.length === 1 ? (
        <SinglePageOption page={options[0]} />
      ) : (
        <>
          <SubText>Select the page you would like to use for this ad.</SubText>
          <ComboBox
            options={options}
            value={ad.active_page}
            onChange={onChange}
            placeholder='Search or browse page name or ID'
            readOnly={readOnly}
          />
        </>
      )}
      {errors?.screen_types && <div className='text-danger'>{errors.screen_types}</div>}
      <BoldHeader>Business Manager (BM) ID</BoldHeader>
      <BusinessManagerId>{businessManagerId}</BusinessManagerId>
      <VerticalPadding />
    </Container>
  );
};
