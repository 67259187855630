import React from 'react';
import { Box, CircularProgress } from '@zitcha/component-library';

const LoadingPreview: React.FC = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '200px',
        height: '150px',
        borderRadius: '5px',
        backgroundColor: '#f0f0f0',
        position: 'relative',
      }}
      data-testid='loading-file-preview'
    >
      <CircularProgress />
    </Box>
  );
};

export default LoadingPreview;
