import { Typography } from '@zitcha/component-library';
import React from 'react';

interface FallbackComponentProps {
  type?: string;
  fieldName?: string;
}

export const FallbackComponent = ({ type, fieldName }: FallbackComponentProps) => {
  return (
    <Typography variant='body1' data-testid={`${fieldName}-fallback-component`}>
      No matching field found for {type} field name {fieldName}
    </Typography>
  );
};
