import React, { useRef, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HexColorPicker } from 'react-colorful';
import { faAngleUp, faAngleDown } from '@fortawesome/pro-regular-svg-icons';
import { styled } from 'styled-components';

const ColorPicker = ({
  color,
  setColor,
  disabled = true,
}: {
  color: string;
  setColor: (color: string) => void;
  disabled?: boolean;
}) => {
  const [showhexPicker, setShowHexPicker] = useState(false);
  const pickerRef = useRef(null);
  const toggleHexPicker = () => {
    setShowHexPicker((prev) => !prev);
  };
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (pickerRef?.current && !(pickerRef?.current as HTMLDivElement).contains(event.target as Node)) {
        setShowHexPicker(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    <Container ref={pickerRef}>
      <Picker onClick={toggleHexPicker} type='button' disabled={disabled}>
        <div>
          <span
            className='color-picked'
            style={{
              backgroundColor: color,
            }}
          ></span>
          <input
            type='text'
            name='color'
            onChange={(e) => {
              setColor(e.target.value);
            }}
            value={color}
            disabled={disabled}
          />
        </div>
        <div>{showhexPicker ? <FontAwesomeIcon icon={faAngleUp} /> : <FontAwesomeIcon icon={faAngleDown} />}</div>
      </Picker>
      <PickerContainer>{showhexPicker && <HexColorPicker color={color} onChange={setColor} />}</PickerContainer>
    </Container>
  );
};

export { ColorPicker };
const Picker = styled.button`
  all: unset;
  display: flex;
  width: 250px;
  height: 40px;
  gap: 8px;
  padding: 0 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  align-items: center;
  justify-content: space-between;
  div {
    display: flex;
    gap: 8px;
    align-items: center;
    input {
      font-size: 14px;
      color: #6c757d;
      line-height: 18.75px;
      border: none;
      height: 100%;
      outline: none;
    }
  }
  .color-picked {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
`;
const Container = styled.div`
  position: relative;
`;
const PickerContainer = styled.div`
  position: absolute;
  top: 42px;
  z-index: 1;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  .react-colorful {
    width: 266px;
    height: 266px;
  }
`;
