import { PlanSearchStatusesItem } from 'v2/lib/api/ad-management';

export const statusNameMapping = {
    [PlanSearchStatusesItem.agreed]: 'Agreed',
    [PlanSearchStatusesItem.archived]: 'Archived',
    [PlanSearchStatusesItem.planning]: 'Draft',
    [PlanSearchStatusesItem.proposed]: 'Proposed',
};

export const statusChipVariantMap: Record<PlanSearchStatusesItem, 'outlined' | 'filled'> = {
    [PlanSearchStatusesItem.agreed]: 'filled',
    [PlanSearchStatusesItem.archived]: 'filled',
    [PlanSearchStatusesItem.planning]: 'filled',
    [PlanSearchStatusesItem.proposed]: 'filled',
};

export const statusChipColorMap: Record<PlanSearchStatusesItem, 'default' | 'primary' | 'secondary' | 'error' | 'warning' | 'info' | 'success'> = {
    [PlanSearchStatusesItem.agreed]: 'warning',
    [PlanSearchStatusesItem.archived]: 'error',
    [PlanSearchStatusesItem.planning]: 'default',
    [PlanSearchStatusesItem.proposed]: 'success',
};