import React, { useCallback, useState } from 'react';
import { NewPlanModal } from 'v2/components/NewPlanModal/NewPlanModal';
import { AddIcon, Button, FactCheckIcon, ReceiptIcon } from '@zitcha/component-library';
import { useNavigate } from 'react-router-dom';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { MenuItemProps, MenuItems } from '../Navigation/ButtonMenuItems';
import OrganisationSelectionModal from 'features/order_form/OrganisationSelectionModal';
import { FeatureFlags } from 'v2/lib/api/system-api';

interface NewPlanOrOrderButtonProps {
  featuresEnabled: FeatureFlags | undefined;
}

export const NewPlanOrOrderButton = ({ featuresEnabled }: NewPlanOrOrderButtonProps) => {
  const [isNewPlanModalOpen, setIsNewPlanModalOpen] = useState(false);
  const navigate = useNavigate();
  const [showOrganisationsModal, setOrganisationsModal] = useState(false);

  const openOrganisationModal = useCallback((e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    e.preventDefault();
    setAnchorElement(null);
    setOrganisationsModal(true);
  }, []);
  const [anchorElement, setAnchorElement] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorElement);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!anchorElement) {
      setAnchorElement(event.currentTarget);
    } else {
      setAnchorElement(null);
    }
  };
  const handleClose = () => {
    setAnchorElement(null);
  };
  const menuItems: Array<MenuItemProps> = [];
  //TODO remove the commented code when we have a order feature flag
  //if (featuresEnabled?.feature_orders) {
  menuItems.push({ icon: <ReceiptIcon />, text: 'New Order', onClick: (e) => openOrganisationModal(e) });
  //}
  if (featuresEnabled?.feature_planning) {
    menuItems.push({ icon: <FactCheckIcon />, text: 'New Plan', onClick: () => navigate('/plans-ad-sets/new-plan') });
  }

  if (menuItems.length === 0) {
    return <div>&nbsp;</div>;
  }

  return (
    <div>
      <Button
        variant='contained'
        sx={{ width: '180px' }}
        color='primary'
        size='large'
        onClick={handleClick}
        aria-label='Create new plan'
        aria-expanded={isNewPlanModalOpen}
        endIcon={<ArrowDropDownIcon style={{ fontSize: 30 }} />}
      >
        <AddIcon />
        New
      </Button>
      <MenuItems
        anchorElement={anchorElement}
        items={menuItems}
        isOpen={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      />
      <NewPlanModal isOpen={isNewPlanModalOpen} onClose={() => setIsNewPlanModalOpen(false)} />
      <OrganisationSelectionModal
        key={Math.random()}
        show={showOrganisationsModal}
        onHide={() => setOrganisationsModal(false)}
        navPath={'/order/create'}
      />
    </div>
  );
};
