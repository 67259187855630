import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPlus} from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components'


const ButtonContainer = styled.button`
  border: 2px dashed #5a5c69;
  width: 100px;
  height: 100px;
  position: relative;

  @media screen and (max-width: 768px) {
    .delete_button {
      display: block;
    }
  }
`;

const Icon = styled(FontAwesomeIcon)`
  color: #e3e6f0;
`;


type AddSquareButtonProps = {
    onClick: () => void,
}

const AddSquareButton = ({onClick}: AddSquareButtonProps) => {
    const handleClick = e => {
        e.preventDefault();
        onClick();
    }

    return <ButtonContainer
        className='m-3 rounded bg-transparent'
        onClick={handleClick}
    >
        <Icon icon={faPlus} size='lg'/>
    </ButtonContainer>
}

export default AddSquareButton
