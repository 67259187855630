import { useState } from 'react';
import useSWR  from 'swr';
import axios from '../../../axios';
const getOrders = async (args) => {
  return axios.get(`/orders?${args}`, {
    timeout: 120000,
  });
};

// fetch orders
const fetcher = (...args) => {
  const params = args[0][0];
  return getOrders(params).then((res) => res);
};

export default function useOrdersLogic({ archived = false }: { archived: boolean }) {
  const [searchParams, setSearchParams] = useState({
    sort_by: 'status',
    organisation: '',
    platform: '',
    order_status: '',
    format: '',
    text: '',
    page: 1,
    onlyArchived: archived,
  });

  // We can use proper searchParams hook from react-router-dom https://reactrouter.com/en/main/hooks/use-search-params
  const stringifiedParams = new URLSearchParams({
    sort_by: searchParams.sort_by ?? '',
    organisation_id: searchParams.organisation,
    platform: searchParams.platform,
    order_status: searchParams.order_status,
    format: searchParams.format,
    text: searchParams.text,
    page: String(searchParams.page),
    onlyArchived: String(archived),
  }).toString();

  const { data, error, isLoading } = useSWR([stringifiedParams], fetcher,{
    revalidateOnFocus:true,
    keepPreviousData:true
  });

  // pagination button handlers
  const handleClick = (direction: 'next' | 'prev') => {
    if (direction === 'prev' && !isLoading && searchParams.page !== 1) {
      setSearchParams((previousParams) => {
        return {
          ...previousParams,
          page: previousParams.page - 1,
        };
      });
    }
    if (direction === 'next' && !isLoading) {
      setSearchParams((previousParams) => {
        return {
          ...previousParams,
          page: previousParams.page + 1,
        };
      });
    }
  };
  return {
    data,
    isLoading,
    error,
    handleClick,
    setSearchParams,
    searchParams,
  };
}
