import React, { useContext, useState } from 'react';
import { Box, Button, FactCheckIcon, ReceiptIcon, Typography } from '@zitcha/component-library';
import { NavigationContext } from 'v2/components/Navigation/Chrome';
import { useNavigate } from 'react-router-dom';
import { useGetFeatureFlags } from 'v2/lib/api/system-api';
import { NewPlanOrOrderButton } from 'v2/components/NewPlanModal/NewPlanOrOrderButton';

export const DashboardHeadingSection = () => {
  const { user } = useContext(NavigationContext);
  const { data: featureFlags, isLoading: isFeatureFlagsLoading } = useGetFeatureFlags();
  const navigate = useNavigate();
  return (
    <Box display='flex' justifyContent='space-between' alignItems='center' mt={3}>
      <Box id='dashboard-heading-section'>
        <Typography variant='h4' data-testid='pageHeading'>
          Welcome back, {user?.first_name}.
        </Typography>
      </Box>

      <Box gap={3} display='flex'>
        {featureFlags?.feature_planning && (
          <>
            <Button
              variant='text'
              color='primary'
              onClick={() => {
                navigate('/orders');
              }}
              startIcon={<ReceiptIcon />}
              id='view-orders-button'
              aria-label='View orders'
            >
              View Orders
            </Button>
            <Button
              variant='text'
              color='primary'
              onClick={() => {
                navigate('/plans-ad-sets?tab=plans');
              }}
              startIcon={<FactCheckIcon />}
              id='view-plans-button'
              aria-label='View plans'
            >
              View Plans
            </Button>
          </>
        )}
        <NewPlanOrOrderButton featuresEnabled={featureFlags} />
      </Box>
    </Box>
  );
};
