import { formatDisplayDateWithTime } from 'helpers/DateHelpers';
import moment from 'moment';
import 'moment-business-days';
import React from 'react';
import { Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { styled } from 'styled-components';
import { selectOrder, selectOrderErrors } from '../orderFormSlice';
import { selectOwnerOrganisation } from '../orderFormSlice';

const ErrorList = styled.ul`
  list-style: none;
  font-size: 14px;
  color: #ef767a;
  padding-inline: inherit;
`;

const DateRangeDescription = () => {
  const startDate = useSelector(selectOrder).start_at;
  const stopDate = useSelector(selectOrder).stop_at;
  const errors = useSelector(selectOrderErrors).start_at;
  const owner = useSelector(selectOwnerOrganisation);
  const retailerTimezone = owner?.settings?.['timezone'] ?? 'UTC';
  if (!startDate && !stopDate) {
    return (
      <div className='d-flex align-items-center'>
        Dates: <span className='text-primary ml-1'>No ad dates have been selected yet.</span>
      </div>
    );
  }

  return (
    <div>
      <div className='d-flex align-items-center'>
        Dates:
        <Form.Group className='mb-0 mx-2'>
          <span className='text-primary cursor-pointer'>
            {startDate ? <>{formatDisplayDateWithTime(moment.utc(startDate))}</> : '–'}
          </span>
        </Form.Group>
        to
        <Form.Group className='mb-0 mx-2'>
          <span className='text-primary cursor-pointer'>
            {stopDate ? <>{formatDisplayDateWithTime(moment.utc(stopDate))}</> : '–'}
          </span>
        </Form.Group>
        <Form.Group className='mb-0 mx-2'>
          <span className='text-muted ml-2'>{retailerTimezone}</span>
        </Form.Group>
      </div>
      {errors?.length > 0 ? (
        <ErrorList>
          {errors.map((error: string, idx: number) => (
            <li key={idx}>{error}</li>
          ))}
        </ErrorList>
      ) : null}
    </div>
  );
};

export default DateRangeDescription;
