import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams, useLocation } from 'react-router-dom';
import { getGetPlanQueryKey, useGetPlan } from '../../../lib/api/ad-management';
import { ViewMode } from './ViewModeType';
import { ViewPlanAdSetsTable } from './reviewAdSets/ViewPlanAdSetsTable';
import { PlanDetailsSection } from './PlanDetailsSection';
import { PlanViewHeadingSection } from './PlanViewHeadingSection';
import Wallet from 'v2/Types/Wallets';
import { SimpleLoadingScreen } from 'v2/components/SimpleLoadingScreen';
import { PlanProvider } from '../PlanProvider';
import { PlanData, initialPlanState } from '../PlanContext';
import { NewPlanAdSets } from '../NewPlanAdSets';
import { Box } from '@zitcha/component-library';
import { useQueryClient } from '@tanstack/react-query';

export const PlanView = () => {
  const { planId } = useParams();
  const location = useLocation();
  const initialViewMode = location.state?.viewMode || ViewMode.REVIEWING;
  const [viewMode, setViewMode] = useState<ViewMode>(initialViewMode);

  const { isLoading, isError, data, error, queryKey, isFetching } = useGetPlan(planId as string, {
    query: { queryKey: [getGetPlanQueryKey(planId as string), viewMode] },
  });
  const QueryClient = useQueryClient();
  const planData: PlanData = useMemo(() => {
    return {
      ...initialPlanState,
      ...(data?.data ?? {}),
      originalPlanData: data?.data,
      retailerId: data?.data?.owner?.id,
      brandId: data?.data?.brand?.id,
      walletId: data?.data?.wallet?.id,
    };
  }, [data]);

  //state for selected wallet and new Ad sets in edit mode
  const [selectedWallet, setSelectedWallet] = useState<Wallet | null>(null);

  // Clear new ad sets and set view mode to reviewing after successful propose
  const onSuccessfulPropose = ({ setViewMode }: { setViewMode: React.Dispatch<React.SetStateAction<ViewMode>> }) => {
    QueryClient.invalidateQueries({ queryKey: ['usePlanAdSets'] });
    QueryClient.invalidateQueries({ queryKey: queryKey });
    setViewMode(ViewMode.REVIEWING);
  };

  useEffect(() => {
    // Reinitialize state variables here
    setSelectedWallet(null);
  }, [viewMode]);

  if (isLoading || isFetching) return <SimpleLoadingScreen />;
  if (isError) return <>An error occurred: {error?.message}</>;
  if (!planData || !planData.id) return <>No plan to display</>;

  return (
    <>
      <Helmet>
        <title>
          Plan - {planData.name || ''} - {process.env.REACT_APP_NAME}
        </title>
      </Helmet>
      <PlanProvider data={planData as PlanData}>
        <>
          <PlanViewHeadingSection
            mode={viewMode}
            setMode={setViewMode}
            selectedWallet={selectedWallet}
            onSuccessfulPropose={() => onSuccessfulPropose({ setViewMode })}
          />
          <PlanDetailsSection mode={viewMode} />
          <ViewPlanAdSetsTable viewMode={viewMode} />
          {viewMode === ViewMode.EDITING && (
            <Box mt={2}>
              <NewPlanAdSets viewMode={ViewMode.EDITING} />
            </Box>
          )}
        </>
      </PlanProvider>
    </>
  );
};
