import { AdSetStatusEnum } from "v2/lib/api/ad-management";

//todo define these based on designs
export const adSetStatusChipColorMap: Record<AdSetStatusEnum, 'default' | 'primary' | 'secondary' | 'error' | 'warning' | 'info' | 'success'> = {
    [AdSetStatusEnum.new]: 'default',
    [AdSetStatusEnum.replaced]: 'info',
    [AdSetStatusEnum.draft]: 'default',
    [AdSetStatusEnum.submitted]: 'info',
    [AdSetStatusEnum.ineligible]: 'error',
    [AdSetStatusEnum.clashed]: 'warning',
    [AdSetStatusEnum.resolved]: 'success',
    [AdSetStatusEnum.rejected]: 'default',
    [AdSetStatusEnum.reallocated]: 'info',
    [AdSetStatusEnum.scheduled]: 'success',
    [AdSetStatusEnum.reserved]: 'warning',//todo warning/main (not just warning)
    [AdSetStatusEnum.pending_approval]: 'warning',
    [AdSetStatusEnum.failed]: 'error',
    [AdSetStatusEnum.live]: 'success',
    [AdSetStatusEnum.delivered]: 'success',
    [AdSetStatusEnum.expired]: 'error',
    [AdSetStatusEnum.paused]: 'default',
    [AdSetStatusEnum.cancelled]: 'error',
};

//todo define these based on designs
export const adSetStatusChipVariantMap: Record<AdSetStatusEnum, 'outlined' | 'filled'> = {
    [AdSetStatusEnum.new]: 'filled',
    [AdSetStatusEnum.replaced]: 'filled',
    [AdSetStatusEnum.draft]: 'filled',
    [AdSetStatusEnum.submitted]: 'filled',
    [AdSetStatusEnum.ineligible]: 'outlined',
    [AdSetStatusEnum.clashed]: 'outlined',
    [AdSetStatusEnum.resolved]: 'filled',
    [AdSetStatusEnum.rejected]: 'filled',
    [AdSetStatusEnum.reallocated]: 'filled',
    [AdSetStatusEnum.scheduled]: 'filled',
    [AdSetStatusEnum.reserved]: 'filled',
    [AdSetStatusEnum.pending_approval]: 'filled',
    [AdSetStatusEnum.failed]: 'outlined',
    [AdSetStatusEnum.live]: 'filled',
    [AdSetStatusEnum.delivered]: 'filled',
    [AdSetStatusEnum.expired]: 'outlined',
    [AdSetStatusEnum.paused]: 'filled',
    [AdSetStatusEnum.cancelled]: 'outlined',
};

export const adSetStatusNameMap: Record<AdSetStatusEnum, string> = {
    [AdSetStatusEnum.new]: 'Draft',
    [AdSetStatusEnum.replaced]: 'Replaced',
    [AdSetStatusEnum.draft]: 'Draft',
    [AdSetStatusEnum.submitted]: 'Submitted',
    [AdSetStatusEnum.ineligible]: 'Ineligible',
    [AdSetStatusEnum.clashed]: 'Clashed',
    [AdSetStatusEnum.resolved]: 'Resolved',
    [AdSetStatusEnum.rejected]: 'Draft', // Rejected ad sets show Draft status on FE
    [AdSetStatusEnum.reallocated]: 'Reallocated',
    [AdSetStatusEnum.scheduled]: 'Scheduled',
    [AdSetStatusEnum.reserved]: 'Reserved',
    [AdSetStatusEnum.pending_approval]: 'Pending approval',
    [AdSetStatusEnum.failed]: 'Failed',
    [AdSetStatusEnum.live]: 'Live',
    [AdSetStatusEnum.delivered]: 'Delivered',
    [AdSetStatusEnum.expired]: 'Expired',
    [AdSetStatusEnum.paused]: 'Paused',
    [AdSetStatusEnum.cancelled]: 'Cancelled',
};