import React, {useState} from "react";
import {Asset} from "../../types";
import FileUploadModal from "../../assets/FileUploadModal";
import {Button} from "react-bootstrap";
import SingleMediaUpload from "../SingleMediaUpload";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {useSelector} from "react-redux";
import {selectSession} from "../../../session/sessionSlice";
import {aspectRatio} from "../../../../lib/aspect_ratio";
import {reorderAssets} from './asset_ordering'


type Ratio = {
    ratio: '1.91:1' | '1:1' | '4:5',
    required: boolean,
    min_width: number,
    min_height: number,
};

type AssetsProp = {
    id: string,
    title: string,
    required?: boolean,
    ratios?: Array<Ratio>,
    value: Array<Asset>,
    readOnly?: boolean,
    max_images: number,
    max_videos: number,
    max_attachments: number,

    onChange: (vs: Array<Asset>) => void,
}

// TODO: Figure out how to make more generic.
const Assets = (props: AssetsProp) => {
    const {
        readOnly = false,
        ratios,
        value,
        max_images,
        max_videos,
        max_attachments,
        onChange,
    } = props;

    const [showModal, setShowModal] = useState<boolean>(false);
    const landscapeAsset = value.find((f) => aspectRatio(f.metadata.width, f.metadata.height) === '1.91:1')
    const squareAsset = value.find(f => aspectRatio(f.metadata.width, f.metadata.height) === '1:1')
    const restOfAssets = value.filter(f => ![landscapeAsset?.id, squareAsset?.id].includes(f.id))

    const landscape = ratios.find(ratio => ratio.ratio === "1.91:1");
    const landscapeInfo = <div>
        <div>Ratio: {landscape.ratio}</div>
        <div>Min Width: {landscape.min_width}px</div>
        <div>Min Height: {landscape.min_height}px</div>
    </div>;
    const [landscapeErrors, setLandscapeErrors] = useState<Array<string>>([]);

    const square = ratios.find(ratio => ratio.ratio === '1:1');
    const squareInfo = <div>
        <div>Ratio: {square.ratio}</div>
        <div>Min Width: {square.min_width}px</div>
        <div>Min Height: {square.min_height}px</div>
    </div>;
    const [squareErrors, setSquareErrors] = useState<Array<string>>([]);

    let formats = [];

    const videoFormats = useSelector(state => selectSession(state).file_formats.videos);
    const imageFormats = useSelector(state => selectSession(state).file_formats.images);

    if (max_images > 0) {
        formats = [...formats, ...imageFormats];
    }
    if (max_videos > 0) {
        formats = [...formats, ...videoFormats];
    }

    const handleShowModal = () => setShowModal(true);
    const handleHideModal = () => setShowModal(false);

    const handleLandscapeChange = (v?: Asset) => {
        const newValue = value.filter(f => f.id !== landscapeAsset?.id)
        if (v) {
            if (aspectRatio(v.metadata.width, v.metadata.height) !== '1.91:1') {
                setLandscapeErrors(['Expected asset ratio to be 1.91:1.'])
                return;
            }
            newValue.push(v);
            setLandscapeErrors([]);
        }
        onChange(reorderAssets(newValue));
    }
    const handleSquareChange = (v?: Asset) => {
        const newValue = value.filter(f => f.id !== squareAsset?.id)
        if (v) {
            if (aspectRatio(v.metadata.width, v.metadata.height) !== '1:1') {
                setSquareErrors(['Expected asset ratio to be 1:1.'])
                return;
            }
            newValue.push(v);
            setSquareErrors([]);
        }
        onChange(reorderAssets(newValue));
    }
    const handleRestOfAssetsChange = (vs: Array<Asset>) => {
        const newValue = [...vs];
        if (landscapeAsset) {
            newValue.push(landscapeAsset);
        }
        if (squareAsset) {
            newValue.push(squareAsset);
        }
        onChange(reorderAssets(newValue));
    }

    return <div>
        <SingleMediaUpload headline='Landscape' info={landscapeInfo} required value={landscapeAsset}
                           onChange={handleLandscapeChange} readOnly={readOnly} accept={formats}
                           errors={landscapeErrors}/>
        <SingleMediaUpload headline='Square' info={squareInfo} required value={squareAsset}
                           onChange={handleSquareChange} readOnly={readOnly} accept={formats}
                           errors={squareErrors}/>
        <Button
            variant='outline-secondary'
            className='py-3 mb-2 w-100'
            onClick={handleShowModal}
            disabled={readOnly}
        >
            <FontAwesomeIcon icon={faPlus} size='4x'/>
            <br/>
            <h6 className='mt-1'>Attach Media</h6>
        </Button>

        <FileUploadModal
            onChange={handleRestOfAssetsChange}
            value={restOfAssets}
            accept={formats}
            show={showModal}
            max={max_attachments - 2}
            onHide={handleHideModal}
        />
    </div>;
}


export default Assets;
