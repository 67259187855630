import React from 'react';
import { TextField } from '@zitcha/component-library';
import { RenderableMediaTypeFieldProperties } from 'v2/lib/api/inventory';

interface InputComponentProps {
  properties: RenderableMediaTypeFieldProperties & {
    validationType?: 'text' | 'url' | 'email';
    minLength?: number;
    maxLength?: number;
    label?: string;
    placeholder?: string;
  };
  fieldName?: string;
  onChange: (...event: Array<any>) => void;
  onBlur: () => void;
  value: any;
}

export const InputComponent = ({ properties, fieldName, onChange, onBlur, value }: InputComponentProps) => {
  const controlledValue = value || '';
  return (
    <TextField
      id={`${fieldName}-input-${properties.label}`}
      placeholder={properties.placeholder}
      variant='outlined'
      fullWidth
      type={properties.validationType === 'email' ? 'email' : 'text'}
      inputProps={{
        minLength: properties.minLength,
        maxLength: properties.maxLength,
      }}
      onChange={onChange}
      onBlur={onBlur}
      value={controlledValue}
      data-testid={`${fieldName}-input-component`}
    />
  );
};
