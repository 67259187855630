import { useState } from 'react';
import { usePlan } from '../PlanContext';
import {
  Ads,
  AdSet, Plan, PlanStatus,
  PlanUpdateForm,
  useCreateAdSetFromBundle, useTransitionPlan,
  useUpdateAdSet,
  useUpdateAdsInAdSet,
  useUpdatePlan,
} from '../../../lib/api/ad-management';
import { batchedPromises } from '../../../lib/utils/batchedPromises';

export const useProposePlan = () => {
  const [loading, setLoading] = useState(false);
  const plan = usePlan();
  const updateAdSet = useUpdateAdSet();
  const adAdSet = useCreateAdSetFromBundle();
  const updateAdsInAdSet = useUpdateAdsInAdSet();
  const updatePlan = useUpdatePlan();
  const transitionPlan = useTransitionPlan();

  const updatePlanDetails = (): Promise<Plan> | null => {
    if (!plan.id) {
      return null
    }
    const planUpdateForm: PlanUpdateForm = {};
    if (plan.name !== null && plan.name !== plan.originalPlanData?.name) {
      planUpdateForm.name = plan.name;
    }
    if (plan.wallet?.id && plan.wallet?.id !== plan.originalPlanData?.wallet?.id) {
      planUpdateForm.wallet_id = plan.wallet.id;
    }

    //update the plan, if there have been any changes.
    if (planUpdateForm.name || planUpdateForm.wallet_id) {
      return updatePlan.mutateAsync({ id: plan.id, data: planUpdateForm });
    }

    return null
  };

  const addNewAdSets = (planId?: string): Array<() => Promise<AdSet>> => {
    if (plan.newAdSets.length > 0) {
      // Call the addAdSets API
      return plan.newAdSets.map((adSet) => () => adAdSet.mutateAsync({
        planId: planId ?? plan.id,
        bundleId: adSet.bundleIds?.[0],
        data: {
          ads: adSet.ads?.map((ad) => ({ name: ad.name ?? '', fields: ad.fields })),
        },
      }));
    }

    return [];
  };

  const updateAdSets = (): Array<() => Promise<AdSet>> => {
    if (plan.updatedAdSets.length > 0) {
      // Call the updateAdSets API
      return plan.updatedAdSets.filter((adSet) =>
        adSet.bundleIds?.[0] !== adSet.existingAdSet.bundleIds?.[0],
      ).map((adSet) => () => updateAdSet.mutateAsync({
        id: adSet.id,
        data: { new_bundle_id: adSet.bundleIds?.[0], existing_bundle_id: adSet.existingAdSet.bundleIds?.[0] },
      },
      ));
    }

    return [];
  };

  const updateAdSetAds = (): Array<() => Promise<Ads>> => {
    if (plan.updatedAdSets.length > 0) {
      // Call the updateAdSets API
      return plan.updatedAdSets.map((adSet) => () => updateAdsInAdSet.mutateAsync({
        adSet: adSet.id,
        data: { ads: adSet.ads.map((ad) => ({ id: ad.id, name: ad.name ?? '', fields: ad.fields })) },
      }));
    }

    return [];
  };

  const savePlanDetails = async (id?: string) => {
    if (plan.id) {
      await updatePlanDetails();
    }

    await batchedPromises(addNewAdSets(id), 5);
    await batchedPromises(updateAdSets(), 5);
    await batchedPromises(updateAdSetAds(), 5);
  };

  // provide ID if plan context does not have the ID of the newly created plan
  const savePlan = async (id?: string) => {
    setLoading(true);
    try {
      await savePlanDetails(id)
    } catch (error) {
      console.error('Error saving plan', error);
    }
    setLoading(false);
  }

  // provide ID if plan context does not have the ID of the newly created plan
  const proposePlan = async (id?: string) => {
    setLoading(true);
    try {
      await savePlanDetails(id)

      if (plan.status === PlanStatus.planning || plan.status === null) {
        await transitionPlan.mutateAsync({ id: plan.id, data: { status: 'proposed' } });
      }
    } catch (error) {
      console.error('Error proposing plan', error);
    }
    setLoading(false);
  };

  return {
    loading,
    savePlan,
    proposePlan,
  };
};
