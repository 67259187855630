import React, { useState } from 'react';
import { Alert, Box, GridColDef, DataGrid, GridPaginationModel, GridRenderCellParams } from '@zitcha/component-library';
import { useFetchAdSets } from 'v2/lib/hooks/useFetchAdSets';
import { AdSetsTableFilters } from './AdSetsTableFilters';
import { type AdSet, AdSetSearch, AdSetStatusEnum, PerPageEnum } from 'v2/lib/api/ad-management';
import {
  renderSKUCode,
  renderRetailer,
  renderSwitch,
  renderAdSetsStatusChip,
  renderAdSetsActions,
  renderRetailerOrBrand,
} from 'v2/features/plansAndAdSets/TableCellRenderers';
import { useToggleAdset } from 'v2/lib/hooks/useToggleAdset';
import { AdSetModal } from 'v2/components/AdSetModal/AdSetModal';
import { RenderSchedule } from './PlanPage/reviewAdSets/ViewPlanAdSetsTableCellRenderers';
import { useUserOrganisation } from 'v2/lib/hooks/useUserOrganisation';
import { BudgetCell } from './NewPlanAdSetsCellRenders';

export const AdSetsTable: React.FC = () => {
  const [editAdSet, setEditAdSet] = useState<AdSet | undefined>(undefined);
  const [viewAdSet, setViewAdSet] = useState<AdSet | undefined>(undefined);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
  const [skuCodeModalState, setSkuCodeModalState] = useState<{ isOpen: boolean; skuCodes: Array<string> }>({
    isOpen: false,
    skuCodes: [],
  });
  const organisation = useUserOrganisation();
  const isRetailer = organisation.is_retailer;
  const organisationTypeFilter = isRetailer
    ? { retailer_ids: [organisation.id], supplier_ids: [] }
    : { retailer_ids: [], supplier_ids: [organisation.id] };
  const initialAdSetSearch: AdSetSearch = {
    statuses: undefined,
    media_space_ids: undefined,
    start_at: undefined,
    end_at: undefined,
    ...organisationTypeFilter,
  };
  const { adSetsData, isLoadingAdSets, adSetsError, adSetPagination, changeAdSetPagination, setAdSetSearch } =
    useFetchAdSets(initialAdSetSearch);
  const { mutate } = useToggleAdset();

  const handleSwitchChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
    params: GridRenderCellParams
  ) => {
    mutate({ id: params.row.id, data: { status: checked ? AdSetStatusEnum.live : AdSetStatusEnum.paused } });
  };

  const rows =
    adSetsData?.map((adSet: AdSet) => ({
      id: adSet.id,
      active: adSet.status,
      status: {
        adSetStatus: adSet.status,
        planStatus: adSet?.plan?.status,
      },
      retailer: adSet?.plan?.owner?.name, // when viewing as supplier
      brand: adSet?.plan?.supplier?.name, // when viewing as retailer
      bundleIds: adSet?.bundleIds,
      'media-type': adSet?.mediaSpace?.name,
      placement: adSet.bundleLocationNames,
      'SKU-codes': adSet?.ads?.map((ad) => ad?.fields?.sku),
      budget: adSet?.price, //need to get the currency from the retailer
      adSetObject: adSet,
    })) || [];

  const columns: Array<GridColDef> = [
    {
      field: 'active',
      headerName: 'Active',
      minWidth: 150,
      renderCell: (params) => {
        return renderSwitch(params, handleSwitchChange);
      },
    },
    { field: 'status', headerName: 'Status', width: 200, renderCell: renderAdSetsStatusChip },
    { field: isRetailer ? 'brand' : 'retailer', headerName: isRetailer ? 'Brand' : 'Retailer', width: 150, renderCell: renderRetailerOrBrand },
    {
      field: 'schedule',
      headerName: 'Schedule',
      width: 250,
      renderCell: (params) => <RenderSchedule bundleIds={params.row.bundleIds} />,
    },
    { field: 'media-type', headerName: 'Media type', width: 150 }, //[]string
    { field: 'placement', headerName: 'Placement', width: 250 }, //[]string
    {
      field: 'SKU-codes',
      headerName: 'SKU code(s)',
      width: 150,
      renderCell: (params) =>
        renderSKUCode({
          params,
          onClick: (skuCodes) => {
            setSkuCodeModalState({ isOpen: true, skuCodes });
          },
        }),
    }, //array of strings
    {
      field: 'budget',
      headerName: 'Budget',
      width: 80,
      renderCell: (params) => <BudgetCell {...params} />,
    }, //need to get the currency from the retailer
    {
      field: 'actions',
      headerName: 'Actions',
      width: 70,
      renderCell: (params) =>
        renderAdSetsActions({
          isLive: params.row.active === AdSetStatusEnum.live,
          onClickEdit: () => setEditAdSet(params?.row?.adSetObject),
          onClickView: () => setViewAdSet(params?.row?.adSetObject),
        }),
    },
  ];

  const handleFilterChange = (newFilter: Partial<AdSetSearch>) => {
    setAdSetSearch((prevFilter) => ({ ...prevFilter, ...newFilter }));
  };

  const dataGridPaginationModel = {
    pageSize: adSetPagination.perPage ?? PerPageEnum.NUMBER_15,
    page: (adSetPagination.currentPage ?? 1) - 1, //-1 to match 0 vs 1 based indexing
  };

  const handlePaginationUpdate = (model: GridPaginationModel) => {
    changeAdSetPagination(model.page + 1, model.pageSize as PerPageEnum);
  };

  return (
    <Box sx={{ backgroundColor: 'white' }} padding={2} mt={3}>
      <AdSetsTableFilters onFilterChange={handleFilterChange} isRetailerView={organisation.is_retailer} />
      {adSetsError && <Alert severity='error'>An error occurred while fetching ad sets.</Alert>}
      <DataGrid
        disableColumnFilter
        disableColumnSorting
        rows={rows}
        paginationMode='server'
        columns={columns}
        pageSizeOptions={[5, 10, 15, 25, 50, 100]}
        autoHeight
        loading={isLoadingAdSets}
        rowCount={adSetPagination.total}
        paginationModel={dataGridPaginationModel}
        onPaginationModelChange={handlePaginationUpdate}
      />
      {editAdSet && (
        <AdSetModal
          isOpen={!!editAdSet}
          isReadOnly={false}
          adSet={editAdSet}
          onClose={() => {
            setEditAdSet(undefined);
          }}
        />
      )}
      {viewAdSet && (
        <AdSetModal
          isOpen={!!viewAdSet}
          isReadOnly={true}
          adSet={viewAdSet}
          onClose={() => {
            setViewAdSet(undefined);
          }}
        />
      )}
    </Box>
  );
};
