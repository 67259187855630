import React from 'react';
import styled from 'styled-components';
import Check from "./Check";
import {formatToCurrency} from "../../../../lib/financial";

const Container = styled.div<{ disabled: boolean }>`
  display: flex;
  width: 100%;
  height: 5rem;
  padding: 0.5rem 1rem;
  margin-bottom: 10px;

  ${({disabled}) => disabled && 'opacity: 0.5;'}

  cursor: pointer;
`;

const ImageContainer = styled.img`
  object-fit: contain;
  height: 100%;
  max-width: 6rem;
`;

const ImageCaption = styled.div`
  box-sizing: content-box;
  text-align: left;
  display: flex;
  align-items: center;
`;

const NameContainer = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  width: 40rem;
  font-size: 90%;
`;

const PriceContainer = styled.div`
  text-align: center;
  display: flex;
  align-items: center;

  margin-left: 10px;

  font-size: 90%;
`;

type ProductSquareProps = {
  name: string,
  src: string,
  price: number,
  currency: string,
  checked?: boolean,
  disabled?: boolean
  onClick: (EventTarget) => void,
};


const ProductRectangle = ({
  name,
  src,
  price,
  currency,
  checked,
  disabled = false,
  onClick = () => { /* do nothing */ }
}: ProductSquareProps) => (
  <Container className='rounded bg-light' disabled={disabled} onClick={e => !disabled && onClick(e)}>
      <ImageContainer className='px-3 rounded d-flex' src={src} alt={name}/>
      <ImageCaption>
          <NameContainer>{name}</NameContainer>
      </ImageCaption>
      <PriceContainer><b className='mr-1'>Price:</b> {formatToCurrency(currency, price)}</PriceContainer>
      <Check checked={checked}/>
  </Container>
);

export default ProductRectangle
