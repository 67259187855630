import React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { PopoverOrigin } from '@mui/material';

const StyledMenu = styled((props: MenuProps, anchorOrigin?: PopoverOrigin, transformOrigin?: PopoverOrigin) => (
  <Menu
    elevation={0}
    anchorOrigin={anchorOrigin}
    transformOrigin={transformOrigin}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: 0,
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.1) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

export interface MenuItemProps {
  icon: React.ReactNode;
  text: string;
  onClick: (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
}

interface MenuItemsProps {
  anchorElement: HTMLElement | null;
  items: Array<MenuItemProps>;
  isOpen: boolean;
  onClose: () => void;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
}

export const MenuItems = ({ anchorElement, items, isOpen, onClose, anchorOrigin, transformOrigin }: MenuItemsProps) => {
  return (
    <StyledMenu anchorEl={anchorElement} open={isOpen} onClose={onClose} anchorOrigin={anchorOrigin} transformOrigin={transformOrigin}>
      {items.map((item, index) => (
        <MenuItem key={index} onClick={(e) => item.onClick(e)} disableRipple>
          {item.icon}
          {item.text}
        </MenuItem>
      ))}
    </StyledMenu>
  );
};

