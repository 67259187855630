import React, { useState } from 'react';
import { styled } from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import { Form, Table } from 'react-bootstrap';
import styles from '../../../features/organisation_settings/components/list.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft, faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';

interface Retailer {
  id: string;
  name: string;
  logo: string;
  is_active: boolean;
  settings: {
    defaultCurrency: string;
    enabled_ads: Record<string, boolean>;
  };
}

export default function RetailerList({ retailers }: { retailers: Array<Retailer> }) {
  const navigate = useNavigate();
  const [retailersList, setRetailersList] = useState<Array<Retailer>>(retailers);
  const [currentOrder, setCurrentOrder] = useState('ascending');
  const [tablePage, setTablePage] = useState(0);
  const [tablePageAmount, setTablePageAmount] = useState(5);

  function nextTable() {
    let x = tablePage;
    x++;
    setTablePage(x);
  }

  function prevTable() {
    let x = tablePage;
    if (tablePage >= 1) x--;
    setTablePage(x);
  }
  const onClick = (organisation: Retailer) => {
    navigate(`/organisation/settings/retailers/${organisation.id}`);
  };

  const sortRetailers = (order: string) => {
    const sortedRetailers = [...retailersList];
    if (order === 'ascending') {
      sortedRetailers.sort((a, b) => a.name.localeCompare(b.name));
    } else {
      sortedRetailers.sort((a, b) => b.name.localeCompare(a.name));
    }
    setRetailersList(sortedRetailers);
  };
  return (
    <>
      <StyledTable className={styles.tablestyle}>
        <thead>
          <TableHeaderRow>
            <th></th>
            <th>
              <div className='center'>
                Retailer
                <SortButton
                  type='button'
                  onClick={() => {
                    setCurrentOrder(currentOrder === 'ascending' ? 'descending' : 'ascending');
                    sortRetailers(currentOrder);
                  }}
                >
                  <FontAwesomeIcon icon={currentOrder === 'ascending' ? faArrowDown : faArrowUp} />
                </SortButton>
              </div>
            </th>
            <th></th>
          </TableHeaderRow>
        </thead>
        <tbody>
          {retailersList.map(
            (retailer, index) =>
              tablePage * tablePageAmount < index + 1 &&
              index < (tablePage + 1) * tablePageAmount && (
                <TableRow
                  key={`${retailer.name}${index}`}
                  onClick={() => {
                    if (onClick) {
                      onClick(retailer);
                    }
                  }}
                >
                  <td>
                    {retailer.logo ? (
                      <StyledImgDiv>
                        <StyledLogo src={retailer.logo} alt={`${retailer.name} logo`} />
                      </StyledImgDiv>
                    ) : (
                      <StyledImgDivDashed />
                    )}
                  </td>
                  <td>
                    <Link
                      to={'#'}
                      onClick={(e) => {
                        e.preventDefault();
                        return onClick(retailer);
                      }}
                    >
                      {retailer.name}
                    </Link>
                  </td>
                  <td>
                    <Link
                      to={'#'}
                      className='ml-auto sup-link'
                      onClick={(e) => {
                        e.preventDefault();
                        return onClick(retailer);
                      }}
                    >
                      <span>Edit</span>
                      <FontAwesomeIcon icon={faChevronRight} />
                    </Link>
                  </td>
                </TableRow>
              )
          )}
        </tbody>
      </StyledTable>

      <SelectContainer>
        <Form.Label>Show</Form.Label>
        <select
          className='custom-select'
          value={tablePageAmount}
          onChange={(e) => {
            setTablePageAmount(+e.target.value);
            setTablePage(0);
          }}
        >
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={15}>15</option>
          <option value={20}>20</option>
        </select>
        <Form.Label>Retailers per page</Form.Label>
      </SelectContainer>
      <div className={styles.paginator}>
        <PaginationBtn type='button' onClick={prevTable}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </PaginationBtn>
        <span>
          {tablePage + 1} of {Math.floor(retailers.length / tablePageAmount + 1)}
        </span>
        <PaginationBtn
          type='button'
          disabled={tablePage + 1 >= Math.floor(retailers.length / tablePageAmount + 1)}
          onClick={
            tablePage + 1 < Math.floor(retailers.length / tablePageAmount + 1)
              ? nextTable
              : () => {
                  return;
                }
          }
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </PaginationBtn>
      </div>
    </>
  );
}
const StyledTable = styled(Table)`
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  overflow: hidden;
`;
const TableRow = styled.tr`
  td:last-child {
    text-align: end;
    .sup-link {
      display: flex;
      align-items: center;
      gap: 10px;
      text-decoration: underline;
      span {
        margin-left: auto;
      }
    }
  }
  &:hover {
    background-color: #f3f0ff;
  }
  cursor: pointer;
  transition: background-color 0.3s;
`;
const TableHeaderRow = styled.tr`
  width: 100%;
  border: 1px solid #ededef;
  border-bottom: 0px;
  background-color: #ededef;
  .center {
    display: flex;
    align-items: center;
  }
`;
const SelectContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
  justify-content: flex-end;
  .custom-select {
    width: 80px;
    margin-right: 5px;
  }
`;
const StyledImgDiv = styled.div`
  width: 100px;
  max-height: 50px;
  margin-right: 1rem;
  position: relative;
  text-align: center;
`;
const StyledImgDivDashed = styled(StyledImgDiv)`
  border: 2px dashed #ddd;
  height: 50px;
`;
const StyledLogo = styled.img`
  max-width: 100px;
  min-height: 50px;
  max-height: 50px;
  margin: 0 auto;
  display: block;
  object-fit: contain;
  mix-blend-mode: multiply;
`;
const PaginationBtn = styled.button`
  all: unset;
  cursor: pointer;
`;
const SortButton = styled.button`
  all: unset;
  cursor: pointer;
  width: 1rem;
  height: 1rem;
  margin-left: 0.5rem;
  font-size: small;
`;
