import React from 'react';
import Select from 'react-select';

const SingleCategorySelect = ({ title, options = [], value = [], onChange = () => {} }) => {
  let optionsWithSelect = options;

  return (
    <Select
      isMulti={false}
      isClearable={false}
      isSearchable={false}
      className='basic-multi-select'
      classNamePrefix='select'
      placeholder={title}
      styles={selectStyles}
      hideSelectedOptions={false}
      backspaceRemovesValue={false}
      closeMenuOnSelect={true}
      value={value}
      onChange={(selected) => {
        onChange(selected.value);
      }}
      options={optionsWithSelect}
    />
  );
};

const selectStyles = {
  option: (styles) => ({
    ...styles,
    backgroundColor: 'while',
    color: 'black',
    overflow: 'wrap',
  }),
};

export default SingleCategorySelect;
