import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Card, Form, Spinner } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { selectSession, selectUserOrganisation } from '../../session/sessionSlice';
import styles from '../../../app/organisation_web_settings.module.scss';
import Breadcrumbs from '../../../app/components/Breadcrumbs';
import GenericWalletSelector from '../../common/inputs/GenericWalletSelector';
import {
  brandStoreConfigureFormUpdated,
  clearBrandStoreConfigureForm,
  loadBrandStoreConfigureForm,
  saveBrandStoreConfiguration,
  updateBrandStoreConfiguration,
} from '../state_management/BrandStoreSlice';
import StoreNameText from './StoreNameText';
import BaseUrlText from './BaseUrlText';
import BrandStoreCost from './BrandStoreCost';
import Schedule from './Schedule';
import TotalCost from './TotalCost';
import SelectedBrand from './SelectedBrand';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BRAND_STORE_ORDER_STATUS } from '../constants';
import { calculateStopAt } from './CreateHelpers';
import { trackCreateBrandStore } from '../../../helpers/segment';
import { selectBrandById } from '../../brands/brandsSlice';

const BrandStoreConfigureForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const session = useSelector(selectSession);
  const { order_id } = useParams();
  const formData = useSelector((state) => state.brand_store.brand_store_configure_form);
  const setFormData = (data) => dispatch(brandStoreConfigureFormUpdated(data));
  const brand = useSelector((state) => selectBrandById(state, formData.supplier_id));
  const retailer = useSelector(selectUserOrganisation);

  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (isCreateForm()) {
      return;
    }

    dispatch(clearBrandStoreConfigureForm());
    setIsLoading(true);

    dispatch(loadBrandStoreConfigureForm({ orderId: order_id })).then((response) => {
      if (response.error) {
        toast.error('Error loading brand store order');
        navigate('/brandstores/home');
      }
      setIsLoading(false);
    });
  }, [order_id]);

  const handleSubmit = async (event) => {
    if (isSubmitting) {
      return;
    }
    event.preventDefault();
    setIsSubmitting(true);

    let response;
    let successMessage;

    if (order_id) {
      response = await dispatch(updateBrandStoreConfiguration(formData, order_id));
      successMessage = 'Brand store updated successfully';
    } else {
      response = await dispatch(saveBrandStoreConfiguration(formData));
      successMessage = 'Brand store created successfully';
    }

    if (response.error) {
      setErrors(response.payload.errors);
    } else {
      toast.success(successMessage);
      const brandStore = response.payload.data;
      trackCreateBrandStore(brandStore, retailer, brand);
      navigate('/brandstores/home');
    }
    setIsSubmitting(false);
  };

  //update stop_at date when start_at, unit_count, or time_unit changes
  const [stopAt, setStopAt] = useState(null);

  useEffect(() => {
    if (formData.start_at && formData.unit_count && formData.time_unit) {
      const stop_at = calculateStopAt(formData.start_at, formData.unit_count, formData.time_unit);
      setStopAt(stop_at);
    }
  }, [formData.start_at, formData.unit_count, formData.time_unit]);

  useEffect(() => {
    if (stopAt) {
      setFormData({ ...formData, stop_at: stopAt });
    }
  }, [stopAt]);

  //update total_cost time_unit, cost_per_unit, or unit_count changes
  useEffect(() => {
    setFormData({ ...formData, total_cost: formData.cost_per_unit * formData.unit_count });
  }, [formData.cost_per_unit, formData.unit_count]);

  const getBreadCrumbs = () => {
    if (order_id) {
      return [{ path: '/brandstores/home', title: 'Brand Stores' }, { title: `Edit Brand Store / ${order_id}` }];
    }

    return [
      { path: '/brandstores/home', title: 'Brand Stores' },
      { path: '/brandstores/configure', title: 'Create New Brand Store' },
    ];
  };

  const getHeading = () => {
    return order_id ? 'Edit Brand Store' : 'Create New Brand Store';
  };

  const getSaveButtonText = () => {
    return order_id ? 'Save Changes' : 'Create Store';
  };

  const isEditForm = useMemo(() => {
    return !!order_id;
  }, [order_id]);

  const isCreateForm = () => {
    return !isEditForm;
  };

  const walletSelectButtonClickHandler = (selectedWalletId) => {
    dispatch(
      brandStoreConfigureFormUpdated({
        wallet: {
          id: selectedWalletId,
        },
      })
    );
  };

  return (
    <>
      <Helmet>
        <title>
          {order_id ? 'Edit' : 'Create'} Brand Stores for {session.user?.active_organisation?.name} -{' '}
          {process.env.REACT_APP_NAME}
        </title>
      </Helmet>

      <div style={{ width: '100%', padding: '50px' }}>
        <Breadcrumbs items={getBreadCrumbs()} />

        <h3 className={styles.topHeading}>{getHeading()}</h3>

        {isLoading && (
          <div className='text-center d-flex align-items-center justify-content-center' style={{ height: '300px' }}>
            <FontAwesomeIcon className={`fa-spin `} icon={faSpinner} size='4x' />
          </div>
        )}

        {!isLoading && (
          <Card style={{ minWidth: '300px' }} className='w-50'>
            <Card.Body>
              <Form onSubmit={handleSubmit}>
                <SelectedBrand formData={formData} />

                <StoreNameText
                  formData={formData}
                  readOnly={formData.status === BRAND_STORE_ORDER_STATUS.expired.status}
                  setFormData={setFormData}
                  errors={errors}
                />

                <BaseUrlText
                  formData={formData}
                  readOnly={formData.status === BRAND_STORE_ORDER_STATUS.expired.status}
                  setFormData={setFormData}
                  errors={errors}
                />

                {/* Disable when an order is approved */}
                <Schedule formData={formData} setFormData={setFormData} errors={errors} />

                {/* Disable when an order is approved */}
                <BrandStoreCost formData={formData} setFormData={setFormData} errors={errors} />

                <TotalCost formData={formData} setFormData={setFormData} />

                {/* Disable when an order is approved */}
                <Form.Group className='mb-3' controlId='formWallet'>
                  <div className='ml-3 font-weight-bold' style={{ maxWidth: '400px' }}>
                    <GenericWalletSelector
                      readOnly={formData?.is_already_approved}
                      isOwnerOverride={true}
                      supplierId={formData.supplier_id}
                      ownerId={formData.owner_id}
                      isOwner={true}
                      selectBtnClickHandler={walletSelectButtonClickHandler}
                      selectedWalletId={formData.wallet?.id}
                      errors={errors}
                    />
                  </div>
                  <Form.Text className='text-muted'>
                    {errors?.wallet?.length > 0 && <div className='text-danger'>{errors?.wallet[0]}</div>}
                    {errors?.supplier_id?.length > 0 && <div className='text-danger'>{errors?.supplier_id[0]}</div>}
                  </Form.Text>
                </Form.Group>

                <Button
                  style={{ width: '150px', height: '40px' }}
                  variant='primary'
                  type='submit'
                  className='mr-auto px-3'
                  size='md'
                  disabled={isSubmitting || formData.status === BRAND_STORE_ORDER_STATUS.expired.status}
                  onSubmit={handleSubmit}
                >
                  {!isSubmitting && <span> {getSaveButtonText()}</span>}
                  <span className='small d-flex'>
                    {isSubmitting && (
                      <Spinner
                        as='span'
                        animation='border'
                        size='sm'
                        role='status'
                        aria-hidden='true'
                        className='ml-auto mr-auto'
                      />
                    )}
                  </span>
                </Button>
                {/*Cancel button */}
                <Button
                  style={{ width: '100px', height: '40px' }}
                  type='button'
                  variant='light'
                  className='mr-auto px-3 ml-2 btn btn-outline-secondary'
                  size='md'
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Cancel
                </Button>
              </Form>
            </Card.Body>
          </Card>
        )}
      </div>
    </>
  );
};

export default BrandStoreConfigureForm;
