import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import styled from 'styled-components';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { useState } from 'react';

const StyledButton = styled.button<{ color: string }>`
    /* Remove default styles */
    border: none;
    background: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    transition: all 0.2s ease-in-out;
    color: ${props => props.color};
    &:active {
        transform: translateY(1px);
    }
`;

type ClipboardProps = {
  text: string,
  color?: string,
  timeout?: number,
}

const Clipboard = ({ 
  text, 
  color = '#000', 
  timeout = 0.7 
}:ClipboardProps) => {

  const copyToClipboard = (e) => {
    e.preventDefault();
    setShow(true);
    // Create a temporary input element to copy the text
    const tempInput = document.createElement('input');
    tempInput.value = text;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);
    setTimeout(() => {
      setShow(false);
    }
    , timeout * 1000);
  };

  const [show, setShow] = useState(false);

  if (!text) return null;

  return (
    <>
      <StyledButton color={color} onClick={(e) => copyToClipboard(e)}>
        <OverlayTrigger
          placement='top'
          trigger={["click",'focus']}
          rootClose={true}
          show={show}
          overlay={
            <Popover>
              <Popover.Content>
                Copied!
              </Popover.Content>
            </Popover>
          }
        >
        <FontAwesomeIcon icon={faCopy} />
        </OverlayTrigger>
      </StyledButton>
    </>
  );
};

export default Clipboard;
