import moment from 'moment';
import React from 'react';
import {Form} from 'react-bootstrap';
import Datetime from 'react-datetime';
import styled from 'styled-components';
import {ErrorFeedback, LabelText} from './Common';
import Errors from './Errors';
import {formatDisplayDate} from 'helpers/DateHelpers';

const DateTimeInput = styled(Datetime)`
  ${(props) =>
    props.directionUp &&
    `
    .rdtPicker {
      top: -253px;
    }
  `}
`;

const Date = ({
                controlId, required = false, readOnly, label, value, isValidDate, onChange = () => {
  }, errors = []
              }) => {
  const handleChange = (val) => {
    if (readOnly) {
      return;
    }
    onChange(val.format('YYYY-MM-DD'));
  };

  return (
    <Form.Group controlId={controlId}>
      <LabelText>
        {label} {required && label && '*'}{' '}
      </LabelText>
      <DateTimeInput
        directionUp
        value={value ? moment.utc(value) : ''}
        renderInput={(props, openCalendar, closeCalendar) => (
          <Form.Control
            type='text'
            onClick={openCalendar}
            readOnly={readOnly}
            value={formatDisplayDate(moment.utc(props.value))}
            onChange={() => {}}
          />
        )}
        autoComplete={false}
        isValidDate={isValidDate}
        timeFormat={false}
        onChange={handleChange}
      />
      <ErrorFeedback type='invalid' isInvalid={errors.length > 0}>
        <Errors errors={errors} />
      </ErrorFeedback>
    </Form.Group>
  );
};

export default Date;
