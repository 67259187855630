import React, { useState } from 'react'
import {
    selectSession
} from "../../session/sessionSlice";
import { useSelector } from "react-redux";
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {OverlayTrigger, Popover} from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FALLBACK_CURRENCY, formatToCurrency, formattedNumber } from '../../../lib/financial';

const CardContainer = styled.article`
    width: 10rem;
    color: #3A3B4F;
`

const CardValue = styled.h1`
    font-weight: 400;
    font-size: 1.7rem;
`

const CardLabel = styled.p`
    font-weight: 500;
`

const renderTooltip = (props) => (
  <Popover id='button-tooltip' {...props}>
    <Popover.Content className='text-justify'>
      This include clicks & link clicks
    </Popover.Content>
  </Popover>
);

const StatisticsCard = ({ header, body, unit, currency = FALLBACK_CURRENCY }) => {

    const session = useSelector(selectSession);
    const [organisation] = useState(session.user.active_organisation);
    const permissions = organisation?.permissions;
    const isCostPerLinkClickActive = permissions?.metrics?.insights['cost_per_inline_link_click'];

    return (
      <CardContainer className='rounded text-center d-flex justify-content-center align-items-center animated--grow-in'>
        <div>
          <CardValue className='text-uppercase mt-3'>
            {unit === "currency"
              ? formatToCurrency(currency, body)
              : formattedNumber(body)}
          </CardValue>
          <CardLabel>
            {isCostPerLinkClickActive && header === "Clicks" ? (
              <OverlayTrigger
                placement='bottom'
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
                trigger={["hover", "focus"]}
              >
                <span>
                  <label>{header}</label>
                  <a className='ml-1'>
                    <FontAwesomeIcon icon={faInfoCircle} />
                  </a>
                </span>
              </OverlayTrigger>
            ) : (
              header
            )}
          </CardLabel>
        </div>
      </CardContainer>
    );
}

StatisticsCard.propTypes = {
    header: PropTypes.string.isRequired,
    body: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    unit: PropTypes.string,
    currency: PropTypes.string,
}

export default StatisticsCard
