import React, { ReactElement } from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

export const Throbber: React.FC = (): ReactElement =>
    <div className='text-center d-flex align-items-center justify-content-center'>
        <FontAwesomeIcon
            className='fa-spin'
            style={{cursor: "pointer", marginTop: "50px"}}
            icon={faSpinner}
            size='4x'
        />
    </div>
