import React from 'react';
import { CircularProgress, Container } from '@zitcha/component-library';

export const SimpleLoadingScreen = () => {
  return (
    <Container
      aria-label='loading-screen'
      sx={{ display: 'flex', minHeight: '400px', alignItems: 'center', justifyContent: 'center' }}
    >
      <CircularProgress />
    </Container>
  );
};
