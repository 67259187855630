import React, {ReactElement} from "react";
import {Asset} from "../../types";
import styled from "styled-components";

const Container = styled.div`
`;

type FilePropsProps = {
    file: Asset,
}

const FileRectangle = (props: FilePropsProps): ReactElement => {
    const {
        file,
    } = props;

    let media;
    if (file.assettype === 'image') {
        media = <img className='p-1 w-100 rounded' src={file.url} alt={file.name}/>;
    } else if (file.assettype === 'video') {
        media = <video key={file.url} controls={true} width='100%' className='w-100 h-100 rounded'>
            <source src={file.url}/>
        </video>;
    } else {
        media = <></>;
    }

    return <Container>
        {media}
    </Container>
}

export default FileRectangle;
