import React, { useEffect, useRef, useState } from 'react';
import { Col, Pagination, Row, Table } from 'react-bootstrap';
import PropTypes from 'prop-types';

/**
 * Minimum implementation to support Formatted Data tables, created as a replacement for BootstrapTable component
 */

export const BootstrapDataTable = ({
  id = '',
  data = [],
  columns = [],
  headerWrapperClasses = '',
  paginationEnable = true,
  expandRow = {},
  rowClasses = (row, rowIndex) => {},
  rowEvents = {},
  hover = false,
  bordered = true,
  striped = false,
  keyField = undefined,
  rowStyle = (row, rowIndex) => {},
  noDataIndication = () => {},
}) => {
  const dataCount = data.length;
  const [paginatedData, setPaginatedData] = useState(data);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [expandedRows, setExpandedRows] = useState(expandRow?.expanded ?? []);
  const [active, setActive] = useState(1);
  const listTopRef = useRef(null);

  let pages = dataCount >= itemsPerPage ? Math.ceil(dataCount / itemsPerPage) : 1;

  useEffect(() => {
    setPaginatedData(paginationEnable ? data.slice(0, itemsPerPage) : data);
  }, [data]);

  const onClickHandler = (pageNumber) => {
    const lastIdx = pageNumber * itemsPerPage;
    setPaginatedData(data.slice(lastIdx - itemsPerPage, lastIdx));
    setActive(pageNumber);
    listTopRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  };

  const getCellValue = (row, cell, data, idx, keyfield) => {
    const defaultValue = <div>&nbsp;</div>;

    const cellValue = getCellValueUsingKeys(cell, row, defaultValue);

    if (cell?.formatter) {
      return cell.formatter(
        cellValue,
        row,
        idx,
        cell?.formatExtraData
          ? {
              ...cell?.formatExtraData,
              ...{
                onExpand: (row) => {
                  expandedRows.includes(keyfield)
                    ? setExpandedRows(expandedRows.filter((r) => r !== keyfield))
                    : setExpandedRows([...expandedRows, keyfield]);
                },
                expanded: expandedRows,
              },
            }
          : data
      );
    }
    return cellValue;
  };

  const getCellValueUsingKeys = (cell, row, defaultValue) => {
    let cellValue = '';

    if (cell.dataField.indexOf('.') !== -1) {
      const keys = cell.dataField.split('.');
      let tmp = row;
      keys.forEach((k) => {
        tmp = tmp?.[k] ?? '';
      });
      cellValue = tmp !== '' ? tmp : defaultValue;
    } else {
      cellValue = row?.[cell.dataField] ?? defaultValue;
    }

    return cellValue;
  };

  const onItemsPerPageChange = (count) => {
    if (data.length >= count) {
      setPaginatedData(data.slice(0, count));
    } else {
      setPaginatedData(data);
    }
    setItemsPerPage(count);
    setActive(1);
    listTopRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  };

  let items = [];
  if (active > 1) {
    items.push(<Pagination.Prev onClick={(e) => onClickHandler(active - 1)} />);
  }

  for (let number = 1; number <= pages; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active} onClick={(e) => onClickHandler(number)}>
        {number}
      </Pagination.Item>
    );
  }
  if (pages > 1 && active < pages) {
    items.push(<Pagination.Next onClick={(e) => onClickHandler(active + 1)} />);
  }

  if (paginatedData && paginatedData.length > 0) {
    return (
      <div>
        <Table id={id} hover={hover} bordered={bordered} striped={striped} ref={listTopRef}>
          {columns.length > 0 && columns.some((c) => c?.text || c?.headerFormatter) && (
            <thead>
              <tr className={headerWrapperClasses}>
                {columns.map((c, idx) => (
                  <th key={idx} style={c?.headerStyle}>
                    {c?.headerFormatter ? c.headerFormatter(c) : c?.text ?? <div>&nbsp;</div>}
                  </th>
                ))}
              </tr>
            </thead>
          )}
          <tbody>
            {paginatedData.map((d, idx, arr) => {
              const kf = keyField ? d?.[keyField] : idx;
              const styles = rowStyle(d, idx);
              const defaultRendered = (
                <tr
                  key={'row_' + idx}
                  style={styles}
                  className={rowClasses(d, idx)}
                  onClick={(e) => (rowEvents?.onClick ? rowEvents.onClick(e, d, idx) : (e) => {})}
                  onDoubleClick={(e) => (rowEvents?.onDoubleClick ? rowEvents.onDoubleClick(e, d, idx) : (e) => {})}
                  onMouseEnter={(e) => (rowEvents?.onMouseEnter ? rowEvents.onMouseEnter(e, d, idx) : (e) => {})}
                  onMouseLeave={(e) => (rowEvents?.onMouseLeave ? rowEvents.onMouseLeave(e, d, idx) : (e) => {})}
                  onContextMenu={(e) => (rowEvents?.onContextMenu ? rowEvents.onContextMenu(e, d, idx) : (e) => {})}
                  onAuxClick={(e) => (rowEvents?.onAuxClick ? rowEvents.onAuxClick(e, d, idx) : (e) => {})}
                >
                  {columns.map((c, cellIdx) => (
                    <td key={`${d.id}_${c.dataField}_${cellIdx}`} style={c?.style}>
                      {getCellValue(d, c, arr, idx, kf)}
                    </td>
                  ))}
                </tr>
              );

              return (
                <>
                  {defaultRendered}
                  {expandedRows.length > 0 && expandedRows.includes(kf) && (
                    <tr key={'rowextra_' + idx}>
                      <td colSpan={columns.length}>{expandRow.renderer(d)}</td>
                    </tr>
                  )}
                </>
              );
            })}
          </tbody>
        </Table>
        {paginationEnable && (
          <Row>
            <Col md='1'>
              <select className='form-control' onChange={(e) => onItemsPerPageChange(e.target.value)}>
                <option value='10'>10</option>
                <option value='25'>25</option>
                <option value='30'>30</option>
                <option value='50'>50</option>
              </select>
            </Col>
            <Col md='11'>
              <Pagination className='d-flex justify-content-center'>{items}</Pagination>
            </Col>
          </Row>
        )}
      </div>
    );
  } else {
    return (
      <Table id={id} bordered>
        {columns.length > 0 && (
          <thead>
            <tr className={headerWrapperClasses}>
              {columns.map((c) => (
                <th>{c?.text}</th>
              ))}
            </tr>
          </thead>
        )}
        <tbody>
          <tr>
            <td colSpan={data.length}>{noDataIndication()}</td>
          </tr>
        </tbody>
      </Table>
    );
  }
};

BootstrapDataTable.propTypes = {
  id: PropTypes.string,
  data: PropTypes.array,
  columns: PropTypes.array,
  headerWrapperClasses: PropTypes.string,
  paginationEnable: PropTypes.bool,
  expandRow: PropTypes.object,
  rowClasses: PropTypes.func,
  rowEvents: PropTypes.object,
  hover: PropTypes.bool,
  bordered: PropTypes.bool,
  striped: PropTypes.bool,
  keyField: PropTypes.string,
  rowStyle: PropTypes.func,
  noDataIndication: PropTypes.func,
};
