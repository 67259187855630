import PropTypes from "prop-types";
import React from "react";
import InventorySpacesSchedule from "./InventorySpacesSchedule";

const InventorySpaceBookingSchedule = ({ orders = [], errors = {} }) => {

  return (
    <div className='border rounded p-3'>
      <article>
        <div className='mb-2'>
          <h5>Booking Schedule</h5>
        </div>
        <div>
          <div>
            {orders.length > 0 && <InventorySpacesSchedule orders={orders} />}
            {orders.length === 0 && <>
              <div className='p-3 text-center'>No data available</div>
            </>}
          </div>
        </div>
      </article>
    </div>
  );
};

InventorySpaceBookingSchedule.propTypes = {
  orders: PropTypes.array,
  errors: PropTypes.object,
};

export default InventorySpaceBookingSchedule;
