import React, { useEffect } from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import PackageForm from '../features/packages/components/PackageForm';
import {
  clearPackage,
  fetchPackage,
  fetchPackageGroups,
  selectCanFullEdit,
  selectRequestType,
  selectStatus,
} from '../features/packages/packageFormSlice';

const Header = styled.h1`
  margin-bottom: 70px;
`;

const EditPackage = () => {
  const dispatch = useDispatch();
  const { package_id } = useParams();
  const requestStatus = useSelector(selectStatus);
  const requestType = useSelector(selectRequestType);
  const canFullEdit = useSelector(selectCanFullEdit);

  useEffect(() => {
    dispatch(clearPackage());
    dispatch(fetchPackage(package_id));
    dispatch(fetchPackageGroups());
  }, [dispatch, package_id]);

  if (requestStatus === 'failed' && requestType === 'fetch') {
    return <Navigate to='/packages' replace />;
  }

  return (
    <>
      <Helmet>
        <title>New Package - {process.env.REACT_APP_NAME}</title>
      </Helmet>
      <Container className='pt-4 px-5' fluid>
        <Row className='mb-3'>
          <Col>
            <Header>Package Editor</Header>
            {requestStatus === 'loading' && requestType === 'fetch' ? (
              <Row className='text-center d-flex align-items-center justify-content-center'>
                <Spinner animation='border' />
                &nbsp;
              </Row>
            ) : (
              <PackageForm canFullEdit={canFullEdit} />
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EditPackage;
