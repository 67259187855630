import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Typography,
  Alert,
  Autocomplete,
  Box,
  Chip,
  Stack,
  TextField,
  DeleteIcon,
  SaveIcon,
  SendIcon,
} from '@zitcha/component-library';
import { Helmet } from 'react-helmet-async';
import { EditableField } from 'v2/components/EditableField/EditableField';
import Brand from 'v2/Types/Brands';
import Wallet from 'v2/Types/Wallets';
import { useLocation, useNavigate } from 'react-router-dom';
import { useBrands } from 'v2/lib/hooks/useBrands';
import { useBrandWallets } from 'v2/lib/hooks/useWallets';
import { NewPlanAdSets } from './NewPlanAdSets';
import { RenderCurrency } from 'v2/components/RenderCurrency/RenderCurrency';
import { calculateTotalBudget } from 'v2/utils/newPlanUtils';
import { ViewMode } from './PlanPage/ViewModeType';
import { useUserOrganisation } from 'v2/lib/hooks/useUserOrganisation';
import { useRetailers } from 'v2/lib/hooks/useRetailers';
import { Organisation } from 'features/organisations/organisations';
import { usePlan, usePlanDispatch, initialPlanState, PlanData } from './PlanContext';
import { PlanProvider } from './PlanProvider';
import { useNewPlanActions } from 'v2/lib/hooks/useNewPlanActions';

export function NewPlanView(): React.ReactElement {
  const { state } = useLocation();
  const organisation = useUserOrganisation();
  const initialPlan: PlanData = {
    ...initialPlanState,
    ...state,
    ...(organisation.is_retailer ? { retailerId: organisation.id } : { brandId: organisation.id })
  };

  return (
    <>
      <Helmet>
        <title>New plan - {process.env.REACT_APP_NAME}</title>
      </Helmet>

      <PlanProvider data={initialPlan}>
        <NewPlanViewContent />
      </PlanProvider>
    </>
  );
}

export function NewPlanViewContent(): React.ReactElement {
  const plan = usePlan();
  const dispatch = usePlanDispatch();
  const navigate = useNavigate();
  const newAdSets = plan.newAdSets;

  const [remainingWalletBalance, setRemainingWalletBalance] = useState<number>(0);
  const [saveEnabled, setSaveEnabled] = useState(false);
  const { loading: savingPlan, createAndSavePlan, createAndProposePlan } = useNewPlanActions();
  const organisation = useUserOrganisation();
  const brands = useBrands();
  const retailers = useRetailers();
  const { wallets, isLoading: isWalletsLoading } = useBrandWallets(
    organisation.is_retailer,
    plan.retailerId,
    plan.brandId
  );
  const cancelPlan = () => navigate('/plans-ad-sets');


  const updatePlan = (updates: Partial<PlanData>) => {
    dispatch({ type: 'updatePlan', plan: { ...updates } });
  };

  const selectBrand = (brand: Brand) =>
    updatePlan({ supplier: brand, brandId: brand.id, wallet: null, walletId: null });
  const selectRetailer = (retailer: Organisation) => updatePlan({ owner: retailer, retailerId: retailer.id });
  const selectWallet = (wallet: Wallet) => updatePlan({ wallet, walletId: wallet.id });
  const selectDiscount = (discount: unknown) => updatePlan({ discount });
  const updatePlanName = (name: string) => updatePlan({ name });

  useEffect(() => {
    if (plan.walletId) {
      const wallet = wallets.find((wallet: Wallet) => wallet.id === plan.walletId);
      // need to calculate the remaining balance based on the plan value
      setRemainingWalletBalance(wallet?.available_balance ?? 0);
    }
  }, [plan.walletId]);

  useEffect(() => {
    const allAdSetsHaveBundleId = newAdSets.every((adSet) => adSet?.bundleIds && adSet.bundleIds?.length > 0);
    if (plan.brandId && plan.walletId && allAdSetsHaveBundleId) {
      setSaveEnabled(true);
    } else if (saveEnabled) {
      setSaveEnabled(false);
    }
  }, [plan, newAdSets]);

  const planValue = useMemo(() => {
    return calculateTotalBudget(newAdSets);
  }, [newAdSets]);

  return (
    <>
      <Helmet>
        <title>New plan - {process.env.REACT_APP_NAME}</title>
      </Helmet>

      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        my={2}
        sx={{
          minHeight: '64px',
        }}
      >
        <Box display='flex' sx={{ alignItems: 'center', gap: 2 }}>
          <Typography variant='h4' data-testid='pageHeading'>
            New plan
          </Typography>
          <EditableField initialValue={plan.name ?? 'Untitled'} onChange={updatePlanName} editable={true} />
        </Box>

        <Box gap={2} display='flex'>
          <Button
            variant='text'
            color='error'
            onClick={cancelPlan}
            startIcon={<DeleteIcon />}
            data-testid='cancel-plan'
            loading={savingPlan}
          >
            Cancel Plan
          </Button>
          <Button
            variant='text'
            disabled={!saveEnabled}
            startIcon={<SaveIcon />}
            onClick={() => createAndSavePlan(plan)}
            data-testid='save-plan'
            loading={savingPlan}
          >
            Save Draft
          </Button>
          <Button
            disabled={!saveEnabled}
            endIcon={<SendIcon />}
            onClick={() => createAndProposePlan(plan)}
            data-testid='propose-plan'
            loading={savingPlan}
          >
            Propose Plan
          </Button>
        </Box>
      </Box>
      <Box display='flex' justifyContent='space-between' my={2}>
        <Typography variant='h5'>
          Plan status <Chip label='Draft' />
        </Typography>
        <Stack direction='row' spacing={2}>
          {organisation.is_retailer ? (
            <Autocomplete
              data-testid='autocomplete-brand'
              onChange={(_, newValue: Brand) => selectBrand(newValue)}
              disableClearable
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='standard'
                  label='Brand'
                  sx={{ minWidth: '225px' }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              getOptionKey={(option) => option.id}
              getOptionLabel={(option) => option.name}
              options={brands}
              value={brands.find((brand) => brand.id === plan.brandId) ?? undefined}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
          ) : (
            <Autocomplete
              data-testid='autocomplete-retailer'
              onChange={(_, newValue: Organisation) => selectRetailer(newValue)}
              disableClearable
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='standard'
                  label='Retailer'
                  sx={{ minWidth: '225px' }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              getOptionKey={(option) => option.id}
              getOptionLabel={(option) => option.name}
              options={retailers}
              value={retailers.find((retailer) => retailer.id === plan.retailerId) ?? undefined}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
          )}
          <Autocomplete
            data-testid='autocomplete-wallet'
            onChange={(_, newValue: Wallet) => selectWallet(newValue)}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                variant='standard'
                label='Wallet'
                sx={{ minWidth: '225px' }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
            getOptionKey={(option) => option?.id}
            getOptionLabel={(option) => option?.name}
            options={wallets}
            loading={isWalletsLoading}
            size='medium'
            value={wallets.find((wallet: Wallet) => wallet?.id === plan.walletId) ?? null}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
          />
          {organisation.is_retailer ? (
            <Autocomplete
              onChange={(_, newValue) => selectDiscount(newValue)}
              disableClearable
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='standard'
                  label='Discount'
                  sx={{ minWidth: '225px' }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              getOptionKey={(option) => option.id}
              getOptionLabel={(option) => option.name}
              options={brands}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
          ) : //TODO - show discount here as readonly when that is done
            null}
          <Box display='flex' flexDirection='row' gap={2}>
            <RenderCurrency amount={planValue} label='Plan value' />
            <RenderCurrency amount={remainingWalletBalance} label='Remaining wallet balance' />
          </Box>
        </Stack>
      </Box>
      {plan.retailerId ? (
        <Alert
          variant='filled'
          severity='info'
          sx={{
            marginBottom: '2rem',
            marginTop: '2rem',
          }}
        >
          Schedule, media type and placement are the minimum inputs required to request an ad set.
        </Alert>
      ) : (
        <Alert
          variant='filled'
          severity='error'
          sx={{
            marginBottom: '2rem',
            marginTop: '2rem',
          }}
        >
          Select a Retailer to being building your plan.
        </Alert>
      )}
      <NewPlanAdSets viewMode={ViewMode.NEW} />
    </>
  );
}
