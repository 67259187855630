import React, { useState } from 'react';
import moment from 'moment';
import { Form, InputGroup, Popover, OverlayTrigger } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Datetime from 'react-datetime';
import { selectSession } from '../../session/sessionSlice';
import Errors from '../../order_form/components/Errors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

interface TimeSelectProps {
  controlId: string;
  label?: string;
  value: string;
  errors: Array<string> | undefined;
  onChange: (controldId: string, value: string) => void;
  popoverText?: string | null;
  placeholder?: string | '';
}
const TimeSelect = ({
  controlId,
  label,
  placeholder,
  value,
  errors = [],
  onChange = () => { /* do nothing */ },
  popoverText = null,
}: TimeSelectProps) => {
  const session = useSelector(selectSession);
  const [selectedTime, setSelectedTime] = useState(value);

  return (
    <Form.Group controlId={controlId}>
      {label && (
        <Form.Label>
          {label}
          {popoverText && (
            <OverlayTrigger
              placement='right'
              trigger='click'
              overlay={
                <Popover id={controlId}>
                  <Popover.Content style={{ textAlign: 'justify' }}>{popoverText}</Popover.Content>
                </Popover>
              }
            >
              <a style={{ marginLeft: '5px' }}>
                <FontAwesomeIcon icon={faInfoCircle} />
              </a>
            </OverlayTrigger>
          )}
        </Form.Label>
      )}
      <InputGroup>
        <Datetime
          value={selectedTime}
          inputProps={{
            id: controlId,
            style: session?.theme?.components?.form_control,
            placeholder,
          }}
          dateFormat={false}
          timeFormat={'hh:mm A'}
          onChange={(v) => {
            setSelectedTime(moment(v).format('hh:mm A'));
            onChange(controlId, moment(v).format('hh:mm A'));
          }}
          timeConstraints={{
            minutes: {
              step: 15,
              min: 0,
              max: 59,
            },
          }}
        />
      </InputGroup>
      <Form.Control.Feedback type='invalid' className={errors.length ? 'd-block' : ''}>
        <Errors errors={errors} />
      </Form.Control.Feedback>
    </Form.Group>
  );
};
export default TimeSelect;
