import { useEffect } from 'react';
import { useGetBundles } from 'v2/lib/api/ad-management';
import { usePlanDispatch, AdSetData } from './PlanContext';

export const useAdSetBundle = (adSet: AdSetData, retailerId: string | null) => {
  const dispatch = usePlanDispatch();

  const {
    data: bundlesData,
    isSuccess: bundlesIsSuccess,
    isLoading,
  } = useGetBundles(
    {
      organisation_id: retailerId || '',
      media_space_id: adSet.mediaSpace?.id,
      calendar_period_id: adSet.schedule?.id,
      location_id: adSet.placement?.id,
    },
    {
      query: {
        queryKey: ['bundles', retailerId, adSet.mediaSpace?.id, adSet.schedule?.id, adSet.placement?.id],
        enabled: !!(
          retailerId &&
          adSet.mediaSpace?.id &&
          adSet.schedule?.id &&
          adSet.placement?.id &&
          !adSet.bundleIds?.length
        ),
      },
    }
  );

  useEffect(() => {
    const bundle = bundlesData?.data && bundlesData?.data?.length > 0 ? bundlesData?.data[0] : null;
    const isNewBundle = adSet.bundleIds?.at(0) !== bundle?.id;
    if (isNewBundle) {
      const updatedAdSet: AdSetData = { ...adSet, bundleIds: bundle?.id ? [bundle.id] : [] };
      dispatch({ type: 'updateAdSet', adSet: updatedAdSet });
    }
  }, [bundlesIsSuccess, bundlesData, adSet, dispatch]);

  return { isLoading };
};
