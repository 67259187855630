import React from 'react';
import { CalendarPeriod, useGetCalendarPeriods } from '../../lib/api/ad-management';
import { PlacementList } from '../../components/PlacementList/PlacementList';
import {
  GridRenderCellParams,
  TextField,
  Box,
  InputAdornment,
  GridActionsCellItem,
  CircularProgress,
  DeleteIcon,
  EditIcon,
} from '@zitcha/component-library';
import { Location } from '../../lib/api/ad-management';
import { Autocomplete, AutocompleteRenderInputParams } from '@mui/material'; //todo export AutocompleteRenderInputParams and isOptionEqualToValue from zitcha/component-library
import Popover from '@mui/material/Popover'; //todo export Popover from zitcha/component-library
import { formatDisplayDate } from 'helpers/DateHelpers';
import { RenderCurrency } from 'v2/components/RenderCurrency/RenderCurrency';
import { useSafeGetBundle } from 'v2/lib/hooks/useGetBundle';
import { MediaSpace, useGetMediaSpaces } from 'v2/lib/api/inventory';
import { AdSetData, PlanData } from './PlanContext';
import { AdSetBundleWrapper } from './AdSetBundleWrapper';

const Flexer = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      display='flex'
      sx={{
        alignItems: 'flex-start',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        padding: '8px',
      }}
    >
      {children}
    </Box>
  );
};
export const ScheduleCell = ({
  updateAdSet,
  retailerId,
  readOnly = false,
  ...props
}: {
  updateAdSet: (adSet: AdSetData) => void;
  retailerId: string;
  readOnly?: boolean;
} & GridRenderCellParams) => {
  const { data: calendarPeriodsData, isLoading: calendarPeriodsIsLoading } = useGetCalendarPeriods({
    organisation_id: retailerId,
  });
  const { row } = props;
  const value = row.schedule;

  const handleValueChange = (newValue: CalendarPeriod | undefined) => {
    updateAdSet({ ...row, schedule: newValue });
  };

  return (
    <Flexer>
      <Autocomplete
        size='medium'
        fullWidth
        getOptionLabel={(data) =>
          `${data?.name} (${formatDisplayDate(data?.startAt)} - ${formatDisplayDate(data?.endAt)})`
        }
        renderOption={(props, data) => (
          <li {...props} key={data.id}>
            {`${data?.name} (${formatDisplayDate(data?.startAt)} - ${formatDisplayDate(data?.endAt)})`}
          </li>
        )}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        renderInput={(params: AutocompleteRenderInputParams) => <TextField {...params} variant='standard' />}
        value={value ?? null}
        options={calendarPeriodsData?.data ?? []}
        loading={calendarPeriodsIsLoading}
        onChange={(_, newValue) => handleValueChange(newValue)}
        readOnly={readOnly}
      />
    </Flexer>
  );
};

// media type
export const MediaSpaceCell = ({
  updateAdSet,
  retailerId,
  readOnly = false,
  ...props
}: {
  updateAdSet: (adSet: AdSetData) => void;
  retailerId: string;
  readOnly?: boolean;
} & GridRenderCellParams) => {
  const { row } = props;
  const value = row.mediaSpace;

  const { data: mediaTypesData, isLoading: isLoadingMediaTypes } = useGetMediaSpaces({ organisation_id: retailerId });

  const handleValueChange = (newValue: MediaSpace | undefined) => {
    updateAdSet({ ...row, mediaSpace: newValue });
  };

  return (
    <Flexer>
      <Autocomplete
        fullWidth
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params: AutocompleteRenderInputParams) => <TextField {...params} variant='standard' />}
        value={value ?? null}
        options={mediaTypesData?.data ?? []}
        loading={isLoadingMediaTypes}
        onChange={(_, newValue) => handleValueChange(newValue)}
        readOnly={readOnly}
      />
    </Flexer>
  );
};

// placement
export const PlacementCell = ({
  updateAdSet,
  retailerId,
  ...props
}: {
  updateAdSet: (adSet: AdSetData) => void;
  retailerId: string;
} & GridRenderCellParams) => {
  const { row } = props;
  const value = row.placement;

  const handleValueChange = (newValue: Location | undefined) => {
    return updateAdSet({ ...row, placement: newValue });
  };
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleChange = (location: Location | undefined) => {
    handleValueChange(location);
    handleClose();
  };

  const textValue = value ? `${value?.type} : ${value?.name}` : '';

  return (
    <>
      <Flexer>
        <TextField
          id='placement'
          value={textValue}
          fullWidth
          variant='standard'
          onClick={handleClick}
          autoComplete='off'
        />
      </Flexer>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <PlacementList retailerId={retailerId} onSelectPlacement={handleChange} />
      </Popover>
    </>
  );
};

// Rate
export const RateCell = (props: GridRenderCellParams) => {
  const { data: bundleData } = useSafeGetBundle(props?.row?.bundleIds?.[0]);
  const rate = bundleData?.data.price || '-';
  return (
    <Flexer>
      <RenderCurrency amount={rate} />
    </Flexer>
  );
};

// Budget
export const BudgetCell = (props: GridRenderCellParams<AdSetData, number>) => {
  const { data: bundleData } = useSafeGetBundle(props?.row?.bundleIds?.[0]);
  const rate = bundleData?.data.price;
  const discount = bundleData?.data?.discount || 0;
  const budget = rate ? rate * (1 - discount / 100) : 0;
  return (
    <Flexer>
      <RenderCurrency amount={budget} />
    </Flexer>
  );
};

// discount

export const DiscountCell = (props: GridRenderCellParams) => {
  const { data: bundleData } = useSafeGetBundle(props?.row?.bundleIds?.[0]);
  const discount = bundleData?.data?.discount || 0;
  return (
    <Flexer>
      <TextField
        fullWidth
        variant='standard'
        InputProps={{
          startAdornment: <InputAdornment position='start'>%</InputAdornment>,
        }}
        value={discount}
        disabled
      />
    </Flexer>
  );
};

// sku codes
export const SkuCodesCell = ({
  updateAdSet,
  ...props
}: GridRenderCellParams<AdSetData, string> & {
  updateAdSet: (adSet: AdSetData) => void;
}) => {
  const { value, row } = props;

  const handleValueChange = (newValue: string | undefined) => {
    updateAdSet({ ...row, skuCodes: newValue });
  };

  return (
    <Flexer>
      <TextField
        fullWidth
        variant='standard'
        value={value}
        onChange={(event) => handleValueChange(event.target.value)}
      />
    </Flexer>
  );
};

export const ActionCell = ({
  params,
  plan,
  setEditAdSet,
  removeAdSet,
}: {
  params: GridRenderCellParams<AdSetData>;
  plan: PlanData;
  setEditAdSet: React.Dispatch<React.SetStateAction<AdSetData | undefined>>;
  removeAdSet: (id: string) => void;
}) => (
  <AdSetBundleWrapper adSet={params.row} retailerId={plan.retailerId}>
    {(isLoading) =>
      isLoading ? (
        <CircularProgress size={24} />
      ) : (
        <>
          <GridActionsCellItem
            key='edit'
            icon={<EditIcon />}
            label='Edit'
            aria-label='edit ad set'
            className='textPrimary'
            onClick={() => {
              setEditAdSet(params.row);
            }}
            color='inherit'
          />
          <GridActionsCellItem
            key='delete'
            icon={<DeleteIcon />}
            label='Delete'
            aria-label='delete ad set'
            onClick={() => removeAdSet(params.row.id)}
            color='inherit'
          />
        </>
      )
    }
  </AdSetBundleWrapper>
);
