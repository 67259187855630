export function isLightColor(hexColor: string) {
    if (!hexColor) return false;
    const color = hexColor?.charAt(0) === '#' ? hexColor.substring(1, 7) : hexColor;
    const r = parseInt(color.substring(0, 2), 16); // Red color value
    const g = parseInt(color.substring(2, 4), 16); // Green color value
    const b = parseInt(color.substring(4, 6), 16); // Blue color value
  
    // Calculate the luminance of the color
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  
    // If the luminance is greater than 0.5, it's a light color
    if (luminance > 0.5) {
      return true;
    } else {
      return false;
    }
  }