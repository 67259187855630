import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faRefresh, faWarning } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatDisplayDateWithTime } from 'helpers/DateHelpers';
import React from 'react';
import { Alert, Badge, Button } from 'react-bootstrap';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.12rem;
  flex-grow: 1;
  font-size: 14px;
  .alert svg {
    margin-right: 8px;
  }
  .badge {
    display: flex;
    height: 1.5625rem;
    padding: 0.25rem 0.5rem;
    align-items: center;
    gap: 0.625rem;
  }
  .badge-not-eligible {
    color: #000000 !important;
    background-color: rgb(239, 118, 122) !important;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
  }

  .badge-eligible {
    background-color: #87f5c0;
  }
  .alert {
    margin: 0;
  }
  .alert-not-eligible {
    color: #000000;
    background-color: #ef767a;

    a {
      color: #000000;
      text-decoration: underline;
    }

    svg {
      margin-right: 8px;
    }
  }
`;

const Label = styled.span`
  font-size: 1rem;
  font-weight: 700;
  margin-right: 6px;
`;

const NotEligibleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 22px;
`;

const EligibleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const LastCheckLabel = styled.span`
  color: #777785;
  font-size: 1rem;
  font-weight: 400;
  margin-left: 10px;
`;
type EligibilityProps = {
  isChecking?: boolean;
  isEligible: boolean;
  lastStatusCheck: Date | null;
  notEligibleReason: string;
} & (withEnableRecheck | withoutEnableRecheck);

interface withEnableRecheck {
  enableRecheck: true;
  onClickCheckEligibility: () => void;
}

interface withoutEnableRecheck {
  enableRecheck?: false;
  onClickCheckEligibility?: never;
}

export const Eligibility = ({
  isChecking,
  isEligible,
  lastStatusCheck,
  notEligibleReason,
  onClickCheckEligibility,
  enableRecheck = true,
}: EligibilityProps) => {
  const renderEligible = () => {
    return (
      <EligibleContainer>
        <Label>Eligibility Status: </Label>
        <Badge variant='eligible'>Eligible</Badge>
        <LastCheckLabel>{`Last check made on ${formatDisplayDateWithTime(lastStatusCheck)}`}</LastCheckLabel>
      </EligibleContainer>
    );
  };

  const renderNotEligible = () => {
    return (
      <>
        <NotEligibleContainer>
          <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Label>Eligibility Status:</Label>
              <Badge variant='not-eligible'>Not Eligible</Badge>
              <LastCheckLabel>{`Last check made on ${formatDisplayDateWithTime(lastStatusCheck)}`}</LastCheckLabel>
            </div>
          </div>
        </NotEligibleContainer>
        {notEligibleReason && (
          <Alert variant='not-eligible'>
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <span>
                <FontAwesomeIcon icon={faWarning as IconProp} />
              </span>
              <span>
                {notEligibleReason}
                <span style={{ paddingLeft: '6px' }}>
                  Please reach out to <a href='mailto://support@zitcha.com'>Zitcha Support</a> for further assistance.
                </span>
              </span>
            </span>
          </Alert>
        )}
        {enableRecheck && (
          <div>
            <Button
              disabled={isChecking}
              onClick={() => {
                onClickCheckEligibility?.();
              }}
              variant='outline-primary'
            >
              {isChecking && (
                <>
                  <FontAwesomeIcon icon={faRefresh as IconProp} spin />
                  <span> </span>
                </>
              )}
              Re-check eligibility
            </Button>
          </div>
        )}
      </>
    );
  };

  return <Container>{isEligible ? renderEligible() : renderNotEligible()}</Container>;
};
