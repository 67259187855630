import React from 'react';
import styled from 'styled-components';

const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const GridRow = styled.div`
  display: flex;
`;

const GridCell = styled.div<{ isBlack: boolean }>`
  width: 0.6rem;
  height: 0.6rem;
  background-color: ${({isBlack}) => (isBlack ? '#000' : '#fff')};
`;

const Sadge: React.FC = () => {
    const grid: Array<Array<number>> = [
        [1, 0, 0, 1],
        [0, 0, 0, 0],
        [0, 1, 1, 0],
        [1, 0, 0, 1],
    ];

    return (
        <GridContainer>
            {grid.map((row, rowIndex) => (
                <GridRow key={rowIndex}>
                    {row.map((cell, cellIndex) => (
                        <GridCell
                            key={cellIndex}
                            isBlack={cell === 1}
                        />
                    ))}
                </GridRow>
            ))}
        </GridContainer>
    );
};

export default Sadge;
