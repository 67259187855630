import React from 'react';
import { Box, Typography } from '@zitcha/component-library';
import UploadIcon from '@mui/icons-material/Upload';

interface AccordionSummaryDetailsProps {
  adIndex: number;
  mediaSpaceName?: string;
}

export const AccordionSummaryDetails: React.FC<AccordionSummaryDetailsProps> = ({ adIndex, mediaSpaceName }) => {
  return (
    <Box display='flex' alignItems='center' gap={1} width='100%'>
      <Typography variant='subtitle1' sx={{ flexGrow: 0 }}>{`Ad ${adIndex + 1}`}</Typography>
      <Box display='flex' alignItems='center' gap={1}>
        <Typography variant='body2' sx={{ flexGrow: 1 }}>
          {mediaSpaceName}
        </Typography>
        <UploadIcon sx={{ color: 'GrayText', flexShrink: 0 }} />
      </Box>
    </Box>
  );
};
