import React, { ReactNode, useEffect, useMemo } from 'react';
import { Control, Controller, ControllerRenderProps, useWatch } from 'react-hook-form';
import {
  Box,
  Stack,
  TextField,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  InfoIcon,
  Autocomplete,
} from '@zitcha/component-library';
import { AdSet, useGetBundle, useGetBundles, useGetCalendarPeriods } from 'v2/lib/api/ad-management';
import Placement from './Placement';
import SkuCodes from './SkuCodes';
import { formatDisplayDate } from 'helpers/DateHelpers';
import { RenderCurrency } from '../RenderCurrency/RenderCurrency';
import { MediaSpace, useGetMediaSpaces } from 'v2/lib/api/inventory';
import { AdSetFormValues } from './AdSetModal';

type DetailsTableProps = {
  control: Control<AdSetFormValues>;
  setValue: any; // todo - type this more specifically
  existingAdSet?: AdSet;
  retailerId: string
};

export const DetailsTable: React.FC<DetailsTableProps> = ({
  control,
  setValue,
  existingAdSet,
  retailerId
}) => {
  const bundleId = existingAdSet?.bundleIds?.[0] || '';
  const { data: existing_bundle, isPending, isSuccess: bundleFound } = useGetBundle(bundleId);

  const { data: mediaSpacesData, isLoading: isLoadingMediaSpaces } = useGetMediaSpaces({ organisation_id: retailerId });
  const mediaSpaces: Array<MediaSpace> = useMemo(() => mediaSpacesData?.data || [], [mediaSpacesData]);

  const { data: schedules, isLoading: isLoadingSchedules } = useGetCalendarPeriods({ organisation_id: retailerId });

  const watchedValues = useWatch({
    control,
    name: ['schedule', 'mediaSpace', 'placement'],
  });

  const [schedule, mediaSpace, placement] = watchedValues;

  const {
    data: bundle,
    isSuccess: isBundleSuccess,
    isLoading: isLoadingBundle,
  } = useGetBundles(
    {
      organisation_id: retailerId,
      media_space_id: mediaSpace?.id,
      calendar_period_id: schedule?.id,
      location_id: placement?.id,
    },
    {
      query: {
        queryKey: ['bundles', retailerId, mediaSpace?.id, schedule?.id, placement?.id],
        enabled: !!(mediaSpace?.id && schedule?.id && placement?.id),
      },
    }
  );

  useEffect(() => {
    if (!isPending && !schedule && existing_bundle?.data?.calendarPeriod) {
      setValue('schedule', existing_bundle.data.calendarPeriod);
    }
    if (!isPending && !placement && existing_bundle?.data?.locations?.[0]) {
      setValue('placement', existing_bundle.data.locations[0]);
    }
    if (!isPending && !mediaSpace && existing_bundle?.data?.mediaSpace) {
      const preselectedMediaSpace = mediaSpaces.find((adType) => adType?.id === existing_bundle.data.mediaSpace.id);
      if (preselectedMediaSpace) {
        setValue('mediaSpace', preselectedMediaSpace);
      }
    }
  }, [existing_bundle, isPending, bundleFound, mediaSpaces, setValue]);

  useEffect(() => {
    if (isBundleSuccess && bundle?.data?.[0]) {
      setValue('rate', bundle.data[0].price ?? 0);
      setValue('discount', 0); // not implemented yet
      setValue('budget', bundle.data[0].price ?? 0);
      setValue('bundleId', bundle.data[0].id ?? null);
    }
  }, [isBundleSuccess, bundle, setValue]);

  return (
    <Stack direction='column' spacing={2}>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label='table'>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: '25%' }}>Schedule</TableCell>
                <TableCell sx={{ width: '25%' }}>Media Type</TableCell>
                <TableCell sx={{ width: '25%' }}>Placement</TableCell>
                <TableCell sx={{ width: '25%' }}>SKU code(s)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <Row>
                <TableCell>
                  <Controller
                    name='schedule'
                    control={control}
                    render={({ field }: {field: ControllerRenderProps}) => {
                      const {ref: _ref, ...rest} = { ...field }
                      return (
                      <Autocomplete
                        {...rest}
                        size='medium'
                        fullWidth
                        getOptionLabel={(data) =>
                          `${data?.name} (${formatDisplayDate(data?.startAt)} - ${formatDisplayDate(data?.endAt)})`
                        }
                        isOptionEqualToValue={(option, value) => option?.id === value?.id}
                        renderInput={(params: any) => <TextField {...params} variant='standard' />}
                        options={schedules?.data ?? []}
                        loading={isLoadingSchedules}
                        onChange={(_, newValue) => field.onChange(newValue)}
                      />
                    )}}
                  />
                </TableCell>
                <TableCell>
                  <Controller
                    name='mediaSpace'
                    control={control}
                    render={({ field }: {field: ControllerRenderProps}) => {
                      const {ref, ...rest } = field
                      return (
                      <Autocomplete
                        {...rest}
                        fullWidth
                        getOptionLabel={(data) => data?.name}
                        renderInput={(params: any) => <TextField {...params} variant='standard' />}
                        isOptionEqualToValue={(option, value) => option?.id === value?.id}
                        options={mediaSpaces}
                        loading={isLoadingMediaSpaces}
                        onChange={(_, newValue) => field.onChange(newValue)}
                      />
                    )}}
                  />
                </TableCell>
                <TableCell>
                  <Controller
                    name='placement'
                    control={control}
                    render={({ field }) => (
                      <Placement onPlacementChange={field.onChange} location={field.value} />
                    )}
                  />
                </TableCell>
                <TableCell>
                  <Controller
                    name='skuCodes'
                    control={control}
                    render={({ field }) => (
                      <SkuCodes
                        data={field.value}
                        onSkuCodesChange={(newSkuCodes) => {
                          field.onChange(newSkuCodes);
                        }}
                      />
                    )}
                  />
                </TableCell>
              </Row>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label='table'>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: '33.3%' }}>
                  Rate{' '}
                  <Tooltip title='Ad set price' placement='right-end'>
                    <InfoIcon color='primary' />
                  </Tooltip>
                </TableCell>
                <TableCell sx={{ width: '33.3%' }}>Discount</TableCell>
                <TableCell sx={{ width: '33.3%' }}>
                  Budget{' '}
                  <Tooltip title='ad set price minus the discount' placement='right-end'>
                    <InfoIcon color='primary' />
                  </Tooltip>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <Row>
                <TableCell>
                  <Controller
                    name='rate'
                    control={control}
                    render={({ field }) => <RenderCurrency amount={field.value} />}
                  />
                </TableCell>
                <TableCell>
                  <Controller
                    name='discount'
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className='twd-w-full'
                        label=''
                        variant='standard'
                        disabled={true}
                      />
                    )}
                  />
                </TableCell>
                <TableCell>
                  <Controller
                    name='budget'
                    control={control}
                    render={({ field }) => <RenderCurrency amount={field.value} />}
                  />
                </TableCell>
              </Row>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Stack>
  );
};

export default DetailsTable;

const Row = ({ children }: { children: ReactNode }) => {
  return (
    <TableRow
      sx={{
        border: 'none',
        borderTop: 'solid rgba(0, 0, 0, 0.12)',
        borderBottom: 'solid rgba(0, 0, 0, 0.12)',
        borderWidth: 'thin',
        backgroundColor: '#FBFBFB',
      }}
    >
      {children}
    </TableRow>
  );
};
