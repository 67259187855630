import React, { FC, PropsWithChildren, useReducer } from 'react';
import { PlanContext, PlanDispatchContext, planReducer, PlanData } from './PlanContext';

interface PlanProviderProps extends PropsWithChildren {
  data: PlanData;
}

export const PlanProvider: FC<PlanProviderProps> = ({ children, data }) => {
  const [plan, dispatch] = useReducer(planReducer, data);

  return (
    <PlanContext.Provider value={plan}>
      <PlanDispatchContext.Provider value={dispatch}>{children}</PlanDispatchContext.Provider>
    </PlanContext.Provider>
  );
};
