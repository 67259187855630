/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * Zitcha Ad Management API
 * This is the API endpoint to manage ads.
 * OpenAPI spec version: 1.0.0
 */

export type PlanStatus = (typeof PlanStatus)[keyof typeof PlanStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PlanStatus = {
  planning: 'planning',
  proposed: 'proposed',
  agreed: 'agreed',
  archived: 'archived',
} as const;
