import React from "react";
import propTypes from "prop-types";
import styled from "styled-components";
import { BootstrapDataTable } from "../../../common/data/BootstrapDataTable";

const StyledTableWrapper = styled.div`
  .table thead th {
    border: none;
    background: #ededef;
    vertical-align: middle;
  }
  .table tbody td {
    border-right: none;
    border-left: none;
    vertical-align: middle;
  }

  ${({ hideHeaders }) =>
    hideHeaders &&
    `
    .table thead {
      display: none;
    }
  `}
`;

const DataTable = ({
  data,
  columns,
  noDataIndication,
  hideHeaders = false,
  alwaysShowPagination = false,
  rowEvents = {},
}) => {


  return (
    <StyledTableWrapper hideHeaders={hideHeaders}>
      <div>
        <BootstrapDataTable
          keyField='id'
          data={data}
          columns={columns}
          rowEvents={rowEvents}
          hover
          noDataIndication={noDataIndication}
          paginationEnable={alwaysShowPagination}
        />
      </div>
    </StyledTableWrapper>
  );
};

DataTable.propTypes = {
  data: propTypes.array.isRequired,
  columns: propTypes.array.isRequired,
  noDataIndication: propTypes.func,
  hideHeaders: propTypes.bool,
  rowEvents: propTypes.object,
  alwaysShowPagination: propTypes.bool,
};

export default DataTable;
