import PropTypes from "prop-types";
import React, { useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { useSelector } from "react-redux";
import { selectSession } from "../../session/sessionSlice";
import Errors from "../../order_form/components/Errors";

const MinimumReviewTime = ({
  controlId,
  label,
  placeholder,
  value,
  errors = [],
  required = false,
  onChange = () => { },
}) => {
  const session = useSelector(selectSession);
  const [state, setState] = useState({
    value: value,
    typingTimeout: 0,
  });

  return (
    <Form.Group controlId={controlId}>
      {label && <Form.Label>{label}</Form.Label>}
      <InputGroup>
        <Form.Control
          type='text'
          value={state.value}
          placeholder={placeholder}
          style={session?.theme?.components?.form_control}
          required={required}
          onChange={(e) => {
            if (state.typingTimeout) {
              clearTimeout(state.typingTimeout);
            }
            let reviewTime;
            if (e.target.value === "") {
              reviewTime = undefined;
            } else {
              reviewTime = e.target.value;
            }
            setState({
              value: reviewTime,
              typingTimeout: setTimeout(() => {
                onChange(controlId, reviewTime);
              }, 1000),
            });
          }}
        />
      </InputGroup>
      <Form.Control.Feedback
        type='invalid'
        className={errors.length && "d-block"}
      >
        <Errors errors={errors} />
      </Form.Control.Feedback>
    </Form.Group>
  );
};

MinimumReviewTime.propTypes = {
  controlId: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default MinimumReviewTime;
