import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { faShieldAlt } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Container, Form, InputGroup, Modal, Row, Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import $style from './switch_active_org_modal.module.scss';
import { selectSession } from '../features/session/sessionSlice';
import { switchOrganisation } from '../lib/api';

const applyFilters = (organisation, filter) => {
  return organisation.filter(
    (org) => !filter.searchTerm || org.name.toLowerCase().includes(filter.searchTerm.toLowerCase())
  );
};

const OrganisationCard = ({ name, logo, isAdmin, onClick }) => {
  return (
    <Card onClick={onClick} className={$style.selectionCard}>
      <div className={$style.selectionCardAdmin}>
        <FontAwesomeIcon
          icon={faShieldAlt}
          title={isAdmin ? 'You are an admin' : 'You are not an admin'}
          className={isAdmin ? 'text-primary' : 'text-muted'}
          size='1x'
        />
      </div>
      {logo ? (
        <Card.Img src={logo} alt={`${name} logo`} className={$style.selectionCardLogo} variant='top' />
      ) : (
        <div className={$style.selectionCardDiv}></div>
      )}

      <Card.Body class='p-2'>
        <h6>{name}</h6>
      </Card.Body>
    </Card>
  );
};

const SwitchActiveOrgModal = ({ show = false, onHide = () => {} }) => {
  const session = useSelector(selectSession);
  const userOrganisations = session.user_organisations || [];
  const [filters, setFilters] = useState({
    searchTerm: null,
  });
  const [organisations, setOrganisations] = useState(userOrganisations);
  const [filteredOrganisations, setFilteredOrganisations] = useState([]);

  useEffect(() => {
    // isUserAdminForOrg(3);
    setOrganisations(_.orderBy(userOrganisations, 'name', 'asc'));
  }, [session]);

  useEffect(() => {
    setFilteredOrganisations(applyFilters(organisations, filters));
  }, [organisations, filters]);

  const handleFilterChange = (filterType) => setFilters({ ...filters, ...filterType });

  const handleOrganisationSwitch = useCallback(async (id) => {
    try {
      await switchOrganisation(id);
      window.location = window.location.origin;
    } catch (e) {
      const message = e?.response?.data?.message;
      if (message && !!message.length) {
        toast.error(message);
      }
    }
  }, userOrganisations);

  return (
    <Modal dialogClassName={$style.modal65w} show={show} onHide={onHide}>
      <Modal.Header closeButton>Select an organisation</Modal.Header>
      <Modal.Body>
        <Container className='container-fluid'>
          {organisations && organisations.length > 5 && (
            <div>
              <Form.Group>
                <Form.Label>Search</Form.Label>
                <InputGroup>
                  <Form.Control
                    type='text'
                    value={filters.searchTerm}
                    style={session?.theme?.components?.form_control}
                    onChange={(e) => handleFilterChange({ searchTerm: e.target.value })}
                  />
                </InputGroup>
              </Form.Group>
            </div>
          )}
          {filteredOrganisations.length > 0 && (
            <div className='my-3'>
              <Row className='center-block'>
                {filteredOrganisations.map((org) => {
                  return (
                    <Col xs={12} md={4} key={org.id} className='center-block my-2'>
                      <OrganisationCard
                        name={org.name}
                        logo={org.logo}
                        isAdmin={org.is_admin}
                        onClick={() => handleOrganisationSwitch(org.id)}
                      />
                    </Col>
                  );
                })}
              </Row>
            </div>
          )}
        </Container>
      </Modal.Body>
    </Modal>
  );
};

SwitchActiveOrgModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
};

export default SwitchActiveOrgModal;
