import React from 'react'

const Footer = () => (
    <footer className='sticky-footer mt-5 mb-2'>
        <div className='container my-auto'>
            <div className='text-center my-auto copyright'>
                <span>Copyright © {process.env.REACT_APP_NAME} {new Date().getFullYear()}</span>
            </div>
        </div>
    </footer>
)

export default Footer
