import React, { useState } from 'react';
import Footer from './Footer';
import Sidebar from './Sidebar';
import Topbar from './Topbar';
import styled from 'styled-components';

const Chrome = ({ children, overflowY = 'scroll' }) => {
  const [toggled, setToggled] = useState(window.innerWidth <= 760);
  const toggleSidebar = () => setToggled(!toggled);
  if (window.screen.width >= 768 && !toggled) {
    setToggled(true);
  }

  return (
    <div className='d-flex flex-column w-100'>
      <Topbar toggleSidebar={toggleSidebar} />
      <Container toggled={toggled}>
        <Sidebar visible={toggled} />
        <div className='w-100' style={{ overflowY: overflowY }}>
          <main className='overflow-auto' role='main'>
            {children}
          </main>
          <Footer />
        </div>
      </Container>
    </div>
  );
};

export default Chrome;

const Container = styled.div`
  display: grid;
  grid-template-columns: ${(props) => (props.toggled ? '4rem auto' : 'auto')};
  height: calc(100vh - 3.5rem);
  overflow: hidden;
`;
