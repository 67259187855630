import React, { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import CheckboxOption from '../../features/order_form/components/CheckboxOption';
import styled from 'styled-components';

export const MetaManagedPartnerAdSettings = ({
  control,
  adSettings,
  setValue,
}: {
  control: any;
  adSettings: any;
  setValue: any;
}) => {
  type Handler = 'retailer' | 'supplier';
  const [value, setValues] = useState<Array<Handler>>(adSettings?.page_handlers || []);

  useEffect(() => {
    setValue('page_handlers', value);
  }, [value, control]);

  return (
    <Col md={12} className='my-4'>
      <h5>Meta Managed Partner Ad Settings</h5>
      <p>This can be overridden on a specific Supplier’s settings page.</p>
      <StyledSubHeading>Default page(s) to display ads.</StyledSubHeading>
      <CheckboxContainer>
        <CheckboxOption
          value='retailer'
          checked={value.includes('retailer')}
          onChange={() => {
            if (value.includes('retailer')) {
              setValues(value.filter((item) => item !== 'retailer'));
            } else {
              setValues([...value, 'retailer']);
            }
          }}
          label='Retailer Page'
        />
        <CheckboxOption
          value='supplier'
          checked={value.includes('supplier')}
          onChange={() => {
            if (value.includes('supplier')) {
              setValues(value.filter((item) => item !== 'supplier'));
            } else {
              setValues([...value, 'supplier']);
            }
          }}
          label='Supplier Page'
        />
      </CheckboxContainer>
    </Col>
  );
};

const CheckboxContainer = styled.div`
  display: flex;
  gap: 12px;
`;
const StyledSubHeading = styled.h5`
  color: #5a5c69;
  font-size: 16px;
  font-weight: 700;
  line-height: 140%;
`;
