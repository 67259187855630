import React from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import AdDateRange from '../components/AdDateRange';
import { selectOwnerOrganisation } from '../orderFormSlice';
import { useSelector } from 'react-redux';
import {useEarliestStartDateTime} from "../../../lib/hooks/useEarliestStartDateTime/useEarliestStartDateTime";
import moment from "moment/moment";

export const ScheduleTab = ({ readOnly, startReadOnly, ad, controlId, errors, handleChange, session }) => {
  const owner = useSelector(selectOwnerOrganisation);
  const retailerTimezone = owner?.settings?.['timezone'] ?? 'UTC';
  const earliestStartDateTime = useEarliestStartDateTime();

  return (
    <Container>
      <Row>
        <Col sm='12'>
          <Form.Label className='font-weight-bold'>Time zone</Form.Label>
          <p>{retailerTimezone}</p>
          <AdDateRange
            controlIdStartAt={`${controlId}.start_at`}
            controlIdStopAt={`${controlId}.stop_at`}
            readOnly={readOnly}
            startReadOnly={startReadOnly}
            required
            value={{
              start_datetime: ad.start_at,
              stop_datetime: ad.stop_at,
            }}
            earliestStartDate={moment(earliestStartDateTime.format('YYYY-MM-DD'))}
            errors={{
              start_datetime: errors.start_at,
              stop_datetime: errors.stop_at,
            }}
            onChange={({ start_datetime, stop_datetime }) => {
              handleChange({
                start_at: start_datetime,
                stop_at: stop_datetime,
              });
            }}
            dateTime={ad.platform !== 'google'}
          />
        </Col>
      </Row>
    </Container>
  );
};
