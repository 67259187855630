import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { Card, useAccordionToggle } from 'react-bootstrap';
interface CustomToggleProps {
  children: React.ReactNode;
  eventKey: string;
  panelIndex: string | null;
  setPanelIndex: (_index: string | null) => void;

}

const CustomToggle = ({ children, eventKey, panelIndex, setPanelIndex }:CustomToggleProps) => {
  const customOnClick = useAccordionToggle(eventKey, () => {
    setPanelIndex(eventKey === panelIndex ? null : eventKey);
  });

  const arrow =
    eventKey === panelIndex ? (
      <FontAwesomeIcon icon={faAngleUp} fixedWidth />
    ) : (
      <FontAwesomeIcon icon={faAngleDown} fixedWidth />
    );

  return (
    <Card.Header className='bg-white' onClick={customOnClick}>
      {children} <span className='float-right'>{arrow}</span>
    </Card.Header>
  );
};
export { CustomToggle };
