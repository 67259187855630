import axios from '../../axios';

export const getMPASupplierSettings = (supplierId) => {
  return axios.get(`/organisations/settings/supplier/${supplierId}`);
};

export const checkMPAEligibility = (supplierId) => {
  return axios.post(`/organisations/mpa/${supplierId}/eligibility`);
};

export const onBoardMPASupplier = (supplierId, fields) => {
  return axios.post(`/organisations/mpa/${supplierId}/on-board`, fields, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const getMpaProductMetrics = (orderAdId) => {
  return axios.get(`/insights/meta-mpa/product-metrics/${orderAdId}`);
};

export const getRetailerMPASettings = (retailerId) => {
  return axios.get(`/organisations/settings/retailer/${retailerId}`);
};

export const prepareSharePageData = (retailerId, redirectUrl) => {
  return axios.get(
    `/organisations/mpa/${retailerId}/oauth-share-pages?redirect_url=${encodeURIComponent(redirectUrl)}`
  );
};

export const getPageSharingStatus = ({ retailerId, stateKey }) => {
  return axios.get(`/organisations/mpa/${retailerId}/oauth-share-pages/${stateKey}`);
};
