import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { NavigationContext } from './Chrome';
import { Organisations } from './Organisations';
import {
  useTheme,
  LogoutIcon,
  CloseIcon,
  Box,
  Menu,
  SwitchAccountIcon,
  Typography,
  IconButton,
  Button,
} from '@zitcha/component-library';
const UserName = () => {
  const { user } = useContext(NavigationContext);
  const theme = useTheme();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  // TODO: Add generic type to the library IconButton
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box display='flex'>
        <Box
          sx={{
            textAlign: 'right',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography variant='body1'>
            {user?.first_name} {user?.last_name}
          </Typography>
          <Typography variant='caption'>{user?.active_organisation?.name}</Typography>
        </Box>
        <IconButton
          id='menu-button'
          aria-controls={open ? 'user-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          sx={{
            color: theme.palette.primary.contrastText,
            marginLeft: '8px',
          }}
          size='medium'
          onClick={handleClick}
        >
          <SwitchAccountIcon />
        </IconButton>
      </Box>
      <Menu
        id='user-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'menu-button',
        }}
        sx={{
          '.MuiMenu-list': {
            backgroundColor: '#F4F6F8',
            minWidth: '21rem',
            width: '100%',
            padding: '1em',
          },
        }}
      >
        <Box>
          <Box
            display='flex'
            sx={{
              justifyContent: 'flex-end',
            }}
          >
            <IconButton
              aria-label='close'
              onClick={handleClose}
              size='medium'
              sx={{
                color: 'grey',
                marginBottom: '1rem',
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
              alignItems: 'center',
            }}
          >
            <Typography variant='caption'>Viewing as {user?.active_organisation?.name}</Typography>
            <Typography variant='h6'>Hi,{user?.first_name}!</Typography>
          </Box>
          <Organisations />
          <Button
            variant='text'
            sx={{
              width: '100%',
              marginTop: '1rem',
              textTransform: 'none',
              padding: '.65rem 1.375rem',
              gap: '.5rem',
              color: 'black',
            }}
            onClick={() => {
              navigate('/logout');
            }}
          >
            <LogoutIcon />
            Logout
          </Button>
        </Box>
      </Menu>
    </>
  );
};
export { UserName };
