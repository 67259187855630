import PropTypes from "prop-types";
import { React, useEffect } from "react";
import { Form } from "react-bootstrap";
import Errors from "../Errors";

const ParentalStatus = ({
  id,
  readOnly = false,
  value,
  errors = [],
  onChange = () => {},
}) => {
  const handleChange = (val) => onChange(val);
  let controlId = `${id}-parental-status-group`;

  useEffect(() => {
    if (value === undefined) {
      handleChange("");
    }
  }, []);

  return (
    <Form.Group controlId={controlId}>
      <Form.Label>Parental Status</Form.Label>
      <Form.Check
        custom
        type='radio'
        name={controlId}
        readOnly={readOnly}
        label='Parent'
        checked={value === "parent"}
        value='parent'
        id={`${id}-parent`}
        onChange={(e) => handleChange(e.target.value)}
      />
      <Form.Check
        custom
        type='radio'
        name={controlId}
        readOnly={readOnly}
        label='Not a Parent'
        checked={value === "not-parent"}
        value='not-parent'
        id={`${id}-not-parent`}
        onChange={(e) => handleChange(e.target.value)}
      />
      <Form.Check
        custom
        type='radio'
        name={controlId}
        readOnly={readOnly}
        label='Undetermined'
        checked={value === "undetermined"}
        value='undetermined'
        id={`${id}-undetermined`}
        onChange={(e) => handleChange(e.target.value)}
      />
      <Form.Control.Feedback type='invalid'>
        <Errors errors={errors} />
      </Form.Control.Feedback>
    </Form.Group>
  );
};

ParentalStatus.propTypes = {
  id: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  value: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
};

export default ParentalStatus;
