import { useQueryClient } from '@tanstack/react-query';
import {
  getGetPlanQueryKey, useTransitionPlan
} from 'v2/lib/api/ad-management';

export const usePlanActions = () => {
  const transitionPlan = useTransitionPlan();
  const queryClient = useQueryClient();

  const agreePlan = async (planId: string) => {
    await transitionPlan.mutateAsync(
      { id: planId, data: { status: 'agreed' } },
      {
        onSuccess: () => {
          const getPlanQueryKey = getGetPlanQueryKey(planId);
          queryClient.invalidateQueries({ queryKey: getPlanQueryKey });
        },
      }
    );
  };

  return {
    agreePlan,
    processing: transitionPlan.isPending,
  }
}