/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * Inventory API
 * Zitcha Inventory API
 * OpenAPI spec version: 1.0.0
 */
import { useQuery } from '@tanstack/react-query';
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import type {
  GetMediaSpaces200,
  GetMediaSpacesByMediaType200,
  GetMediaSpacesByMediaTypeParams,
  GetMediaSpacesParams,
  GetMediaTypes200,
  GetMediaTypesParams,
  GetRenderableByMediaType200,
  MediaType,
} from '../model';
import { customInstance } from '../../../axios/axiosMainInstance';
import type { ErrorType } from '../../../axios/axiosMainInstance';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Get all media types
 */
export const getMediaTypes = (
  params: GetMediaTypesParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<GetMediaTypes200>({ url: `/inventory/v1/media-types`, method: 'GET', params, signal }, options);
};

export const getGetMediaTypesQueryKey = (params: GetMediaTypesParams) => {
  return [`/inventory/v1/media-types`, ...(params ? [params] : [])] as const;
};

export const getGetMediaTypesQueryOptions = <
  TData = Awaited<ReturnType<typeof getMediaTypes>>,
  TError = ErrorType<void>,
>(
  params: GetMediaTypesParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMediaTypes>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMediaTypesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMediaTypes>>> = ({ signal }) =>
    getMediaTypes(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getMediaTypes>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMediaTypesQueryResult = NonNullable<Awaited<ReturnType<typeof getMediaTypes>>>;
export type GetMediaTypesQueryError = ErrorType<void>;

/**
 * @summary Get all media types
 */
export const useGetMediaTypes = <TData = Awaited<ReturnType<typeof getMediaTypes>>, TError = ErrorType<void>>(
  params: GetMediaTypesParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMediaTypes>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetMediaTypesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get a media type by ID
 */
export const getMediaTypeById = (
  mediaTypeId: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<MediaType>({ url: `/inventory/v1/media-types/${mediaTypeId}`, method: 'GET', signal }, options);
};

export const getGetMediaTypeByIdQueryKey = (mediaTypeId: string) => {
  return [`/inventory/v1/media-types/${mediaTypeId}`] as const;
};

export const getGetMediaTypeByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getMediaTypeById>>,
  TError = ErrorType<void>,
>(
  mediaTypeId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMediaTypeById>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMediaTypeByIdQueryKey(mediaTypeId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMediaTypeById>>> = ({ signal }) =>
    getMediaTypeById(mediaTypeId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!mediaTypeId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getMediaTypeById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMediaTypeByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getMediaTypeById>>>;
export type GetMediaTypeByIdQueryError = ErrorType<void>;

/**
 * @summary Get a media type by ID
 */
export const useGetMediaTypeById = <TData = Awaited<ReturnType<typeof getMediaTypeById>>, TError = ErrorType<void>>(
  mediaTypeId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMediaTypeById>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetMediaTypeByIdQueryOptions(mediaTypeId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get all fields and their properties to render the field.
 */
export const getRenderableByMediaType = (
  mediaTypeId: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<GetRenderableByMediaType200>(
    { url: `/inventory/v1/media-types/${mediaTypeId}/renderable`, method: 'GET', signal },
    options
  );
};

export const getGetRenderableByMediaTypeQueryKey = (mediaTypeId: string) => {
  return [`/inventory/v1/media-types/${mediaTypeId}/renderable`] as const;
};

export const getGetRenderableByMediaTypeQueryOptions = <
  TData = Awaited<ReturnType<typeof getRenderableByMediaType>>,
  TError = ErrorType<void>,
>(
  mediaTypeId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getRenderableByMediaType>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetRenderableByMediaTypeQueryKey(mediaTypeId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getRenderableByMediaType>>> = ({ signal }) =>
    getRenderableByMediaType(mediaTypeId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!mediaTypeId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getRenderableByMediaType>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetRenderableByMediaTypeQueryResult = NonNullable<Awaited<ReturnType<typeof getRenderableByMediaType>>>;
export type GetRenderableByMediaTypeQueryError = ErrorType<void>;

/**
 * @summary Get all fields and their properties to render the field.
 */
export const useGetRenderableByMediaType = <
  TData = Awaited<ReturnType<typeof getRenderableByMediaType>>,
  TError = ErrorType<void>,
>(
  mediaTypeId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getRenderableByMediaType>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetRenderableByMediaTypeQueryOptions(mediaTypeId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get all media spaces of a media type
 */
export const getMediaSpacesByMediaType = (
  mediaTypeId: string,
  params?: GetMediaSpacesByMediaTypeParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<GetMediaSpacesByMediaType200>(
    { url: `/inventory/v1/media-types/${mediaTypeId}/media-spaces`, method: 'GET', params, signal },
    options
  );
};

export const getGetMediaSpacesByMediaTypeQueryKey = (mediaTypeId: string, params?: GetMediaSpacesByMediaTypeParams) => {
  return [`/inventory/v1/media-types/${mediaTypeId}/media-spaces`, ...(params ? [params] : [])] as const;
};

export const getGetMediaSpacesByMediaTypeQueryOptions = <
  TData = Awaited<ReturnType<typeof getMediaSpacesByMediaType>>,
  TError = ErrorType<void>,
>(
  mediaTypeId: string,
  params?: GetMediaSpacesByMediaTypeParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMediaSpacesByMediaType>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMediaSpacesByMediaTypeQueryKey(mediaTypeId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMediaSpacesByMediaType>>> = ({ signal }) =>
    getMediaSpacesByMediaType(mediaTypeId, params, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!mediaTypeId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getMediaSpacesByMediaType>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMediaSpacesByMediaTypeQueryResult = NonNullable<Awaited<ReturnType<typeof getMediaSpacesByMediaType>>>;
export type GetMediaSpacesByMediaTypeQueryError = ErrorType<void>;

/**
 * @summary Get all media spaces of a media type
 */
export const useGetMediaSpacesByMediaType = <
  TData = Awaited<ReturnType<typeof getMediaSpacesByMediaType>>,
  TError = ErrorType<void>,
>(
  mediaTypeId: string,
  params?: GetMediaSpacesByMediaTypeParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMediaSpacesByMediaType>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetMediaSpacesByMediaTypeQueryOptions(mediaTypeId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get all media spaces
 */
export const getMediaSpaces = (
  params: GetMediaSpacesParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<GetMediaSpaces200>(
    { url: `/inventory/v1/media-spaces`, method: 'GET', params, signal },
    options
  );
};

export const getGetMediaSpacesQueryKey = (params: GetMediaSpacesParams) => {
  return [`/inventory/v1/media-spaces`, ...(params ? [params] : [])] as const;
};

export const getGetMediaSpacesQueryOptions = <
  TData = Awaited<ReturnType<typeof getMediaSpaces>>,
  TError = ErrorType<unknown>,
>(
  params: GetMediaSpacesParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMediaSpaces>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMediaSpacesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMediaSpaces>>> = ({ signal }) =>
    getMediaSpaces(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getMediaSpaces>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMediaSpacesQueryResult = NonNullable<Awaited<ReturnType<typeof getMediaSpaces>>>;
export type GetMediaSpacesQueryError = ErrorType<unknown>;

/**
 * @summary Get all media spaces
 */
export const useGetMediaSpaces = <TData = Awaited<ReturnType<typeof getMediaSpaces>>, TError = ErrorType<unknown>>(
  params: GetMediaSpacesParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getMediaSpaces>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetMediaSpacesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
