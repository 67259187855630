import React, { useEffect, useState } from 'react';
import { debounce } from 'debounce';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  refreshSession,
  selectRefreshStatus,
  selectSession,
  selectUser,
  selectUserOrganisationCurrency,
} from '../features/session/sessionSlice';
import { makeTemplateEditRequest, makeTemplateRequest, makeVirtualSpacesRequest } from '../lib/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import $modalStyles from './web_ads_modal.module.scss';
import { faPlus, faSpinner } from '@fortawesome/free-solid-svg-icons';
import $styles from './organisation_web_settings.module.scss';
import Breadcrumbs from './components/Breadcrumbs';
import { getOwnedSpaces } from '../lib/api/ownedSpaces';
import ChannelPagination from './components/ChannelPagination';
import { trackCreateSpace } from '../helpers/segment';
import {
  Button,
  Col,
  Container,
  Form,
  Row,
  Alert,
  DropdownButton,
  ButtonGroup,
  Dropdown,
  Modal,
  ToggleButton,
  ToggleButtonGroup,
  Badge,
  Pagination,
} from 'react-bootstrap';
import { BootstrapDataTable } from '../features/common/data/BootstrapDataTable';
import { currencySymbol } from '../lib/financial';

const breadcrumbItems = [
  {
    path: '/organisation/settings',
    title: 'Settings',
  },
  {
    path: '/organisation/channels',
    title: 'Owned Channels',
  },
  {
    path: '/organisation/websettings',
    title: 'Spaces & Templates',
    active: true,
  },
];

const OrganisationWebSettings = () => {
  const navigate = useNavigate();
  const [formMessages, setFormMessages] = useState({});
  const user = useSelector(selectUser);
  const session = useSelector(selectSession);
  const refreshStatus = useSelector(selectRefreshStatus);
  const hasWebSpaces = session?.channels_enabled;
  const hasWebCategories = session?.webcategories && session.webcategories.length > 0;
  const hasWebTemplates = session?.webtemplates && session.webtemplates.length > 0;
  const dispatch = useDispatch();
  const [organisation] = useState(session.user.organisation);
  const webCategories = session?.webcategories ?? [];
  const [filterByWebSpaceCategory, setFilterByWebSpaceCategory] = useState([]);
  const [filterByWebSpaceFormat, setFilterByWebSpaceFormat] = useState([]);
  const [filterByWebTemplateFormat, setFilterByWebTemplateFormat] = useState([]);
  const [filterByWebSpaceName, setFilterByWebSpaceName] = useState(null);
  const [filterByWebTemplateName, setFilterByWebTemplateName] = useState(null);
  const [tabSelection, setTabSelection] = useState(0);
  const [showVirtualSpaceModal, setVirtualSpaceModal] = useState(false);
  const [showEmbeddedSpaceModal, setEmbeddedSpaceModal] = useState(false);
  const [showTemplateModal, setTemplateModal] = useState(false);
  const [virtalSpacesform, setVirtalSpacesForm] = useState({
    name: '',
    displayLocation: '',
    format: 'banners',
    subformat: 'web',
    notes: '',
    mediafile: '',
  });

  const [templateForm, setTemplateForm] = useState({
    name: '',
    notes: '',
    description: '',
    mediafile: '',
  });

  const clearTemplateForm = () => {
    setTemplateForm({
      name: '',
      notes: '',
      description: '',
      mediafile: '',
    });
    setSelectedTemplateFile(null);
  };

  const [templateFormEnabled, setTemplateFormEnabled] = useState(true);

  const [templateFormEditModal, setTemplateFormEditModal] = useState(false);

  const handleTemplateEditSubmit = (e) => {
    e.preventDefault();

    makeTemplateEditRequest(
      user.active_organisation_id,
      templateForm.id,
      templateForm.name,
      templateForm.description
    ).then(() => {
      setFormMessages({ success: true });
      dispatch(refreshSession());
      handleTemplateEditClose();
    });
  };

  const handleTemplateFormChange = (field) => setTemplateForm({ ...templateForm, ...field });

  const [embeddedSpacesform, setEmbeddedSpacesForm] = useState({
    format: 'banners',
    subformat: 'web',
  });

  const [virtalSpacesformEnabled, setVirtalSpacesFormEnabled] = useState(true);
  const [selectedVirtalSpacesFile, setSelectedVirtalSpacesFile] = useState(null);

  const [selectedTemplateFile, setSelectedTemplateFile] = useState(null);

  const handleVirtalSpacesChange = (field) => setVirtalSpacesForm({ ...virtalSpacesform, ...field });

  const clearTemplateEditForm = () =>
    setTemplateForm({
      name: '',
      notes: '',
      mediafile: '',
    });

  const clearVirtualSpaceRequestForm = () => {
    setVirtalSpacesForm({
      name: '',
      displayLocation: '',
      format: 'banners',
      subformat: 'web',
      notes: '',
      mediafile: '',
    });
    setSelectedVirtalSpacesFile(null);
  };

  const handleTemplateSubmit = (e) => {
    e.preventDefault();

    setFormMessages({});
    setTemplateFormEnabled(false);

    makeTemplateRequest(user.active_organisation.id, templateForm.name, templateForm.notes, selectedTemplateFile)
      .then(() => {
        setTemplateFormEnabled(true);
        setFormMessages({ success: true });
        clearTemplateForm();
      })
      .catch((err) => {
        setTemplateFormEnabled(true);
        if (err.response?.status === 400) {
          setFormMessages({ errors: err?.response?.data?.errors });
        } else {
          setFormMessages({ failed: true });
        }
      });
  };

  const handleVirtualSpacesSubmit = (e) => {
    e.preventDefault();

    setFormMessages({});
    setVirtalSpacesFormEnabled(false);

    makeVirtualSpacesRequest(
      user.active_organisation.id,
      virtalSpacesform.name,
      virtalSpacesform.displayLocation,
      virtalSpacesform.format,
      virtalSpacesform.subformat,
      virtalSpacesform.notes,
      selectedVirtalSpacesFile
    )
      .then((resp) => {
        setVirtalSpacesFormEnabled(true);
        setFormMessages({ success: true });
        trackCreateSpace({ ...virtalSpacesform, placement: 'virtual', id: undefined });
        clearVirtualSpaceRequestForm();
      })
      .catch((err) => {
        setVirtalSpacesFormEnabled(true);
        if (err.response?.status === 422) {
          setFormMessages({ errors: err?.response?.data?.errors });
        } else {
          setFormMessages({ failed: true });
        }
      });
  };

  const [isLoading, setIsLoading] = useState(true);
  const [webspaceData, setWebSpaceData] = useState([]);
  const [activeWebSpacesPage, setActiveWebSpacesPage] = useState(1);
  const [webspacesPaginationItems, setWebspacesPaginationItems] = useState([]);
  const [pageItemCount] = useState(30);
  const currency = useSelector(selectUserOrganisationCurrency);
  const symbol = currencySymbol(currency);

  let filter = {};

  useEffect(() => {
    filter = {};
    if (filterByWebSpaceCategory.length > 0) {
      filter = { ...filter, spaceCategory: filterByWebSpaceCategory };
    }
    if (filterByWebSpaceFormat.length > 0) {
      filter = { ...filter, spaceFormat: filterByWebSpaceFormat };
    }
    if (filterByWebSpaceName && filterByWebSpaceName.length > 0) {
      filter = { ...filter, spaceName: filterByWebSpaceName };
    }

    setIsLoading(true);

    getOwnedSpaces(user.active_organisation.id, filter, pageItemCount, pageItemCount * (activeWebSpacesPage - 1))
      .then((res) => {
        let data = res.data?.data;
        let total = res.data?.total;

        let { allItems: items } = ChannelPagination(pageItemCount, total, activeWebSpacesPage, setActiveWebSpacesPage);
        if (data) {
          setWebspacesPaginationItems(items);
          setWebSpaceData(data);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }, [filterByWebSpaceCategory, filterByWebSpaceFormat, filterByWebSpaceName, activeWebSpacesPage]);

  const webtemplateData =
    session?.webtemplates && session.webtemplates.length > 0
      ? session.webtemplates
          .filter((template) => {
            //filter by format
            if (filterByWebTemplateFormat.length > 0) {
              return filterByWebTemplateFormat.includes(template.format);
            }

            return true;
          })
          .filter((template) => {
            //filter by name
            if (filterByWebTemplateName && filterByWebTemplateName.length > 0) {
              return template.name.toUpperCase().indexOf(filterByWebTemplateName.toUpperCase()) >= 0;
            }

            return true;
          })
      : [];

  const handleFilterByWebSpaceNameValue = debounce((val) => {
    setFilterByWebSpaceName(val);
    setActiveWebSpacesPage(1);
  }, 500);

  const filterByWebSpaceCategories = (category) => {
    if (category === 'all') {
      setFilterByWebSpaceCategory([]);
    } else {
      setFilterByWebSpaceCategory([category]);
    }
  };

  const filterByWebSpaceFormats = (format) => {
    if (format === 'all') {
      setFilterByWebSpaceFormat([]);
    } else {
      setFilterByWebSpaceFormat([format]);
    }
  };

  const filterByWebTemplateFormats = (format) => {
    if (format === 'all') {
      setFilterByWebTemplateFormat([]);
    } else {
      setFilterByWebTemplateFormat([format]);
    }
  };

  const filterByWebSpaceNameValue = (val) => {
    handleFilterByWebSpaceNameValue(val);
  };

  const filterByWebTemplateNameValue = (val) => {
    setFilterByWebTemplateName(val);
  };

  const ucfirst = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const webspaceColumns = [
    {
      dataField: 'rowNo',
      text: '#',
      formatter: (cell, row, rowIndex, extraData) => (
        <div>
          <span>{pageItemCount * (activeWebSpacesPage - 1) + rowIndex + 1}</span>
        </div>
      ),
    },
    {
      dataField: 'name',
      text: 'Name',
      formatter: (cell, row) => {
        return (
          <div className='d-flex justify-content-between align-items-baseline'>
            <a
              href='javascript:void(0)'
              className={$styles.spaceLink}
              onClick={(e) => navigate('/organisation/websettings/edit/' + row.id)}
            >
              {row.name}
            </a>
            {row?.is_active && (
              <Badge className={[$styles.statusBadge, $styles.statusBadgeActive].join(' ')}>Active</Badge>
            )}

            {!row?.is_active && (
              <Badge className={[$styles.statusBadge, $styles.statusBadgeInactive].join(' ')}>Inactive</Badge>
            )}
          </div>
        );
      },
    },
    {
      dataField: 'category',
      text: 'Category',
      formatter: (cell, row) => {
        return row?.owned_category?.name;
      },
    },
    {
      dataField: 'format',
      text: 'Format',
      formatter: ucfirst,
    },
    {
      dataField: 'subformat',
      text: 'Subformat',
      formatter: ucfirst,
    },
    {
      dataField: 'slots',
      text: 'Slots',
    },
    {
      dataField: 'templates',
      text: 'Templates',
      formatter: (cell, row) => {
        return row?.owned_templates?.length;
      },
    },
    {
      dataField: 'placement',
      text: 'Placement',
      formatter: ucfirst,
    },
    {
      dataField: 'baserate',
      text: 'Base Rate',
      formatter: (cell, row) => {
        return `${symbol} ${row?.slot_price?.toFixed(2)}`;
      },
    },
  ];

  const webtemplateColumns = [
    {
      dataField: 'rowNo',
      text: '#',
      formatter: (cell, row, rowIndex, extraData) => (
        <div>
          <span>{rowIndex + 1}</span>
        </div>
      ),
    },
    {
      dataField: 'name',
      text: 'Name',
      formatter: (cell, row, rowIndex, extraData) => (
        <div>
          <a
            href='javascript:void(0)'
            onClick={(e) => {
              setTemplateForm({
                id: row.id,
                name: row.name,
                description: row.description,
              });
              setTemplateFormEditModal(true);
            }}
          >
            {row['name']}
          </a>
        </div>
      ),
    },
    {
      dataField: 'format',
      text: 'Format',
      formatter: ucfirst,
    },
  ];

  const webSpaceNoDataForFilter = () => {
    return <div className={$styles.webSpaceNoDataForFilter}>There are no owned web spaces defined for this filter</div>;
  };

  const handleVirtualSpaceClose = () => {
    setVirtualSpaceModal(false);
    clearVirtualSpaceRequestForm();
    setFormMessages({});
    document.body.click();
  };

  const handleEmbeddedSpaceClose = () => {
    setEmbeddedSpaceModal(false);
    document.body.click();
  };

  const handleTemplateClose = () => {
    setTemplateModal(false);
    clearTemplateForm();
    setFormMessages({});
    document.body.click();
  };

  const handleTemplateEditClose = () => {
    setTemplateFormEditModal(false);
    setFormMessages({});
    clearTemplateEditForm();
  };

  return (
    <>
      <Helmet>
        <title>
          Web Settings for Organisation {organisation?.name ?? session.user?.active_organisation?.name} -
          {process.env.REACT_APP_NAME}
        </title>
      </Helmet>

      <Container className='pt-4'>
        <Breadcrumbs items={breadcrumbItems} />
        <Form>
          <Row className='mb-3'>
            <Col>
              <div className='rounded my-4 d-flex'>
                <div className='d-inline-block'>
                  <h3 className={$styles.topHeading}>Web Channel Settings</h3>
                </div>
              </div>
              <ToggleButtonGroup
                type='radio'
                defaultValue={tabSelection}
                name='options'
                className='mb-2'
                onChange={(v) => setTabSelection(v)}
              >
                <ToggleButton
                  id='tbg-check-1'
                  value={0}
                  className={[$styles.toggleButton, tabSelection === 0 ? 'active' : ''].join(' ')}
                >
                  Spaces
                </ToggleButton>
                <ToggleButton
                  id='tbg-check-2'
                  value={1}
                  className={[$styles.toggleButton, tabSelection === 1 ? 'active' : ''].join(' ')}
                >
                  Templates
                </ToggleButton>
              </ToggleButtonGroup>
            </Col>
          </Row>
          <div style={{ display: tabSelection === 0 ? 'block' : 'none' }}>
            <Row>
              <Col class='md-3'>
                <form className='form-inline' style={{ float: 'right' }} onSubmit={(e) => e.preventDefault()}>
                  {hasWebCategories && (
                    <select
                      className='custom-select mb-2 mr-sm-2'
                      id='inlineFormCustomSelectPref'
                      onChange={(e) => filterByWebSpaceCategories(e.target.value)}
                    >
                      <option value='all' selected>
                        All Categories
                      </option>
                      {webCategories.map((category) => (
                        <option key={category.id} value={category.id}>
                          {category.name}
                        </option>
                      ))}
                    </select>
                  )}
                  <select
                    className='custom-select mb-2 mr-sm-2'
                    id='inlineFormCustomSelectPref'
                    onChange={(e) => filterByWebSpaceFormats(e.target.value)}
                  >
                    <option value='all' selected>
                      All Types
                    </option>
                    <option value='banners'>Banners</option>
                    <option value='product'>Product</option>
                  </select>

                  <div className='input-group mb-2 mr-sm-2'>
                    <input
                      type='text'
                      className='form-control'
                      id='search'
                      placeholder='Search'
                      onChange={(e) => filterByWebSpaceNameValue(e.target.value)}
                    />
                  </div>
                  <DropdownButton
                    as={ButtonGroup}
                    className={`mb-2 ${$styles.newSpaceBtn}`}
                    key={'down'}
                    id={`dropdown-button-drop-right`}
                    drop={'down'}
                    variant='outline-secondary'
                    title={
                      <span>
                        <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon> New Space
                      </span>
                    }
                  >
                    <Dropdown.Item className={'mb-2'} eventKey='1' onClick={(e) => setVirtualSpaceModal(true)}>
                      Virtual Space
                    </Dropdown.Item>
                    <Dropdown.Item className={'mb-2'} eventKey='2' onClick={(e) => setEmbeddedSpaceModal(true)}>
                      Embedded Space
                    </Dropdown.Item>
                  </DropdownButton>
                </form>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className='d-flex my-2 justify-content-between'>
                  {/* <span>Showing 1 - 10 / 214</span>
                    <span className={$styles.markedBaseRateText}>* Marked Base Rates has been inherited from Category</span> */}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                {isLoading && (
                  <div
                    className='text-center d-flex align-items-center justify-content-center'
                    style={{ height: '300px' }}
                  >
                    <FontAwesomeIcon className='fa-spin' style={{ cursor: 'pointer' }} icon={faSpinner} size='4x' />
                  </div>
                )}
                {hasWebSpaces && !isLoading && (
                  <div>
                    <BootstrapDataTable
                      keyField='rowNo'
                      headerWrapperClasses={$styles.webspacesTemplatesHeader}
                      data={webspaceData}
                      columns={webspaceColumns}
                      noDataIndication={webSpaceNoDataForFilter}
                      paginationEnable={false}
                    />
                    <div className='table-responsive mb-2'>
                      <Pagination className='mb-0' style={{ overflowY: 'auto' }}>
                        {webspacesPaginationItems}
                      </Pagination>
                    </div>
                  </div>
                )}
                {!hasWebSpaces && <p>There are no owned web spaces defined.</p>}
              </Col>
            </Row>
            <Row>
              <Col>
                <div>&nbsp;</div>
              </Col>
            </Row>
          </div>
          <div style={{ display: tabSelection === 1 ? 'block' : 'none' }}>
            <Row>
              <Col>
                <form className='form-inline' style={{ float: 'right' }} onSubmit={(e) => e.preventDefault()}>
                  <select
                    className='custom-select mb-2 mr-sm-2'
                    id='inlineFormCustomSelectPref'
                    onChange={(e) => filterByWebTemplateFormats(e.target.value)}
                  >
                    <option value='all' selected>
                      All Formats
                    </option>
                    <option value='banners'>Banners</option>
                    <option value='product'>Product</option>
                  </select>

                  <div className='input-group mb-2 mr-sm-2'>
                    <input
                      type='text'
                      className='form-control'
                      id='search'
                      placeholder='Search'
                      onChange={(e) => filterByWebTemplateNameValue(e.target.value)}
                    />
                  </div>
                  <Button
                    variant='outline-secondary'
                    className={'mb-2'}
                    key={'down'}
                    id={`dropdown-button-drop-right`}
                    onClick={(e) => setTemplateModal(true)}
                  >
                    New Template
                  </Button>
                </form>
              </Col>
            </Row>
            <Row>
              <Col>
                {refreshStatus === 'loading' && (
                  <div
                    className='text-center d-flex align-items-center justify-content-center'
                    style={{ height: '300px' }}
                  >
                    <FontAwesomeIcon className='fa-spin' style={{ cursor: 'pointer' }} icon={faSpinner} size='4x' />
                  </div>
                )}
                {hasWebTemplates && refreshStatus !== 'loading' && (
                  <div>
                    <BootstrapDataTable
                      keyField='rowNo'
                      headerWrapperClasses={$styles.webspacesTemplatesHeader}
                      data={webtemplateData}
                      columns={webtemplateColumns}
                      paginationEnable={true}
                      noDataIndication={webSpaceNoDataForFilter}
                    />
                  </div>
                )}
                {!hasWebTemplates && <p>There are no owned web templates defined.</p>}
              </Col>
            </Row>
          </div>
        </Form>
        <Modal
          className={$modalStyles.modal}
          show={showVirtualSpaceModal}
          onHide={handleVirtualSpaceClose}
          backdrop='static'
          keyboard={false}
          size='lg'
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <div className='text-secondary'>Request a new virtual space</div>
            </Modal.Title>
          </Modal.Header>
          <Form onSubmit={(e) => handleVirtualSpacesSubmit(e)}>
            <Modal.Body>
              <Container>
                {formMessages.success && (
                  <Alert variant={'success'}>Your request for a new virtual space has been sent successfully.</Alert>
                )}
                {formMessages.failed && (
                  <Alert variant={'danger'}>There was an error sending this request, please try again.</Alert>
                )}
                <Form.Group className='mb-3'>
                  <Form.Label>What is the name of this space?</Form.Label>
                  <Form.Control
                    value={virtalSpacesform.name}
                    onChange={(e) => handleVirtalSpacesChange({ name: e.target.value })}
                    disabled={virtalSpacesformEnabled ? false : true}
                    type='text'
                  />
                  {formMessages?.errors?.name && (
                    <div style={{ color: 'red', fontSize: '12px' }}>{formMessages.errors.name}</div>
                  )}
                </Form.Group>
                <Form.Group className='mb-3'>
                  <Form.Label>Where should this ad display?</Form.Label>
                  <Form.Control
                    value={virtalSpacesform.displayLocation}
                    onChange={(e) =>
                      handleVirtalSpacesChange({
                        displayLocation: e.target.value,
                      })
                    }
                    disabled={virtalSpacesformEnabled ? false : true}
                    type='text'
                  />
                  {formMessages?.errors?.displayLocation && (
                    <div style={{ color: 'red', fontSize: '12px' }}>{formMessages.errors.displayLocation}</div>
                  )}
                </Form.Group>
                <Form.Group className='mb-3' controlId='formFile'>
                  <Form.Label>
                    Upload an image of desired look or placement of your space if applicable. (Optional)
                  </Form.Label>
                  <Form.Control
                    type='file'
                    value={virtalSpacesform.mediafile}
                    disabled={virtalSpacesformEnabled ? false : true}
                    onChange={(e) => {
                      handleVirtalSpacesChange({ mediafile: e.target.value });
                      setSelectedVirtalSpacesFile(e.target.files[0]);
                    }}
                  />
                  {formMessages?.errors?.mediafile && (
                    <div style={{ color: 'red', fontSize: '12px' }}>{formMessages.errors.mediafile}</div>
                  )}
                </Form.Group>
                <Form.Group className='mb-3'>
                  <Form.Label>What is the format of the ad in this space?</Form.Label>
                  <div>
                    <Form.Check
                      checked={virtalSpacesform.format === 'banners' ? true : false}
                      onChange={(e) => handleVirtalSpacesChange({ format: e.target.value })}
                      disabled={virtalSpacesformEnabled ? false : true}
                      value={'banners'}
                      inline
                      type='radio'
                      name='format'
                      label='Banners'
                    />
                    <Form.Check
                      checked={virtalSpacesform.format === 'products' ? true : false}
                      value={'products'}
                      onChange={(e) => handleVirtalSpacesChange({ format: e.target.value })}
                      disabled={virtalSpacesformEnabled ? false : true}
                      inline
                      type='radio'
                      name='format'
                      label='Products'
                    />
                  </div>
                  {formMessages?.errors?.format && (
                    <div style={{ color: 'red', fontSize: '12px' }}>{formMessages.errors.format}</div>
                  )}
                </Form.Group>
                <Form.Group className='mb-3'>
                  <Form.Label>What is the subformat of the ad in this space?</Form.Label>
                  <div>
                    <Form.Check
                      checked={virtalSpacesform.subformat === 'web'}
                      onChange={(e) => handleVirtalSpacesChange({ subformat: e.target.name })}
                      disabled={virtalSpacesformEnabled ? false : true}
                      inline
                      type='radio'
                      name='web'
                      label='Web'
                    />
                    <Form.Check
                      checked={virtalSpacesform.subformat === 'search'}
                      onChange={(e) => handleVirtalSpacesChange({ subformat: e.target.name })}
                      disabled={virtalSpacesformEnabled ? false : true}
                      inline
                      type='radio'
                      name='search'
                      label='Search'
                    />
                  </div>
                  {formMessages?.errors?.subformat && (
                    <div style={{ color: 'red', fontSize: '12px' }}>{formMessages.errors.subformat}</div>
                  )}
                </Form.Group>
                <Form.Group className='mb-3'>
                  <Form.Label>Additional Notes (Optional)</Form.Label>
                  <Form.Control
                    as='textarea'
                    value={virtalSpacesform.notes}
                    onChange={(e) => handleVirtalSpacesChange({ notes: e.target.value })}
                    disabled={virtalSpacesformEnabled ? false : true}
                    rows={3}
                  />
                </Form.Group>
              </Container>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' type='submit' disabled={virtalSpacesformEnabled ? false : true}>
                {!virtalSpacesformEnabled && (
                  <FontAwesomeIcon className='fa-spin' icon={faSpinner} size='1x' style={{ marginRight: '5px' }} />
                )}
                Submit
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>

        <Modal
          className={$modalStyles.modal}
          show={showEmbeddedSpaceModal}
          onHide={handleEmbeddedSpaceClose}
          backdrop='static'
          keyboard={false}
          size='lg'
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <div className='text-secondary'>Create a new Embedded Space</div>
            </Modal.Title>
          </Modal.Header>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              navigate(
                '/organisation/websettings/create/embedded/' +
                  embeddedSpacesform.format +
                  '/' +
                  embeddedSpacesform.subformat
              );
            }}
          >
            <Modal.Body>
              <Container>
                <Form.Group className='mb-3'>
                  <Form.Label>What is the format of the ad in this space?</Form.Label>
                  <div>
                    <Form.Check
                      value={'banners'}
                      checked={embeddedSpacesform.format === 'banners'}
                      inline
                      type='radio'
                      name='format'
                      label='Banners'
                      onClick={(e) => setEmbeddedSpacesForm({ ...embeddedSpacesform, format: e.target.value })}
                    />
                    <Form.Check
                      value={'product'}
                      checked={embeddedSpacesform.format === 'product'}
                      inline
                      type='radio'
                      name='format'
                      label='Product'
                      onClick={(e) => setEmbeddedSpacesForm({ ...embeddedSpacesform, format: e.target.value })}
                    />
                  </div>
                </Form.Group>
                <Form.Group className='mb-3'>
                  <Form.Label>What is the subformat of the ad in this space?</Form.Label>
                  <div>
                    <Form.Check
                      checked={embeddedSpacesform.subformat === 'web'}
                      onChange={(e) => setEmbeddedSpacesForm({ ...embeddedSpacesform, subformat: e.target.name })}
                      inline
                      type='radio'
                      name='web'
                      label='Web'
                    />
                    <Form.Check
                      checked={embeddedSpacesform.subformat === 'search'}
                      onChange={(e) => setEmbeddedSpacesForm({ ...embeddedSpacesform, subformat: e.target.name })}
                      inline
                      type='radio'
                      name='search'
                      label='Search'
                    />
                  </div>
                </Form.Group>
              </Container>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' type='submit'>
                Create Space
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>

        <Modal
          className={$modalStyles.modal}
          show={showTemplateModal}
          onHide={handleTemplateClose}
          backdrop='static'
          keyboard={false}
          size='lg'
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <div className='text-secondary'>Request a new Template</div>
            </Modal.Title>
          </Modal.Header>
          <Form onSubmit={(e) => handleTemplateSubmit(e)}>
            {formMessages.success && (
              <Alert variant={'success'}>Your request for a new template has been sent successfully.</Alert>
            )}
            <Modal.Body>
              <Container>
                {formMessages.failed && (
                  <Alert variant={'danger'}>There was an error sending this request, please try again.</Alert>
                )}
                <Form.Group className='mb-3'>
                  <Form.Label>What is the name of this Template?</Form.Label>
                  <Form.Control
                    value={templateForm.name}
                    disabled={templateFormEnabled ? false : true}
                    onChange={(e) =>
                      handleTemplateFormChange({
                        name: e.target.value,
                      })
                    }
                    type='text'
                  />
                  {formMessages?.errors?.name && (
                    <div style={{ color: 'red', fontSize: '12px' }}>{formMessages.errors.name}</div>
                  )}
                </Form.Group>
                <Form.Group className='mb-3'>
                  <Form.Label>What are the key points of this banner?</Form.Label>
                  <Form.Control
                    as='textarea'
                    placeholder='e.g. Horizontal carousel with 4 products'
                    value={templateForm.notes}
                    onChange={(e) =>
                      handleTemplateFormChange({
                        notes: e.target.value,
                      })
                    }
                    disabled={templateFormEnabled ? false : true}
                    rows={3}
                  />
                  {formMessages?.errors?.notes && (
                    <div style={{ color: 'red', fontSize: '12px' }}>{formMessages.errors.notes}</div>
                  )}
                </Form.Group>
                <Form.Group className='mb-3' controlId='formFile'>
                  <Form.Label>Image upload (Optional)</Form.Label>
                  <Form.Control
                    type='file'
                    value={templateForm.mediafile}
                    disabled={templateFormEnabled ? false : true}
                    onChange={(e) => {
                      handleTemplateFormChange({
                        mediafile: e.target.value,
                      });
                      setSelectedTemplateFile(e.target.files[0]);
                    }}
                  />
                  {formMessages?.errors?.mediafile && (
                    <div style={{ color: 'red', fontSize: '12px' }}>{formMessages.errors.mediafile}</div>
                  )}
                </Form.Group>
              </Container>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' type='submit'>
                Submit Request
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
        <Modal
          className={$modalStyles.modal}
          show={templateFormEditModal}
          onHide={handleTemplateEditClose}
          backdrop='static'
          keyboard={false}
          size='lg'
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <div className='text-secondary'>Edit Template</div>
            </Modal.Title>
          </Modal.Header>
          <Form
            onSubmit={(e) => {
              handleTemplateEditSubmit(e);
            }}
          >
            <Modal.Body>
              <Container>
                {formMessages.failed && (
                  <Alert variant={'danger'}>There was an error sending this request, please try again.</Alert>
                )}
                <Form.Group className='mb-3'>
                  <Form.Label>What is the name of this Template?</Form.Label>
                  <Form.Control
                    value={templateForm.name}
                    onChange={(e) => handleTemplateFormChange({ name: e.target.value })}
                    type='text'
                  />
                  {formMessages?.errors?.name && (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '12px',
                        width: '100%',
                      }}
                    >
                      {formMessages.errors.name}
                    </div>
                  )}
                </Form.Group>
                <Form.Group className='mb-3'>
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    value={templateForm.description}
                    onChange={(e) => handleTemplateFormChange({ description: e.target.value })}
                    as='textarea'
                  />
                  {formMessages?.errors?.description && (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '12px',
                        width: '100%',
                      }}
                    >
                      {formMessages.errors.description}
                    </div>
                  )}
                </Form.Group>
              </Container>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' type='submit'>
                Update Template
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </Container>
    </>
  );
};

export default OrganisationWebSettings;
