import React, { useEffect } from 'react';
import styled from 'styled-components';
import { RadioOption } from '../../components/RadioOption';
import { CreativeAssetUploadSection } from './CreativeAssetUploadSection';

import landscape_image_1 from './placeholder_images/landscape_image_1.svg';
import landscape_image_2 from './placeholder_images/landscape_image_2.svg';
import landscape_image_3 from './placeholder_images/landscape_image_3.svg';

import landscape_video_1 from './placeholder_images/landscape_video_1.svg';
import landscape_video_2 from './placeholder_images/landscape_video_2.svg';
import landscape_video_3 from './placeholder_images/landscape_video_3.svg';

import portrait_image_1 from './placeholder_images/portrait_image_1.svg';
import portrait_image_2 from './placeholder_images/portrait_image_2.svg';
import portrait_image_3 from './placeholder_images/portrait_image_3.svg';

import portrait_video_1 from './placeholder_images/portrait_video_1.svg';
import portrait_video_2 from './placeholder_images/portrait_video_2.svg';
import portrait_video_3 from './placeholder_images/portrait_video_3.svg';

const Header = styled.div`
  font-weight: bold;
  font-size: 14px;
  color: black;
  margin-bottom: 16px;
`;

export const ContentTab = ({ ad, controlId, handleChange, readOnly, orderAssets }) => {
  useEffect(() => {
    // Array of SVGs to preload
    const svgsToPreload = [
      landscape_image_1,
      landscape_image_2,
      landscape_image_3,
      landscape_video_1,
      landscape_video_2,
      landscape_video_3,
      portrait_image_1,
      portrait_image_2,
      portrait_image_3,
      portrait_video_1,
      portrait_video_2,
      portrait_video_3,
    ];

    // Preload each SVG
    svgsToPreload.forEach((svg) => {
      const img = new Image();
      img.src = svg;
    });
  }, []);
  return (
    <div style={{ paddingLeft: '24px', paddingRight: '24px' }}>
      <Header>When do you want to upload your creative?</Header>
      <RadioOption
        name={`upload_time_${controlId}`}
        value='now'
        checked={!ad.upload_asset_later}
        onChange={() => handleChange({ upload_asset_later: false })}
        label='Upload Now'
        description='Upload final creative assets for your booked screens.'
        readOnly={readOnly}
      />
      <RadioOption
        name={`upload_time_${controlId}`}
        value='later'
        checked={!!ad.upload_asset_later}
        onChange={() => handleChange({ upload_asset_later: true })}
        label='Upload Later'
        description='Book screens today and come back later to upload creative assets.'
        readOnly={readOnly}
      />
      {!ad.upload_asset_later && (
        <CreativeAssetUploadSection ad={ad} handleChange={handleChange} orderAssets={orderAssets} readOnly={readOnly} />
      )}
    </div>
  );
};
