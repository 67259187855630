import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Col, Form, Row, Button } from "react-bootstrap";
import styled from "styled-components";
import styles from './bespokeFields.module.scss';
import PropTypes from "prop-types";
import { randomCharacters } from "../../../../lib/random";

const Plus = styled(Button)`
    border: 1px solid #33363F;
    color: #33363F;
    background: transparent;
    border-radius: 10px;
    display: inline-block;
    padding: 5px 10px;
    transition: 0.3s;
    &:hover {
        background: #33363F;
        color: #fff;
    }
    &:active,
    &::selection {
        background: #6a7083;
    }
`
const Remove = styled(Button)`
    padding: 4px 5px;
    margin: 5px;
    vertical-align: baseline;
    line-height: 0;
    border-radius: 100%;
    background: red;
    border-color: red;
    &:hover,
    &:focus {
        background: darkred !important;
        border-color: darkred !important;
        color: #fff;
    }
    &:active,
    &::selection {
        background: darkred !important;
        border-color: darkred !important;
    }
    svg {
        font-size: 10px;
    }
`

const ListField = (props) => {

    const {
        field,
        index,
        updateLayoutData,
        layoutData,
        idHelper,
        errors,
    } = props

    const [listArr, setListArr] = useState(Object.keys(field.options ?? []).map(col => field.options[col]) ?? []);
    const [listAmmount, setlistAmmount] = useState(Object.keys(field.options ?? []).length ?? 1);

    const updateListAmmount = (val, keyindex, remove = false, plus = false) => {
        let newListArr = [...listArr];
        if (remove) { newListArr.splice(keyindex, remove) }
        else if (plus) { newListArr.splice(keyindex, remove, val) }
        else {
            newListArr[keyindex] = val;
        }

        let newListObj = {}
        newListArr.map((item) =>
            newListObj = {
                ...newListObj,
                [`item-${item}-${randomCharacters(3)}`]: item
            }
        )
        setListArr(newListArr)
        setlistAmmount(newListArr.length);
        updateLayoutData({ options: newListObj }, index)
    }

    return (
        <Row>
            <Col md={6}>
                <Form.Group className={styles.group_style} controlId={field.type + index + '-Name'}>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                        type='text'
                        value={layoutData[index]?.title ?? ""}
                        onChange={(e) => updateLayoutData({ id: idHelper(e.target.value), title: e.target.value }, index)}
                        isInvalid={!layoutData[index]?.title && errors.length > 0}
                    />
                </Form.Group>
                <Form.Group className={styles.group_style} controlId={field.type + index + '-Default'}>
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                        type='text'
                        value={layoutData[index]?.notice ?? ""}
                        onChange={(e) => updateLayoutData({ notice: e.target.value }, index)}
                    />
                </Form.Group>
                <Form.Group className={styles.group_style} controlId={field.type + index + '-Info'}>
                    <Form.Label>Info</Form.Label>
                    <Form.Control
                        type='text'
                        value={layoutData[index]?.info ?? ""}
                        onChange={(e) => updateLayoutData({ info: e.target.value }, index)}
                    />
                </Form.Group>
            </Col>
            <Col md={6}>
                <List ammount={listAmmount} updateListAmmount={updateListAmmount} listArr={listArr} {...props} />
                <div className={styles.addition + " d-block text-center"}>
                    <Plus onClick={() => updateListAmmount("", listArr.length + 1, false, true)}>
                        <FontAwesomeIcon icon={faPlus} />
                    </Plus>
                </div>
            </Col>
        </Row>
    );
}

const List = ({
    ammount = 0,
    index,
    field,
    layoutData,
    updateLayoutData,
    idHelper,
    updateListAmmount,
    listArr,
}) => {
    let inputs = [];
    for (let i = 1; i <= (ammount); i++) {

        inputs.push(
            <Form.Group className={styles.list_items} controlId={field.type + "-" + index + '-color' + i}>
                <Form.Control
                    type='text'
                    placeholder={"Item "+i}
                    id={`list-${index}-1-${i}`}
                    value={listArr?.[i-1] ?? ""}
                    onChange={(e) => updateListAmmount(e.target.value, i-1)}
                />
                <Remove onClick={() => updateListAmmount(null, i-1, true )}>
                    <FontAwesomeIcon icon={faTimes} />
                </Remove>
            </Form.Group>
        )
    }

    return inputs
}

ListField.propTypes = {
    field: PropTypes.object,
    index: PropTypes.number,
    updateLayoutData: PropTypes.func,
    layoutData: PropTypes.func,
    idHelper: PropTypes.func,
    errors: PropTypes.array,
}

export default ListField