import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import { selectOwnerOrganisation } from '../../../features/order_form/orderFormSlice';

export const useEarliestStartDateTime = (overrides: { organisation?: object | null } = {}) => {
  const organisation = useSelector(selectOwnerOrganisation) ?? overrides.organisation;
  const retailerTimezone = organisation?.settings?.['timezone'] ?? null;
  const minimumReviewTimeInHours = organisation?.settings?.['minimumReviewTime'] ?? null;
  const openingTimeInRetailerTimezone = organisation?.settings?.['openingTime'] ?? null;
  const closingTimeInRetailerTimezone = organisation?.settings?.['closingTime'] ?? null;

  const validStartDateTime = getEarliestStartDate(
    retailerTimezone,
    minimumReviewTimeInHours,
    openingTimeInRetailerTimezone,
    closingTimeInRetailerTimezone
  );

  return validStartDateTime;
};

export const getEarliestStartDate = (
  retailerTimezone: string,
  minimumReviewTimeInHours: number,
  openingTimeInRetailerTimezone: string,
  closingTimeInRetailerTimezone: string,
  browserDateTime: moment.Moment = moment()
): moment.Moment => {
  if (retailerTimezone && openingTimeInRetailerTimezone && closingTimeInRetailerTimezone && minimumReviewTimeInHours) {
    //This is to get the current date time in the timezone, otherwise comparisons won't work
    const nowInRetailerTimezone = browserDateTime.tz(retailerTimezone);

    const openingTimeMoment = moment.tz(
      `${nowInRetailerTimezone.format('YYYY-MM-DD')} ${openingTimeInRetailerTimezone}`,
      'YYYY-MM-DD hh:mm a',
      retailerTimezone
    );
    const closingTimeMoment = moment.tz(
      `${nowInRetailerTimezone.format('YYYY-MM-DD')} ${closingTimeInRetailerTimezone}`,
      'YYYY-MM-DD hh:mm a',
      retailerTimezone
    );

    const nowWithMRT = nowInRetailerTimezone.clone().add(minimumReviewTimeInHours, 'hours');

    // Before opening time
    if (nowInRetailerTimezone.isBefore(openingTimeMoment)) {
      return openingTimeMoment.clone().add(minimumReviewTimeInHours, 'hours');
    }

    // during work hours
    if (nowWithMRT.isSameOrAfter(openingTimeMoment) && nowWithMRT.isSameOrBefore(closingTimeMoment)) {
      return nowWithMRT;
    }

    // After work hours
    if (nowWithMRT.isAfter(closingTimeMoment)) {
      if (nowInRetailerTimezone.isBefore(closingTimeMoment)) {
        const diff = nowWithMRT.diff(closingTimeMoment, 'minutes');
        return openingTimeMoment.clone().add(1, 'days').add(diff, 'minutes');
      }
      return openingTimeMoment.clone().add(1, 'days').add(minimumReviewTimeInHours, 'hours');
    }
  }

  return browserDateTime;
};
