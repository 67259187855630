import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import ConnectedOrderFormChrome from '../features/order_form/ConnectedOrderFormChrome';
import OrderAdForm from '../features/order_form/containers/OrderAdForm';
import {
  availableAdFormats,
  availableWebSpaces,
  orderFormEditable,
  selectOrder,
  selectOtherOrganisation,
  updateOrderFormOrganisations,
} from '../features/order_form/orderFormSlice';

const CreateOrder = () => {
  const dispatch = useDispatch();
  const order = useSelector(selectOrder);
  const selectedOrg = useSelector(selectOtherOrganisation);
  useEffect(() => {
    dispatch(orderFormEditable());
    dispatch(updateOrderFormOrganisations());
    dispatch(availableAdFormats());
    dispatch(availableWebSpaces());
  }, [dispatch, order?.owner_id, order?.supplier_id]);

  if (!selectedOrg) {
    return <Navigate to='/' replace />;
  }

  return (
    <>
      <Helmet>
        <title>Create Order - {process.env.REACT_APP_NAME}</title>
      </Helmet>

      <Container fluid className='pt-4'>
        <Row>
          <Col>
            <ConnectedOrderFormChrome>
              <OrderAdForm />
            </ConnectedOrderFormChrome>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CreateOrder;
