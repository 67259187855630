import React from 'react';
import { Box, Typography } from '@zitcha/component-library';

interface DetailItemProps {
  label: string;
  value: React.ReactNode;
}

export const DetailItem: React.FC<DetailItemProps> = ({ label, value }) => (
  <Box display='flex' alignItems='center' gap={1}>
    <Box>
      <Typography variant='overline'>{label}</Typography>
    </Box>
    {value}
  </Box>
);
