import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import React from 'react'
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap'
import Errors from '../Errors'

const Checkbox = ({
    header,
    controlId,
    readOnly = false,
    required = false,
    value = [],
    onChange = () => { },
    info = null,
    notice = null,
    errors = [],
    options,
}) => {
    return (
        <Form.Group controlId={controlId}>
            <Form.Label className='font-weight-bold'>
                {header} {required && '*'}
            </Form.Label>
            {(info || notice) && <span style={{ "marginLeft": "5px" }}>
                {info && (
                    <OverlayTrigger
                        placement='left'
                        overlay={
                            <Tooltip id={`${controlId}-info-popover`}>{info}</Tooltip>
                        }
                    >
                        <FontAwesomeIcon icon={faQuestionCircle} size='sm' />
                    </OverlayTrigger>
                )}
                {notice && (
                    <Form.Control.Feedback className='d-block text-body mb-2'>
                        {notice}
                    </Form.Control.Feedback>
                )}
            </span>}
            {Object.keys(options).map((opt) => (
                <Form.Check
                    type='checkbox'
                    name={controlId}
                    disabled={readOnly}
                    id={opt}
                    key={opt}
                    label={options[opt]}
                    checked={value.includes(opt)}
                    isInvalid={errors.length > 0}
                    onChange={e => {
                        if (!readOnly) {
                            const newValue = value.filter(f => f !== opt)
                            if (e.target.checked) {
                                onChange([...newValue, opt])
                            } else {
                                onChange([...newValue])
                            }
                        }
                    }}
                    required={required}
                    value={opt}
                />
            ))}
            <Form.Control.Feedback type='invalid'>
                <Errors errors={errors} />
            </Form.Control.Feedback>
        </Form.Group>
    )
}

Checkbox.propTypes = {
    header: PropTypes.string.isRequired,
    controlId: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    value: PropTypes.string,
    errors: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func,
    options: PropTypes.object.isRequired,
}

export default Checkbox
