import ControlsContainer from "../../controls/ControlsContainer";
import Dropdown from "../../controls/Dropdown";
import Search from "../../controls/Search";
import React from "react";
import styled from "styled-components";
import Sadge from "../../throbbers/Sadge";

const BodyContainer = styled.div`
  line-height: 10rem;
  font-size: 0.8rem;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const TextContainer = styled.div`
  margin-left: 20px;
`;

type FailMessageProps = {
    label: string,
};

const FailMessage = ({label}: FailMessageProps) =>
    <BodyContainer>
        <Sadge/> <TextContainer>{label}</TextContainer>
    </BodyContainer>

export const NoProductSets = () =>
    <div>
        <ControlsContainer>
            <Dropdown
                label='Product Set'
                width='15rem'
                value={''}
                options={[{value: '', label: 'None'}]}
                onChange={e => { /* do nothing */ }}
            />
            <Search
                value=''
                onChange={e => { /* do nothing */ }}
                disabled={true}
            />
        </ControlsContainer>
        <FailMessage label='No product sets available.'/>
    </div>


export const FailedProducts = () => <FailMessage label='Error loading products.'/>
export const NoProducts = () => <FailMessage label='No products found.'/>
