import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import styles from './filesquare.module.scss'

const AddFileSquare = ({ fileRef }) => {
    return (
        <button
            className={`m-3 rounded bg-transparent ${styles.container}`}
            onClick={e => {
                e.preventDefault()
                fileRef.current.click()
            }}
        >
            <FontAwesomeIcon icon={faPlus} size='lg' style={{ color: '#e3e6f0' }} />
        </button>
    )
}

AddFileSquare.propTypes = {
    fileRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    ]),
}

export default AddFileSquare
